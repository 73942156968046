/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { regions } from '../../../utils';

export default overrideProps => ({
  accessor: 'country',
  resizable: false,
  headerClassName: 'uikit-table__cell--first-cell',
  Header: (
    <FormattedMessage id="table.country">
      {txt => <p className="cell-inner country">{txt} {SortIcon}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <FormattedMessage id="table.total">{txt => <span className="cell-inner">{txt}</span>}</FormattedMessage>
  ),
  Cell: props => (
    <div className="cell-inner" title={regions.getRegion(props.value)}>
      <i className={`flag-icon flag-icon-${props.value.toLowerCase()}`} />
      {regions.getRegion(props.value)}
    </div>
  ),
  ...overrideProps,
});
