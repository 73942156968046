import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { stats as statsUtils } from 'utils';
import columns from 'components/tables/columns';

export const getDisplayedColumns = ({ totalsData, columnsConfig }) => {
  const totals = statsUtils.fromServerData(totalsData);
  const displayedColumns = [
    {
      Header: '',
      columns: [
        columns.date({ minWidth: 120 }, totals),
        columns.newAudiencePageViews({ show: columnsConfig.outPageViews }, totals),
        columns.widgetViews({ width: 150, show: columnsConfig.widgetViews }, totals),
      ],
    },
    {
      Header: <FormattedMessage id="table.outgoingTraffic">{txt => <span>{txt}</span>}</FormattedMessage>,
      columns: [
        columns.outAdShows({ minWidth: 80, maxWidth: 100, show: true }, totals),
        columns.outClicks({
          maxWidth: 100,
          show: columnsConfig.outClicks,
          className: 'uikit-table__cell--bold',
        }, totals, true),
        columns.outCtr({ maxWidth: 80, show: columnsConfig.outCtr }, totals),
        columns.wctr({
          maxWidth: 90,
          show: columnsConfig.wctr,
          className: 'uikit-table__cell--bold',
        }, totals),
      ],
    },
    {
      Header: <FormattedMessage id="table.incomingNewTraffic">{txt => <span>{txt}</span>}</FormattedMessage>,
      columns: [
        columns.inClicks({
          maxWidth: 200,
          minWidth: 150,
          show: columnsConfig.inClicks,
          className: 'uikit-table__cell--bold',
        }, totals, true),
      ],
    },
    {
      Header: '',
      columns: [
        columns.exchangeRatio({ maxWidth: 100, show: columnsConfig.exchangeRatio }, totals),
      ],
    },
  ];

  return displayedColumns;
};

export default { getDisplayedColumns };
