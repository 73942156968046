/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';
import PropTypes from 'prop-types';
import { differenceWith, isEqual } from 'lodash';
import { ls } from 'utils';
import { usePrevious, useUpdateEffect, useMount } from 'react-use';
import { general, datetime, category, tooltip } from './chartOptions';
import ChartLegend from './ChartLegend';
import ChartView from './ChartView';
import './Charts.scss';

const Charts = ({ pageType, legend, groups, id, statsData, timeframe, byHour }) => {
  const [state, setState] = React.useState({
    keyDisabledLegends: null,
    data: null,
    legendDisabled: null,
    charts: null,
  });
  const { keyDisabledLegends, data, legendDisabled, charts } = state;
  const prevProps = usePrevious({ statsData, timeframe });

  const setKeyDisabledLegends = (id = 'default') => `legendDisabled${id.replace(/./, c => c.toUpperCase())}`;

  const setLegendDisabled = (key, legend) => {
    const data = ls.get(key, []);

    if (data.length !== legend.length) {
      legend.map((_, index) => data[index] = data[index] || false);
      ls.set(key, data);
    }

    return data;
  };

  const createOptions = (chartData, data, keyDisabledLegends) => {
    let options = {};
    // NOTE: Типы графиков в highcharts: linear, logarithmic, datetime or category
    // NOTE: Типы данных (pageType): date, country, device, top, widget
    switch (chartData.type) {
      case 'date':
      case 'hour':
        options = {
          ...general.get(chartData),
          ...datetime.get(chartData),
          ...tooltip.get(chartData, data, keyDisabledLegends),
        };
        break;
      case 'country':
      case 'device':
      case 'referer':
      case 'widget':
        options = {
          ...general.get(chartData),
          ...category.get(chartData),
          ...tooltip.get(chartData, data, keyDisabledLegends),
        };
        break;
      case 'top':
      default:
        break;
    }
    return options;
  };

  const legendClickHandler = (idx) => {
    // По клику вкл/выкл график и сохраняем список включенных в localStorage
    const newLegend = legendDisabled.map((item, index) => {
      if (index === idx) return !item;
      return item;
    });

    ls.set(keyDisabledLegends, newLegend);

    setState(prevState => ({
      ...prevState,
      legendDisabled: newLegend,
    }));
  };

  const prepareData = React.useCallback(
    () => {
      const dataField = timeframe;
      const data = [];
      const charts = [];

      if (dataField && dataField.length > 0) {
        // Собираем супер массив данных для удобной работы с графиками
        legend.map((param) => {
          const temp = [];
          const type = pageType === 'date' && byHour ? 'hour' : pageType;

          dataField.map((item) => {
            temp.push({
              keyValue: item[type],
              value: item[param.field],
            });
            return temp;
          });
          const obj = { ...param,
            type,
            data: temp };
          data.push(obj);
          return true;
        });

        // Нужно в options передать не только данные для конкретного графика, но и для всех сразу,
        // чтобы в tooltip для каждого графика можно было вывести данные по всем.
        data.map((item) => {
          const options = createOptions(item, data, keyDisabledLegends);
          charts.push(options);
          return charts;
        });

        setState(prevState => ({
          ...prevState,
          data,
          charts,
        }));
      }
    },
    [timeframe, byHour, keyDisabledLegends, legend, pageType],
  );

  useMount(() => {
    prepareData();
  });

  React.useEffect(() => {
    // При первой установке компонента формируем ключ для данных о легенде в памяти
    // и запрашиваем из localStorage эти данные.
    if (legend.length && !keyDisabledLegends && !legendDisabled) {
      const keyDisabledLegends = setKeyDisabledLegends(id);
      const legendDisabled = setLegendDisabled(keyDisabledLegends, legend);

      setState(prevState => ({
        ...prevState,
        keyDisabledLegends,
        legendDisabled,
      }));
    }
  }, [id, keyDisabledLegends, legend, legendDisabled]);

  useUpdateEffect(() => {
    const checkedArrStats = differenceWith(statsData, prevProps.statsData, isEqual);
    const checkedArrTimeframe = differenceWith(timeframe, prevProps.timeframe, isEqual);

    const itemsLength = prevProps.timeframe.length || prevProps.statsData.length || 0;
    const itemsLengthNew = timeframe.length || statsData.length || 0;

    if (
      checkedArrStats.length > 0 ||
      checkedArrTimeframe.length > 0 ||
      itemsLength !== itemsLengthNew
    ) { // поменялись данные в отчете
      prepareData();
    }
  }, [timeframe, statsData]);

  return (
    <div className="row chart">
      <div className="chart__container col-md-10 col-xs-12">
        {
          pageType !== 'top' && charts && (
            <ChartView
              data={data}
              charts={charts}
              legendDisabled={legendDisabled}
            />
          )
        }
      </div>
      <div className="col-md-2 col-xs-12">
        {
          pageType !== 'top' && charts && (
            <ChartLegend
              legend={legend}
              groups={groups}
              clickHandler={legendClickHandler}
              legendDisabled={legendDisabled}
            />
          )
        }
      </div>
    </div>
  );
};

export default Charts;

Charts.propTypes = {
  statsData: PropTypes.array,
  timeframe: PropTypes.array,
  byHour: PropTypes.bool,
  pageType: PropTypes.string.isRequired,
  groups: PropTypes.array,
  legend: PropTypes.array,
  id: PropTypes.string.isRequired,
};

Charts.defaultProps = {
  statsData: [],
  timeframe: [],
  byHour: false,
  groups: [{}],
  legend: [],
};
