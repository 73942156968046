import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ajax, cookie } from 'app/utils';
import { AccountInfoState, AccountInfoApiResponse, SelectSiteApiResponse } from 'app/ts/interfaces';

const isAdminFromCookie = Boolean(cookie.getIsCookie('is_admin'));

const initialState: AccountInfoState = {
  admin: null,
  sitesList: [],
  currentSite: undefined,
  currentSiteName: null,
  totalRevenueBalance: 0,
  VAT: 0,
  revenueBalance: 0,
  newAudienceBalance: 0,
  lurkMode: false,
  lurkCodeDomain: null,
  isConstructorAllowed: false,
  isConstructorDevModeAllowed: false,
  adsTxtLines: null,
};

export const fetchInfo = createAsyncThunk(
  'account/fetchInfo',
  async (): Promise<AccountInfoState | any> => {
    const response = await ajax.get('/account_info');
    const data = response.data as AccountInfoApiResponse;
    if (data) {
      const {
        admin,
        sites_list: sitesList,
        total_revenue_balance: totalRevenueBalance,
        vat: VAT,
        current_site: {
          id: currentSite,
          name: currentSiteName,
          revenue_balance: revenueBalance,
          newaudience_balance: newAudienceBalance,
          settings: {
            lurk_mode: lurkMode,
            lurk_code_domain: lurkCodeDomain,
            constructor_allowed: isConstructorAllowed,
            constructor_dev_mode_allowed: isConstructorDevModeAllowed,
            adstxt_lines: adsTxtLines,
          },
        },
      } = data;

      return {
        admin: admin || isAdminFromCookie || null,
        sitesList,
        currentSite,
        currentSiteName,
        totalRevenueBalance,
        VAT,
        revenueBalance,
        newAudienceBalance,
        lurkMode: lurkMode || false, // необязательное поле но проверка на него нужна
        lurkCodeDomain,
        isConstructorAllowed: isConstructorAllowed || isAdminFromCookie,
        isConstructorDevModeAllowed: isConstructorDevModeAllowed || isAdminFromCookie,
        adsTxtLines: adsTxtLines || null,
      } as AccountInfoState;
    }

    return null;
  },
);

export const changeCurrentSite = createAsyncThunk(
  'account/changeCurrentSite',
  async (id: number): Promise<AccountInfoState | any> => {
    const params = { id };
    const { data } = await ajax.get('/select_site', { params });

    if (data) {
      const {
        id: currentSite,
        name: currentSiteName,
        revenue_balance: revenueBalance,
        newaudience_balance: newAudienceBalance,
        settings: {
          constructor_allowed: isConstructorAllowed,
          constructor_dev_mode_allowed: isConstructorDevModeAllowed,
          lurk_mode: lurkMode,
          lurk_code_domain: lurkCodeDomain,
          adstxt_lines: adsTxtLines,
        },
      } = data as SelectSiteApiResponse;

      sessionStorage.removeItem('filterContentDataFilters');
      sessionStorage.removeItem('filterSocialDataFilters');

      return {
        currentSite,
        currentSiteName,
        revenueBalance,
        newAudienceBalance,
        lurkMode: lurkMode || false,
        lurkCodeDomain,
        isConstructorAllowed: isConstructorAllowed || isAdminFromCookie,
        isConstructorDevModeAllowed: isConstructorDevModeAllowed || isAdminFromCookie,
        adsTxtLines: adsTxtLines || null,
      } as AccountInfoState;
    }

    return null;
  },
);

const accountInfoSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setInfo: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(changeCurrentSite.fulfilled, (state, action) => ({ ...state, ...action.payload }));
  },
});

export const { setInfo } = accountInfoSlice.actions;

export default accountInfoSlice.reducer;
