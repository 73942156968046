/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';

export default (overrideProps, totals) => ({
  accessor: 'readingTime',
  resizable: false,
  Header: (
    <FormattedMessage id="table.readingTime">
      {txt => (
        <p className="cell-inner">
          {SortIcon} {txt[0].split(' ').slice(0, 1).join(' ')}<br />{txt[0].split(' ').slice(1).join(' ')}
        </p>
      )}
    </FormattedMessage>
  ),
  Footer: (
    <span className="cell-inner" title={formatUtils.time(totals.readingTime) || '0:00'}>
      {totals.readingTime ? formatUtils.time(totals.readingTime) : '0:00'}
    </span>
  ),
  Cell: props => (
    <span className="cell-inner" title={formatUtils.time(props.value) || '0:00'}>
      {props.value ? formatUtils.time(props.value) : '0:00'}
    </span>
  ),
  ...overrideProps,
});
