/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { Link } from 'react-router-dom';

export default overrideProps => ({
  accessor: 'author',
  resizable: false,
  Header: (
    <FormattedMessage id="table.author">
      {txt => <p className="cell-inner">{txt} {SortIcon}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <FormattedMessage id="table.total">{txt => <span className="cell-inner">{txt}</span>}</FormattedMessage>
  ),
  Cell: props => (
    <span className="cell-inner" title={props.value}>
      <Link to={`/reports/editorial/content?author=${props.value}&author_id=${props.original.authorId}`}>
        {props.value}
      </Link>
    </span>
  ),
  ...overrideProps,
});
