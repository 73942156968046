/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';

export default overrideProps => ({
  accessor: 'title',
  resizable: false,
  Header: (
    <FormattedMessage id="table.title">{txt => <p className="cell-inner">{txt} {SortIcon}</p>}</FormattedMessage>
  ),
  Cell: props => <div className="cell-inner" title={props.value}><span>{props.value}</span></div>,
  ...overrideProps,
});
