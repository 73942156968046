import { flow } from 'lodash/fp';
import numeral from 'numeral';
import { tz } from 'app/utils';

const toInteger = (value: string): number => +value;

const toFloat = (value: string): number => parseFloat(parseFloat(value).toFixed(2));

export const money: (value: string) => string = flow(
  toFloat,
  (value: number): string => numeral(value).format('0,0.0[0] $'),
);

export const percentage: (value: string) => string = flow(
  toFloat,
  (value: number): string => numeral(value / 100).format('0.[00]%'),
);

export const integer: (value: string) => string = flow(
  toInteger,
  (value: number): string => numeral(value).format(value > 9999 ? '0,0' : '0'),
);

// убираем пробел между тысячами и единицами, если целая часть числа четырехзначная
export const trimNumbers = (val: number | string): string => {
  const str: string = String(val);
  const spacesRegEx: RegExp = /\s/g;
  const firstIntegerRegEx: RegExp = /[\d|\s]+/;
  const number: string = str.match(firstIntegerRegEx)[0].replace(spacesRegEx, '');
  if (+number > 9999 || !number) return str;
  return str.replace(firstIntegerRegEx, number);
};

export const mathRound = (number: number, floatP: number, plain?: boolean): number | string => {
  const floatParam: number = 10 ** floatP; // особенность js для правильного округления
  const num: number = (Math.round(parseFloat(String(number)) * floatParam) / floatParam);

  if (plain) {
    return num;
  }

  return trimNumbers(
    num.toLocaleString('ru-RU').replace(/\s/g, String.fromCharCode(8201)), // заменяем пробелы на тонкие
  );
};

export const float = (value: number, zerosCount: number = 1): string =>
  numeral(+value).format(value % 1 === 0 ? '0' : `0.${'0'.repeat(zerosCount)}`);

export const time = (value: number): string => tz.convertToMinSecString(mathRound(value, 2));

export default { money, percentage, integer, trimNumbers, mathRound, float, time };
