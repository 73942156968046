import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, TextField } from 'uikit';
import { useHistory, NavLink } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useMount } from 'react-use';
import config from 'config';
import { cookie, ajax, showAlert } from 'utils';
import { cloneDeep } from 'lodash';
import helper from '../helper';
import Header from '../components/constructor/Header/Header';
import Sidebar from '../components/constructor/Sidebar/Sidebar';
import Workspace from '../components/constructor/Workspace/Workspace';
import builder from '../builder';
import './ClientConstructor.scss';

const ClientConstructor = ({ match, isConstructorAllowed, isConstructorDevModeAllowed }) => {
  const history = useHistory();
  const intl = useIntl();

  const [state, setState] = React.useState({
    widget: {
      id: null,
      name: null,
      settings: {
        config: {},
        cells: [],
        cols: null,
        pattern: null,
      },
      tpl: {
        preload_js: '',
        css: '',
        footer: '',
        header: '',
        item: '',
        row_footer: '',
        row_header: '',
      },
    },
  });
  const { widget, widget: { settings: { cells, cols }, name } } = state;

  const parseResponse = ({ data: { settings: resSettings, template: resTemplate, ae_adp_id: id, name: resName } }) => {
    const settings = { ...resSettings };
    const template = { ...resTemplate };

    // Если стили записаны в <header>, то забираем их оттуда, вставляем в CSS и удаляем из <header>
    const div = document.createElement('div');
    div.innerHTML = template.header;
    if (div.querySelector('style')) {
      const styles = div.querySelector('style').innerHTML;
      template.css += styles;
      template.header = template.header.replace(/<style>([^<]*)<\/style>/g, '');
    }

    return {
      id,
      name: resName,
      settings: {
        cells: settings.cells,
        cols: settings.cols,
        config: settings.config,
        pattern: settings.pattern || 'default',
      },
      tpl: {
        preload_js: template.preload_js || '',
        css: template.css,
        footer: template.footer,
        header: template.header,
        item: template.item,
        row_footer: template.row_footer,
        row_header: template.row_header,
        config: (() => {
          delete settings.cells;
          delete settings.cols;
          delete settings.config;
          delete settings.pattern;
          delete settings.idx;
          delete settings.type;
          delete settings.ae;
          delete settings.na;
          delete settings.rv;
          let str = '';
          Object.keys(settings).map((key) => {
            str += `${key}:${settings[key]}\n`;
            return false;
          });
          return str;
        })(),
      },
    };
  };

  const updateWidget = (widgetState, callback) => {
    // Хак для параметров, для которых нет формы, но записываются в config в режиме разработчика
    const template = widgetState.tpl;
    const paramsToSettings = widgetState.tpl.config;
    const { settings } = widgetState;
    delete template.config;

    paramsToSettings.split('\n').map((item) => {
      if (item.length > 0) {
        const [key, value] = item.split(/:(.+)/, 2);
        let validateValue;
        if (value === 'true') {
          validateValue = true;
        } else if (value === 'false') {
          validateValue = false;
        } else {
          validateValue = (value || '').trim();
        }
        settings[key.trim()] = validateValue;
      }
      return false;
    });

    const params = {
      id: widgetState.id,
      name: widgetState.name,
      template,
      settings,
    };

    ajax.put(`/widgets/${match.params.id}`, params).then(() => callback && callback());
  };

  const saveWidget = () => {
    const { widget: widgetState } = state;

    updateWidget(widgetState, () => {
      showAlert(intl.messages['constructor.msg.widget-saved']);
      history.push('/constructor');
    });
  };

  const handleChangeCells = (event) => {
    const { target } = event;
    const { value } = target;
    const { dataset: { index } } = target;

    const updatedWidget = cloneDeep(widget);
    updatedWidget.settings.cells[index] = value;

    setState({ widget: updatedWidget });
  };

  const handleSubmit = () => {
    // Добавляем класс "grf-list--rv" в "grf-list", если все тизеры рекламные
    const countRV = cells.filter(item => item === 'rv').length;
    // @todo: что-то намутил тут похоже внутри условий. Нужно проверить.
    const updatedWidget = cloneDeep(widget);
    updatedWidget.tpl.header = countRV === cells.length
      ? helper.replaceClassInBrick(widget.tpl.header, 'grf-list', 'grf-list grf-list--rv')
      : helper.replaceClassInBrick(widget.tpl.header, 'grf-list', 'grf-list');

    // Записываем массив с типами ячеек в исходный объект
    setState({ widget: updatedWidget });
    saveWidget();
  };

  const handleChangeName = (event) => {
    const { value } = event.target;

    if (value) {
      const updatedWidget = cloneDeep(widget);
      const newWidgetState = { ...updatedWidget, name: value };

      setState({ widget: newWidgetState });
    }
  };

  useMount(() => {
    if (!isConstructorAllowed && !cookie.getIsCookie('is_admin')) {
      history.push('/dashboard');
      return;
    }

    ajax.get(`/widgets/${match.params.id}`)
      .then(({ data }) => {
        if (data) {
          const newState = parseResponse(data);
          setState({ widget: newState });
        }
      });
  });

  const workspaceContent = builder.showBlock(widget);
  const userForm = () => (
    <form className="form form--widget" onSubmit={handleSubmit}>
      <div className="form__control">
        <TextField
          label={<FormattedMessage id="constructor.widget-name" />}
          type="text"
          defaultValue={name}
          elementId="name"
          name="name"
          onChange={handleChangeName}
          required="required"
        />
        <span />
      </div>

      <div className="form__control form__subtitle">
        <FormattedMessage id="constructor.cells-type" />
      </div>
      {cells.map((item, key) => (
        <div key={`form__control--${key}`} className="form__control" data-colwidth={cols}>
          <div className="form__control--wrapper">
            <label key={`form__label--${key}`} htmlFor={`cells-${key}`} className="form__label" data-element="select">
              №{key + 1}
            </label>
            <select
              key={`form__select--${key}`}
              data-index={key}
              className="form__select"
              value={item}
              name="cellType"
              id={`cells-${key}`}
              onChange={handleChangeCells}
            >
              {
                  config.teaserTypes.map(type => (
                    <option
                      key={type.toLowerCase()}
                      value={type.toLowerCase()}
                    >
                      {type.toUpperCase()}
                    </option>
                  ))
                }
            </select>
          </div>
        </div>
      ))}
    </form>
  );

  return (
    widget.id && (
    <div className="client-constructor">
      <Header
        saveWidget={saveWidget}
      />
      <header className="client-constructor__header">
        <Button
          component={NavLink}
          format="icon"
          kind="secondary"
          to="/constructor"
        >
          <Icon name="arrow-left" />
          {/^#\d+$/.test(widget.name) ? (`Widget ${widget.name}`) : widget.name}
        </Button>
      </header>
      <Sidebar
        userForm={userForm}
        devForm={userForm}
        devMode={false}
        changeMode={() => {}}
        isConstructorDevModeAllowed={isConstructorDevModeAllowed}
        isConstructorAllowed={isConstructorAllowed}
      />
      <Workspace content={workspaceContent} />
    </div>
    )
  );
};

export default ClientConstructor;

ClientConstructor.propTypes = {
  match: PropTypes.object.isRequired,
  isConstructorAllowed: PropTypes.bool.isRequired,
  isConstructorDevModeAllowed: PropTypes.bool.isRequired,
};
