/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import Switch from 'components/Switch';
import { Icon, Button } from 'uikit';

export default (overrideProps, totals, { removeItem, changeStatus }) => ({
  accessor: 'status',
  resizable: false,
  Cell: props => (
    <>
      <span className="cell-inner cell-inner__switcher">
        {props.original.isNew && (
          <Button
            kind="outline"
            format="icon"
            className="yui-button--transparent"
            onClick={() => removeItem(props.original.id)}
          >
            <Icon name="trash" />
          </Button>
        )}
        {!props.original.isNew && (
          <Switch
            flag={props.original.isNew === true}
            checked={props.value.toLowerCase() === 'active'}
            id={props.original.id}
            keyId={props.original.id}
            action={changeStatus}
          />
        )}
      </span>
    </>
  ),
  ...overrideProps,
});
