/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';

export default (overrideProps, totals, pageType, reportName) => ({
  accessor: 'wctr',
  resizable: false,
  headerClassName: `${pageType === 'widget' || reportName === 'revenue' ? '' : 'border-right'}`,
  headerStyle: {
    paddingRight: `${reportName === 'revenue' || reportName === 'engagement' ? '16px' : '40px'}`,
  },
  style: {
    paddingRight: `${reportName === 'revenue' || reportName === 'engagement' ? '16px' : '40px'}`,
    borderRight: `${pageType === 'widget' || reportName === 'revenue' ? '0px' : '1px solid #ededed'}`,
  },
  Header: (
    <FormattedMessage id="table.wctr">
      {txt => <p className="cell-inner wctr">{SortIcon} {txt}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <span className="cell-inner" title={formatUtils.percentage(totals.wctr)}>
      {formatUtils.percentage(totals.wctr)}
    </span>
  ),
  Cell: props => (
    <span className="cell-inner" title={formatUtils.percentage(props.value)}>
      {formatUtils.percentage(props.value)}
    </span>
  ),
  ...overrideProps,
});
