import { stats as statsUtils } from 'utils';
import columns from 'components/tables/columns';

export const getDisplayedColumns = ({
  totalsData,
  pageType,
  columnsConfig,
  allWidgetsActive,
}) => {
  const totals = statsUtils.fromServerData(totalsData);
  const displayedColumns = [
    columns.widget({
      minWidth: 140,
      show: pageType === 'widget',
      className: 'uikit-table__cell--ae-first-cell',
    }, totals),
    columns.date({
      minWidth: 140,
      show: pageType === 'date',
      className: 'uikit-table__cell--ae-first-cell',
    }, totals),
    columns.country({
      show: pageType === 'country',
      className: 'uikit-table__cell--ae-first-cell',
    }, totals),
    columns.device({
      show: pageType === 'device',
      className: 'uikit-table__cell--ae-first-cell',
    }, totals),
    columns.itemTitleSimple({
      minWidth: 300,
      show: pageType === 'top',
      className: 'uikit-table__cell--ae-first-cell',
    }, totals),
    columns.audienceEngagementPageViews({
      minWidth: 100,
      maxWidth: 200,
      show: columnsConfig.pageViews && pageType !== 'top',
    }, totals),
    columns.widgetViews({
      maxWidth: 150,
      show: columnsConfig.widgetViews && pageType !== 'top',
    }, totals),
    columns.impressions({
      maxWidth: 150,
      show: columnsConfig.impressions && pageType === 'top',
    }, totals),
    columns.clicks({
      maxWidth: 110,
      show: columnsConfig.clicks,
      className: 'uikit-table__cell--bold',
    }, totals),
    columns.ctr({
      maxWidth: 110,
      show: columnsConfig.ctr,
    }, totals),
    columns.wctr({
      maxWidth: 110,
      show: columnsConfig.wctr && pageType !== 'top',
    }, totals, pageType, 'engagement'),
    columns.viewsAfter({
      maxWidth: 180,
      show: columnsConfig.viewsAfter && pageType !== 'widget' && allWidgetsActive,
    }, totals),
    columns.increase({
      maxWidth: 110,
      show: columnsConfig.increase && pageType !== 'widget' && pageType !== 'top' && allWidgetsActive,
    }, totals),
  ];

  return displayedColumns;
};

export default { getDisplayedColumns };
