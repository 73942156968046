import * as React from 'react';
import { ajax } from 'app/utils';
import PaymentHistoryPgTable from 'app/components/tables/containers/PaymentHistory';
import { useMount, useUpdateEffect } from 'react-use';
import { ReactElement } from 'react';
import { PaymentHistoryState } from 'app/ts/types/PaymentHistory';

const PaymentHistory = ({ currentSite }: { currentSite: number }): ReactElement => {
  const [state, setState] = React.useState<PaymentHistoryState>({
    payments: [],
    loading: true,
  });
  const { payments, loading } = state;

  const changeState = async (): Promise<void> => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    ajax.get('/payments')
      .then(({ data }) => {
        if (data) {
          setState(prevState => ({
            ...prevState,
            payments: data.payments || [],
            loading: false,
          }));
        }
      });
  };

  useMount(() => {
    changeState();
  });

  useUpdateEffect(() => {
    changeState(); // получаем новые данные с сервера после выбора другого сайта
  }, [currentSite]); // eslint-disable-line

  return (
    <div>
      <PaymentHistoryPgTable
        paymentsData={payments}
        isLoading={loading}
      />
    </div>
  );
};

export default PaymentHistory;
