import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Tooltip } from 'uikit';
import { cookie, ajax } from 'utils';
import { FormattedMessage } from 'react-intl';

const HeaderExit = ({ handleMobileMenuToggle, path }) => {
  const handleLogout = () => {
    cookie.deleteCookie('token');
    sessionStorage.removeItem('filterContentDataFilters');
    sessionStorage.removeItem('filterSocialDataFilters');
    ajax.defaults.params.token = undefined;
    window.location = window.location.origin;
  };
  return (
    <div className="page-header__auth">
      {/* eslint-disable-next-line */}
      <div className={`auth ${path === '/reports/revenue' ? 'auth--rv-report' : ''} ${path === '/reports/new_audience' ? 'auth--na-report' : ''}`}>
        <div className="auth__uname">
          <span className="auth__email">{cookie.getIsCookie('email')}</span>
          <Button format="icon" kind="secondary" onClick={handleLogout}>
            <Tooltip content={(
              <FormattedMessage id="tooltip.logout">
                {text => <span className="tooltip__text">{text}</span>}
              </FormattedMessage>
            )}
            >
              <span><Icon name="exit" /></span>
            </Tooltip>
          </Button>
        </div>
      </div>
      <button
        type="button"
        className="page-header__dd-button yui-icon yui-icon--account"
        aria-label="Toggle site menu"
        onClick={handleMobileMenuToggle}
      />
    </div>
  );
};

HeaderExit.propTypes = {
  handleMobileMenuToggle: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default HeaderExit;
