import React, { ReactElement } from 'react';

import { EngagementGraffProps, EngagementLegend } from 'app/ts/types/EngagementGraff';

import Charts from '../default/Charts';

const EngagementGraff = (props: EngagementGraffProps): ReactElement => {
  const { timeframe, statsData, byHour, pageType } = props;
  if ((!timeframe && !statsData) || (!timeframe.length && !statsData.length)) {
    return null;
  }

  const legend: Array<EngagementLegend> = [
    {
      title: 'Audience engagement page views',
      field: 'pageviews',
      disabled: true,
      valueDecimals: 0,
      color: '#ff6363',
    }, {
      title: 'Widget views',
      field: 'widget_views',
      disabled: true,
      valueDecimals: 0,
      color: '#4285f4',
    }, {
      title: 'Clicks',
      field: 'clicks',
      valueDecimals: 0,
      color: '#00db71',
    }, {
      title: 'WCTR (%)',
      field: 'wctr',
      suffix: '%',
      valueDecimals: 3,
      color: '#ae80ff',
    },
  ];

  if (pageType !== 'widget') {
    legend.push({
      title: 'Views after clicks',
      field: 'viewafter',
      valueDecimals: 0,
      disabled: true,
      color: '#ffc400',
    });
  }

  return (
    <Charts
      timeframe={timeframe}
      statsData={statsData}
      byHour={byHour}
      id="engagement"
      legend={legend}
      groups={[]}
      pageType={pageType}
    />
  );
};

export default EngagementGraff;
