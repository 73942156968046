import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import moment from 'moment';
/* eslint-disable react/prop-types */

export default overrideProps => ({
  accessor: 'excluded',
  resizable: false,
  Header: (
    <FormattedMessage id="table.excludedOn">
      {txt => <p className="cell-inner">{txt} {SortIcon}</p>}
    </FormattedMessage>
  ),
  Cell: ({ value = '' }) => (
    <div className="cell-inner cell-inner__with-small" title={value}>
      {value.split(' ')[0]}
      <span className="cell-inner-small">{value.split(' ')[1]}</span>
    </div>
  ),
  sortMethod: (a, b) => {
    const a1 = moment(a, 'DD.MM.YYYY hh:mm').valueOf();
    const b1 = moment(b, 'DD.MM.YYYY hh:mm').valueOf();
    return a1 > b1 ? 1 : -1;
  },
  ...overrideProps,
});
