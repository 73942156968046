import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'config';
import { cookie, showAlert } from 'app/utils';
import { ReportData, Sources } from 'app/ts/interfaces';

// HACK for #24987
if (window.location.hostname === 'eu.giraff.io') {
  config.serverAddress = 'https://eu.giraff.io/api';
}

export const sources: Sources = {
  pages: {}, // для хранения pathname страниц
  urls: {}, // для хранения url запросов
};

export const ajax: AxiosInstance = axios.create({
  timeout: 60000,
  baseURL: config.serverAddress,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*',
  },
  params: {
    token: cookie.getIsCookie('token') || undefined,
  },
});

ajax.interceptors.request.use((request: AxiosRequestConfig): AxiosRequestConfig => {
  const { pathname }: { pathname: string } = window.location;
  /*
    Добавляем в pages адреса страниц, чтобы можно было отменить все запросы этой страницы
    - key: "адрес страницы"
    - value: "массив url запросов этой страницы"
  */
  (sources.pages[pathname] = sources.pages[pathname] || []).push(request.url);

  if (sources.urls[request.url] !== undefined) {
    sources.urls[request.url].cancel();
  }

  // Добавляем cancelToken для каждого запроса
  sources.urls[request.url] = axios.CancelToken.source();
  request.cancelToken = sources.urls[request.url].token;
  return request;
});

ajax.interceptors.response.use((response: AxiosResponse<ReportData>): AxiosResponse<ReportData> => {
  const { data, headers: { 'content-type': contentType }, request: { responseURL } } = response;
  const isContentTypeJSON: boolean = contentType === 'application/json';

  if (data && !data.success && isContentTypeJSON) {
    const requestURL: string = responseURL.split('?')[0]; // удаляем токен
    console.error(`required 'success' field is missing in ${requestURL} response.`); // eslint-disable-line
  }

  return response;
},
(error) => {
  if (error.response) {
    const { data, status } = error.response;
    const contentType: string = status !== 500 ? error.response.headers['content-type'] : null;
    const isContentTypeJSON: boolean = contentType === 'application/json';

    if (status >= 400 && status < 500 && isContentTypeJSON && data) {
      const { error_code: errorCode, error_message: errorMessage } = data;
      showAlert(`Error: ${errorMessage} (${errorCode})`);
    }

    if (status === 401) { // разлогиниваем юзера при ответе 401 Unauthorized
      cookie.deleteCookie('token');
      sessionStorage.removeItem('filterContentDataFilters');
      sessionStorage.removeItem('filterSocialDataFilters');
      ajax.defaults.params.token = undefined;
      if (
        (window.location.hostname === 'localhost' || window.location.hostname === 'test.giraff.io')
      &&
      (window.location.pathname !== '/login')
      ) {
        window.location.pathname = '/login';
      } else {
        window.location.pathname = '/';
      }
    }

    if (status === 500) { // пока что не можем возвращать JSON (#31823)
      showAlert('An error occured. Please try again later.');
    }

    return Promise.reject(new Error(`${status}`));
  }

  if (error.message) {
    showAlert('An error has occured — please retry your request.');
    return Promise.reject(new Error(`${error.message}. Request has been canceled.`));
  }

  return false;
});

export default { ajax, sources };
