import { stats as statsUtils } from 'utils';
import columns from 'components/tables/columns';

export const getDisplayedColumns = ({
  totalsData,
  pageType,
  columnsConfig,
}) => {
  const totals = statsUtils.fromServerData(totalsData);
  const displayedColumns = [
    columns.widget({
      minWidth: 120,
      show: pageType === 'widget',
      className: 'uikit-table__cell--first-cell' }, totals),
    columns.date({
      minWidth: 120,
      show: pageType === 'date',
      className: 'uikit-table__cell--first-cell' }, totals),
    columns.country({
      minWidth: 140,
      show: pageType === 'country',
      className: 'uikit-table__cell--first-cell' }, totals),
    columns.device({
      show: pageType === 'device',
      className: 'uikit-table__cell--first-cell' }, totals),
    columns.revenuePageViews({ maxWidth: 130, show: columnsConfig.pageViews }, totals),
    columns.widgetViews({ maxWidth: 125, show: columnsConfig.widgetViews }, totals),
    columns.adShows({ maxWidth: 120, show: true }, totals),
    columns.clicks({
      maxWidth: 120,
      show: columnsConfig.clicks,
      className: 'uikit-table__cell--bold',
    }, totals),
    columns.ctr({ maxWidth: 80, show: columnsConfig.ctr }, totals),
    columns.wctr({ maxWidth: 80, show: columnsConfig.wctr }, totals, null, 'revenue'),
    columns.cpc({ maxWidth: 80, show: columnsConfig.cpc }, totals),
    columns.rpm({ maxWidth: 125, show: columnsConfig.rpm }, totals),
    columns.wrpm({ maxWidth: 100, show: columnsConfig.wrpm }, totals),
    columns.revenue({
      maxWidth: 120,
      show: columnsConfig.revenue,
      className: 'uikit-table__cell--bold',
    }, totals),
  ];

  return displayedColumns;
};

export default { getDisplayedColumns };
