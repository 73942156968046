import * as React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import columns from 'components/tables/columns';
import { stats as statsUtils } from 'utils';

const PaymentHistory = (props) => {
  const { paymentsData, isLoading } = props;

  const getColumnsConfig = () => [
    columns.paymentDate({ maxWidth: 200 }),
    columns.paymentSum({ maxWidth: 400 }),
  ];

  return (
    <div className="payment-history-pg-table">
      <Table
        columns={getColumnsConfig()}
        data={paymentsData.map(statsUtils.fromServerData)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PaymentHistory;

PaymentHistory.propTypes = {
  paymentsData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
