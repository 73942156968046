import React, { ReactElement } from 'react';
import Table from 'app/components/Table';
import columns from 'app/components/tables/columns';
import { stats as statsUtils } from 'app/utils';
import './Social.scss';

import { SocialPgTableProps } from 'app/ts/types/SocialPgTable';
import { ColumnsConfig } from 'app/ts/types/ColumnsConfig';
import { TotalsData } from 'app/ts/types/utils';

const Social = (props: SocialPgTableProps): ReactElement => {
  const { statsData, totalsData, totalItems, changeState, isLoading } = props;

  const tableRef = React.useRef<ReactElement>();

  const getColumnsConfig = (): Array<ColumnsConfig> => {
    const totals: TotalsData = statsUtils.fromServerData(totalsData);

    return [
      columns.itemTitleSimple({ minWidth: 300 }),
      columns.pageViews({ maxWidth: 200 }, totals),
      columns.shares({ maxWidth: 150 }, totals),
      columns.sharesPerMile({ maxWidth: 150 }, totals),
      columns.socialClicks({ maxWidth: 150 }, totals),
      columns.socialClicksPerShare({ width: 200 }, totals),
    ];
  };

  return (
    <div className="social-pg-table">
      <Table
        columns={getColumnsConfig()}
        data={statsData.map(statsUtils.fromServerData)}
        dataCount={totalItems}
        changeState={changeState}
        tableRef={(ref: ReactElement) => tableRef.current = ref}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Social;
