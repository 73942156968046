const rad = 87;
export const pieMax = Math.PI * 2 * rad;

const getRad = (value, position) => {
  if (value < 5 && value !== 0) {
    switch (position) {
      case 0:
      case 2:
        return rad - 10;
      case 1:
      case 3:
        return rad + 10;
      default: return rad;
    }
  } else return rad;
};

const getXY = (deg, radius) => {
  const toRad = Math.PI / 180;
  const pieRotate = -90;
  return `${radius * Math.cos((pieRotate + deg) * toRad)}px,${radius * Math.sin((pieRotate + deg) * toRad)}px`;
};

const getSliders = (slider, slideLock, enabled = true) => {
  const elements = [...slider.parentNode.children].filter(node => node !== slider);
  return elements.slice(0, -1).filter(elem => (enabled
    ? elem.getAttribute('data-slider_name') !== slideLock
    : elem.getAttribute('data-slider_name') === slideLock
  ));
};

// сбалансированное изменение 3 значений a+b+c=100 с возможным блокированием "с" и с откатами к прежним значениям
export const getPerformanceValues = ({
  activeSlide, nextSlide, fixedSlide, activeValue, oldValue, slideLock, oldValueNext, value,
}) => {
  const valueFixed = parseInt(value, 10);
  const values = {};
  values[fixedSlide] = valueFixed; // если не залочено, меняется в 3-ю очередь

  if (slideLock) {
    values[activeSlide] = Math.max(0, Math.min(activeValue, 100 - valueFixed));
    values[nextSlide] = 100 - valueFixed - values[activeSlide];
  } else {
    values[activeSlide] = activeValue;
    values[nextSlide] = oldValueNext + oldValue - activeValue;
    if (oldValue > activeValue) {
      if (values[nextSlide] > 100) {
        values[fixedSlide] = valueFixed + 100 - values[nextSlide];
        values[nextSlide] = 100;
      }
    } else if (values[nextSlide] < 0) {
      values[fixedSlide] = valueFixed + values[nextSlide];
      values[nextSlide] = 0;
    }
  }
  return values;
};

export const getPieChartClassName = (value, color = 'blue') => {
  if (value < 5) {
    if (value === 0) {
      return `pie-chart__text-${color} pie-chart__text--null`;
    }
    return `pie-chart__text-${color} pie-chart__text--small`;
  }
  return `pie-chart__text-${color}`;
};

export const getStrokeDashValues = ({ engagement, newAudience, revenue }) => ([
  (pieMax * engagement) / 100,
  (pieMax * newAudience) / 100,
  (pieMax * revenue) / 100,
]);

export const getPieChartPositions = ({ engagement, newAudience, revenue }) => ([
  getXY(1.8 * engagement, getRad(engagement, 0)),
  getXY(3.6 * engagement + 1.8 * newAudience, getRad(newAudience, 1)),
  getXY(3.6 * engagement + 3.6 * newAudience + 1.8 * revenue, getRad(revenue, 2)),
]);

export const getSliderOldValue = (performanceSliders, activeSlide, activeValue) => {
  const sliderPropActive = performanceSliders.find(el => el.name === activeSlide);
  const oldValue = sliderPropActive && (sliderPropActive.oldValue != null ? sliderPropActive.oldValue : activeValue);
  return oldValue;
};

export const getSliderNextOldValue = (performanceSliders, nextSlide, nextValue) => {
  const sliderPropNext = performanceSliders.find(el => el.name === nextSlide);
  const oldValueNext = sliderPropNext && (sliderPropNext.oldValue != null ? sliderPropNext.oldValue : nextValue);
  return oldValueNext;
};

// TODO: need to change logic which uses querySelectorAll, etc

export const getActiveSliderState = ({ sliderName, sliderValue, parent, event }) => {
  const activeSlide = sliderName;
  const slider = parent[sliderName];
  const activeSlider = slider.querySelectorAll('.slider-settings')[0].noUiSlider;
  const activeValue = +(event && event.target
    ? parseInt(event.target.value, 10) || sliderValue || +slider.querySelector('.psettings__input').value
    : sliderValue);

  return { activeSlide, activeSlider, activeValue };
};

export const getNextSliderState = ({ sliderName, slideLock, parent }) => {
  const slider = parent[sliderName];
  const index = sliderName === 'newAudience' && !slideLock ? 1 : 0;
  const enabledSliders = getSliders(slider, slideLock, true);

  const nextSlide = enabledSliders[index].querySelector('.psettings__input').getAttribute('id');
  const nextSlider = enabledSliders[index].querySelectorAll('.slider-settings')[0].noUiSlider;
  const nextValue = nextSlider.get()[1];

  return { nextSlide, nextSlider, nextValue };
};

export const getFixedSliderState = ({ sliderName, slideLock, parent }) => {
  const slider = parent[sliderName];
  const fixedIndex = sliderName !== 'newAudience' ? 1 : 0;
  const sliderDisabled = getSliders(slider, slideLock, false)[0];
  const enabledSliders = getSliders(slider, slideLock, true);
  const fixedSlide = slideLock
    ? sliderDisabled.querySelector('.psettings__input').getAttribute('id')
    : enabledSliders[fixedIndex].querySelector('.psettings__input').getAttribute('id');

  const fixedSlider = slideLock
    ? sliderDisabled.querySelectorAll('.slider-settings')[0].noUiSlider
    : enabledSliders[fixedIndex].querySelectorAll('.slider-settings')[0].noUiSlider;

  return { fixedSlide, fixedSlider };
};
