import { getLocalTime } from './tz';
import { CookieOptions, DeleteCookie, GetIsCookie, SetCookie } from '../ts/types/utils';

export const getIsCookie: GetIsCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)' // eslint-disable-line
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie: SetCookie = (name, value, options) => {
  const cookieOptions: CookieOptions = options || {};
  let { expires } = cookieOptions;
  if (typeof expires === 'number' && expires) {
    const d: Date = getLocalTime();
    d.setTime(d.getTime() + expires * 1000);
    cookieOptions.expires = d;
    expires = d;
  }
  if (expires && typeof expires === 'object' && expires.toUTCString) {
    cookieOptions.expires = expires.toUTCString();
  }
  const cookieValue: string = encodeURIComponent(value);
  let updatedCookie: string = `${name}=${cookieValue}`;
  for (const propName in cookieOptions) {
    if (cookieOptions[propName]) {
      updatedCookie += `; ${propName}`;
      const propValue = cookieOptions[propName];
      if (propValue !== true) {
        updatedCookie += `=${propValue}`;
      }
    }
  }
  return document.cookie = updatedCookie;
};

// delete cookie
export const deleteCookie: DeleteCookie = (name) => setCookie(
  name,
  '',
  {
    path: '/',
    expires: -1,
  },
);

export default { getIsCookie, setCookie, deleteCookie };
