import React, { BaseSyntheticEvent, ReactElement } from 'react';
import './Switch.scss';
import { SwitchProp } from 'app/ts/types/constructor/Switch';

const Switch = (props: SwitchProp): ReactElement => {
  const { keyId, flag, action, id, checked: previousChecked } = props;
  const [checked, setChecked] = React.useState<boolean>(previousChecked);
  const blockToggle = (event: BaseSyntheticEvent) => {
    action(id, flag, event, () => {
      setChecked(event.target.checked);
    });
  };

  return (
    <div className={`material-switch ${flag ? 'material-switch--disabled' : ''}`}>
      <input
        checked={checked}
        onChange={blockToggle}
        id={`someSwitchOption-${keyId}`}
        name="SwitchOption001"
        type="checkbox"
      />
      <label htmlFor={`someSwitchOption-${keyId}`} className="label-default" />
    </div>
  );
};

export default Switch;
