import React, { ReactElement } from 'react';
import js from 'common';
import { Button } from 'uikit';
import EngagementGraff from 'app/components/charts/engagement/EngagementGraff';
import EngagementGraffWidget from 'app/components/charts/engagement/EngagementGraffWidget';
import PgFilter from 'app/components/PgFilter';
import PgTablePager from 'app/components/PgTablePager';
import AudienceEngagementPgTable from 'app/components/tables/containers/AudienceEngagement';
import { dom as domUtils, metrics, tz, menuList, ajax, ls } from 'app/utils';
import { widgets as widgetsCSV, sites as sitesCSV } from 'app/components/csv/audienceEngagement';
import { useMount, useUpdateEffect } from 'react-use';
import { cloneDeep } from 'lodash';
import { AxiosResponse } from 'axios';

import { AudienceEngagementState } from 'app/ts/types/AudienceEngagement';
import {
  Params,
  PageType,
  SiteProps,
  AudienceList,
  AudienceProps,
} from 'app/ts/types/Audience';
import { IntlMessage } from 'app/ts/types/IntlMessage';
import { GetPage, NewCsvData } from 'app/ts/types/utils';
import { AccountInfoState, StateData, StateItem } from 'app/ts/interfaces';

import { useAppSelector } from '../../store/hooks';

const AudienceEngagement = (): ReactElement => {
  const { currentSite, currentSiteName }: AccountInfoState = useAppSelector(reduxState => reduxState.accountInfo);

  const [state, setState] = React.useState<AudienceEngagementState>({
    stats: [],
    totals: {},
    totalItems: 0,
    timeframe: [],
    csvData: [],
    csvName: '',
    pageType: 'date',
    pageN: 1,
    sortField: 'date',
    sortOrder: 'desc',
    pageContent: 'widgets',
    widgets: {
      list: [],
      active: '',
    },
    sites: {
      list: [],
      active: [],
    },
    loading: true,
  });

  const {
    stats,
    totals,
    totalItems,
    timeframe,
    csvData,
    csvName,
    pageType,
    pageN,
    sortField,
    sortOrder,
    pageContent,
    widgets,
    sites,
    loading,
  } = state;

  const displayPageContentOptions: boolean = sites.list.length > 1;
  const displayDefaultCharts: boolean = pageType !== 'widget' && (pageContent === 'sites' || widgets.active === 'all');

  const isTimeIntervalToday: boolean = sessionStorage.getItem('filterDateFrom') === tz.now()
    && sessionStorage.getItem('filterDateTo') === tz.now();
  const isTimeIntervalYesterday: boolean = (sessionStorage.getItem('filterDateFrom') === tz.addDayToDate(-1))
    && (sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1));
  const isChartsVisible: boolean = (
    sessionStorage.getItem('filterDateFrom') !== sessionStorage.getItem('filterDateTo')
      || isTimeIntervalToday
      || isTimeIntervalYesterday
  ) // интервал > 1 дня && не сегодня/вчера (приходят данные по часам)
    && (
      (pageContent === 'widgets' && widgets.active === 'all' && pageType !== 'top')
      || (pageContent === 'sites' && pageType !== 'top')
    );
  const byHour: boolean = isTimeIntervalToday || isTimeIntervalYesterday;

  const getUrlParams = (paramsProps: AudienceProps = {}) => {
    const { pageTypeToChange, pageNumToChange, state: newState } = paramsProps;
    const pgNameStore: string = `filter${menuList.engagement.storageKey}`;
    const itemsPerPage: Storage = ls.get('engagementTableSize', 20);
    const params: Params = {};
    const groupBy: string = pageTypeToChange || pageType;
    let sortFieldNew: string;
    if (!sessionStorage.filterDateGlobal) {
      if (!sessionStorage[pgNameStore]) {
        sessionStorage[pgNameStore] = 'Date7days';
        sessionStorage.filterDateGlobal = 'Date7days';
      } else {
        sessionStorage.filterDateGlobal = sessionStorage[pgNameStore];
      }
    } else {
      sessionStorage[pgNameStore] = sessionStorage.filterDateGlobal;
    }
    switch (sessionStorage[pgNameStore]) {
      case 'Date1day':
        if (sessionStorage.getItem('filterDateTo')) {
          if (sessionStorage.getItem('filterDateTo') === tz.now()) {
            params.period = 'today';
          } else if (sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = sessionStorage.getItem('filterDateFrom') || tz.now();
            params.to = sessionStorage.getItem('filterDateTo') || tz.now();
          }
        }
        break;
      case 'Date7days':
        params.from = sessionStorage.getItem('filterDateFrom') || tz.addDayToDate(-7);
        params.to = sessionStorage.getItem('filterDateTo') || tz.now();
        break;
      case 'Date30days':
        params.from = sessionStorage.getItem('filterDateFrom') || tz.addDayToDate(-30);
        params.to = sessionStorage.getItem('filterDateTo') || tz.now();
        break;
      case 'DateInterval':
        if (sessionStorage.getItem('filterDateFrom') === tz.now()
          && sessionStorage.getItem('filterDateTo') === tz.now()) {
          params.period = 'today';
        } else if (sessionStorage.getItem('filterDateFrom') === tz.addDayToDate(-1)
          && sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1)) {
          params.period = 'yesterday';
        } else {
          params.from = sessionStorage.getItem('filterDateFrom');
          params.to = sessionStorage.getItem('filterDateTo');
        }
        break;
      default:
        break;
    }

    switch (groupBy) {
      case 'date':
        sortFieldNew = 'date';
        break;
      case 'country':
        sortFieldNew = 'pageviews';
        break;
      case 'device':
        sortFieldNew = 'pageviews';
        break;
      case 'widget':
        sortFieldNew = 'pageviews';
        break;
      case 'top':
        sortFieldNew = 'impressions';
        break;

      default:
        break;
    }

    params.limit = +itemsPerPage;
    params.offset = pageNumToChange ? (pageNumToChange - 1) * +itemsPerPage : 0;
    // state существует, если таблица сортируется на бэкенде
    params.sort_field = (newState && newState.sortField) || sortFieldNew;
    params.sort_order = (newState && newState.sortOrder) || sortOrder;

    if (groupBy !== 'top') { // если Top Content, то groupby не должен передаваться в запросе
      params.groupby = groupBy;
    }

    return params;
  };

  const changeState = (changeStateProps: AudienceProps): void => {
    const { pageTypeToChange, pageNumToChange, state: newState } = changeStateProps;
    const params: Params = getUrlParams({ pageTypeToChange, pageNumToChange, state: newState });
    const url: string = (pageTypeToChange || pageType) !== 'top' ? '/stats/engagement' : '/stats/top_content';
    if (pageContent === 'widgets' && widgets.active && widgets.active !== 'all') {
      params.wid = widgets.active;
    }

    if (pageContent === 'sites') {
      params.sites = sites.active.map(item => item.value).join(',');
    }

    ajax.get(url, { params }).then(({ data }: AxiosResponse<StateData>) => {
      if (data) {
        const { stats: statsNew, totals: totalsNew, timeframe: timeframeNew, total_items: totalItemsNew } = data;

        let csvDataNew: NewCsvData;
        let csvNameNew: string;

        if (pageContent === 'sites') {
          csvDataNew = sitesCSV.prepareCSV({
            stats: statsNew,
            totals: totalsNew,
            pageType: pageTypeToChange || pageType,
          });
          csvNameNew = sitesCSV.prepareCSVName();
        } else {
          csvDataNew = widgetsCSV.prepareCSV({
            stats: statsNew,
            totals: totalsNew,
            widgets,
            pageType: pageTypeToChange || pageType,
          });
          csvNameNew = widgetsCSV.prepareCSVName({
            widgets,
            currentSiteName,
            pageType: pageTypeToChange || pageType,
          });
        }

        setState(prevState => ({
          ...cloneDeep(prevState),
          pageType: pageTypeToChange || pageType,
          stats: statsNew,
          totals: totalsNew,
          timeframe: timeframeNew,
          totalItems: totalItemsNew,
          csvData: csvDataNew,
          csvName: csvNameNew,
          pageN: pageNumToChange || pageN,
          sortField,
          sortOrder,
        }));

        domUtils.callUiKitTableApiResize();

        setState(prevState => ({
          ...cloneDeep(prevState),
          loading: false,
        }));
      }
    });
  };

  const init = (): void => {
    const params: Params = {
      site_id: currentSite,
    };
    ajax.get('/widgets', { params }).then(({ data }: AxiosResponse<StateData>) => {
      if (data) {
        const { list: widgetArr } = data;
        const widgetList: Array<AudienceList> = [
          {
            active: true,
            title: 'All',
            name: 'all',
          },
        ];

        widgetArr.sort((a, b) => (a.name > b.name ? 1 : -1));
        widgetArr.map((widget) => {
          const title: IntlMessage = /^#\d+$/.test(widget.name as string) ? (`Widget ${widget.name}`) : widget.name;
          return widgetList.push({
            active: widget.settings && ('active' in widget.settings) ? widget.settings.active : true,
            name: widget.id,
            title,
          });
        });

        setState(prevState => ({
          ...cloneDeep(prevState),
          widgets: {
            list: widgetList,
            active: 'all',
          },
        }));
      }
    });

    ajax.get('/sites', { params }).then(({ data }: AxiosResponse<StateData>) => {
      if (data) {
        const { list: siteArr } = data;
        const siteList: Array<SiteProps> = [];

        siteArr.map(site => siteList.push({
          value: site.id,
          label: site.name,
        }));

        setState(prevState => ({
          ...cloneDeep(prevState),
          sites: {
            list: siteList,
            active: siteList,
          },
        }));
      }
    });
  };

  useMount(() => {
    init();
    metrics.metricEvent('hit', '/reports/engagement');
  });

  useUpdateEffect(() => {
    if (widgets.list.length > 0 && loading) {
      changeState({ pageTypeToChange: pageType });
    }
  }, [widgets]);

  useUpdateEffect(() => {
    init();
  }, [currentSite]);

  const getPage: GetPage = () => js.D.page('engagement');

  const changeWidget = (wid: string): void => {
    const widgetList: Array<AudienceList> = widgets.list;
    const url: string = pageContent === 'sites' && pageType === 'top' ? '/stats/top_content' : '/stats/engagement';
    const params: Params = getUrlParams();

    if (wid !== 'all') {
      params.wid = wid;
      params.groupby = 'date';
    }

    ajax.get(url, { params }).then(({ data }: AxiosResponse<StateData>) => {
      if (data) {
        const { stats: statsNew, totals: totalsNew, timeframe: timeframeNew, total_items: totalItemsNew } = data;

        setState(prevState => ({
          ...cloneDeep(prevState),
          stats: statsNew,
          totals: totalsNew,
          timeframe: timeframeNew,
          totalItems: totalItemsNew,
          widgets: {
            list: widgetList,
            active: wid,
          },
          csvData: widgetsCSV.prepareCSV({
            stats: statsNew,
            totals: totalsNew,
            pageType,
            widgets: { active: wid },
          }),
          csvName: widgetsCSV.prepareCSVName({
            currentSiteName,
            pageType,
            widgets: {
              list: widgetList,
              active: wid,
            },
          }),
          pageType: wid !== 'all' ? 'date' : pageType,
          pageN: 1,
          pageContent: 'widgets',
        }));
      }
    });
  };

  const changeSite = (updatedSites: Array<SiteProps>): void => {
    const newSites: Array<SiteProps> | Array<AudienceList> =
      updatedSites && updatedSites.length > 0 ? updatedSites : sites.list;
    const params: Params = getUrlParams();
    const url: string = pageType !== 'top' ? '/stats/engagement' : '/stats/top_content';

    params.sites = newSites.map(item => item.value).join(',');

    ajax.get(url, { params }).then(({ data }: AxiosResponse<StateData>) => {
      if (data) {
        const { stats: statsNew, totals: totalsNew, timeframe: timeframeNew, total_items: totalItemsNew } = data;

        setState(prevState => ({
          ...cloneDeep(prevState),
          stats: statsNew,
          totals: totalsNew,
          timeframe: timeframeNew,
          totalItems: totalItemsNew,
          sites: {
            ...sites,
            active: newSites,
          },
          csvData: sitesCSV.prepareCSV({ stats: statsNew, totals: totalsNew, pageType }),
          csvName: sitesCSV.prepareCSVName(),
          pageType,
          pageN: 1,
          pageContent: 'sites',
        }));
      }
    });
  };

  const changePageContent = (contentType: string): void => {
    if (contentType === 'widgets') {
      changeWidget('all');
    } else {
      changeSite(sites.list);
    }
  };

  const changePageN = (pageNumToChange: number, pageTypeToChange: PageType): void => {
    changeState({ pageNumToChange, pageTypeToChange });
  };

  const changePage = (pageTypeNew: PageType): void => {
    if (pageTypeNew !== pageType) {
      changePageN(1, pageTypeNew);
    }
  };

  return (
    <div className="engagement__content-wrapper">
      <div className="wrapper wrapper--dashboard">
        <div className="container-fluid">
          {displayPageContentOptions && (
            <>
              <Button
                kind={pageContent !== 'widgets' ? 'outline' : 'primary'}
                className="widgets__button"
                onClick={() => changePageContent('widgets')}
              >
                By widgets
              </Button>
              <Button
                kind={pageContent !== 'sites' ? 'outline' : 'primary'}
                className="widgets__button"
                onClick={() => changePageContent('sites')}
              >
                By sites
              </Button>
            </>
          )}
          <PgFilter
            widgets={pageContent === 'widgets' ? { ...widgets } : null}
            change={pageContent === 'widgets' ? changeWidget : changeSite}
            sites={pageContent === 'sites' ? { ...sites } : null}
            breakdownByListExtend={[{ title: 'Top content', name: 'top' }]}
            groupBy={pageType}
            csvName={csvName}
            csvData={csvData}
            changeState={changeState}
            getPage={getPage}
            changePage={changePage}
          />
          {isChartsVisible && displayDefaultCharts && (
            <EngagementGraff
              statsData={stats}
              pageType={pageType}
              timeframe={timeframe}
              byHour={byHour}
            />
          )}
          {isChartsVisible && !displayDefaultCharts && (
            <EngagementGraffWidget
              statsData={stats}
              pageType={pageType}
              timeframe={timeframe}
              byHour={byHour}
            />
          )}
        </div>
      </div>
      <PgTablePager
        pagerName="engagementTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
      <AudienceEngagementPgTable
        statsData={stats}
        totalsData={totals as StateItem}
        pageType={pageType}
        totalItems={totalItems}
        widgetsActive={widgets.active}
        pageContent={pageContent}
        changeState={changeState}
        isLoading={loading}
      />
      <PgTablePager
        pagerName="engagementTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
    </div>
  );
};

export default AudienceEngagement;
