import React, { ReactElement, BaseSyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Button } from 'uikit';
import moment from 'moment';
import AuthorsList from 'app/components/filters/AuthorsList';
import FilterTitle from 'app/components/filters/FilterTitle';
import FilterByList from 'app/components/filters/FilterByList';
import FilterFromTo from 'app/components/filters/FilterFromTo';
import FilterPublicDate from 'app/components/filters/FilterPublicDate';
import FilterByJSONList from 'app/components/filters/FilterByJSONList';
import FilterPeriod from 'app/components/FilterPeriod';
import { useMount, useClickAway } from 'react-use';
import {
  tz,
  ajax,
  pageTypes,
  listOfPageTypes,
  metricFilterTypes,
} from 'app/utils';

import {
  ContentAndSocialFilterProps,
  AdditionalFilters,
  ParseFilters,
  Filt,
  Social,
} from 'app/ts/types/ContentAndSocialFilter';
import { DateIntervalProps } from 'app/ts/types/DateIntervalProps';
import { Filters } from 'app/ts/types/ContentSocial';
import { ContentAndSocialFilterData } from 'app/ts/interfaces';
import { AxiosResponse } from 'axios';

import { useAppSelector } from '../store/hooks';
import 'components/ContentAndSocialFilter.scss';

const {
  AVERAGE_READING_TIME,
  PAGE_VIEWS,
  READING_DEPTH,
  REVENUE,
  RPM,
  SHARES,
  SOCIAL_CLICKS,
  SOC_PER_SHARE,
  SHARES_PER_MILE,
} = metricFilterTypes;

const { PAGE_OTHER, PAGE_CONTENT_ARTICLES } = pageTypes;

const additionalFilters: AdditionalFilters = {
  socnet: '',
  shares_from: '', // shares
  shares_to: '', // shares
  social_clicks_from: '', // social_clicks
  social_clicks_to: '', // social_clicks
  soc_per_share_from: '', // soc_per_share
  soc_per_share_to: '', // soc_per_share
  spm_from: '', // spm
  spm_to: '', // spm

  pubdate_from: '',
  pubdate_to: '',
  is_content: '', // content type all='', content='1', other='0'
  title: '', // title text
  pv_from: '', // page views
  pv_to: '', // page views
  sd_from: '', // Reading depth
  sd_to: '', // Reading depth
  art_from: '', // average reading time
  art_to: '', // average reading time
  rpm_from: '', // rpm
  rpm_to: '', // rpm
  rev_from: '', // revenue
  rev_to: '', // revenue
};
const dataPubdateFrom: string = additionalFilters.pubdate_from;
const dataPubdateTo: string = additionalFilters.pubdate_to;
const initialSubmenusState: { [key: string]: boolean } = {
  authors: false,
  title: false,
  pubDate: false,
  pageViews: false,
  scrollDepth: false,
  averageReadingTime: false,
  shares: false,
  socialClicks: false,
  socialClicksShare: false,
  sharesPerMile: false,
}; // pageType и socialNetwork это <select>, они нормально работают и без state

const ContentAndSocialFilter = (props: ContentAndSocialFilterProps): ReactElement => {
  const { mode, changeState, changePage, getPage, statsData } = props;
  const currentSite: number = useAppSelector(reduxState => reduxState.accountInfo.currentSite);

  const [isOpen, setIsOpen] = React.useState<boolean>(false); // ContentAndSocialFilter
  const [isOpenMobile, setIsOpenMobile] = React.useState<boolean>(false); // ContentAndSocialFilter (mobile)
  const [authors, setAuthors] = React.useState<Array<Social>>([]);
  const [socials, setSocials] = React.useState<Array<Social>>([]);
  const [mobileSubmenusOpen, setMobileSubmenusOpen] =
    React.useState<{ [key: string]: boolean }>(initialSubmenusState); // подменю (mobile)
  const {
    authors: submenuAuthors,
    title: submenuTitle,
    pubDate: submenuPubDate,
    pageViews: submenuPageViews,
    scrollDepth: submenuScrollDepth,
    averageReadingTime: submenuAverageReadingTime,
    shares: submenuShares,
    socialClicks: submenuSocialClicks,
    socialClicksShare: submenuSocialClicksShare,
    sharesPerMile: submenuSharesPerMile,
  } = mobileSubmenusOpen;
  const history = useHistory<History>();

  const localStorageName: string = mode === 'content' ? 'filterContentDataFilters' : 'filterSocialDataFilters';
  const store: string = sessionStorage.getItem(localStorageName);
  const isMobile: boolean = window.innerWidth < 768;
  const dropdownRef = React.useRef<HTMLDivElement>();
  useClickAway<any>(dropdownRef, (e: BaseSyntheticEvent) => {
    if (
      e.target?.parentNode?.id !== 'addFilterDropdownMenu'
      && e.target.id !== 'addFilterDropdownMenu'
      && isOpen
    ) {
      setIsOpen(false);
    }
  });

  React.useEffect(() => {
    const loadAuthors = async (): Promise<void> => {
      const { data }: AxiosResponse<ContentAndSocialFilterData> = await ajax.get('/tools/list_editors');

      if (data) {
        const fetchedAuthors: Array<Social> = [...data.list];
        fetchedAuthors.unshift({ id: '', name: 'All' });
        setAuthors(fetchedAuthors);

        // Проверяем что ранее выбранный автор присутствует в списке
        let flagExistsAuthor: boolean = false;
        if (sessionStorage[localStorageName]) {
          for (let i: number = 0; i < fetchedAuthors.length; i += 1) {
            const activeFilters: Filters = JSON.parse(sessionStorage[localStorageName]);
            for (let k: number = 0; k < activeFilters.length; k += 1) {
              if (activeFilters[k].label === 'author'
                && fetchedAuthors[i].name === 'author'
                && fetchedAuthors[i].id === activeFilters[k].object.author_id
              ) {
                flagExistsAuthor = true;
                break;
              }
            }
          }
          if (!flagExistsAuthor) {
            const activeFilters: Filters = JSON.parse(sessionStorage[localStorageName]);
            for (let j: number = 0; j < activeFilters.length; j += 1) {
              if (activeFilters[j].label === 'author') {
                activeFilters.splice(j, 1);
              }
            }
          }
        }
      }
    };

    const loadSocials = async (): Promise<void> => {
      const { data }: AxiosResponse<ContentAndSocialFilterData> = await ajax.get('/tools/list_socialnetworks');

      if (data) {
        const fetchedSocials: Array<Social> = [...data.list];
        fetchedSocials.unshift({ id: '', name: 'All' });
        setSocials(fetchedSocials);
      }
    };

    loadAuthors();
    loadSocials();
  }, [currentSite, localStorageName]);

  const chooseFilter = (label: string, params: { [key: string]: any }): void => {
    let activeFilters: Filters = [];

    if (sessionStorage.getItem(localStorageName) !== 'undefined'
      && sessionStorage.getItem(localStorageName) !== ''
      && sessionStorage.getItem(localStorageName) !== null) {
      activeFilters = JSON.parse(sessionStorage.getItem(localStorageName));
    }

    // Функция создает список активных фильтров
    let index: number = -1;

    // проверяем, есть ли элемент в числе активных фильтров
    for (let i: number = 0; i < activeFilters.length; i += 1) {
      if (activeFilters[i].label === label) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      activeFilters.splice(index, 1, {
        id: activeFilters.length,
        label,
        object: params,
      });

      sessionStorage.setItem(localStorageName, JSON.stringify(activeFilters));
    } else {
      activeFilters.push({
        id: activeFilters.length,
        label,
        object: params,
      });

      sessionStorage.setItem(localStorageName, JSON.stringify(activeFilters));
    }

    setIsOpen(false);
    setIsOpenMobile(false);
    changePage(1);
  };

  const collapseMobileSubmenu = (filter: string): void => {
    setMobileSubmenusOpen(prevState => ({
      ...prevState,
      [filter]: !prevState[filter],
    }));
  };

  const selectInpPub = (type: string, e: BaseSyntheticEvent): void => {
    // Функция для мобильной версии (интервал дат)
    const emptyText: string = type === 'pubdate_from' ? 'From' : 'To';
    const emptyTextSize: string = type === 'pubdate_from' ? '30px' : '15px';

    e.target.dataset.date = e.target.value === '' ? emptyText : e.target.value;
    e.target.style.width = e.target.value === '' ? emptyTextSize : '65px';

    const date: string = tz.changeFormatPoint(e.target.value);
    // Получилась ли дата?
    if (moment(date).valueOf()) {
      if (type === 'pubdate_from') {
        additionalFilters.pubdate_from = date;
      } else {
        additionalFilters.pubdate_to = date;
      }
      const fromDate: number = moment(tz.changeFormatPoint(additionalFilters.pubdate_from)).valueOf();
      const toDate: number = moment(tz.changeFormatPoint(additionalFilters.pubdate_to)).valueOf();

      if (fromDate && toDate) {
        chooseFilter('publicDate',
          {
            publicDate: `Publication date: ${tz.changeFormatPoint(additionalFilters.pubdate_from)} `
              + `- ${tz.changeFormatPoint(additionalFilters.pubdate_to)}`,
            from: tz.changeFormatPoint(additionalFilters.pubdate_from),
            to: tz.changeFormatPoint(additionalFilters.pubdate_to),
          });
      }
    }
  };

  const chooseSocial = (network: Social): void => {
    additionalFilters.socnet = network.id;
    chooseFilter('socnet', { id: network.id, name: network.name });
  };

  const chooseSocialMob = (event: BaseSyntheticEvent): void => {
    const { id } = event.target.querySelector('option:checked').dataset;
    additionalFilters.socnet = id;
    chooseFilter('socnet', { id, name: event.target.value });
  };

  const handleDateIntervalPub = (dateIntervalPubProps: DateIntervalProps): void => {
    const { startDate, endDate } = dateIntervalPubProps;
    const inputBeginValue: string = moment(startDate).format('YYYY-MM-DD');
    const inputEndValue: string = moment(endDate).format('YYYY-MM-DD');

    additionalFilters.pubdate_from = inputBeginValue;
    additionalFilters.pubdate_to = inputEndValue;
    chooseFilter(
      'publicDate',
      {
        publicDate: 'Publication date: '
          + `${inputBeginValue} - ${inputEndValue}`,
        from: inputBeginValue,
        to: inputEndValue,
      },
    );

    setIsOpen(false);
  };

  const readFiltersFromUrl = (): void => {
    if (!history) {
      return;
    }
    const { search } = history.location;

    const searchParts: Array<string> = search.replace('?', '').split('&');
    const filters: Filt = {};

    searchParts.forEach((searchPart) => {
      const [field, value] = searchPart.split('=');

      if (field && value !== undefined) {
        filters[field as keyof Filt] = value;
      }
    });

    if (filters.author) {
      chooseFilter('author', filters);
      history.replace(`/reports/editorial/${mode}`);
    }
  };

  useMount(() => {
    readFiltersFromUrl();
  });

  const removeFilter = (key: number): void => {
    // Функция удаляет фильтр из списка активных
    const activeFilters: Filters = JSON.parse(sessionStorage[localStorageName]);

    activeFilters.splice(key, 1);
    sessionStorage.setItem(localStorageName, JSON.stringify(activeFilters));

    changeState({});
  };

  const chooseTitle = (text: string): void => {
    additionalFilters.title = text;
    chooseFilter('title', { text });
  };

  const choosePageType = (type: string): void => {
    // есть ощущение, что на бэке обрабатываются только 1 и 0, причем 1 - это контент, а 0 - это All.
    // Таким образом Other page и All сейчас одно и тоже,
    // но если сделать на бэке что 0 это other page, то будет все норм
    switch (type) {
      case PAGE_CONTENT_ARTICLES:
        additionalFilters.is_content = 1;
        break;
      case PAGE_OTHER:
        additionalFilters.is_content = 0;
        break;
      default:
        additionalFilters.is_content = '';
        break;
    }

    chooseFilter('page_type', { type });
  };

  const chooseFromTo = (filter: string, from: number | string, to: number | string): void => {
    switch (filter) {
      case PAGE_VIEWS:
        additionalFilters.pv_from = from;
        additionalFilters.pv_to = to;
        break;
      case READING_DEPTH:
        additionalFilters.sd_from = from;
        additionalFilters.sd_to = to;
        break;
      case AVERAGE_READING_TIME:
        additionalFilters.art_from = from;
        additionalFilters.art_from = to;
        break;
      case RPM:
        additionalFilters.rpm_from = from;
        additionalFilters.rpm_to = to;
        break;
      case REVENUE:
        additionalFilters.rev_from = from;
        additionalFilters.rev_to = to;
        break;
      case SHARES:
        additionalFilters.shares_from = from;
        additionalFilters.shares_to = to;
        break;
      case SOCIAL_CLICKS:
        additionalFilters.social_clicks_from = from;
        additionalFilters.social_clicks_from = to;
        break;
      case SOC_PER_SHARE:
        additionalFilters.soc_per_share_from = from;
        additionalFilters.soc_per_share_to = to;
        break;
      case SHARES_PER_MILE:
        additionalFilters.spm_from = from;
        additionalFilters.spm_to = to;
        break;
      default:
        // eslint-disable-next-line
        console.log('Ошибка. Неизвестный тип фильтра для компонента FilterFromTo');
        break;
    }
    chooseFilter(filter, { from, to });
  };

  const handleCloseModal = (): void => {
    setIsOpenMobile(false);
    setMobileSubmenusOpen(initialSubmenusState);
  };

  /* eslint-disable react/destructuring-assignment */
  const hashParseFilters = (): ParseFilters => ({
    author: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a className="complicated-filter__activef-text" onClick={() => setIsOpenMobile(true)}>
          {obj.author}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul className="dropdown-list__author">
          <li className="filterSubmenu__authors">
            {authors?.length !== 0
              ? (
                <AuthorsList
                  list={authors}
                  onChange={chooseFilter}
                />
              ) : null}
          </li>
        </ul>
      </div>
    ),
    title: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          {obj.text}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__title">
            <FilterTitle
              statsData={statsData}
              onChange={chooseTitle}
            />
          </li>
        </ul>
      </div>
    ),
    publicDate: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          {obj.publicDate}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__publicDate">
            <FilterPublicDate
              handleDateIntervalPub={handleDateIntervalPub}
              data_pubdate_from={additionalFilters.pubdate_from}
              data_pubdate_to={additionalFilters.pubdate_to}
            />
          </li>
        </ul>
      </div>
    ),
    page_type: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a className="complicated-filter__activef-text" onClick={() => setIsOpenMobile(true)}>
          {obj.type}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__pageType">
            <FilterByList
              uid="ptSub"
              list={listOfPageTypes}
              onChange={choosePageType}
              setIsFilterOpen={setIsOpen}
            />
          </li>
        </ul>
      </div>
    ),
    page_views: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          Page views
          {obj.from ? ` from ${obj.from}` : ''}
          {obj.to ? ` to ${obj.to}` : ''}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__pageViews">
            <FilterFromTo store={store} filter={PAGE_VIEWS} onChange={chooseFromTo} />
          </li>
        </ul>
      </div>
    ),
    reading_depth: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          Reading depth
          {obj.from ? ` from ${obj.from} %` : ''}
          {obj.to ? ` to ${obj.to} %` : ''}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__scrollDepth">
            <FilterFromTo store={store} filter={READING_DEPTH} onChange={chooseFromTo} />
          </li>
        </ul>
      </div>
    ),
    average_reading_time: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          Average reading time
          {obj.from ? ` from ${obj.from} min` : ''}
          {obj.to ? ` to ${obj.to} min` : ''}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__averTime">
            <FilterFromTo store={store} filter="average_reading_time" onChange={chooseFromTo} />
          </li>
        </ul>
      </div>
    ),
    socnet: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a className="complicated-filter__activef-text" onClick={() => setIsOpenMobile(true)}>
          {obj.name}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__pageType">
            <FilterByJSONList
              uid="ptSub"
              listObjects={socials}
              onChange={(network: Social | BaseSyntheticEvent) => chooseSocial(network as Social)}
            />
          </li>
        </ul>
      </div>
    ),
    shares: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          Shares {obj.from ? ` from ${obj.from}` : ''}
          {obj.to ? ` to ${obj.to}` : ''}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__scrollDepth">
            <FilterFromTo store={store} filter={SHARES} onChange={chooseFromTo} />
          </li>
        </ul>
      </div>
    ),
    social_clicks: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          Social clicks {obj.from ? ` from ${obj.from}` : ''}
          {obj.to ? ` to ${obj.to}` : ''}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__averTime">
            <FilterFromTo store={store} filter={SOCIAL_CLICKS} onChange={chooseFromTo} />
          </li>
        </ul>
      </div>
    ),
    soc_per_share: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          Social clicks per share {obj.from ? ` from ${obj.from}` : ''}
          {obj.to ? ` to ${obj.to}` : ''}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__soc_per_share">
            <FilterFromTo store={store} filter={SOC_PER_SHARE} onChange={chooseFromTo} />
          </li>
        </ul>
      </div>
    ),
    spm: (obj, key) => (
      <div key={key} className="complicated-filter__activef-item">
        <a
          className="complicated-filter__activef-text"
          onClick={() => setIsOpenMobile(true)}
        >
          Shares per mile {obj.from ? ` from ${obj.from}` : ''}
          {obj.to ? ` to ${obj.to}` : ''}
        </a>
        <a className="complicated-filter__activef-remove" onClick={() => removeFilter(key)}>
          <Icon name="close" />
        </a>
        <ul>
          <li className="filterSubmenu__spm">
            <FilterFromTo store={store} filter={SHARES_PER_MILE} onChange={chooseFromTo} />
          </li>
        </ul>
      </div>
    ),
  });
  /* eslint-enable react/destructuring-assignment */

  // хэш для работы с формами в активных фильтрах (определяем какую форму выводить для какого фильтра)
  let activeFilters: Filters = [];
  if (sessionStorage.getItem(localStorageName) !== 'undefined'
    && sessionStorage.getItem(localStorageName) !== ''
    && sessionStorage.getItem(localStorageName) != null) {
    activeFilters = JSON.parse(sessionStorage.getItem(localStorageName));
  }

  return (
    <div>
      {isMobile && (
        <div className="wrapper wrapper--socialf wrapper--noborders complicated-calendar__social">
          <div className="socialf clearfix">
            <div className="socialf__left">Filters</div>
            <div className="socialf__right">
              <a
                href="#"
                className="socialf__link"
                data-toggle="modal"
                data-target="#filters_modal"
                onClick={() => setIsOpenMobile(true)}
              >
                Add filter
              </a>
            </div>
          </div>
          {activeFilters.length && authors ? (
            <div className="socialf clearfix">
              <div className="complicated-filter__activef">
                {activeFilters.map((v, index) => hashParseFilters()[v.label as keyof ParseFilters](v.object, index))}
              </div>
            </div>
          ) : (
            null
          )}
          <FilterPeriod
            responsiveFlag="mobile"
            changeState={changeState}
            page={getPage}
          />
          <div className="socialf__bottom" />
          <div
            className={`filters__modal-wrapper ${isOpenMobile ? 'modal-open' : ''}`}
          >
            <div
              id="filters_modal"
              className={`modal fade slide-modal modal--filters collapse ${isOpenMobile ? 'in' : ''}`}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <Button
                      format="icon"
                      kind="secondary"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal}
                    >
                      <span aria-hidden="true">&times;</span>
                    </Button>

                    <div
                      id="accordion"
                      className="modal-body__content"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="clearfix modal-fmenu__subtitle">
                        <h5>By Property:</h5>
                      </div>

                      <div className="card modal-fmenu__item-wrapper">
                        <div className="modal-fmenu__item clearfix" role="tab">
                          <a
                            className="modal-fmenu__item-link collapsed"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            href="#socialf__authorsWrapper"
                            aria-expanded="false"
                            aria-controls="socialf__authorsWrapper"
                            onClick={() => collapseMobileSubmenu('authors')}
                          >
                            Authors
                          </a>
                        </div>
                        <div
                          id="socialf__authorsWrapper"
                          className={`socialf socialf__authorsWrapper collapse ${submenuAuthors ? 'in' : ''}`}
                          role="tabpanel"
                        >
                          {authors?.length !== 0
                            ? (
                              <AuthorsList
                                responsiveFlag="mobile"
                                list={authors}
                                onChange={chooseFilter}
                              />
                            )
                            : null}
                        </div>
                      </div>

                      <div className="card modal-fmenu__item-wrapper">
                        <div className="modal-fmenu__item clearfix" role="tab">
                          <a
                            className="modal-fmenu__item-link collapsed"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            href="#socialf__titleWrapper"
                            aria-expanded="false"
                            aria-controls="socialf__titleWrapper"
                            onClick={() => collapseMobileSubmenu('title')}
                          >
                            Title
                          </a>
                        </div>
                        <div
                          id="socialf__titleWrapper"
                          className={`socialf socialf__titleWrapper collapse ${submenuTitle ? 'in' : ''}`}
                          role="tabpanel"
                        >
                          <FilterTitle
                            statsData={statsData}
                            onChange={chooseTitle}
                          />
                        </div>
                      </div>

                      <div className="card modal-fmenu__item-wrapper">
                        <div className="modal-fmenu__item clearfix" role="tab">
                          <a
                            className="modal-fmenu__item-link collapsed"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            href="#publication-date-modal"
                            aria-expanded="false"
                            aria-controls="publication-date-modal"
                            onClick={() => collapseMobileSubmenu('pubDate')}
                          >
                            Publication date
                          </a>
                        </div>

                        <div
                          className={`socialf socialf__pageType collapse ${submenuPubDate ? 'in' : ''}`}
                          id="publication-date-modal"
                          role="tabpanel"
                        >
                          <FilterPublicDate
                            responsiveFlag="mobile"
                            selectInpPubFrom={(event: BaseSyntheticEvent) => selectInpPub('pubdate_from', event)}
                            selectInpPubTo={(event: BaseSyntheticEvent) => selectInpPub('pubdate_to', event)}
                          />
                        </div>
                      </div>

                      <div className="modal-fmenu__item clearfix">
                        <div className="modal-fmenu__link-wrapper">
                          <FilterByList
                            uid="ptMobile"
                            responsiveFlag="mobile"
                            list={listOfPageTypes}
                            onChange={choosePageType}
                          />
                        </div>
                      </div>

                      {mode === 'social' && (
                        <div className="modal-fmenu__item clearfix">
                          <div className="modal-fmenu__link-wrapper">
                            {socials?.length !== 0
                              ? (
                                <FilterByJSONList
                                  uid="ptMobile"
                                  responsiveFlag="mobile"
                                  listObjects={socials}
                                  onChange={(event: Social | BaseSyntheticEvent) => (
                                    chooseSocialMob(event as BaseSyntheticEvent)
                                  )}
                                />
                              ) : null}
                          </div>
                        </div>
                      )}

                      <div className="clearfix modal-fmenu__subtitle">
                        <h5>By Metric:</h5>
                      </div>

                      <div className="card modal-fmenu__item-wrapper">
                        <div className="modal-fmenu__item clearfix" role="tab">
                          <a
                            className="modal-fmenu__item-link collapsed"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            href="#socialf__pageViews"
                            aria-expanded="false"
                            aria-controls="socialf__pageViews"
                            onClick={() => collapseMobileSubmenu('pageViews')}
                          >
                            Page views
                          </a>
                        </div>
                        <div
                          id="socialf__pageViews"
                          className={`socialf socialf__pageViews collapse ${submenuPageViews ? 'in' : ''}`}
                          role="tabpanel"
                        >
                          <FilterFromTo
                            store={store}
                            filter={PAGE_VIEWS}
                            onChange={chooseFromTo}
                          />
                        </div>
                      </div>

                      {mode === 'content' && (
                        <div className="card modal-fmenu__item-wrapper">
                          <div className="modal-fmenu__item clearfix" role="tab">
                            <a
                              className="modal-fmenu__item-link collapsed"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              href="#socialf__scrollDepth"
                              aria-expanded="false"
                              aria-controls="socialf__scrollDepth"
                              onClick={() => collapseMobileSubmenu('scrollDepth')}
                            >
                              Scroll depth
                            </a>
                          </div>
                          <div
                            id="socialf__scrollDepth"
                            className={`socialf socialf__scrollDepth collapse ${submenuScrollDepth ? 'in' : ''}`}
                            role="tabpanel"
                          >
                            <FilterFromTo
                              store={store}
                              filter={READING_DEPTH}
                              onChange={chooseFromTo}
                            />
                          </div>
                        </div>
                      )}

                      {mode === 'content' && (
                        <div className="card modal-fmenu__item-wrapper">
                          <div className="modal-fmenu__item clearfix" role="tab">
                            <a
                              className="modal-fmenu__item-link collapsed"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              href="#socialf__averTime"
                              aria-expanded="false"
                              aria-controls="socialf__averTime"
                              onClick={() => collapseMobileSubmenu('averageReadingTime')}
                            >
                              Average reading time
                            </a>
                          </div>
                          <div
                            id="socialf__averTime"
                            className={`socialf socialf__averTime collapse ${submenuAverageReadingTime ? 'in' : ''}`}
                            role="tabpanel"
                          >
                            <FilterFromTo
                              store={store}
                              filter={AVERAGE_READING_TIME}
                              onChange={chooseFromTo}
                            />
                          </div>
                        </div>
                      )}

                      {mode === 'social' && (
                        <div className="card modal-fmenu__item-wrapper">
                          <div className="modal-fmenu__item clearfix" role="tab">
                            <a
                              className="modal-fmenu__item-link collapsed"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              href="#socialf__scrollDepth"
                              aria-expanded="false"
                              aria-controls="socialf__scrollDepth"
                              onClick={() => collapseMobileSubmenu(SHARES)}
                            >
                              Shares
                            </a>
                          </div>
                          <div
                            id="socialf__scrollDepth"
                            className={`socialf socialf__scrollDepth collapse ${submenuShares ? 'in' : ''}`}
                            role="tabpanel"
                          >
                            <FilterFromTo
                              store={store}
                              filter={SHARES}
                              onChange={chooseFromTo}
                            />
                          </div>
                        </div>
                      )}

                      {mode === 'social' && (
                        <div className="card modal-fmenu__item-wrapper">
                          <div className="modal-fmenu__item clearfix" role="tab">
                            <a
                              className="modal-fmenu__item-link collapsed"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              href="#socialf__averTime"
                              aria-expanded="false"
                              aria-controls="socialf__averTime"
                              onClick={() => collapseMobileSubmenu('socialClicks')}
                            >
                              Social clicks
                            </a>
                          </div>
                          <div
                            id="socialf__averTime"
                            className={`socialf socialf__averTime collapse ${submenuSocialClicks ? 'in' : ''}`}
                            role="tab"
                            aria-expanded="false"
                          >
                            <FilterFromTo
                              store={store}
                              filter={SOCIAL_CLICKS}
                              onChange={chooseFromTo}
                            />
                          </div>
                        </div>
                      )}
                      {mode === 'social' && (
                        <div className="card modal-fmenu__item-wrapper">
                          <div className="modal-fmenu__item clearfix" role="tab">
                            <a
                              className="modal-fmenu__item-link collapsed"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              href="#socialf__soc_per_share"
                              aria-expanded="false"
                              aria-controls="socialf__soc_per_share"
                              onClick={() => collapseMobileSubmenu('socialClicksShare')}
                            >
                              Social clicks per share
                            </a>
                          </div>
                          <div
                            id="socialf__soc_per_share"
                            role="tabpanel"
                            className={`
                              socialf socialf__soc_per_share collapse ${submenuSocialClicksShare ? 'in' : ''}
                            `}
                          >
                            <FilterFromTo
                              store={store}
                              filter={SOC_PER_SHARE}
                              onChange={chooseFromTo}
                            />
                          </div>
                        </div>
                      )}
                      {mode === 'social' && (
                        <div className="card modal-fmenu__item-wrapper">
                          <div className="modal-fmenu__item clearfix" role="tab">
                            <a
                              className="modal-fmenu__item-link collapsed"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              href="#socialf__spm"
                              aria-expanded="false"
                              aria-controls="socialf__spm"
                              onClick={() => collapseMobileSubmenu('sharesPerMile')}
                            >
                              Shares per mile
                            </a>
                          </div>
                          <div
                            id="socialf__spm"
                            className={`socialf socialf__spm collapse ${submenuSharesPerMile ? 'in' : ''}`}
                            role="tabpanel"
                          >
                            <FilterFromTo store={store} filter={SHARES_PER_MILE} onChange={chooseFromTo} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isOpenMobile && <div className="modal-backdrop fade in" />}
        </div>
      )}

      {!isMobile && (
        <div className="row complicated-calendar__big">
          <div className="col-lg-12">
            <div className="complicated-calendar clearfix">
              <div className="complicated-calendar__bottom">
                <FilterPeriod
                  changeState={changeState}
                  page={getPage}
                />
              </div>
              <div className="complicated-filter">
                <div className={`dropdown complicated-filter__dd ${isOpen ? 'active' : ''}`}>
                  <button
                    type="button"
                    data-toggle="dropdown"
                    aria-label="breakdownMenu"
                    aria-haspopup="true"
                    aria-expanded="true"
                    id="addFilterDropdownMenu"
                    className="sdrop-down sdrop-down--filter complicated-filter__breakdown-select"
                    onClick={() => setIsOpen(prevState => !prevState)}
                  >
                    <Icon name="filter" />
                    <span>Add filter</span>
                    <Icon name="arrow-compact-down" />
                  </button>
                  {isOpen && (
                    <div
                      aria-labelledby="breakdownMenu"
                      className="dropdown-menu sdrop-down__menu"
                      ref={dropdownRef}
                    >
                      <ul className="complicated-filter__list">
                        <li className="complicated-filter__list-subtitle">By property:</li>
                        <li className="complicated-filter__list-item">
                          <a>Author</a>
                          <ul className="dropdown-list__author">
                            <li className="filterSubmenu__author">
                              <div className="form-group">
                                {authors?.length !== 0
                                  ? (
                                    <AuthorsList
                                      list={authors}
                                      onChange={chooseFilter}
                                    />
                                  )
                                  : null}
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="complicated-filter__list-item">
                          <a>Title</a>
                          <ul>
                            <li className="filterSubmenu__title">
                              <FilterTitle
                                statsData={statsData}
                                onChange={chooseTitle}
                              />
                            </li>
                          </ul>
                        </li>
                        <li className="complicated-filter__list-item">
                          <a>Publication date</a>
                          <ul>
                            <li className="filterSubmenu__pubDate">
                              <FilterPublicDate
                                handleDateIntervalPub={handleDateIntervalPub}
                                data_pubdate_from={dataPubdateFrom}
                                data_pubdate_to={dataPubdateTo}
                              />
                            </li>
                          </ul>
                        </li>
                        <li className="complicated-filter__list-item">
                          <a>Page type</a>
                          <ul>
                            <li className="filterSubmenu__pageType">
                              <FilterByList
                                uid="ptDesc"
                                list={listOfPageTypes}
                                onChange={choosePageType}
                              />
                            </li>
                          </ul>
                        </li>
                        {mode === 'social' && (
                          <li className="complicated-filter__list-item">
                            <a>Social network</a>
                            <ul>
                              <li className="filterSubmenu__pageType">
                                {socials?.length !== 0
                                  ? (
                                    <FilterByJSONList
                                      uid="ptDesc"
                                      listObjects={socials}
                                      onChange={(netwok: Social | BaseSyntheticEvent) => chooseSocial(netwok as Social)}
                                    />
                                  )
                                  : null}
                              </li>
                            </ul>
                          </li>
                        )}
                        <li className="complicated-filter__list-subtitle">By metric:</li>
                        <li className="complicated-filter__list-item">
                          <a>Page views</a>
                          <ul>
                            <li className="filterSubmenu__pageViews">
                              <FilterFromTo store={store} filter={PAGE_VIEWS} onChange={chooseFromTo} />
                            </li>
                          </ul>
                        </li>
                        {mode === 'content' && (
                          <li className="complicated-filter__list-item">
                            <a>Reading depth</a>
                            <ul>
                              <li className="filterSubmenu__scrollDepth">
                                <FilterFromTo store={store} filter={READING_DEPTH} onChange={chooseFromTo} />
                              </li>
                            </ul>
                          </li>
                        )}
                        {mode === 'content' && (
                          <li className="complicated-filter__list-item">
                            <a>Average reading time</a>
                            <ul>
                              <li className="filterSubmenu__averTime">
                                <FilterFromTo
                                  store={store}
                                  filter={AVERAGE_READING_TIME}
                                  onChange={chooseFromTo}
                                />
                              </li>
                            </ul>
                          </li>
                        )}
                        {mode === 'social' && (
                          <li className="complicated-filter__list-item">
                            <a>Shares</a>
                            <ul>
                              <li className="filterSubmenu__scrollDepth">
                                <FilterFromTo store={store} filter={SHARES} onChange={chooseFromTo} />
                              </li>
                            </ul>
                          </li>
                        )}

                        {mode === 'social' && (
                          <li className="complicated-filter__list-item">
                            <a>Social clicks</a>
                            <ul>
                              <li className="filterSubmenu__averTime">
                                <FilterFromTo store={store} filter={SOCIAL_CLICKS} onChange={chooseFromTo} />
                              </li>
                            </ul>
                          </li>
                        )}

                        {mode === 'social' && (
                          <li className="complicated-filter__list-item">
                            <a>Social clicks per share</a>
                            <ul>
                              <li className="filterSubmenu__soc_per_share">
                                <FilterFromTo store={store} filter={SOC_PER_SHARE} onChange={chooseFromTo} />
                              </li>
                            </ul>
                          </li>
                        )}

                        {mode === 'social' && (
                          <li className="complicated-filter__list-item">
                            <a>Shares per mile</a>
                            <ul>
                              <li className="filterSubmenu__spm">
                                <FilterFromTo store={store} filter={SHARES_PER_MILE} onChange={chooseFromTo} />
                              </li>
                            </ul>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="complicated-filter__activef">
                  {
                    activeFilters.length ?
                      activeFilters.map((v, index) => (
                        hashParseFilters()[v.label as keyof ParseFilters](v.object, index)
                      )) : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentAndSocialFilter;
