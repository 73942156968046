/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'uikit';
import { SortIcon } from 'components/Table';

export default overrideProps => ({
  accessor: 'itemTitle',
  resizable: false,
  headerClassName: 'uikit-table__cell--ae-first-cell',
  Header: (
    <FormattedMessage id="table.contentItem">
      {txt => <p className="cell-inner item-title-simple">{txt} {SortIcon}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <FormattedMessage id="table.total">
      {txt => <span className="cell-inner">{txt}</span>}
    </FormattedMessage>
  ),
  Cell: (props) => {
    if (props.value === 'Others') { // отчёт Content (#33122)
      return (
        <span className="cell-inner cell-inner--with-image cell-inner--wrap">
          <span className="image-holder">
            <img src={props.original.itemImage} />
          </span>
          <Tooltip
            content={(
              <span className="tooltip__text">
                URLs that don&#39;t match teaser patterns get consolidated into &#39;Others&#39;
              </span>
            )}
            placement="bottom-start"
            maxWidth={280}
          >
            <span className="others-text">{props.value}</span>
          </Tooltip>
        </span>
      );
    }
    return (
      <span className="cell-inner cell-inner--wrap" title={props.value}>
        <span className="image-holder">
          <img src={props.original.itemImage} alt={props.value} />
        </span>
        <span className="title-wrap">
          <a target="_blank" rel="noopener noreferrer" href={props.original.itemUrl}>{props.value}</a>
          {props.original.itemAuthor !== null || props.original.itemPublished !== null ? (
            <span>
              {props.original.itemAuthor !== null ?
                <span>{props.original.itemAuthor}</span> : ''}
              {props.original.itemPublished !== null ?
                <span>{props.original.itemPublished}</span> : ''}
            </span>
          )
            : '' }
        </span>
      </span>
    );
  },
  ...overrideProps,
});
