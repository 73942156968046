import momentTimezone from 'moment-timezone';
import moment, { Moment } from 'moment';
import config from 'config';
import {
  AddDayToDate,
  ChangeFormat,
  ChangeFormatPoint,
  ConvertToMinSecString,
  Diff,
  GetDateformat,
  GetFormattedDate,
  GetTimeStamp,
  HangeDateByDays,
  IsToday,
  Now,
} from 'app/ts/types/utils';

const dateFormat = 'YYYY-MM-DD';

export const getLocalTimeMoment = (): Moment => (
  typeof config === 'object' ? moment().tz(config.timezone) : moment()
);

export const getLocalTime = (): Date => {
  const momentDate = getLocalTimeMoment();

  return momentTimezone(new Date(
    momentDate.year(),
    momentDate.month(),
    momentDate.date(),
    momentDate.hours(),
    momentDate.minutes(),
    momentDate.seconds(),
    momentDate.milliseconds(),
  )).toDate();
};

export const isToday: IsToday = (date) => {
  const date1 = momentTimezone(date);
  const date2 = getLocalTimeMoment();

  return date1.year() === date2.year() && date1.month() === date2.month() && date1.date() === date2.date();
};

export const getFormattedDate: GetFormattedDate = (date, format) => momentTimezone(date).format(format);

export const diff: Diff = (date1, date2) => momentTimezone(date1).diff(momentTimezone(date2), 'days');

export const now: Now = () => moment().format(dateFormat);

export const addDayToDate: AddDayToDate = (number) => moment().add(number, 'd').format(dateFormat);

export const changeDateByDays: HangeDateByDays = (date, number) => moment(date).add(number, 'd').format(dateFormat);

export const changeFormat: ChangeFormat = (date) => date.split('-').reverse().join('.');

export const changeFormatPoint: ChangeFormatPoint = (date) => date.split('.').reverse().join('-');

export const getCalendarDateformat: GetDateformat = (time = getLocalTime()) => moment(time).format('DD.MM.YYYY');

export const getDateformatMonth: GetDateformat = (time = getLocalTime()) => moment(time).format('MMM DD');

export const getDateformat: GetDateformat = (time = getLocalTime()) => moment(time).format('YYYY MMM DD');

export const getTimeStamp: GetTimeStamp = (date) => moment(date).valueOf();

export const convertToMinSecString: ConvertToMinSecString = (minutes) => {
  const formattedMinutes = typeof minutes === 'string' ? parseFloat(minutes.replace(',', '.')) : minutes;
  const min = Math.floor(formattedMinutes);
  let sec;
  if (Math.floor((formattedMinutes - min) * 60) < 10) {
    sec = `0${Math.floor((formattedMinutes - min) * 60)}`;
  } else {
    sec = Math.floor((formattedMinutes - min) * 60);
  }
  return `${min}:${sec}`;
};

export default {
  getLocalTimeMoment,
  getLocalTime,
  isToday,
  getFormattedDate,
  diff,
  now,
  addDayToDate,
  changeDateByDays,
  changeFormat,
  changeFormatPoint,
  getCalendarDateformat,
  getDateformatMonth,
  getDateformat,
  getTimeStamp,
  convertToMinSecString,
};
