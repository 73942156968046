/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';

export default (overrideProps, totals, skipIn) => ({
  accessor: 'inClicks',
  resizable: false,
  headerStyle: { paddingRight: '40px', paddingLeft: '40px', borderRight: '1px solid #ededed' },
  style: { paddingRight: '40px', paddingLeft: '40px', borderRight: '1px solid #ededed' },
  Header: (
    <FormattedMessage id={skipIn ? 'table.clicks' : 'table.inClicks'}>
      {txt => <p className="cell-inner in-clicks">{SortIcon} {txt}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <span className="cell-inner" title={formatUtils.integer(totals.inClicks)}>
      {formatUtils.integer(totals.inClicks)}
    </span>
  ),
  Cell: props => (
    <span className="cell-inner" title={formatUtils.integer(props.value)}>{formatUtils.integer(props.value)}</span>
  ),
  ...overrideProps,
});
