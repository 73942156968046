import React, { BaseSyntheticEvent, ReactElement, useState } from 'react';
import { Checkbox, Dropdown, Icon, Button, Tooltip } from 'uikit';
import moment from 'moment';
import './MonthlyPerformanceReport.scss';
import config from 'config';
import { FormattedMessage } from 'react-intl';
import { cookie } from 'app/utils';

export const MonthlyPerformanceReport = (): ReactElement => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [checkedPeriods, setCheckedPeriods] = useState([]);
  const [downloadPeriod, setDownloadPeriod] = useState({ from: '', to: '' });
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const monthCounts = 3;

  const getDownloadUrl = () => {
    const urlParams = {
      ...downloadPeriod,
      token: cookie.getIsCookie('token'),
    };
    return [
      config.serverAddress,
      '/acts/closing',
      '?',
      (new URLSearchParams(urlParams)).toString(),
    ].join('');
  };

  const downLoadReport = () => {
    const url = getDownloadUrl();
    window.open(url, '_blank');
  };

  const formatMonthToPeriod = (period: { month: number, year: number }) => moment()
    .set({ month: period.month - 1, year: period.year })
    .format('MM-YYYY');

  const checkPeriods = (newPeriods: Array<{ month: number, year: number }>) => {
    setIsDownloadDisabled(true);

    if (newPeriods.length === 0) {
      return;
    }

    if (newPeriods.length !== 1) {
      let previousPeriod = 0;
      for (const checkedPeriod of newPeriods) {
        if (previousPeriod && previousPeriod + 1 !== checkedPeriod.month) {
          return;
        }
        previousPeriod = checkedPeriod.month;
      }
    }

    setIsDownloadDisabled(false);

    setDownloadPeriod({
      from: formatMonthToPeriod(newPeriods[0]),
      to: formatMonthToPeriod(newPeriods[newPeriods.length - 1]),
    });
  };

  const handleChangeMonth = (event: BaseSyntheticEvent): void => {
    const { target, target: { name, value } } = event;
    const { checked } = target;

    let newPeriods: Array<{ month: number, year: number }> = [];

    if (!checked) {
      newPeriods = checkedPeriods.filter(e => JSON.stringify(e) !==
        JSON.stringify({ month: parseInt(name, 10), year: parseInt(value, 10) }));
    } else {
      newPeriods = [{ month: parseInt(name, 10), year: parseInt(value, 10) }, ...checkedPeriods];
    }

    newPeriods.sort((prev, next) =>
      prev.year - next.year || prev.month - next.month);

    checkPeriods(newPeriods);

    setCheckedPeriods(newPeriods);
  };

  const createPeriodsOptionList = () => {
    const items = [];

    for (let i = 1; i <= monthCounts; i++) {
      const date = moment().subtract(i, 'month');
      const monthName = date.format('MMMM');
      const monthIndex = date.format('M');
      const year = date.format('YYYY');
      const checkBoxId = `monthly-performance-report-period-${i}`;

      items.push(
        <li key={i}>
          <Checkbox
            name={monthIndex}
            value={year}
            id={checkBoxId}
            onChange={handleChangeMonth}
          />
          <label htmlFor={checkBoxId} className="send-reports-label" tabIndex={-1}>
            {year} {monthName}
          </label>
        </li>,
      );
    }

    return (
      <ul className="monthly-performance-report__period-list">
        {items}
      </ul>
    );
  };
  return (
    <div className="monthly-performance-report">
      <div className="send-reports-label--monthly-performance send-reports-label">
        <Tooltip content={(
          <FormattedMessage id="tooltip.monthly-performance-report" />
        )}
        >
          <span>
            <FormattedMessage id="report.monthly-performance" />
          </span>
        </Tooltip>
        <Button
          type="button"
          kind="secondary"
          className="monthly-performance-report__download-button"
          format="icon"
          disabled={isDownloadDisabled}
          onClick={downLoadReport}
        >
          <Icon name="yui-icon yui-icon--download" size={18} />
        </Button>
      </div>

      <div className="dropdown dropdown--monthly-performance-report-periods">
        <Dropdown
          visible={isDropdownVisible}
          onClickOutside={() => setIsDropdownVisible(false)}
          content={createPeriodsOptionList()}
          trigger="click"
          placement="bottom-start"
          arrow={false}
          offset={[0, 5]}
          interactive
        >
          <button
            type="button"
            aria-label="formatTypes"
            aria-haspopup="true"
            aria-expanded="true"
            className="sdrop-down format-types__button"
            onClick={() => setIsDropdownVisible(true)}
          >
            <FormattedMessage id="report.select-period" />
            <Icon name="arrow-compact-down" />
          </button>
        </Dropdown>
      </div>
    </div>
  );
};
