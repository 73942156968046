import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ajax, metrics, cookie, isValidJSONString, showAlert } from 'app/utils';
import { TextArea, TextField } from 'uikit';
import { useAppSelector, useAppDispatch } from 'app/store/hooks';
import { setInfo } from 'app/store/accountInfo/accountInfoSlice';
import { Site } from 'app/ts/interfaces';
import './SiteSettings.scss';

const SiteSettings = () => {
  const {
    currentSite,
    currentSiteName,
    adsTxtLines,
    admin,
    sitesList,
  } = useAppSelector(reduxState => reduxState.accountInfo);
  const [siteData, setSiteData] = useState({
    collector_settings: '',
    settings: '',
    spider_settings: '',
    name: '',
    url: '',
    is_https: false,
    is_collector_enabled: false,
    is_spider_enabled: false,
  });
  const [validFields, setValidFields] = useState({
    collector_settings: true,
    settings: true,
    spider_settings: true,
  });
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleChange = useCallback((event) => {
    const { target } = event;
    const { name } = target;
    if (name in validFields) {
      setValidFields(prevValidFields => ({
        ...prevValidFields,
        [name]: isValidJSONString(target.value),
      }));
    }
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setSiteData(prevSiteData => ({
      ...prevSiteData,
      [name]: value,
    }));
  }, [validFields]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    const { collector_settings, settings, spider_settings } = siteData; // eslint-disable-line
    const nextValidFields = {
      collector_settings: isValidJSONString(collector_settings),
      settings: isValidJSONString(settings),
      spider_settings: isValidJSONString(spider_settings),
    };
    setValidFields(nextValidFields);

    if (Object.values(nextValidFields).every(Boolean)) {
      ajax.put(`/sites/${currentSite}`, siteData).then(({ data: responseData }) => {
        if (responseData?.data?.id) {
          const updatedSitesList = sitesList.map((item: Site) => {
            if (item.id === responseData.data.id) {
              return { ...item, name: responseData.data.name };
            }
            return item;
          });

          dispatch(setInfo({ sitesList: updatedSitesList }));
          showAlert('Site settings have been saved');

          metrics.metricEvent('reachGoal', 'SAVE_SITE_SETTIGNS', {
            admin,
            siteName: currentSiteName,
          });
        }
      });
    }
  }, [siteData, currentSite, admin, currentSiteName, sitesList, dispatch]);

  useEffect(() => {
    const getSiteData = () => {
      ajax.get(`/sites/${currentSite}`).then(({ data }) => {
        if (data) {
          const { data: receivedSiteData } = data;

          setSiteData({
            ...receivedSiteData,
          });
          setValidFields({
            collector_settings: isValidJSONString(receivedSiteData.collector_settings),
            settings: isValidJSONString(receivedSiteData.settings),
            spider_settings: isValidJSONString(receivedSiteData.spider_settings),
          });
        }
      });
    };

    if (!cookie.getIsCookie('is_admin')) {
      history.push('/dashboard');
    } else {
      getSiteData();
    }

    metrics.metricEvent('hit', '/site-settings');
    metrics.metricEvent('reachGoal', 'OPEN_SITE_SETTIGNS', {
      admin,
      siteName: currentSiteName,
    });
  }, [admin, currentSiteName, currentSite, history]);

  return (
    <div className="wrapper wrapper--site-settings">
      {adsTxtLines &&
          (
            <>
              <h5 className="site-settings__subtitle">ADS.TXT</h5>
              <div className="site-settings__adstxt-lines">
                <pre>
                  {adsTxtLines}
                </pre>
              </div>
            </>
          )}
      <div className="container-fluid">
        {(!!Object.keys(siteData).length) && (
        <form className="form form--site-settings" onSubmit={handleSubmit}>
          <div className="form__control mb24">
            <div className="form--bordered">
              <div className="form__control form__control--title">
                Common settings
              </div>
              <div className="form__control form__control--body">
                <div className="form__control form__control--site-settings form__control--w6">
                  <TextField
                    type="text"
                    label="Name"
                    value={siteData.name || ''}
                    elementId="name"
                    name="name"
                    onChange={handleChange}
                    required
                  />
                  <span />
                </div>
                <div className="form__control form__control--site-settings form__control--w6">
                  <TextField
                    type="text"
                    label="URL"
                    value={siteData.url || ''}
                    elementId="url"
                    name="url"
                    onChange={handleChange}
                    required
                  />
                  <span />
                </div>
                <div className="form__control form__control--site-settings form__control--w3">
                  <div className="material-switch material-switch--site-settings">
                    <input
                      checked={siteData.is_https}
                      onChange={handleChange}
                      id="is_https"
                      name="is_https"
                      type="checkbox"
                    />
                    <label htmlFor="is_https" className="form__label">HTTPS</label>
                  </div>
                </div>
                <div className="form__control form__control--site-settings form__control--w3">
                  <div className="material-switch material-switch--site-settings">
                    <input
                      checked={siteData.is_collector_enabled}
                      onChange={handleChange}
                      id="is_collector_enabled"
                      name="is_collector_enabled"
                      type="checkbox"
                    />
                    <label htmlFor="is_collector_enabled" className="form__label">Collector enabled</label>
                  </div>
                </div>
                <div className="form__control form__control--site-settings form__control--w3">
                  <div className="material-switch material-switch--site-settings">
                    <input
                      checked={siteData.is_spider_enabled}
                      onChange={handleChange}
                      id="is_spider_enabled"
                      name="is_spider_enabled"
                      type="checkbox"
                    />
                    <label htmlFor="is_spider_enabled" className="form__label">Spider enabled</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form__control form__control--w6 mb24">
            <div className="form--bordered">
              <div className="form__control form__control--title ">
                Collector settings
              </div>
              <div className="form__control form__control--body ">
                <TextArea
                  invalid={!validFields.collector_settings}
                  value={siteData.collector_settings || ''}
                  elementId="collector_settings"
                  name="collector_settings"
                  onChange={handleChange}
                  helper={!validFields.collector_settings ? 'invalid JSON' : null}
                  helperClassName="site-settings__error-message"
                  rows={8}
                />
                <span />
              </div>
            </div>
          </div>
          <div className="form__control form__control--w6 mb24">
            <div className="form--bordered">
              <div className="form__control form__control--title ">
                Spider settings
              </div>
              <div className="form__control form__control--body ">
                <TextArea
                  invalid={!validFields.spider_settings}
                  value={siteData.spider_settings || ''}
                  elementId="spider_settings"
                  name="spider_settings"
                  onChange={handleChange}
                  helper={!validFields.spider_settings ? 'invalid JSON' : null}
                  helperClassName="site-settings__error-message"
                  rows={8}
                />
                <span />
              </div>
            </div>
          </div>
          <div className="form__control form__control--w6 mb24">
            <div className="form--bordered">
              <div className="form__control form__control--title ">
                Site settings
              </div>
              <div className="form__control form__control--body ">
                <TextArea
                  invalid={!validFields.settings}
                  value={siteData.settings || ''}
                  elementId="settings"
                  name="settings"
                  onChange={handleChange}
                  helper={!validFields.settings ? 'invalid JSON' : null}
                  helperClassName="site-settings__error-message"
                  rows={8}
                />
                <span />
              </div>
            </div>
          </div>
          <div className="form__control text--center mb24">
            <input type="submit" value="Submit" className="constructor-btn" />
          </div>
        </form>
        )}
      </div>
    </div>
  );
};

export default SiteSettings;
