import { ExportToCSV, ProcessCSVRow, CSVRow } from 'app/ts/types/utils';

const processRow: ProcessCSVRow = (row) => {
  let finalVal: string = '';
  for (let j: number = 0; j < row.length; j += 1) {
    let innerValue: string = row[j] === null ? '' : row[j].toString();
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString();
    }
    let result: string = innerValue.replace(/"/g, '""');
    if (result.match(/\./gmi) && result.match(/\./gmi)?.length === 1) result = result.replace('.', ',');
    if (j > 0) finalVal += ';';
    finalVal += `"${result}"`;
  }
  return `${finalVal}\n`;
};

export const exportToCsv: ExportToCSV = (filename, rows) => {
  let csvFile: string = '';
  for (let i = 0; i < rows.length; i += 1) {
    csvFile += processRow(rows[i] as CSVRow);
  }

  const blob: Blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link: HTMLAnchorElement = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url: string = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export default { exportToCsv };
