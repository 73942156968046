import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ajax, isEmptyObject } from 'app/utils';
import { AccountSettingsState, NewAudienceColumns, EngagementColumns, RevenueColumns } from 'app/ts/interfaces';

export const initialState: AccountSettingsState = {
  reports: {
    newaudience: {
      columns: {
        outPageViews: true,
        widgetViews: true,
        outAdShows: true,
        outClicks: true,
        outCtr: false,
        wctr: true,
        inClicks: true,
        exchangeRatio: true,
      },
    },
    engagement: {
      columns: {
        pageViews: true,
        widgetViews: true,
        impressions: true,
        clicks: true,
        ctr: false,
        wctr: true,
        viewsAfter: true,
        increase: true,
      },
    },
    revenue: {
      columns: {
        pageViews: true,
        widgetViews: true,
        adShows: true,
        clicks: true,
        ctr: false,
        wctr: true,
        cpc: true,
        rpm: false,
        wrpm: true,
        revenue: true,
      },
    },
  },
};

export const fetchSettings = createAsyncThunk('account/fetchSettings', async () => {
  const response = await ajax.get('/account/settings');
  return response.data.data;
});

const accountSettingsSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setEngagementColumns(state, action: PayloadAction<EngagementColumns>) {
      const newState = JSON.parse(JSON.stringify(state));
      newState.reports.engagement.columns = action.payload;
      ajax.post('/account/settings', newState);
      return newState;
    },
    setNewAudienceColumns(state, action: PayloadAction<NewAudienceColumns>) {
      const newState = JSON.parse(JSON.stringify(state));
      newState.reports.newaudience.columns = action.payload;
      ajax.post('/account/settings', newState);
      return newState;
    },
    setRevenueColumns(state, action: PayloadAction<RevenueColumns>) {
      const newState = JSON.parse(JSON.stringify(state));
      newState.reports.revenue.columns = action.payload;
      ajax.post('/account/settings', newState);
      return newState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.fulfilled, (state, action) => {
        if (isEmptyObject(action.payload)) {
          ajax.post('/account/settings', { ...initialState });
          return initialState;
        }
        return action.payload;
      });
  },
});

export const {
  setEngagementColumns,
  setNewAudienceColumns,
  setRevenueColumns,
} = accountSettingsSlice.actions;

export default accountSettingsSlice.reducer;
