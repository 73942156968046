import * as React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Tooltip } from 'uikit';
import { FormattedMessage } from 'react-intl';
import { format } from '../../utils';

const PieChartDataInfo = (props) => {
  const { percent, flags, pwDelta, pw } = props;
  const className = 'info-block__value-dif';
  let Pagewiew = (
    <div className="info-block__title info-block__title--board">
      <div className="info-block__marker" data-percent={format.percentage(percent)} />
      <div className="info-block__text info-block__text--marker">
        <Tooltip
          content={(
            <span className="tooltip__text">
              <FormattedMessage id="pieChartDataInfo.audience-engagement" />
            </span>
          )}
          placement="bottom"
        >
          <span>Audience engagement</span>
        </Tooltip>
      </div>
    </div>
  );

  if (flags === 'newA') {
    Pagewiew = (
      <div className="info-block__title info-block__title--board">
        <div
          className="info-block__marker info-block__marker--orange"
          data-percent={format.percentage(percent)}
        />
        <div className="info-block__text info-block__text--marker">
          <Tooltip
            content={(
              <span className="tooltip__text">
                <FormattedMessage id="pieChartDataInfo.new-audience" />
              </span>
            )}
            placement="bottom"
          >
            <span>New audience</span>
          </Tooltip>
        </div>
      </div>
    );
  } else if (flags === 'Rev') {
    Pagewiew = (
      <div className="info-block__title info-block__title--board">
        <div
          className="info-block__marker info-block__marker--green"
          data-percent={format.percentage(percent)}
        />
        <div className="info-block__text info-block__text--marker">
          <Tooltip
            content={(
              <span className="tooltip__text">
                <FormattedMessage id="pieChartDataInfo.revenue" />
              </span>
            )}
            placement="bottom"
          >
            <span>Revenue</span>
          </Tooltip>
        </div>
      </div>
    );
  } else if (flags === 'wGi') {
    Pagewiew = (
      <div className="info-block__title info-block__title--board">
        <div
          className="info-block__marker info-block__marker--gray"
          data-percent={format.percentage(percent)}
        />
        <div className="info-block__text info-block__text--marker">
          <Tooltip
            content={(
              <span className="tooltip__text">
                <FormattedMessage id="pieChartDataInfo.without-giraff-widget" />
              </span>
            )}
            placement="bottom"
          >
            <span>
              Without <br />Giraff.io&nbsp;widget
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }
  const pwFloated = (typeof (pwDelta) === 'number' ? format.mathRound(pwDelta, 2) : 0);

  return (
    <div className="info-block">
      <div className="info-block__value">{ (typeof (pw) === 'number' ? numeral(pw).format() : 0) }</div>
      <div className={className + (pwDelta >= 0 ? '' : ' info-block__value-dif--negative')}>
        {(pwDelta >= 0 ? '+' : '') + format.trimNumbers(pwFloated)}
      </div>
      <div className="clearfix" />
      {Pagewiew}
    </div>
  );
};

PieChartDataInfo.propTypes = {
  percent: PropTypes.any,
  flags: PropTypes.string,
  pwDelta: PropTypes.any,
  pw: PropTypes.number,
};

PieChartDataInfo.defaultProps = {
  percent: 0,
  flags: 'audEng',
  pwDelta: 0,
  pw: 0,
};

export default PieChartDataInfo;
