/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';

export default overrideProps => ({
  accessor: 'referer',
  resizable: false,
  Header: (
    <FormattedMessage id="table.referrer">{txt => <p className="cell-inner">{txt} {SortIcon}</p>}</FormattedMessage>
  ),
  Footer: (
    <FormattedMessage id="table.totalAndAverage">{txt => <span className="cell-inner">{txt}</span>}</FormattedMessage>
  ),
  Cell: props => <span className="cell-inner" title={props.value}>{props.value}</span>,
  ...overrideProps,
});
