import * as React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Tooltip } from 'uikit';
import { FormattedMessage } from 'react-intl';
import { format } from '../../utils';

const PieChartTotal = (props) => {
  const { total } = props;
  const ftPageviewsDelta = (typeof (total.pageviews_delta) === 'number' ?
    format.mathRound(total.pageviews_delta, 2) : 0);
  const classNameDelta = 'info-block__value-dif info-block__value-dif--big';
  const classNameInc = 'info-block__value-dif info-block__value-dif--sub';
  return (
    <div className="dashboard__info-block--big">
      <div className="info-block info-block--big">
        <div className="info-block__value">
          {numeral(total.pageviews).format()}
        </div>
        <div className="info-block--diffgrp">
          <div className={total.pageviews_delta > 0 ?
            classNameDelta : `${classNameDelta} info-block__value-dif--negative`}
          >
            {
              total.pageviews_delta > 0 ?
                `+${format.trimNumbers(ftPageviewsDelta)}` : format.trimNumbers(ftPageviewsDelta)
            }
          </div>
          <div className={classNameInc + (total.pageviews_inc > 0 ? '' : ' info-block__value-dif--negative')}>
            {`(${format.percentage(total.pageviews_inc)})`}
          </div>
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--big">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="pieChartTotal.total" />
                </span>
                )}
              placement="bottom"
            >
              <span>Total</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

PieChartTotal.propTypes = {
  total: PropTypes.object.isRequired,
};

export default PieChartTotal;
