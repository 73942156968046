import React, { ReactElement } from 'react';

import { AudienceGraffProps, AudienceLegend } from 'app/ts/types/AudienceGraff';

import Charts from '../default/Charts';

const AudienceGraff = (props: AudienceGraffProps): ReactElement => {
  const { timeframe, byHour } = props;
  if (!timeframe?.length) {
    return null;
  }

  const legend: Array<AudienceLegend> = [
    {
      group: 0,
      title: 'Page views',
      color: '#ff6363',
      field: 'out_pageviews',
      valueDecimals: 0,
      disabled: true,
    },
    {
      group: 1,
      title: 'Clicks',
      color: '#00db71',
      field: 'out_clicks',
      valueDecimals: 0,
    },
    {
      group: 1,
      title: 'WCTR (%)',
      color: '#ae80ff',
      field: 'wctr',
      suffix: '%',
      disabled: true,
    },
    {
      group: 2,
      title: 'Clicks',
      color: '#ffc400',
      field: 'in_clicks',
      valueDecimals: 0,
      disabled: true,
    },
    {
      group: 3,
      title: 'Exchange ratio',
      color: '#2a75ff',
      valueDecimals: 3,
      field: 'exchange_ratio',
      disabled: true,
    },
  ];

  return (
    <Charts
      timeframe={timeframe}
      byHour={byHour}
      id="Audience"
      legend={legend}
      groups={[
        {},
        { title: 'Outgoing traffic' },
        { title: 'Incoming new traffic' },
        {},
      ]}
      pageType="date"
    />
  );
};

export default AudienceGraff;
