import React, { ReactElement } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dropdown, Icon, Checkbox } from 'uikit';
import Table from 'app/components/Table';
import { stats as statsUtils } from 'app/utils';

import { NewAudiencePgTableProps, ColumnsConfig, DisplayedColumns } from 'app/ts/types/NewAudiencePgTable';

import { AppDispatch } from 'app/store';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { setNewAudienceColumns } from '../../../../store/accountSettings/accountSettingsSlice';
import { getDisplayedColumns } from '../../columnsHelpers/newAudience';

import './NewAudience.scss';

const NewAudience = (props: NewAudiencePgTableProps): ReactElement => {
  const { totalsData, statsData, totalItems, changeState, isLoading } = props;
  const columnsConfig: ColumnsConfig =
    useAppSelector(reduxState => reduxState.accountSettings.reports.newaudience.columns);
  const dispatch: AppDispatch = useAppDispatch();
  const displayedColumns: DisplayedColumns = getDisplayedColumns({ totalsData, columnsConfig });
  const displayedColumnsNames: Array<string> = displayedColumns
    .reduce((accumulator, currentItem) => {
      currentItem.columns.forEach((element) => {
        accumulator.push(element.accessor);
      });

      return accumulator;
    }, [])
    .filter(item => item !== 'date');

  const columnsSettingsContent: ReactElement = (
    <ul>
      {displayedColumnsNames.map((item: string, index: number) => (
        <li
          key={index}
        >
          <Checkbox
            id={item}
            name={item}
            checked={columnsConfig[item as keyof ColumnsConfig]}
            onChange={() => {
              const payload = {
                ...columnsConfig,
                [item]: !columnsConfig[item as keyof ColumnsConfig],
              };
              dispatch(setNewAudienceColumns(payload));
            }}
          >
            <FormattedMessage id={`table.${item}`} />
          </Checkbox>
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <div className="columns-settings">
        <Dropdown
          theme="dropdown-checkbox-list"
          placement="bottom-end"
          content={columnsSettingsContent}
          arrow={false}
          trigger="click"
          interactive
        >
          <button
            type="button"
            className="dropdown__button dropdown__button--columns-settings"
          >
            Settings
            <Icon
              name="settings"
              size={18}
              className="dropdown__icon dropdown__icon--columns-settings"
            />
          </button>
        </Dropdown>
      </div>
      <div className="new-audience-pg-table">
        <Table
          columns={displayedColumns}
          data={statsData.map(statsUtils.fromServerData)}
          dataCount={totalItems}
          changeState={changeState}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default injectIntl(NewAudience);
