import React from 'react';
import PropTypes from 'prop-types';
import { menuGroupTitles } from 'utils';

const HeaderTitle = ({ page }) => (
  <div className="page-header__bottom">
    <div className="wrapper wrapper--header">
      <div className="page-header__title">
        {
          page && page.title ? (
            <div>
              <div className="page-header__upper">
                {menuGroupTitles[page.menuGroup] && menuGroupTitles[page.menuGroup].title}
              </div>
              <div className={`page-header__title--dou page-header__title--${
                page.title.toLowerCase().replace(/\s+/g, '-')
              }`}
              >
                {page.title}
              </div>
            </div>
          ) : <div className="page-header__title-name">Dashboard</div>
        }
      </div>
    </div>
  </div>
);

HeaderTitle.propTypes = {
  page: PropTypes.shape({ title: PropTypes.string, menuGroup: PropTypes.string }),
};

HeaderTitle.defaultProps = {
  page: {},
};

export default HeaderTitle;
