import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'uikit';
import { format } from '../../utils';

const Revenue = (props) => {
  const className = 'info-block__value-dif info-block__value-dif--board';
  const { revenue } = props;
  return (
    <div className="board__content  board__table">
      <div className="info-block info-block--board">
        <div className="info-block__value">
          {typeof (revenue.rpm) === 'number' ? format.money(revenue.rpm) : 0}
        </div>
        <div
          className={revenue.rpm_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(revenue.rpm_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.revenue_rpm" />
                </span>
              )}
              placement="bottom"
            >
              <span>RPM</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info-block info-block--board">
        <div className="info-block__value">
          {typeof (revenue.wrpm) === 'number' ? format.money(revenue.wrpm) : 0}
        </div>
        <div
          className={revenue.wrpm_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(revenue.wrpm_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.revenue_wrpm" />
                </span>
              )}
              placement="bottom"
            >
              <span>WRPM</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info-block info-block--board">
        <div className="info-block__value">
          {typeof (revenue.revenue) === 'number' ? format.money(revenue.revenue) : 0}
        </div>
        <div
          className={revenue.revenue_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(revenue.revenue_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.revenue" />
                </span>
              )}
              placement="bottom"
            >
              <span>Revenue</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

Revenue.propTypes = {
  revenue: PropTypes.object.isRequired,
};

export default Revenue;
