import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import Table from 'app/components/Table';
import columns from 'app/components/tables/columns';
import { stats as statsUtils } from 'app/utils';
import './SummaryReport.scss';

import { SummaryGraffProps } from 'app/ts/types/SummaryGraff';
import { TotalsData } from 'app/ts/types/utils';
import { ColumnsConfig } from 'app/ts/types/ColumnsConfig';

const SummaryReport = (props: SummaryGraffProps): ReactElement => {
  const { statsData, totalsData, totalItems, changeState, isLoading } = props;
  const getColumnsConfig = (): Array<ColumnsConfig> => {
    const totals: TotalsData = statsUtils.fromServerData(totalsData);
    return [
      {
        Header: '',
        columns: [
          columns.date({ minWidth: 120 }),
          columns.totalPageViews({ width: 165 }, totals),
        ],
      },
      {
        Header: <FormattedMessage id="table.audienceEngagement">{txt => <span>{txt}</span>}</FormattedMessage>,
        columns: [
          columns.engagementClicks({ maxWidth: 125 }, totals),
          columns.engagementCtr({ maxWidth: 125 }, totals),
        ],
      },
      {
        Header: <FormattedMessage id="table.newAudience">{txt => <span>{txt}</span>}</FormattedMessage>,
        columns: [
          columns.outClicks({ maxWidth: 150 }, totals),
          columns.inClicks({ maxWidth: 150 }, totals),
          columns.exchangeRatio({ maxWidth: 150 }, totals),
        ],
      },
      {
        Header: <FormattedMessage id="table.revenue">{txt => <span>{txt}</span>}</FormattedMessage>,
        columns: [
          columns.rpm({ maxWidth: 125 }, totals),
          columns.revenue({ maxWidth: 150 }, totals),
        ],
      },
    ];
  };

  return (
    <div className="summary-report-pg-table">
      <Table
        columns={getColumnsConfig()}
        data={statsData.map(statsUtils.fromServerData)}
        dataCount={totalItems}
        changeState={changeState}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SummaryReport;
