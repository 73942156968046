import React, { BaseSyntheticEvent, ReactElement } from 'react';
import Table from 'app/components/Table';
import columns from 'app/components/tables/columns';
import { stats as statsUtils } from 'app/utils';
import cx from 'classnames';
import './AuthorsContent.scss';
import './AuthorsSocial.scss';

import { TotalsData } from 'app/ts/types/utils';
import { AuthorsStats } from 'app/ts/interfaces';
import { ColumnsConfig } from 'app/ts/types/ColumnsConfig';
import { AuthorsPgTableProps } from 'app/ts/types/AuthorsPgTable';

const Authors = (props: AuthorsPgTableProps): ReactElement => {
  const { mode, statsData, totalItems, totalsData, changeState, isLoading } = props;
  const [sortField, setSortField] = React.useState<keyof AuthorsStats>();
  const [sortDesc, setSortDesc] = React.useState<boolean>(true);

  const tableRef = React.useRef<ReactElement>();

  let data: Array<AuthorsStats> = statsData.slice();
  if (sortField) {
    statsUtils.sortData(data, sortField);
  }
  data = data.map(statsUtils.fromServerData);

  const forceSorting = (event: BaseSyntheticEvent, passedSortField: keyof AuthorsStats): void => {
    event.stopPropagation();
    event.preventDefault();

    if (passedSortField !== sortField) {
      setSortField(passedSortField);
    } else {
      setSortDesc(prevState => !prevState);
    }
  };

  const getColumnsConfig = (): Array<ColumnsConfig> => {
    const totals: TotalsData = statsUtils.fromServerData(totalsData);

    return [
      columns.author({ minWidth: 300 }),
      columns.pageViews({ maxWidth: 200 }, totals),
      columns.readingDepth(
        { width: 150, show: mode === 'content' },
        totals,
        { sortField, forceSorting },
      ),
      columns.readingTime(
        { width: 150, show: mode === 'content' },
        totals,
        { sortField, forceSorting },
      ),
      columns.rpm({ maxWidth: 150, show: mode === 'content' }, totals),
      columns.revenueGiraff({ maxWidth: 150, show: mode === 'content' }, totals),
      columns.shares({ maxWidth: 150, show: mode === 'social' }, totals),
      columns.sharesPerMile({ maxWidth: 150, show: mode === 'social' }, totals),
      columns.socialClicks({ maxWidth: 150, show: mode === 'social' }, totals),
      columns.socialClicksPerShare({ width: 200, show: mode === 'social' }, totals),
    ];
  };

  return (
    <div className={cx({
      'authors-content-pg-table': mode === 'content',
      'authors-social-pg-table': mode === 'social',
    })}
    >
      <Table
        columns={getColumnsConfig()}
        data={data}
        dataCount={totalItems}
        changeState={changeState}
        tableRef={(ref: ReactElement) => tableRef.current = ref}
        defaultSorted={sortField ? [{ id: sortField, desc: sortDesc }] : undefined}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Authors;
