import adShows from './adShows';
import audienceEngagementPageViews from './audienceEngagementPageViews';
import author from './author';
import clicks from './clicks';
import country from './country';
import cpc from './cpc';
import ctr from './ctr';
import date from './date';
import device from './device';
import engagementClicks from './engagementClicks';
import engagementCtr from './engagementCtr';
import exchangeRatio from './exchangeRatio';
import excluded from './excluded';
import impressions from './impressions';
import inClicks from './inClicks';
import increase from './increase';
import itemTitle from './itemTitle';
import itemTitleMoreInfo from './itemTitleMoreInfo';
import itemTitleSimple from './itemTitleSimple';
import newAudiencePageViews from './newAudiencePageViews';
import outAdShows from './outAdShows';
import outClicks from './outClicks';
import outCtr from './outCtr';
import pageViews from './pageViews';
import pageViewsPerVisit from './pageViewsPerVisit';
import readingDepth from './readingDepth';
import readingDepthSimple from './readingDepthSimple';
import readingTime from './readingTime';
import readingTimeSimple from './readingTimeSimple';
import referer from './referer';
import pinAction from './pinAction';
import revenue from './revenue';
import revenueGiraff from './revenueGiraff';
import revenuePageViews from './revenuePageViews';
import revenueWidgetShows from './revenueWidgetShows';
import rpm from './rpm';
import shares from './shares';
import sharesPerMile from './sharesPerMile';
import socialClicks from './socialClicks';
import socialClicksPerShare from './socialClicksPerShare';
import statusSwitcher from './statusSwitcher';
import title from './title';
import totalPageViews from './totalPageViews';
import trash from './trash';
import url from './url';
import viewsAfter from './viewsAfter';
import visits from './visits';
import wctr from './wctr';
import widget from './widget';
import widgetViews from './widgetViews';
import wrpm from './wrpm';
import paymentDate from './paymentDate';
import paymentSum from './paymentSum';

export default {
  adShows,
  audienceEngagementPageViews,
  author,
  clicks,
  country,
  cpc,
  ctr,
  date,
  device,
  engagementClicks,
  engagementCtr,
  exchangeRatio,
  excluded,
  impressions,
  inClicks,
  increase,
  itemTitle,
  itemTitleMoreInfo,
  itemTitleSimple,
  newAudiencePageViews,
  outAdShows,
  outClicks,
  outCtr,
  pageViews,
  pageViewsPerVisit,
  paymentDate,
  paymentSum,
  readingDepth,
  readingDepthSimple,
  readingTime,
  readingTimeSimple,
  referer,
  pinAction,
  revenue,
  revenueGiraff,
  revenuePageViews,
  revenueWidgetShows,
  rpm,
  shares,
  sharesPerMile,
  socialClicks,
  socialClicksPerShare,
  statusSwitcher,
  title,
  totalPageViews,
  trash,
  url,
  viewsAfter,
  visits,
  wctr,
  widget,
  widgetViews,
  wrpm,
};
