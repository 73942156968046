import React, { BaseSyntheticEvent, ReactElement, Reducer } from 'react';
import { DatePicker, Icon } from 'uikit';
import { tz } from 'app/utils';
import moment from 'moment';
import './FilterPeriod.scss';

import { FilterPeriodProps, PeriodInitialState, Action } from 'app/ts/types/FilterPeriod';
import { DateIntervalProps } from 'app/ts/types/DateIntervalProps';

const reducer = (state: PeriodInitialState, action: Action): PeriodInitialState => {
  switch (action.type) {
    case 'closeDatePicker':
      return {
        ...state,
        isDatePickerOpen: false,
        outputDate: action?.payload ? action.payload.value : state.outputDate,
        dataDateFrom: action?.payload ? action.payload.from : state.dataDateFrom,
        dataDateTo: action?.payload ? action.payload.to : state.dataDateTo,
      };
    case 'toggleDatePicker':
      return {
        ...state,
        isDatePickerOpen: !state.isDatePickerOpen,
        dataDateFrom: action?.payload ? action.payload.from : state.dataDateFrom,
        dataDateTo: action?.payload ? action.payload.to : state.dataDateTo,
      };
    case 'dateDaysIncDecr':
      return {
        ...state,
        isDatePickerOpen: false,
        selectedPeriod: action.payload.selectedPeriod,
      };
    case 'showTimeInterval':
      return {
        ...state,
        selectedItem: 'interval',
        dateInterval: true,
        countDay: null,
      };
    case 'Date1day':
      return {
        ...state,
        selectedItem: '1 day',
        countDay: 1,
        dateInterval: false,
        selectedPeriod: action.payload.selectedPeriod,
        checkEndInterval: action.payload.checkEndInterval,
        isDatePickerOpen: false,
        outputDate: 'Custom period',
        dataDateFrom: '',
        dataDateTo: '',
      };
    case 'Date7days':
      return {
        ...state,
        selectedItem: '7 days',
        countDay: 7,
        dateInterval: false,
        selectedPeriod: action.payload?.selectedPeriod,
        checkEndInterval: action.payload?.checkEndInterval,
        outputDate: 'Custom period',
        dataDateFrom: '',
        dataDateTo: '',
      };
    case 'Date30days':
      return {
        ...state,
        selectedItem: '30 days',
        countDay: 30,
        dateInterval: false,
        selectedPeriod: action.payload.selectedPeriod,
        checkEndInterval: action.payload.checkEndInterval,
        outputDate: 'Custom period',
        dataDateFrom: '',
        dataDateTo: '',
      };
    case 'DateInterval':
      return {
        ...state,
        selectedItem: 'interval',
        countDay: null,
        dateInterval: true,
        selectedPeriod: 'Other',
        dataDateFrom: state.dataDateFrom ? '' : sessionStorage.filterDateFrom,
        dataDateTo: state.dataDateTo ? '' : sessionStorage.filterDateTo,
        outputDate: action.payload ? action.payload.value : state.outputDate,
      };
    default:
      return state;
  }
};

const FilterPeriod = (props: FilterPeriodProps): ReactElement => {
  const { changeState, groupBy: pageTypeToChange = null, page: getPageInfo, responsiveFlag = 'desktop' } = props;
  const initialState: PeriodInitialState = {
    page: getPageInfo(), // название отчёта/страницы, раздел в котором он находится и т.д.
    showTimeIntervalMobile: false,
    isDatePickerOpen: false,
    outputDate: 'Custom period', // даты на кнопке Custom period (десктоп)
    selectedPeriod: '', // текущий выбранный период
    selectedItem: '', // одна из доступных опций: 1/7/30/interval
    checkEndInterval: false, // стрелочки на кнопках (десктоп), Previous/Next 'x' days (мобайл)
    dateInterval: null, // выбрана ли опция "interval"
    countDay: 0, // кол-во дней в выбранном периоде
    dataDateFrom: '', // если был выбран кастомный период (не 1/7/30), то дата начала интервала
    dataDateTo: '', // если был выбран кастомный период (не 1/7/30), то дата конца интервала
  };

  const [state, dispatch] = React.useReducer<Reducer<PeriodInitialState, Action>>(reducer, initialState);
  const {
    page: { storageKey },
    isDatePickerOpen,
    outputDate,
    selectedPeriod,
    selectedItem,
    checkEndInterval,
    countDay,
    dataDateFrom,
    dataDateTo,
    dateInterval,
  } = state;

  const sessionStoragePeriod: string = sessionStorage[`filter${storageKey}`];

  const defaultStartDate: Date = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);

  const handleDate1day = (): void => {
    sessionStorage.setItem(`filter${storageKey}`, 'Date1day');
    sessionStorage.setItem('filterDateGlobal', 'Date1day');
    sessionStorage.setItem('filterDateTo', tz.now());
    sessionStorage.setItem('filterDateFrom', tz.now());

    changeState({ pageTypeToChange });
    dispatch({ type: 'closeDatePicker' });
  };

  const handleDate7days = React.useCallback(
    (): void => {
      sessionStorage.setItem(`filter${storageKey}`, 'Date7days');
      sessionStorage.setItem('filterDateGlobal', 'Date7days');
      sessionStorage.setItem('filterDateTo', tz.now());
      sessionStorage.setItem('filterDateFrom', tz.addDayToDate(-7));

      changeState({ pageTypeToChange });
      dispatch({ type: 'closeDatePicker' });
    },
    [changeState, pageTypeToChange, storageKey],
  );

  const handleDate30days = React.useCallback(
    (): void => {
      sessionStorage.setItem(`filter${storageKey}`, 'Date30days');
      sessionStorage.setItem('filterDateGlobal', 'Date30days');
      sessionStorage.setItem('filterDateTo', tz.now());
      sessionStorage.setItem('filterDateFrom', tz.addDayToDate(-30));

      changeState({ pageTypeToChange });
      dispatch({ type: 'closeDatePicker' });
    },
    [changeState, pageTypeToChange, storageKey],
  );

  const handleToggleDatePicker = (): void => {
    const timeFrom: string = sessionStorage.filterDateFrom;
    const timeTo: string = sessionStorage.filterDateTo;
    dispatch({
      type: 'toggleDatePicker',
      payload: {
        from: moment(timeFrom).format('YYYY-MM-DD'),
        to: moment(timeTo).format('YYYY-MM-DD'),
      },
    });
  };

  const applyDatePicker = (pickerProsp: DateIntervalProps): void => { // изменение периода с помощью дейтпикера
    const { startDate, endDate } = pickerProsp;
    sessionStorage.setItem(`filter${storageKey}`, 'DateInterval');
    sessionStorage.setItem('filterDateGlobal', 'DateInterval');
    sessionStorage.setItem('filterDateFrom', moment(startDate).format('YYYY-MM-DD'));
    sessionStorage.setItem('filterDateTo', moment(endDate).format('YYYY-MM-DD'));
    const timeFrom: string = tz.getDateformat(tz.getTimeStamp(sessionStorage.filterDateFrom));
    const timeTo: string = tz.getDateformat(tz.getTimeStamp(sessionStorage.filterDateTo));
    changeState({ pageTypeToChange });
    dispatch({
      type: 'closeDatePicker',
      payload: {
        value: `${timeFrom} - ${timeTo}`,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
      },
    });
  };

  const handleDateIntervalMobile = (): void => {
    sessionStorage.setItem(`filter${storageKey}`, 'DateInterval');
    sessionStorage.setItem('filterDateGlobal', 'DateInterval');

    changeState({ pageTypeToChange });
    dispatch({ type: 'showTimeInterval' });
  };
  // добавление/вычитание дней с помощью стрелок на кнопках с периодами
  const dateDaysIncDecr = (numberOfDays: number): void => {
    const dateFromStamp: number = tz.getTimeStamp(sessionStorage.getItem('filterDateFrom'));
    const dateToStamp: number = tz.getTimeStamp(sessionStorage.getItem('filterDateTo'));

    sessionStorage.setItem('filterDateFrom', tz.changeDateByDays(dateFromStamp, numberOfDays));
    sessionStorage.setItem('filterDateTo', tz.changeDateByDays(dateToStamp, numberOfDays));

    const dateFrom: string = sessionStorage.filterDateFrom;
    const dateTo: string = sessionStorage.filterDateTo;
    let newSelectedPeriod: string = null;

    if ((moment(dateFrom).year()) !== (moment(dateTo).year())) {
      newSelectedPeriod = `${tz.getDateformat(dateFrom)} - ${tz.getDateformat(dateTo)}`;
    } else {
      newSelectedPeriod = `${tz.getDateformatMonth(dateFrom)} - ${tz.getDateformatMonth(dateTo)}`;
    }

    changeState({ pageTypeToChange });
    dispatch({ type: 'dateDaysIncDecr',
      payload: {
        selectedPeriod: newSelectedPeriod,
      } });
  };

  const handleSelectPeriodMobile = (e: BaseSyntheticEvent): void => {
    const selectedPeriodMobile: string = e.target.value;
    switch (selectedPeriodMobile) {
      case '1 day':
        handleDate1day();
        break;
      case '7 days':
        handleDate7days();
        break;
      case '30 days':
        handleDate30days();
        break;
      case 'Other':
        handleDateIntervalMobile();
        break;

      default:
        break;
    }
  };

  const handleDatePickerMobile = (type: string, e: BaseSyntheticEvent): boolean => {
    const date: string = tz.changeFormatPoint(e.target.value);

    if (!moment(date).valueOf()) return false;

    sessionStorage[`filter${type === 'from' ? 'DateFrom' : 'DateTo'}`] = date;

    if (!tz.getTimeStamp(sessionStorage.filterDateFrom) || !tz.getTimeStamp(sessionStorage.filterDateTo)) {
      return false;
    }

    if (sessionStorage.filterDateFrom && sessionStorage.filterDateTo) {
      changeState({ pageTypeToChange });
    }

    return null;
  };

  React.useEffect(() => {
    if (sessionStorage.filterDateGlobal) {
      sessionStorage[`filter${storageKey}`] = sessionStorage.filterDateGlobal;

      if (sessionStorage.filterDateTo && sessionStorage.filterDateFrom) {
        switch (sessionStorage[`filter${storageKey}`]) {
          case 'Date1day': {
            const date: string = sessionStorage.filterDateTo;
            const newSelectedPeriod: string = tz.getDateformatMonth(date);
            let newCheckEndInterval: boolean = null;
            const d1: Date = tz.getLocalTime();

            if (tz.getDateformat(moment(date)) === tz.getDateformat(d1)) {
              newCheckEndInterval = true;
            } else {
              newCheckEndInterval = false;
            }

            dispatch({ type: 'Date1day',
              payload: {
                selectedPeriod: newSelectedPeriod,
                checkEndInterval: newCheckEndInterval,
              } });

            break;
          }
          case 'Date7days': {
            const dateFrom7: string = sessionStorage.filterDateFrom;
            const dateTo7: string = sessionStorage.filterDateTo;
            const d7: Date = tz.getLocalTime();
            let newSelectedPeriod: string = null;
            let newCheckEndInterval: boolean = null;

            if ((moment(dateFrom7).year()) !== (moment(dateTo7).year())) {
              newSelectedPeriod = `${tz.getDateformat(dateFrom7)} - ${tz.getDateformat(dateTo7)}`;
            } else {
              newSelectedPeriod = `${tz.getDateformatMonth(dateFrom7)} - ${tz.getDateformatMonth(dateTo7)}`;
            }

            if (moment(dateTo7).year() > moment(d7).year()) {
              handleDate7days();
            } else if (
              (moment(dateTo7)).year() === moment(d7).year() &&
                (moment(dateTo7)).month() > moment(d7).month()
            ) {
              handleDate7days();
            } else if (
              (moment(dateTo7)).date() > moment(d7).date() &&
                (moment(dateTo7)).month() === moment(d7).month() &&
                (moment(dateTo7)).year() === moment(d7).year()
            ) {
              handleDate7days();
            } else if (
              (moment(dateTo7)).date() === moment(d7).date() &&
                (moment(dateTo7)).month() === moment(d7).month() &&
                (moment(dateTo7)).year() === moment(d7).year()
            ) {
              newCheckEndInterval = true;
            } else {
              newCheckEndInterval = false;
            }

            dispatch({ type: 'Date7days',
              payload: {
                selectedPeriod: newSelectedPeriod,
                checkEndInterval: newCheckEndInterval,
              } });

            break;
          }
          case 'Date30days': {
            const dateTo30: string = sessionStorage.filterDateTo;
            const dateFrom30: string = sessionStorage.filterDateFrom;
            const d30: Date = tz.getLocalTime();
            let newSelectedPeriod: string = null;
            let newCheckEndInterval: boolean = null;

            if (
              (moment(dateFrom30).year()) !== (moment(dateTo30).year())
            ) {
              newSelectedPeriod = `${tz.getDateformat(dateFrom30)} - ${tz.getDateformat(dateTo30)}`;
            } else {
              newSelectedPeriod = `${tz.getDateformatMonth(dateFrom30)} - ${tz.getDateformatMonth(dateTo30)}`;
            }

            if ((moment(dateTo30)).year() > moment(d30).year()) {
              handleDate30days();
            } else if (
              (moment(dateTo30)).year() === moment(d30).year() &&
                (moment(dateTo30)).month() > moment(d30).month()
            ) {
              handleDate30days();
            } else if (
              (moment(dateTo30)).date() > moment(d30).date() &&
                (moment(dateTo30)).month() === moment(d30).month() &&
                (moment(dateTo30)).year() === moment(d30).year()
            ) {
              handleDate30days();
            } else if (
              (moment(dateTo30)).date() === moment(d30).date() &&
                (moment(dateTo30)).month() === moment(d30).month() &&
                (moment(dateTo30)).year() === moment(d30).year()
            ) {
              newCheckEndInterval = true;
            } else {
              newCheckEndInterval = false;
            }
            dispatch({
              type: 'Date30days',
              payload: {
                selectedPeriod: newSelectedPeriod,
                checkEndInterval: newCheckEndInterval,
              } });

            break;
          }
          case 'DateInterval': {
            const timeFrom: string = tz.getDateformat(tz.getTimeStamp(sessionStorage.filterDateFrom));
            const timeTo: string = tz.getDateformat(tz.getTimeStamp(sessionStorage.filterDateTo));

            dispatch({
              type: 'DateInterval',
              payload: { value: `${timeFrom} - ${timeTo}` },
            });

            break;
          }
          default:
            break;
        }
      } else {
        sessionStorage.filterDateTo = tz.now();
        sessionStorage.filterDateFrom = tz.addDayToDate(-7);

        dispatch({ type: 'Date7days' });
      }
    }
  }, [
    handleDate30days,
    handleDate7days,
    storageKey,
    sessionStoragePeriod,
    responsiveFlag,
    selectedPeriod,
  ]);

  return (
    <div>
      {responsiveFlag === 'mobile' ? (
        <div>
          <div className="socialf clearfix">
            <div className="socialf__left">
              Period
            </div>
            <div className="socialf__right">
              <div className="socialf__link--other">
                <div className="filter-period">
                  <div
                    style={{ display: selectedItem === 'interval' ? 'block' : 'none' }}
                    className={`socialf__time-interval ${selectedItem === 'interval' ? 'socialf__time-interval--active' : ''}`} // eslint-disable-line max-len
                  >
                    <input
                      onChange={(e: BaseSyntheticEvent) => handleDatePickerMobile('from', e)}
                      type="date"
                      max={tz.now()}
                      value={sessionStorage.filterDateFrom || ''}
                      id="otherPeriodCalendar-beg"
                      name="otherPeriodCalendar-beg"
                      className="socialf__link socialf__input socialf__input--beg"
                    />
                    <div className="socialf__sepr">-</div>
                    <input
                      onChange={(e: BaseSyntheticEvent) => handleDatePickerMobile('to', e)}
                      type="date"
                      id="otherPeriodCalendar-end"
                      max={tz.now()}
                      value={sessionStorage.filterDateTo || ''}
                      name="otherPeriodCalendar-end"
                      className="socialf__link socialf__input socialf__input--end"
                    />
                  </div>
                  <select
                    onChange={handleSelectPeriodMobile}
                    className="socialf__time-period"
                    value={selectedItem !== 'interval' ? selectedItem : 'Other'}
                  >
                    <option value="1 day">1 day</option>
                    <option value="7 days">7 days</option>
                    <option value="30 days">30 days</option>
                    <option value="Other">Custom period</option>
                  </select>
                  <span className="socialf__time-other">Other</span>
                </div>
              </div>
            </div>
          </div>
          {(selectedItem !== 'interval') ? (
            <div className="socialf clearfix filter-period__footer">
              <div className="socialf__left">
                <a onClick={() => dateDaysIncDecr((-1) * countDay)}>
                  <Icon name="arrow-compact-left" />
                  <span className="filter-period__button-text">
                    Previous {selectedItem}
                  </span>
                </a>
              </div>
              <div className="socialf__right">
                {
                !checkEndInterval ? (
                  <a onClick={() => dateDaysIncDecr(countDay)}>
                    <span className="filter-period__button-text">
                      Next {selectedItem}
                    </span>
                    <Icon name="arrow-compact-right" />
                  </a>
                ) : ''
                }
              </div>
            </div>
          ) : ''}
        </div>
      ) : (
        <div className="filter-period">

          {selectedItem === '1 day'
            ? (
              <div className="filter-period__button complicated-calendar__btn filter-period__button--active">
                <Icon
                  name="arrow-compact-left"
                  onClick={() => dateDaysIncDecr(-1)}
                />
                <span className="filter-period__button-text">{selectedPeriod}</span>
                {
              checkEndInterval
                ? null
                : (
                  <Icon
                    name="arrow-compact-right"
                    onClick={() => dateDaysIncDecr(1)}
                  />
                )
            }
              </div>
            )
            : (
              <button
                type="button"
                onClick={handleDate1day}
                className="filter-period__button complicated-calendar__btn"
              >
                1 day
              </button>
            )}

          {selectedItem === '7 days'
            ? (
              <div className="filter-period__button complicated-calendar__btn filter-period__button--active">
                <Icon name="arrow-compact-left" onClick={() => dateDaysIncDecr(-7)} />
                <span className="filter-period__button-text">{selectedPeriod}</span>
                {checkEndInterval
                  ? null
                  : (
                    <Icon name="arrow-compact-right" onClick={() => dateDaysIncDecr(7)} />
                  )}
              </div>
            )
            : (
              <button
                type="button"
                onClick={handleDate7days}
                className="filter-period__button complicated-calendar__btn"
              >
                7 days
              </button>
            )}

          {selectedItem === '30 days'
            ? (
              <div className="filter-period__button complicated-calendar__btn filter-period__button--active">
                <Icon name="arrow-compact-left" onClick={() => dateDaysIncDecr(-30)} />
                <span className="filter-period__button-text">{selectedPeriod}</span>
                {checkEndInterval
                  ? null
                  : (
                    <Icon name="arrow-compact-right" onClick={() => dateDaysIncDecr(30)} />
                  )}
              </div>
            )
            : (
              <button
                type="button"
                onClick={handleDate30days}
                className="filter-period__button complicated-calendar__btn"
              >
                30 days
              </button>
            )}

          <div className="dropdown hid complicated-calendar__breakdown-select">
            <button
              onClick={() => handleToggleDatePicker()}
              type="button"
              data-toggle="dropdown"
              aria-label="dropdownMenu1"
              aria-haspopup="true"
              aria-expanded="true"
              className={`sdrop-down filter-period__button btn complicated-calendar__btn complicated-calendar__btn--last
            ${dateInterval ? 'filter-period__button--active' : ''}`}
            >
              <Icon name="calendar" className="filter-period__icon-calendar" />
              {outputDate}
              <Icon name="arrow-compact-down" />
            </button>
            <div
              aria-labelledby="dropdownMenu1"
              className="complicated-calendar__wrap"
            >
              {isDatePickerOpen && (
              <DatePicker
                applyDatePicker={({ startDate, endDate }: DateIntervalProps) => applyDatePicker({ startDate, endDate })}
                closeDatePicker={() => dispatch({ type: 'closeDatePicker' })}
                applyText="Select"
                placeholder="dd.mm.yyyy"
                displayFormat="DD.MM.YYYY"
                daySize={40}
                initialStartDate={dataDateFrom
                  ? moment(dataDateFrom, 'YYYY-MM-DD')
                  : moment(defaultStartDate, 'YYYY-MM-DD')}
                initialEndDate={dataDateTo
                  ? moment(dataDateTo, 'YYYY-MM-DD')
                  : moment(new Date(), 'YYYY-MM-DD')}
                mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
              />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterPeriod;
