import React, { ReactElement } from 'react';
import config from 'config';
import { SummaryGraffProps, SummaryLegend } from 'app/ts/types/SummaryGraff';
import Charts from '../default/Charts';

const SummaryGraff = (props: SummaryGraffProps): ReactElement => {
  const { byHour, timeframe } = props;
  const legend: Array<SummaryLegend> = [
    {
      title: 'Giraff.io page views',
      field: 'total_pageviews',
      disabled: true,
      valueDecimals: 0,
      color: '#ff5252',
    }, {
      group: 1,
      title: 'Clicks',
      field: 'engagement_clicks',
      valueDecimals: 0,
      color: '#00db71',
    }, {
      group: 1,
      title: 'CTR (%)',
      field: 'engagement_ctr',
      disabled: true,
      suffix: '%',
      color: '#ae80ff',
    }, {
      group: 2,
      title: 'Out clicks',
      field: 'out_clicks',
      disabled: true,
      valueDecimals: 0,
      color: '#7a797c',
    }, {
      group: 2,
      title: 'In clicks',
      field: 'in_clicks',
      valueDecimals: 0,
      color: '#ff8921',
    }, {
      group: 2,
      title: 'Exch. ratio',
      field: 'exchange_ratio',
      disabled: true,
      valueDecimals: 3,
      color: '#2a75ff',
    }, {
      group: 3,
      title: `RPM (${config.currency})`,
      field: 'rpm',
      disabled: true,
      suffix: `&thinsp;${config.currency}`, // Рубли
      color: '#2df',
    }, {
      group: 3,
      title: `Revenue (${config.currency})`,
      field: 'revenue',
      suffix: `&thinsp;${config.currency}`, // Рубли
      color: '#ffc400',
    },
  ];

  return (
    <Charts
      timeframe={timeframe}
      byHour={byHour}
      id="summary"
      legend={legend}
      groups={[
        {},
        { title: 'Audience engagement' },
        { title: 'New audience' },
        { title: 'Revenue' },
      ]}
      pageType="date"
    />
  );
};

export default SummaryGraff;
