import React from 'react';
import PropTypes from 'prop-types';

const HeaderNewAudienceInfo = ({ balance }) => (
  <div className="click-balance__text">
    <span className="click-balance__text--gray">Clicks reserve: </span>
    <span className={`click-balance__text--${
      // eslint-disable-next-line no-nested-ternary
      balance > 0 ? 'green' : balance < 0 ? 'red' : 'default'}`}
    >
      {`${Math.floor(parseFloat(balance))}`}
    </span>
  </div>
);

HeaderNewAudienceInfo.propTypes = {
  balance: PropTypes.number.isRequired,
};

export default HeaderNewAudienceInfo;
