import React, { ReactElement } from 'react';
import config from 'config';

import { RevenueGraffProps, RevenueGraffLegend } from 'app/ts/types/RevenueGraff';

import Charts from '../default/Charts';

const RevenueGraff = (props: RevenueGraffProps): ReactElement => {
  const { timeframe, statsData, byHour, pageType } = props;
  if (!timeframe?.length) {
    return null;
  }

  const legend: Array<RevenueGraffLegend> = [
    {
      title: 'Revenue page views',
      field: 'pageviews',
      color: '#ff6363',
      valueDecimals: 0,
      disabled: true,
    },
    {
      title: 'Clicks',
      field: 'clicks',
      color: '#00db71',
      valueDecimals: 0,
    },
    {
      title: 'WCTR (%)',
      field: 'wctr',
      color: '#ae80ff',
      suffix: '%',
      disabled: true,
    },
    {
      title: `CPC (${config.currency})`,
      field: 'cpc',
      color: '#ffc400',
      suffix: `&thinsp;${config.currency}`,
      disabled: true,
    },
    {
      title: `WRPM (${config.currency})`,
      field: 'wrpm',
      color: '#2a75ff',
      suffix: `&thinsp;${config.currency}`,
    },
    {
      title: `Revenue (${config.currency})`,
      field: 'revenue',
      color: '#ff8921',
      suffix: `&thinsp;${config.currency}`,
    },
  ];

  return (
    <Charts
      timeframe={timeframe}
      statsData={statsData}
      byHour={byHour}
      id="revenue"
      legend={legend}
      groups={[]}
      pageType={pageType}
    />
  );
};

export default RevenueGraff;
