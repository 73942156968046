import React, { ReactElement } from 'react';

import { AudienceGraffProps, AudienceLegend } from 'app/ts/types/AudienceGraff';

import Charts from '../default/Charts';

const AudienceGraffWidget = (props: AudienceGraffProps): ReactElement => {
  const { timeframe, byHour } = props;
  if (!timeframe?.length) {
    return null;
  }

  const legend: Array<AudienceLegend> = [
    {
      group: 0,
      title: 'Widget shows',
      color: '#ff6363',
      field: 'out_pageviews',
      valueDecimals: 0,
      disabled: true,
    },
    {
      group: 1,
      title: 'Clicks',
      color: '#00db71',
      field: 'out_clicks',
      valueDecimals: 0,
    },
    {
      group: 1,
      title: 'WCTR (%)',
      color: '#ae80ff',
      field: 'wctr',
      suffix: '%',
      disabled: true,
    },
  ];

  return (
    <Charts
      timeframe={timeframe}
      byHour={byHour}
      id="Audience"
      legend={legend}
      groups={[
        {},
        { title: 'Outgoing traffic' },
      ]}
      pageType="date"
    />
  );
};

export default AudienceGraffWidget;
