import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import './Sidebar.scss';

import { SidebarProps } from 'app/ts/types/constructor';
import { AppState } from 'app/ts/interfaces';

import { cookie } from '../../../utils';
import { useAppSelector } from '../../../store/hooks';

const Sidebar = (props: SidebarProps): ReactElement => {
  const {
    devMode,
    devForm,
    userForm,
    changeMode,
    isConstructorDevModeAllowed,
    isConstructorAllowed,
    showSwitch = true,
  } = props;

  const { isDrawerOpen }: AppState = useAppSelector(reduxState => reduxState.appState);

  return (
    <aside className={`sidebar ${isDrawerOpen ? '' : 'sidebar--lg'}`}>
      <div className="sidebar__area">
        {devMode ? devForm() : userForm()}
        {showSwitch && (isConstructorDevModeAllowed || isConstructorAllowed || cookie.getIsCookie('is_admin')) && (
          <div className="sidebar__switch">
            <div className="sidebar__switch-title">
              <FormattedMessage id="constructor.devmode" />
            </div>
            <div className="constructor-switch">
              <input
                checked={devMode}
                onChange={changeMode}
                className="constructor-switch__input"
                type="checkbox"
                name="toggle"
                id="toggle"
              />
              <label className="constructor-switch__label" htmlFor="toggle">
                <span className="constructor-switch__span" />
              </label>
            </div>
          </div>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
