import React, { ReactElement } from 'react';
import cx from 'classnames';
import { Table } from 'uikit';
import { stats as statsUtils } from 'app/utils';
import './Table.scss';
import { TableComponentProps } from 'app/ts/types/TableComponent';

const TableComponent = (props: TableComponentProps): ReactElement => {
  const {
    columns,
    data,
    dataCount = undefined,
    AddPromoComponent = false,
    isLoading,
    changeState = () => {},
    tableRef = () => {},
    ...otherProps
  } = props;

  const isServerSideSorting: boolean = dataCount !== undefined && data.length !== dataCount;

  const onFetchData = (state: any): void => {
    const sortItem = state.sorted.length > 0 ? state.sorted[0] : undefined;
    if (isServerSideSorting && sortItem) {
      changeState({
        state: {
          sortField: statsUtils.appToServerParamsMap[sortItem.id] || sortItem.id,
          sortOrder: sortItem.desc ? 'desc' : 'asc',
        },
      });
    }
  };

  return (
    <Table
      manual={isServerSideSorting}
      columns={columns}
      data={data}
      className="uikit-table"
      getTheadGroupThProps={() => ({ className: cx(['uikit-table__cell', 'uikit-table__header-group-cell']) })}
      getTheadThProps={() => ({ className: cx(['uikit-table__cell', 'uikit-table__header-cell']) })}
      getTdProps={() => ({ className: cx(['uikit-table__cell', 'uikit-table__body-cell']) })}
      getTfootTdProps={() => ({ className: cx(['uikit-table__cell', 'uikit-table__footer-cell']) })}
      pageSize={data.length}
      showPagination={false}
      footerAsTotal
      withStickyHeader
      isLoading={isLoading}
      AddPromoComponent={AddPromoComponent}
      onFetchData={onFetchData}
      tableRef={tableRef}
      defaultSortDesc
      {...otherProps}
    />
  );
};

export default TableComponent;
