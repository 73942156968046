import React, { BaseSyntheticEvent, ReactElement } from 'react';
import { Button, TextField } from 'uikit';
import { setCookie } from 'app/utils/cookie';
import { ajax } from 'app/utils';
import { createBrowserHistory, History } from 'history';
import config from 'config';

import './Auth.scss';

import { AxiosResponse } from 'axios';
import { Response } from 'app/ts/interfaces';

const Auth = (): ReactElement => {
  const history: History = createBrowserHistory({ forceRefresh: true });
  const onSubmit = async (e: BaseSyntheticEvent): Promise<void> => {
    e.preventDefault();

    const { target: { login: { value: login }, password: { value: password } } } = e;
    const credentials: { login: string, password: string } = { login, password };

    ajax.post(`${config.serverAddress}/login`, credentials)
      .then((response: AxiosResponse<Response>) => {
        if (response.status >= 400) {
          throw new Error('Bad response from server');
        } else {
          if (response.data.success === false) {
            throw new Error(response.data.error_message);
          }
          setCookie('token', response.data.token, {
            path: '/',
            expires: 9e9,
          });
          setCookie('email', credentials.login, {
            path: '/',
            expires: 9e9,
          });
          history.push('/app');
        }
      });
  };

  return (
    <section>
      <form onSubmit={onSubmit} className="login">
        <TextField
          type="text"
          name="login"
        />
        <TextField
          type="password"
          name="password"
        />
        <Button type="submit" className="login__submit">Login</Button>
      </form>
    </section>
  );
};

export default Auth;
