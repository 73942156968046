import React, { ReactElement } from 'react';
import { Button } from 'uikit';
import RevenueGraff from 'app/components/charts/revenue/RevenueGraff';
import RevenueGraffWidget from 'app/components/charts/revenue/RevenueGraffWidget';
import { MonthlyPerformanceReport } from 'app/pages/Revenue/MonthlyPerformanceReport';
import PgFilter from 'app/components/PgFilter';
import RevenuePgTable from 'app/components/tables/containers/Revenue';
import { metrics, tz, ajax, ls, chart } from 'app/utils';
import PgTablePager from 'app/components/PgTablePager';
import { widgets as widgetsCSV, sites as sitesCSV } from 'app/components/csv/revenue';
import { cloneDeep } from 'lodash';
import { useMount, useUpdateEffect } from 'react-use';
import { AxiosResponse } from 'axios';
import config from 'config';

import { AudienceList, PageType, Params, SiteProps } from 'app/ts/types/Audience';
import { StateData, RevenueData, AccountInfoState } from 'app/ts/interfaces';
import { GetChartInfo, NewCsvData } from 'app/ts/types/utils';
import { ChangeStateProps, RevenueState } from 'app/ts/types/Revenue';
import { IntlMessage } from 'app/ts/types/IntlMessage';

import { useAppSelector } from '../../store/hooks';
import { instanceLang } from '../../ts/constants';

const Revenue = (): ReactElement => {
  const { currentSite, currentSiteName }: AccountInfoState = useAppSelector(reduxState => reduxState.accountInfo);

  const [state, setState] = React.useState<RevenueState>({
    stats: [],
    totals: {},
    totalItems: 0,
    timeframe: [],
    csvData: [],
    csvName: '',
    pageType: 'date',
    pageN: 1,
    sortField: 'date',
    sortOrder: 'desc',
    pageContent: 'widgets',
    widgets: {
      list: [],
      active: '',
    },
    sites: {
      list: [],
      active: [],
    },
    loading: true,
  });

  const {
    stats,
    totals,
    totalItems,
    timeframe,
    csvData,
    csvName,
    pageType,
    pageN,
    sortOrder,
    pageContent,
    widgets,
    sites,
    loading,
  } = state;

  const displayPageContentOptions: boolean = sites.list.length > 1;
  const displayDefaultCharts: boolean = pageType !== 'widget' && (pageContent === 'sites' || widgets.active === 'all');
  const { isChartsVisible, byHour }: GetChartInfo = chart.getChartInfo();

  const getFilterUrl = (filterProps: ChangeStateProps = {}): Params => {
    const { pageTypeToChange, pageNumberToChange, state: passedState } = filterProps;
    const params: Params = {};
    const itemsPerPage: Storage = ls.get('revenueTableSize', 20);
    const storageKey: string = chart.initializeStorage('revenue');
    const filterDateTo: string = sessionStorage.getItem('filterDateTo');
    const filterDateFrom: string = sessionStorage.getItem('filterDateFrom');
    const groupBy: string = pageTypeToChange || pageType;
    let newSortField: string;

    switch (sessionStorage[storageKey]) {
      case 'Date1day':
        if (filterDateTo) {
          if (filterDateTo === tz.now()) {
            params.period = 'today';
          } else if (filterDateTo === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = filterDateFrom || tz.now();
            params.to = filterDateTo || tz.now();
          }
        }
        break;
      case 'Date7days':
        params.from = filterDateFrom || tz.addDayToDate(-7);
        params.to = filterDateTo || tz.now();
        break;
      case 'Date30days':
        params.from = filterDateFrom || tz.addDayToDate(-30);
        params.to = filterDateTo || tz.now();
        break;
      case 'DateInterval':
        if (filterDateFrom === tz.now() && filterDateTo === tz.now()) {
          params.period = 'today';
        } else if (filterDateFrom === tz.addDayToDate(-1) && filterDateTo === tz.addDayToDate(-1)) {
          params.period = 'yesterday';
        } else {
          params.from = filterDateFrom;
          params.to = filterDateTo;
        }
        break;
      default:
        break;
    }

    if (groupBy === 'date') {
      newSortField = 'date';
    } else {
      newSortField = 'pageviews';
    }

    params.groupby = groupBy;
    params.limit = +itemsPerPage;
    params.offset = pageNumberToChange ? (pageNumberToChange - 1) * +itemsPerPage : 0;
    // passedState существует, если таблица сортируется на бэкенде
    params.sort_field = passedState?.sortField || newSortField;
    params.sort_order = passedState?.sortOrder || sortOrder;

    return params;
  };

  const changeWidget = (wid: string): void => {
    const widgetList: Array<AudienceList> = widgets.list;
    const params: Params = getFilterUrl();

    if (wid !== 'all') {
      params.wid = wid;
      params.groupby = 'date';
      params.sort_field = 'date';
    }

    ajax.get('/stats/revenue', { params }).then(({ data }: AxiosResponse<RevenueData>) => {
      if (data) {
        const newCsvData: NewCsvData = widgetsCSV.prepareCSV({
          stats: data.stats,
          totals: data.totals,
          pageType: wid !== 'all' ? 'date' : pageType,
        });
        const newCsvName: string = widgetsCSV.prepareCSVName({
          widgets: {
            list: widgetList,
            active: wid,
          },
          currentSiteName,
          pageType,
        });

        setState(prevState => ({
          ...cloneDeep(prevState),
          stats: data.stats,
          totals: data.totals,
          timeframe: data.timeframe,
          totalItems: data.total_items,
          widgets: {
            list: widgetList,
            active: wid,
          },
          csvData: newCsvData,
          csvName: newCsvName,
          pageType: wid !== 'all' ? 'date' : pageType,
          pageN: 1,
          pageContent: 'widgets',
        }));
      }
    });
  };

  const changeSite = (updatedSites: Array<SiteProps>) => {
    const newSites: Array<SiteProps> | Array<AudienceList> =
      updatedSites && updatedSites.length > 0 ? updatedSites : sites.list;
    const params: Params = getFilterUrl();

    params.sites = newSites.map(item => item.value).join(',');

    ajax.get('/stats/revenue', { params }).then(({ data }: AxiosResponse<RevenueData>) => {
      if (data) {
        const newCsvData: NewCsvData = sitesCSV.prepareCSV({
          stats: data.stats,
          totals: data.totals,
          pageType,
        });
        const newCsvName: string = sitesCSV.prepareCSVName();

        setState(prevState => {
          const prevStateCopy: RevenueState = cloneDeep(prevState);

          return {
            ...prevStateCopy,
            stats: data.stats,
            totals: data.totals,
            timeframe: data.timeframe,
            totalItems: data.total_items,
            csvData: newCsvData,
            csvName: newCsvName,
            pageType,
            pageN: 1,
            pageContent: 'sites',
            sites: {
              ...prevStateCopy.sites,
              active: newSites,
            },
          };
        });
      }
    });
  };

  const changeState = (changeStateProps: ChangeStateProps) => {
    const { state: passedState, widgets: passedWidgets, pageNumberToChange, pageTypeToChange } = changeStateProps;
    const params: Params = getFilterUrl({ pageTypeToChange, pageNumberToChange, state: passedState });

    if (pageContent === 'widgets' && widgets.active && widgets.active !== 'all') {
      params.wid = widgets.active;
    }

    if (pageContent === 'sites') {
      params.sites = sites.active.map(item => item.value).join(',');
    }

    ajax.get('/stats/revenue', { params }).then(({ data }: AxiosResponse<RevenueData>) => {
      if (data) {
        let newCsvData: NewCsvData;
        let newCsvName: string;

        if (pageContent === 'widgets') {
          newCsvData = widgetsCSV.prepareCSV({
            stats: data.stats,
            totals: data.totals,
            pageType: pageTypeToChange || pageType,
          });
          newCsvName = widgetsCSV.prepareCSVName({
            widgets: passedWidgets || widgets,
            currentSiteName,
            pageType: pageTypeToChange || pageType,
          });
        }

        if (pageContent === 'sites') {
          newCsvData = sitesCSV.prepareCSV({
            stats: data.stats,
            totals: data.totals,
            pageType: pageTypeToChange || pageType,
          });
          newCsvName = sitesCSV.prepareCSVName();
        }

        setState(prevState => ({
          ...cloneDeep(prevState),
          stats: data.stats,
          totals: data.totals,
          timeframe: data.timeframe,
          totalItems: data.total_items,
          csvData: newCsvData,
          csvName: newCsvName,
          pageN: pageNumberToChange || pageN,
          pageType: pageTypeToChange || pageType,
          loading: false,
        }));
      }
    });
  };

  const init = async (): Promise<void> => {
    const params: Params = {
      site_id: currentSite,
    };

    const fetchedWidgets: Array<AudienceList> = await ajax.get('/widgets', { params })
      .then(({ data }: AxiosResponse<StateData>) => {
        if (data) {
          const { list: widgetArr } = data;
          const widgetList: Array<AudienceList> = [
            {
              active: true,
              name: 'all',
              title: 'All',
            },
          ];

          widgetArr.sort((a, b) => (a.name > b.name ? 1 : -1));
          widgetArr.map((item) => {
            const title: IntlMessage = /^#\d+$/.test(item.name as string) ? (`Widget ${item.name}`) : item.name;
            return widgetList.push({
              active: item.settings && ('active' in item.settings) ? item.settings.active : true,
              name: item.id,
              title,
            });
          });

          return widgetList;
        }

        return null;
      });

    const fetchedSites = await ajax.get('/sites', { params }).then(({ data }: AxiosResponse<StateData>) => {
      if (data) {
        const { list: siteArr } = data;
        const sitesList: Array<SiteProps> = [];

        siteArr.map(item => sitesList.push({
          value: item.id,
          label: item.name,
        }));

        return sitesList;
      }

      return null;
    });

    setState(prevState => ({
      ...cloneDeep(prevState),
      widgets: {
        list: fetchedWidgets,
        active: 'all',
      },
      sites: {
        list: fetchedSites,
        active: fetchedSites,
      },
    }));

    changeState({
      pageTypeToChange: pageType,
      widgets: {
        list: fetchedWidgets,
        active: 'all',
      },
    });
  };

  const changePageN = (pageNumberToChange: number, pageTypeToChange: PageType): void => {
    changeState({ pageNumberToChange, pageTypeToChange });
  };

  const changePage = (newPageType: PageType): void => {
    if (pageType !== newPageType) {
      changePageN(1, newPageType);
    }
  };

  const changePageContent = (contentType: string): void => {
    if (contentType === 'widgets') {
      changeWidget('all');
    } else {
      changeSite(sites.list);
    }
  };

  useMount(() => {
    init();
    metrics.metricEvent('hit', '/reports/new_audience');
  });

  useUpdateEffect(() => {
    init();
  }, [currentSite]);

  return (
    <div className="revenue__content-wrapper">
      <div className="wrapper wrapper--dashboard">
        <div className="container-fluid">

          <div className="row">
            <div className="col-md-12">
              {config.instanceLang === instanceLang.RU && <MonthlyPerformanceReport />}
            </div>
          </div>

          {displayPageContentOptions && (
            <>
              <Button
                kind={pageContent !== 'widgets' ? 'outline' : 'primary'}
                className="widgets__button"
                onClick={() => changePageContent('widgets')}
              >
                By widgets
              </Button>
              <Button
                kind={pageContent !== 'sites' ? 'outline' : 'primary'}
                className="widgets__button"
                onClick={() => changePageContent('sites')}
              >
                By sites
              </Button>
            </>
          )}
          <PgFilter
            widgets={pageContent === 'widgets' ? { ...widgets } : null}
            change={pageContent === 'widgets' ? changeWidget : changeSite}
            sites={pageContent === 'sites' ? { ...sites } : null}
            groupBy={pageType}
            csvName={csvName}
            csvData={csvData}
            changeState={changeState}
            getPage={() => chart.getPage('revenue')}
            changePage={changePage}
          />
          {isChartsVisible && displayDefaultCharts && (
            <RevenueGraff
              statsData={stats}
              pageType={pageType}
              timeframe={timeframe}
              byHour={byHour}
            />
          )}
          {isChartsVisible && !displayDefaultCharts && (
            <RevenueGraffWidget
              statsData={stats}
              pageType={pageType}
              timeframe={timeframe}
              byHour={byHour}
            />
          )}
        </div>
      </div>
      <PgTablePager
        pagerName="revenueTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
      <RevenuePgTable
        statsData={stats}
        totalsData={totals}
        totalItems={totalItems}
        changeState={changeState}
        pageType={pageType}
        isLoading={loading}
      />
      <PgTablePager
        pagerName="revenueTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
    </div>
  );
};

export default Revenue;
