export default {
  // Добавление или замена класса, если он есть. На входе строка classList, на выходе тоже.
  replaceClass(classList: string, oldClass: string, newClass: string): string {
    const arr: Array<string> = classList.split(' ');

    let replaced: boolean = false;
    for (let i: number = 0; i < arr.length; i += 1) {
      if (arr[i] === oldClass) {
        arr[i] = newClass;
        replaced = true;
      }
    }
    if (!replaced) {
      arr.push(newClass);
    }
    return arr.join(' ');
  },
  // замена класса в "кирпиче"
  replaceClassInBrick(brick: string, selector: string, classList: string): string {
    const arr: RegExpMatchArray | null = brick.match(/"(\w*\s+"|:?)(.*?)"/g);
    let currentSelector: string = '';
    if (arr && arr.length) {
      for (let i: number = 0; i < arr.length; i += 1) {
        if (arr[i].indexOf(selector) >= 0) {
          currentSelector = arr[i];
        }
      }
    }
    const replacedBrick: string = brick.replace(currentSelector, `"${classList}"`);
    return replacedBrick;
  },
};
