import * as React from 'react';
import './Drawer.scss';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'uikit';
import { menuList, menuGroupTitles, isMobile } from 'utils';
import cx from 'classnames';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { setDrawerState } from '../store/appState/appStateSlice';
import GiraffLogo from './GiraffLogo';

const Drawer = ({ isConstructorAllowed }) => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector(reduxState => reduxState.accountInfo.admin);
  const location = useLocation();

  const menuPages = Object.keys(menuList)
    .map(pageName => ({ pageName, ...menuList[pageName] }))
    .filter(({ title }) => title);
  const menuGroups = Object.keys(menuGroupTitles)
    .map(groupName => ({
      groupName,
      ...menuGroupTitles[groupName],
      menuPages: menuPages.filter(({ menuGroup }) => menuGroup === groupName),
    }));

  const menuMainLink = (id) => {
    switch (id) {
      case 3:
        return (<Icon name="home" className="drawer__dash-img" />);
      case 4:
        return (<Icon name="dashboard" className="drawer__dash-img" />);
      default:
        return null;
    }
  };

  const handleClick = () => {
    if (!isMobile) return;

    dispatch(setDrawerState(false));
  };

  const renderMenuPage = (menuPage) => {
    const { pageName, link, title, adminOnly, id } = menuPage;
    const isIconed = menuPage.id === 3 || menuPage.id === 4;
    const validPermissions = adminOnly ? Boolean(admin) : true;

    const isFront = pageName === 'front';
    const cssSuffix = cx({
      drawer__header: isFront,
      drawer__dash: !isFront && isIconed,
      drawer__link: !isFront && !isIconed,
    });

    return validPermissions
      ? (
        <Link
          to={`/${link}`}
          className={cx(cssSuffix, { [`${cssSuffix}--active`]: location.pathname.includes(link) })}
          key={pageName}
          onClick={handleClick}
        >
          {isFront
            ? (<span className="drawer__name" title="Giraff.io"><GiraffLogo inverse /></span>)
            : menuMainLink(id)}
          {isFront ? '' : title}
        </Link>
      )
      : null;
  };

  const renderMenuGroup = (menuGroup) => {
    const { adminOnly, groupName, menuPages } = menuGroup; // eslint-disable-line @typescript-eslint/no-shadow
    const validPermissions = adminOnly ? Boolean(admin) : true;

    // #40561 Делаем констуктор видимым не только для админов, но и кому передается параметр constructor_allowed
    if (groupName === 'settings' && !isConstructorAllowed) {
      menuPages.splice(menuPages.findIndex(prop => prop.pageName === 'constructor'), 1);
    }

    return validPermissions
      ? [
        (<div className="drawer__title" key={groupName}>{menuGroup.title}</div>),
        Array.isArray(menuPages) ? menuPages.map(renderMenuPage) : null,
      ]
      : null;
  };

  return (
    <aside className="drawer drawer__container">
      <div className="drawer__content">
        {menuGroups.map(renderMenuGroup)}
      </div>
    </aside>
  );
};

Drawer.propTypes = {
  isConstructorAllowed: PropTypes.bool,
};

Drawer.defaultProps = {
  isConstructorAllowed: false,
};

export default Drawer;
