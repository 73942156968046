import React from 'react';
import { Route, Redirect, BrowserRouter, Switch } from 'react-router-dom';

import Layout from './layout';
import App from './App';
import Auth from './pages/Auth';
import { getIsCookie } from './utils/cookie';

const Giraff = () => {
  const { location: { hostname } } = window;
  const isUnAuthorized = ['localhost', 'test.giraff.io'].includes(hostname) && !getIsCookie('token');

  return isUnAuthorized ? (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Auth />
        </Route>

        <Redirect to="/login" />
      </Switch>
    </BrowserRouter>
  ) : (
    <BrowserRouter basename="/app">
      <App>
        <Layout />
      </App>
    </BrowserRouter>
  );
};

export default Giraff;
