import React, { useState } from 'react';
import { compose, branch, renderComponent } from 'recompose';
import { Dropdown, Icon } from 'uikit';
import PropTypes from 'prop-types';
import './SaveFile.scss';
import { csv, excel } from '../utils';

const formats = ['CSV', 'Excel'];

const SaveFile = ({ name, data }) => {
  const [selectedFormat, setSelectedFormat] = useState('CSV');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const changeFormatType = ({ target: { innerText } }) => {
    setSelectedFormat(innerText);
    setIsDropdownVisible(false);
  };

  const handleDownload = () => {
    if (selectedFormat === 'CSV') {
      csv.exportToCsv(name, data);
    } else {
      const filename = `${name.split('.')[0]}.xlsx`;
      excel.exportToExcel(filename, data);
    }
  };

  return (
    <div className="format-types">
      <div className="dropdown">
        <Dropdown
          visible={isDropdownVisible}
          onClickOutside={() => setIsDropdownVisible(false)}
          content={(
            <ul>
              {formats.map((type) => (type === selectedFormat ? null : (
                <li key={type}>
                  <a
                    onClick={changeFormatType}
                    data-name={type}
                    className="dropdown__link"
                  >
                    {type}
                  </a>
                </li>
              )))}
            </ul>
            )}
          trigger="click"
          placement="bottom-start"
          arrow={false}
          offset={[0, 5]}
          interactive
        >
          <button
            type="button"
            aria-label="formatTypes"
            aria-haspopup="true"
            aria-expanded="true"
            className="sdrop-down format-types__button"
            onClick={() => setIsDropdownVisible(true)}
          >
            {selectedFormat}
            <Icon name="arrow-compact-down" />
          </button>
        </Dropdown>
      </div>
      <Icon onClick={handleDownload} name="download" size={18} />
    </div>
  );
};

export default compose(
  branch(() => document.createElement('a').download === undefined, renderComponent(() => null)),
)(SaveFile);

SaveFile.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
