import * as React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ChartView = ({ data, charts, legendDisabled }) => (
  charts.map((option, index) => (
    !legendDisabled[index] && (
      <div key={data[index].field} className="chart__wrapper">
        <HighchartsReact highcharts={Highcharts} options={option} />
      </div>
    )
  ))
);

ChartView.propTypes = {
  data: PropTypes.array.isRequired,
  charts: PropTypes.array.isRequired,
  legendDisabled: PropTypes.array.isRequired,
};

export default ChartView;
