import React, { ReactElement } from 'react';
import { Icon, Dropdown } from 'uikit';
import { useWindowSize } from 'react-use';
import { useAppSelector, useAppDispatch } from 'app/store/hooks';
import { changeCurrentSite } from 'app/store/accountInfo/accountInfoSlice';
import './SitesList.scss';

import { AccountInfoState, SitesListProps, Site } from 'app/ts/interfaces';

const SitesList = ({ path }: SitesListProps): ReactElement => {
  const {
    currentSite, sitesList: fetchedSitesList,
  }: AccountInfoState = useAppSelector(reduxState => reduxState.accountInfo);
  const [isDropdownVisible, setIsDropdownVisible] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const isDesktop: boolean = width > 767;
  const sitesList: Array<Site> = React.useMemo(() => [...fetchedSitesList].sort((a, b) => ((a.name > b.name) ? 1 : -1)),
    [fetchedSitesList]);
  const currentSiteName: string = sitesList?.find(site => site.id === currentSite)?.name || '';
  const isMultipleSites: boolean = sitesList?.length > 1;

  const changeSite = (id: number): void => {
    if (id !== currentSite) {
      dispatch(changeCurrentSite(id));
    }
  };

  const changeSiteHandler: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    const { id } = event.currentTarget.dataset as unknown as { id: string };

    changeSite(+id);
    setIsDropdownVisible(false);
  };

  const changeSiteHandlerMobile: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.currentTarget;

    changeSite(+value);
  };

  return (
    // eslint-disable-next-line max-len
    <div className={`page-header__sites ${path === '/reports/revenue' ? 'page-header__sites--rv-report' : ''} ${path === '/reports/new_audience' ? 'page-header__sites--na-report' : ''}`}>
      {!isMultipleSites && (
        <div className="dropdown sites-list">
          {isDesktop && (
          <Dropdown
            visible={isDropdownVisible}
            onClickOutside={() => setIsDropdownVisible(false)}
            content={(
              <ul>
                <li>
                  <a
                    className="dropdown__link"
                    onClick={() => setIsDropdownVisible(false)}
                  >
                    {currentSiteName}
                  </a>
                </li>
              </ul>
                )}
            placement="bottom-start"
            arrow={false}
            offset={[0, 5]}
            interactive
          >
            <button
              type="button"
              aria-label="sitesList"
              aria-haspopup="true"
              aria-expanded="true"
              className="sdrop-down sites-list__button"
              onClick={() => setIsDropdownVisible(true)}
            >
              {currentSiteName}
              <Icon name="arrow-compact-down" />
            </button>
          </Dropdown>
          )}
          {!isDesktop && (
          <div className="socialf clearfix">
            <div className="socialf__left">
              Website
            </div>
            <div className="socialf__right">
              <span className="sites-list__text--mobile">
                {currentSiteName}
              </span>
            </div>
          </div>
          )}
        </div>
      )}
      {isMultipleSites && (
      <div className="sites-list">
        {isDesktop && (
        <div className="dropdown sites-list__option">
          <Dropdown
            visible={isDropdownVisible}
            onClickOutside={() => setIsDropdownVisible(false)}
            content={(
              <ul>
                {sitesList.map(item => (
                  <li key={item.id}>
                    <a
                      onClick={changeSiteHandler}
                      data-id={item.id}
                      data-name={item.name}
                      className="dropdown__link"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            trigger="click"
            placement="bottom-start"
            arrow={false}
            offset={[0, 5]}
            interactive
          >
            <button
              type="button"
              aria-label="sitesList"
              aria-haspopup="true"
              aria-expanded="true"
              className="sdrop-down sdrop-down--header sites-list__button"
              onClick={() => setIsDropdownVisible(true)}
            >
              {currentSiteName}
              <Icon name="arrow-compact-down" />
            </button>
          </Dropdown>
        </div>
        )}
        {!isDesktop && (
        <div className="socialf clearfix">
          <div className="socialf__left">Website</div>
          <div className="socialf__right">
            <div className="socialf__link--other">
              <select
                onChange={changeSiteHandlerMobile}
                value={currentSite}
                className="socialf__time-period"
              >
                {sitesList.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span className="socialf__time-other">Other</span>
            </div>
          </div>
        </div>
        )}
      </div>
      )}
    </div>
  );
};

export default SitesList;
