/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';

export default overrideProps => ({
  accessor: 'widget',
  headerClassName: 'uikit-table__cell--first-cell',
  resizable: false,
  Header: (
    <FormattedMessage id="table.widget">
      {txt => <p className="cell-inner widget">{txt} {SortIcon}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <FormattedMessage id="table.total">
      {txt => <span className="cell-inner">{txt}</span>}
    </FormattedMessage>
  ),
  Cell: props => (
    <div className="cell-inner" title={props.value}>
      {props.value}
    </div>
  ),
  ...overrideProps,
});
