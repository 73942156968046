import React, { BaseSyntheticEvent, ReactElement } from 'react';
import Table from 'app/components/Table';
import columns from 'app/components/tables/columns';
import { stats as statsUtils } from 'app/utils';
import './Content.scss';

import { ContentPgTableProps } from 'app/ts/types/ContentPgTable';
import { TotalsData } from 'app/ts/types/utils';
import { ColumnsConfig } from 'app/ts/types/ColumnsConfig';

const Content = (props: ContentPgTableProps): ReactElement => {
  const { statsData, totalsData, totalItems, changeState, isLoading } = props;
  const [sortField, setSortField] = React.useState<string>();
  const [sortDesc, setSortDesc] = React.useState<boolean>(true);

  const tableRef = React.useRef<ReactElement>();

  const forceSorting = (e: BaseSyntheticEvent, passedSortField: string): void => {
    e.stopPropagation();
    e.preventDefault();

    if (passedSortField !== sortField) {
      setSortField(passedSortField);
    } else {
      setSortDesc(prevState => !prevState);
    }
  };

  const getColumnsConfig = (): Array<ColumnsConfig> => {
    const totals: TotalsData = statsUtils.fromServerData(totalsData);

    return [
      columns.itemTitleMoreInfo({ minWidth: 300 }),
      columns.pageViews({ maxWidth: 200 }, totals),
      columns.readingDepth({ width: 150 }, totals, { sortField, forceSorting }),
      columns.readingTime({ width: 150 }, totals, { sortField, forceSorting }),
      columns.rpm({ maxWidth: 150 }, totals),
      columns.revenueGiraff({ maxWidth: 150 }, totals),
    ];
  };

  return (
    <div className="content-pg-table">
      <Table
        columns={getColumnsConfig()}
        data={statsData.map(statsUtils.fromServerData)}
        dataCount={totalItems}
        changeState={changeState}
        tableRef={(ref: ReactElement) => tableRef.current = ref}
        defaultSorted={sortField ? [{ id: sortField, desc: sortDesc }] : undefined}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Content;
