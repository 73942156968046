import React, { BaseSyntheticEvent, ReactElement } from 'react';
import { Icon, Button, Tooltip } from 'uikit';
import { useHistory, Link } from 'react-router-dom';
import config from 'config';
import Switch from 'app/components/Switch';
import { ajax, isMobile, showAlert } from 'app/utils';
import { useIntl, FormattedMessage, IntlShape } from 'react-intl';
import { useMount } from 'react-use';
import {
  ConstructorListState,
  WidgetsListProps,
  SwitchProps,
} from 'app/ts/types/constructor/ConstructorList';
import { NewWidgetData, WidgetsData, WidgetListItem } from 'app/ts/interfaces/';
import { History } from 'history';

import { AxiosResponse } from 'axios';

import { useAppSelector } from '../store/hooks';
import DefaultWidget from '../components/constructor/Widgets/Default';
import './Constructor.scss';

const ConstructorList = (): ReactElement => {
  const history: History = useHistory();
  const intl: IntlShape = useIntl();
  const [state, setState] = React.useState<ConstructorListState>({
    widgets: [],
    showActiveWidgets: true,
    sites: [],
    mobile: false,
  });
  const { widgets, showActiveWidgets, sites, mobile } = state;

  const {
    admin,
    currentSite,
    lurkMode,
    lurkCodeDomain,
    isConstructorAllowed,
  } = useAppSelector(reduxState => reduxState.accountInfo);
  const domain: string = (lurkMode && lurkCodeDomain) || config.partnerCodeDomain;

  const getWidgets = React.useCallback(() => {
    ajax.get('/widgets').then(({ data }: AxiosResponse<WidgetsData>) => {
      if (data) {
        const { list: widgetList } = data;
        let w: Array<WidgetListItem> = [];

        w = widgetList.map((item) => {
          const newItem: WidgetListItem = item;
          if (newItem.settings) {
            newItem.settings.active = newItem.settings.active === undefined ? true : Boolean(newItem.settings.active);
          }
          return newItem;
        });

        w.sort((a, b) => a.idx - b.idx);

        setState(prevState => ({
          ...prevState,
          widgets: w,
          mobile: isMobile,
        }));
      }
    });
  }, []);

  const getSites = () => {
    ajax.get('/sites').then(({ data }: AxiosResponse<WidgetsData>) => {
      if (data) {
        setState(prevState => ({
          ...prevState,
          sites: data.list,
          mobile: isMobile,
        }));
      }
    });
  };

  const removeWidget = (widgetID: number) => {
    ajax.delete(`/widgets/${widgetID}`).then(() => {
      const newWidgetList: Array<WidgetListItem> = widgets;
      let removeIndex: number = -1;

      newWidgetList.map((item, i) => {
        if (item.id === widgetID) {
          removeIndex = i;
        }
        return false;
      });

      newWidgetList.splice(removeIndex, 1);

      setState(prevState => ({
        ...prevState,
        widgets: newWidgetList,
      }));

      showAlert(intl.messages['constructor.msg.widget-removed']);
    });
  };

  const createWidget = (event: BaseSyntheticEvent) => {
    event.preventDefault();

    ajax.post('/widgets', {
      settings: DefaultWidget.settings,
      template: DefaultWidget.tpl,
    }).then(({ data }: AxiosResponse<NewWidgetData>) => {
      if (data) {
        const { data: newWidget } = data;
        const newWidgetList: Array<WidgetListItem> = [...widgets, newWidget];

        setState(prevState => ({
          ...prevState,
          widgets: newWidgetList,
        }));

        showAlert(intl.messages['constructor.msg.widget-created']);
      }
    });
  };

  const updateWidget = (widget: WidgetListItem, callback: Function) => {
    const { id } = widget;
    ajax.put(`/widgets/${id}`, widget)
      .then(({ data: responseData }: AxiosResponse<NewWidgetData>) => {
        if (responseData) {
          const { data: widgetData } = responseData;
          const newWidgetList: Array<WidgetListItem> =
            widgets.map(item => ((item.id === widgetData.id) ? widgetData : item));

          setState(prevState => ({
            ...prevState,
            widgets: newWidgetList,
          }));

          if (callback) {
            callback(widgetData);
          }
        }
      });
  };

  useMount(() => {
    if (!isConstructorAllowed && !admin) {
      history.push('/dashboard');
    } else {
      getWidgets();
      getSites();
    }
  });

  React.useEffect(() => {
    getWidgets();
  }, [currentSite, getWidgets]);

  return (
    <div className="constructor-list">
      <div className="wrapper wrapper--widgets">
        {
          mobile
            ? (
              <div className="container-fluid">
                <div className="widgets widgets--mobile">Constructor is not accessible on mobile</div>
              </div>
            )
            : (
              <div className="container-fluid">
                {
                  sites.length > 0 ? (
                    <div className="constructor-widgets widgets">
                      {sites.map(item => (item.id === currentSite && (
                        <div className="widgets__item" key={`sitesid-${item.id}`}>
                          <h5 className="widgets__subtitle"><FormattedMessage id="constructor_list-subtitle_1" /></h5>
                          <div className="widgets__script">
                            <pre>
                              {
                                `<script type="text/javascript" src="https://${domain}/data/widget-${item.ftrack_id}.js" async></script>`
                              }
                            </pre>
                          </div>
                          <h5 className="widgets__subtitle">
                            <FormattedMessage id="constructor_list-subtitle_2" />
                          </h5>
                          <Button
                            kind={showActiveWidgets === false ? 'outline' : 'primary'}
                            className="widgets__button"
                            onClick={() => setState(prevState => ({
                              ...prevState,
                              showActiveWidgets: true,
                            }))}
                          >
                            <FormattedMessage id="constructor_list-btn-active" />
                          </Button>
                          <Button
                            kind={showActiveWidgets !== false ? 'outline' : 'primary'}
                            className="widgets__button"
                            onClick={() => setState(prevState => ({
                              ...prevState,
                              showActiveWidgets: false,
                            }))}
                          >
                            <FormattedMessage id="constructor_list-btn-inactive" />
                          </Button>
                          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                          <WidgetsList
                            widgets={widgets}
                            showActiveWidgets={showActiveWidgets}
                            item={item}
                            createWidget={createWidget}
                            updateWidget={updateWidget}
                            removeWidget={removeWidget}
                          />
                        </div>
                      )))}
                    </div>
                  ) : null
                }
              </div>
            )
        }
      </div>
    </div>
  );
};

function WidgetsList(props: WidgetsListProps): ReactElement {
  // @comment Используется только при острой необходимости
  // function submit(id, e) {  // eslint-disable-line
  //   e.preventDefault();
  //
  //   confirmAlert({
  //     customUI: ({ onClose }) => (
  //       <div className="react-confirm-alert-body disabled">
  //         <div className="react-confirm-alert-message center">
  //                           Are you sure you wish to delete this item?
  //           <div className="react-confirm-alert-button-group">
  //             <button
  //               type="button"
  //               onClick={() => {
  //                 props.removeWidget(id);
  //                 onClose();
  //               }}
  //             >
  //               Yes
  //             </button>
  //             <button type="button" onClick={onClose}>No</button>
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //   });
  // }
  const { widgets, item, updateWidget, showActiveWidgets } = props;

  function dynamicSort(property: string): (a: { [key: string]: any }, b: { [key: string]: any }) => number {
    let sortOrder: number = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1); // eslint-disable-line
    }
    return (a, b) => {
      let result: number = null;
      if (a[property] < b[property]) {
        result = -1;
      } else if (a[property] > b[property]) {
        result = 1;
      } else {
        result = 0;
      }
      return result * sortOrder;
    };
  }

  const SwitchItem = (switchProps: SwitchProps): ReactElement => {
    const { widget, widget: { settings, id } } = switchProps;
    const widgetState: WidgetListItem = widget;
    return (
      <Switch
        flag={false}
        checked={settings.active}
        id={id}
        keyId={id}
        action={(actionId, flag, event, callback) => {
          settings.active = !settings.active;
          widgetState.settings = settings;
          updateWidget(widgetState, callback(event));
        }}
      />
    );
  };

  const hostname: string = window.location.hostname !== 'localhost' ? window.location.origin : 'https://www.giraff.io';

  const w: ReactElement = (
    <div>
      {
        widgets.length > 0 && (
          <ul className="widgets__list">
            <li className="widgets__list-row widgets__list-head" key="widget-head">
              <div className="widgets__list-col widgets__list-col--status">Active</div>
              <div className="widgets__list-col widgets__list-col--div">DIV code</div>
              <div className="widgets__list-col widgets__list-col--ids">APD IDs</div>
              <div className="widgets__list-col widgets__list-col--name">Widget&apos;s name</div>
              <div className="widgets__list-col widgets__list-col--link">Actions</div>
            </li>
            {
              widgets.sort(dynamicSort('idx')).map(widget =>
                widget.settings && widget.settings.active === showActiveWidgets && (
                  <li className="widgets__list-row widgets__list-body" key={`widget-${widget.id}`}>
                    <div className="widgets__list-col widgets__list-col--status">
                      <SwitchItem widget={widget} />
                    </div>
                    <div className="widgets__list-col widgets__list-col--div">
                      <pre>
                        {
                          `<div id="grf_${item.ftrack_id}${widget.idx > 0 ? (`_${widget.idx + 1}`) : ''}"></div>`
                        }
                      </pre>
                    </div>
                    <div className="widgets__list-col widgets__list-col--ids">
                      AE ID:
                      {` ${widget.ae_adp_id}`}
                      <br />
                      NA ID:
                      {` ${widget.na_adp_id}`}
                      <br />
                      RV ID:
                      {` ${widget.rv_adp_id}`}
                    </div>
                    <div className="widgets__list-col widgets__list-col--name">
                      {/^#\d+$/.test(widget.name) ? (`Widget ${widget.name}`) : widget.name}
                    </div>
                    <div className="widgets__list-col widgets__list-col--link">
                      <div className="widgets__col-wrapper">
                        <a
                          href={`${hostname}/demo/${item.ftrack_id}/${widget.idx + 1}`}
                          className="widgets__link--demo"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tooltip
                            content={(
                              <FormattedMessage id="tooltip.preview">
                                {text => <span className="tooltip__text">{text}</span>}
                              </FormattedMessage>
                            )}
                          >
                            <span><Icon name="eye" /></span>
                          </Tooltip>
                        </a>
                        <Link
                          to={`/constructor/${widget.id}`}
                          className="widgets__link--edit"
                        >
                          <Tooltip
                            content={(
                              <FormattedMessage id="tooltip.edit">
                                {text => <span className="tooltip__text">{text}</span>}
                              </FormattedMessage>
                            )}
                          >
                            <span><Icon name="edit" /></span>
                          </Tooltip>
                        </Link>
                        {/* <a
                          href="/"
                          onClick={submit.bind(this, widget.id)}
                          className="widgets__link--remove"
                        >
                          <Icon name="x" />
                        </a> */}
                      </div>
                    </div>
                  </li>
                ))
            }
          </ul>
        )
      }
      <Button size="l" kind="secondary" className="widgets__list-add" onClick={props.createWidget}>Add new widget
      </Button>
    </div>
  );

  return w;
}

export default ConstructorList;
