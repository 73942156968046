// данные для всех страниц и их API. Доступ api.page('audience') возможен по мод-страницам, содержащим ссылку .default
import { cookie } from './app/utils';

const Api = function Api() {
  this.menuGroups = { // секции меню. Для .<key> ищем this.pages.<key2>, у которых .menuGroup = <key>
    front: '',
    dashboard: '',
    perf: { title: 'Giraff.io performance' }, // В каждой - ищутся pages{}, чтобы построить группы в меню
    content: { title: 'Editorial performance' },
    refs: { title: 'Referrers' },
    tools: { title: 'Editorial tools' },
    settings: { title: 'Settings' },
    admins: {
      title: 'Admin panel',
      admin: true,
    },
  };

  this.pages = {
    dashboard: {
      title: 'Dashboard',
      html: '1', // HTML перед названием (иконка, ...)
      menuGroup: 'dashboard',
      urlPage: 'dashboard',
      storageKey: 'Dashboard',
    },
    summary: {
      title: 'Summary report',
      menuGroup: 'perf',
      // urlPage: 'page=summary',
      urlPage: 'reports/summary',
      storageKey: 'Summary',
    },
    engagement: {
      title: 'Audience engagement',
      menuGroup: 'perf',
      // urlPage: 'page=engagement',
      urlPage: 'reports/engagement',
      storageKey: 'Engagement',
      pageTypes: ['date', 'country', 'device', 'widget', 'top'], // если нет такого, то 'date' по умолчанию в дальнейшем
    },
    newaudience: {
      title: 'New audience',
      menuGroup: 'perf',
      urlPage: 'reports/new_audience',
      storageKey: 'Audience',
    },
    revenue: {
      title: 'Revenue',
      menuGroup: 'perf',
      urlPage: 'reports/revenue',
      storageKey: 'Revenue',
    },
    content: {
      title: 'Content',
      menuGroup: 'content',
      urlPage: 'reports/editorial/content',
      storageKey: 'Content',
    },
    top_content: {
      title: 'Top content',
      menuGroup: 'content2', // TODO нигде не применяется, но требуется в Header.jsx
      urlPage: 'reports/engagement/top', // TODO нигде не применяется
      storageKey: '',
      filter: { pageType: 'top', COMMENT: '==pageType is placed in engagement; * no place in storage' },
    },
    social: {
      title: 'Social',
      menuGroup: 'content',
      urlPage: 'reports/editorial/social',
      storageKey: 'Social',
    },
    author: {
      title: 'Authors',
      menuGroup: 'content',
      urlPage: 'reports/editorial/authors',
      storageKey: 'Authors',
    },
    referers: { // называлось Performance.jsx
      title: 'Performance report',
      menuGroup: 'refs',
      urlPage: 'reports/referrers/performance',
      storageKey: 'Performance',
    },
    manageOwn: {
      title: 'Manage own content',
      menuGroup: 'tools',
      urlPage: 'tools/manage_content/own',
      storageKey: 'Own',
    },
    manageOthers: {
      title: 'Manage others content',
      menuGroup: 'tools',
      urlPage: 'tools/manage_content/others',
      storageKey: 'Others',
    },
    exclude_url: {
      title: 'Exclude URLs',
      menuGroup: 'tools',
      urlPage: 'tools/exclude/urls',
      storageKey: 'Others',
    },
    exclude_title: {
      title: 'Exclude titles',
      menuGroup: 'tools',
      urlPage: 'tools/exclude/titles',
      storageKey: 'Others',
    },
    performance: {
      title: 'Performance',
      menuGroup: 'settings',
      urlPage: 'settings/performance',
      storageKey: '',
      enabled: () => cookie.getIsCookie('is_admin'),
    },
    time_limits: {
      title: 'Time limits',
      menuGroup: 'settings',
      urlPage: 'settings/time_limits',
      storageKey: '',
    },
    constructor: {
      title: 'Constructor',
      urlPage: 'constructor',
      menuGroup: 'settings',
      enabled: isConstructorAllowed => isConstructorAllowed || cookie.getIsCookie('is_admin'),
    },
    // API-адреса (TODO можно назвать отдельным свойством, не .pages)
    list_editors: { // это - только API, не страница. Для Content
      title: 'List editors',
    },
    list_socialnetworks: { // это - только API, не страница. Для Social
      title: 'List socialnetworks',
    },
    sites_list: { // это - только API, не страница. Для sitesList.jsx
      title: 'All sites',
    },
    select_site: { // это - только API, не страница. Для sitesList.jsx
      title: 'Select site',
    },
    widgets: { // это - только API, не страница.
      title: 'Widgets',
    },
    siteSettings: {
      title: 'Site settings',
      urlPage: 'site-settings',
      menuGroup: 'admins',
      admin: true,
    },
    abTest: {
      title: 'A/B testing',
      menuGroup: 'settings',
      urlPage: 'ab_tests',
    },
    externalAds: {
      title: 'External ads codes',
      menuGroup: 'settings',
      urlPage: 'external_ads',
    },
    paymentHistory: {
      title: 'Payment history',
      menuGroup: 'settings',
      urlPage: 'payment_history',
    },
  };
};
Api.prototype = {
  page(page) { // если единственный .default - это страница-алиас; если что-то есть ещё, то это - мод-страница.
    let currentPage = page;
    if (!currentPage) return false;
    while (this.pages[currentPage].default && Object.getOwnPropertyNames(this.pages[currentPage]).length === 1) {
      currentPage = this.pages[currentPage].default;
    } // проход по алиасам
    return this.pages[currentPage];
  },
};
export default (window.Ap0 = []).api = new Api();
