import React, { ReactElement } from 'react';
import { Pagination } from 'uikit';

import { PgTablePagerProps } from 'app/ts/types/PgTablePager';

const PgTablePager = (props: PgTablePagerProps): ReactElement => {
  const { totalItems: dataLength, pageN, changePageN, pagerName } = props;
  const getActiveTableSize = (newPagerName: string): string => localStorage.getItem(newPagerName) || '20';

  const [activeTableSize, setActiveTableSize] = React.useState<string>(getActiveTableSize(pagerName));

  const previousActiveTableSize: string = getActiveTableSize(pagerName);

  React.useEffect(() => {
    if (previousActiveTableSize !== activeTableSize) {
      if (!['20', '50', '100'].includes(previousActiveTableSize)) {
        localStorage.setItem(pagerName, '20');
        setActiveTableSize('20');
      }
      localStorage.setItem(pagerName, previousActiveTableSize);
      setActiveTableSize(previousActiveTableSize);
    }
  }, [activeTableSize, pagerName, previousActiveTableSize]);

  const changeTableSize = (val: number): void => {
    localStorage.setItem(pagerName, val.toString());
    changePageN(1);
  };

  let buttons: Array<number> = [];
  let countNumber: number = 0;
  let paginationStatus: boolean = false;

  if (dataLength > 20 && dataLength <= 50) {
    paginationStatus = true;
    buttons = [20, 50];
  } else if (dataLength > 50) {
    paginationStatus = true;
    buttons = [20, 50, 100];
  }

  if (paginationStatus === true) {
    countNumber = Math.floor(dataLength / parseInt(activeTableSize, 10));
  }

  return paginationStatus === false ? null : (
    <div className="page__table-buttons">
      <div className="table-size__wrapper" data-text-label="Items per page">
        {buttons.map((v, index) => (
          <button
            type="button"
            onClick={() => changeTableSize(v)}
            key={index}
            data-val={v}
            className={v.toString() === activeTableSize.toString() ? 'table-size__btn--active' : 'table-size__btn'}
          >
            {v}
          </button>
        ))}
      </div>
      <div className="table-pagination__wrapper gpagination">
        {countNumber > 0 && (
          <Pagination
            maxItems={dataLength}
            itemsPerPage={parseInt(activeTableSize, 10)}
            currentPage={pageN}
            changePage={changePageN}
          />
        )}
      </div>
    </div>
  );
};

export default PgTablePager;
