/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';

export default (overrideProps, totals) => ({
  accessor: 'shares',
  resizable: false,
  Header: (
    <FormattedMessage id="table.shares">
      {txt => <p className="cell-inner">{SortIcon} {txt}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <span className="cell-inner" title={formatUtils.integer(totals.shares)}>{formatUtils.integer(totals.shares)}</span>
  ),
  Cell: props => (
    <span className="cell-inner" title={formatUtils.integer(props.value)}>{formatUtils.integer(props.value)}</span>
  ),
  ...overrideProps,
});
