import * as React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'uikit';
import { FormattedMessage } from 'react-intl';
import { tz, format } from '../../utils';

const Content = (props) => {
  const className = 'info-block__value-dif info-block__value-dif--board';
  const { content } = props;

  return (
    <div className="board__content">
      <div className="info-block info-block--board">
        <div className="info-block__value">
          { format.percentage(content.reading_depth) }
        </div>
        <div className={content.reading_depth_inc >= 0 ? className : `${className} info-block__value-dif--negative`}>
          { format.percentage(content.reading_depth_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.content_reading_depth" />
                </span>
              )}
              placement="bottom"
            >
              <span>Reading depth</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info-block info-block--board">
        <div className="info-block__value">
          { typeof (content.pv_time) === 'number' ?
            tz.convertToMinSecString(format.mathRound(content.pv_time, 2)) : '0 min' }
        </div>
        <div className={className + (content.pv_time_inc >= 0 ? '' : ' info-block__value-dif--negative')}>
          { format.percentage(content.pv_time_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.content_reading_time" />
                </span>
              )}
              placement="bottom"
            >
              <span>Reading time</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

Content.propTypes = {
  content: PropTypes.object.isRequired,
};

export default Content;
