import React, { ReactElement } from 'react';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown, Icon, Checkbox } from 'uikit';
import Table from 'app/components/Table';
import { stats as statsUtils } from 'app/utils';

import { AudienceEngagementPgTable, EngagementColumnsConfig } from 'app/ts/types/AudienceEngagementPgTable';
import { ColumnsConfig } from 'app/ts/types/ColumnsConfig';

import { AppDispatch } from 'app/store';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { setEngagementColumns } from '../../../../store/accountSettings/accountSettingsSlice';
import { getDisplayedColumns } from '../../columnsHelpers/audienceEngagement';

import './AudienceEngagement.scss';

const AudienceEngagement = (props: AudienceEngagementPgTable): ReactElement => {
  const {
    statsData,
    totalItems,
    changeState,
    pageType,
    totalsData,
    widgetsActive,
    pageContent,
    isLoading,
  } = props;
  const columnsConfig: EngagementColumnsConfig =
    useAppSelector(reduxState => reduxState.accountSettings.reports.engagement.columns);
  const dispatch: AppDispatch = useAppDispatch();
  const allWidgetsActive: boolean = !(pageContent === 'widgets' && widgetsActive !== 'all');
  const displayedColumns: Array<ColumnsConfig> = getDisplayedColumns({
    totalsData,
    pageType,
    columnsConfig,
    allWidgetsActive,
  });

  const columnsSettingsContent: ReactElement = (
    <ul>
      {displayedColumns.map((item: ColumnsConfig, index: number): ReactElement => {
        if (['widget', 'date', 'country', 'device', 'itemTitle'].includes(item.accessor)) {
          return null;
        }
        if ((pageType === 'top' && item.accessor === 'pageViews')
          || (pageType === 'top' && item.accessor === 'widgetViews')
          || (pageType !== 'top' && item.accessor === 'impressions')
          || (pageType === 'top' && item.accessor === 'wctr')
          || (item.accessor === 'viewsAfter' && (pageType === 'widget' || !allWidgetsActive))
          || (item.accessor === 'increase' && (pageType === 'widget' || pageType === 'top' || !allWidgetsActive))) {
          return null;
        }
        return (
          <li
            key={index}
          >
            <Checkbox
              id={item.accessor}
              name={item.accessor}
              checked={columnsConfig[item.accessor as keyof EngagementColumnsConfig]}
              onChange={() => {
                const payload = {
                  ...columnsConfig,
                  [item.accessor]: !columnsConfig[item.accessor as keyof EngagementColumnsConfig],
                };
                dispatch(setEngagementColumns(payload));
              }}
            >
              <FormattedMessage id={`table.${item.accessor}`} />
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      <div className="columns-settings">
        <Dropdown
          theme="dropdown-checkbox-list"
          placement="bottom-end"
          content={columnsSettingsContent}
          arrow={false}
          trigger="click"
          interactive
        >
          <button
            type="button"
            className="dropdown__button dropdown__button--columns-settings"
          >
            Settings
            <Icon
              name="settings"
              size={18}
              className="dropdown__icon dropdown__icon--columns-settings"
            />
          </button>
        </Dropdown>
      </div>
      <div
        className={cx({
          'audience-engagement-pg-table': pageType !== 'top',
          'audience-engagement-pg-table-top': pageType === 'top',
        })}
      >
        <Table
          columns={displayedColumns}
          data={statsData.map(statsUtils.fromServerData)}
          dataCount={totalItems}
          changeState={changeState}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default injectIntl(AudienceEngagement);
