import React, { ReactElement } from 'react';
import { WidgetPreviewProps } from '../../../ts/types/constructor/WidgetPreview';
import { PreviewWidgetSize } from '../../../ts/types/constructor';

const WidgetPreview = (props: WidgetPreviewProps): ReactElement => {
  const {
    size,
    previewWidgetContent,
    preloadJS,
    pattern,
  } = props;

  const previewWidgetSize: PreviewWidgetSize = {
    width: !size.auto ? size.width : '100%',
    height: !size.auto ? size.height : '100%',
  };

  const patternsRequiresText = ['rollIn', 'inline', 'inline-green-button', 'inline-default'];
  const mobilePatterns = ['rollIn', 'mobileFullscreen'];
  const isWidgetRequiresText = patternsRequiresText.indexOf(pattern) > -1 && pattern !== 'default';
  const isRollInOMobileFullscreen = mobilePatterns.indexOf(pattern) > -1;

  const text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non efficitur leo, sit amet' +
    'gravida nunc. Donec faucibus nul ac nisl dictum, et scelerisque massa convallis. Duis a vulputate est.' +
    'In vulputate pellentesque mauriuis auctor. Nulla vitae eros non metus suscipit pretium eget molestie' +
    'ligula. Nam suscipit ac eros non interdum. Mauris convallis iaculis lorem, ac convallis eros efficitur' +
    'nec.Aenean eu sollicitudin nulla. Duis dictum quam eu pretium aliquet. Morbi volutpat vulputate' +
    'porttitor. Aliquam suscipit eros sem, quis rhoncus massa dignissim non. Nam iaculis lacinia est';

  const iframeContent = `<script type="text/javascript">window.giraff_iframe_mode=true;</script>
    <script>
      setTimeout(() => {
        if (\`${isWidgetRequiresText}\` === 'true') {
            const text = document.createElement('p');
            text.innerText = \`${text}\`;
            text.style.fontSize = '42px';
            document.body.appendChild(text);
        }
        function setUserAgent(window, userAgent) {
          if (window.navigator.userAgent != userAgent) {
            var userAgentProp = {
              get: function() {
                return userAgent;
              }
            };
            try {
              Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
            } catch (e) {
              window.navigator = Object.create(navigator, {
                userAgent: userAgentProp
              });
            }
          }
        }

        if (\`${isRollInOMobileFullscreen}\` === 'true') {
          setUserAgent(
            window,
            'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X)' +
             ' AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1'
          );
        }
      }, 0);
    </script>
    <script>
      setTimeout(() => {
        const widget = document.createElement('div');
        widget.setAttribute('id', 'template-widget');
        const tpl = \`${previewWidgetContent}\`;
        const preloadJS = \`${preloadJS}\`.replace(/\\r|\\n/g, "");
        document.body.appendChild(widget);
        const options = {
          div: 'template-widget',
        };
        if (preloadJS.length > 0) {
             try {
               ${preloadJS}
             } catch (error) {
               console.error('Caught at preload_js: ' + error.message);
             }
        } else {
          document.getElementById(options.div).innerHTML = tpl;
        }
      }, 0);
  </script>
  <script>
      setTimeout(() => {
        if (\`${isWidgetRequiresText}\` === 'true') {
          const text = document.createElement('p');
          text.innerText = \`${text}\`;
          text.style.fontSize = '42px';
          document.body.appendChild(text);
        }
      }, 0);
    </script>`;
  return (
    <iframe
      className="widget-preview"
      title="preview widget"
      frameBorder="0"
      height={previewWidgetSize.height}
      width={previewWidgetSize.width}
      srcDoc={iframeContent.toString()}
    />
  );
};

export default WidgetPreview;
