const mobileUserAgentRegexs: Array<RegExp> = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i,
];
const { navigator: { userAgent } } = window;
const isMobile: boolean = mobileUserAgentRegexs.some(regex => userAgent.match(regex));

export default isMobile;
