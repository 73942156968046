import { ShowAlert } from 'app/ts/types/utils';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Snackbar } from 'uikit';

const showAlert: ShowAlert = (message) => {
  confirmAlert({
    customUI: ({ onClose }) => { // eslint-disable-line
      setTimeout(onClose, 5000);
      return (
        <Snackbar
          message={message}
          onClose={() => {}}
          vertical="top"
          open
          className="giraff-alert"
        />
      );
    },
  });
};

export default showAlert;
