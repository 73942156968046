import React, { ReactElement } from 'react';

import { EngagementGraffProps, EngagementLegend } from 'app/ts/types/EngagementGraff';

import Charts from '../default/Charts';

const EngagementGraffWidget = (props: EngagementGraffProps): ReactElement => {
  const { timeframe, statsData, byHour, pageType } = props;
  if ((!timeframe && !statsData) || (!timeframe.length && !statsData.length)) {
    return null;
  }

  const legend: Array<EngagementLegend> = [
    {
      title: 'Audience engagement widget shows',
      color: '#ff6363',
      field: 'pageviews',
      valueDecimals: 0,
      disabled: true,
    }, {
      title: 'Widget views',
      color: '#00db71',
      field: 'widget_views',
      valueDecimals: 0,
    }, {
      title: 'Clicks',
      color: '#ae80ff',
      field: 'clicks',
      disabled: true,
    }, {
      title: 'WCTR (%)',
      color: '#ffc400',
      field: 'wctr',
      suffix: '&thinsp;%',
      disabled: true,
    },
  ];

  return (
    <Charts
      timeframe={timeframe}
      statsData={statsData}
      byHour={byHour}
      id="engagement"
      legend={legend}
      groups={[]}
      pageType={pageType}
    />
  );
};

export default EngagementGraffWidget;
