import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './ABTestCreator.scss';
import { Icon, Button } from 'uikit';
import ABFormItem from './AbFormItem';

const ABTestCreator = ({ newTest, widgets, actions }) => {
  const filteredWidgets = widgets.filter(item => !item.in_test);
  return (
    <div className="ab-creator">
      <div className="ab-creator__header">
        <FormattedMessage id="abtest.create-new" />
      </div>
      {
        filteredWidgets.length > 1 ? (
          <form action="" className="ab-creator__form">
            {
              newTest.map((item, index) => (
                <ABFormItem
                  key={`test-widget-${item.name.toLowerCase().replace(/ /g, '-')}-${item.idx}`}
                  item={item}
                  index={index}
                  changeTestWidget={actions.changeTestWidget}
                  removeWidget={actions.removeWidget}
                  filteredWidgets={filteredWidgets}
                  newTest={newTest}
                />
              ))
              }
            {
              filteredWidgets.length !== newTest.length && newTest.length > 1 &&
              !newTest.filter(item => item.idx === -1).length && (
                <div className="ab-creator__form-group">
                  <div className="ab-creator__form-group-item">
                    <button
                      type="button"
                      className="ab-creator__link ab-creator__link--green"
                      onClick={() => actions.addWidget()}
                    >
                      <Icon name="plus" />
                      <FormattedMessage id="abtest.add-widget" />
                    </button>
                  </div>
                </div>
              )
            }
            <div className="ab-creator__form-group">
              {
                newTest.filter(item => item.idx !== -1).length >= 2 && (
                  <div className="ab-creator__form-group-item">
                    <Button
                      kind="outline"
                      disabled={Boolean(newTest.filter(item => item.idx === -1).length)}
                      className="ab-creator__button"
                      type="button"
                      onClick={actions.createTest}
                    >
                      <FormattedMessage id="abtest.create" />
                    </Button>
                  </div>
                )
              }
              {
                newTest.length > 1 && (
                <div className="ab-creator__form-group-item">
                  <Button
                    kind="outline"
                    className="ab-creator__button"
                    onClick={actions.cancelTest}
                  >
                    <FormattedMessage id="abtest.cancel" />
                  </Button>
                </div>
                )
              }
            </div>
          </form>
        ) : <FormattedMessage id="abtest.no-widgets" />
      }
    </div>
  );
};

export default ABTestCreator;

ABTestCreator.propTypes = {
  newTest: PropTypes.array.isRequired,
  widgets: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};
