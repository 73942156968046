import { logo, logoUrl, logoDimensions } from 'app/config/constructor';

export default {
  settings: {
    active: true,
    pattern: 'default',
    cols: 3,
    cells: ['rtb', 'rtb', 'rtb'],
    config: {
      '%ADS_SHOW%': 'block',
      '%ANNOUNCE_ALIGN%': 'left',
      '%ANNOUNCE_DISPLAY%': 'none',
      '%ANNOUNCE_FONT%': 'inherit',
      '%ANNOUNCE_FONT_COLOR%': '#000',
      '%ANNOUNCE_FONT_SIZE%': '14px',
      '%ANNOUNCE_FONT_UNDERLINE%': 'none',
      '%ANNOUNCE_FONT_WEIGHT%': '400',
      '%ANNOUNCE_LINE_HEIGHT%': '18px',
      '%ANNOUNCE_PADDING%': '6px',
      '%BOX_SHADOW%': 'none',
      '%HOVER_BOX_SHADOW%': 'none',
      '%HOVER_TRANSITION%': 'all 0s ease 0s',
      '%TEASER_BG%': 'transparent',
      '%LINK_PADDING%': '0 0 0 0',
      '%LINK_BORDER_RADIUS%': '0 0 0 0',
      '%WIDGET_PADDINGS%': `0 0 ${logoDimensions.height} 0`,
      '%WIDGET_WIDTH%': '100%',
      '%WIDGET_HEIGHT%': 'none',
      '%WIDGET_GAP%': '0 2px 14px 2px',
      '%WIDGET_GAP_MARGIN%': '0 -2px 0 -2px',
      '%WIDGET_BG%': 'transparent',
      '%TITLE_FONT%': 'inherit',
      '%TITLE_FONT_SIZE%': '18px',
      '%TITLE_FONT_COLOR%': '#000',
      '%TITLE_HOVER_COLOR%': '#000',
      '%TITLE_HOVER_TRANSITION%': 'all 0s ease 0s',
      '%TITLE_FONT_WEIGHT%': '700',
      '%TITLE_FONT_UNDERLINE%': 'none',
      '%TITLE_LINE_HEIGHT%': '22px',
      '%TITLE_ALIGN%': 'left',
      '%TEXT_PADDING%': '0 0 0 0',
      '%IMAGE_GAP%': '4px',
      '%IMAGE_RELATIVE_HEIGHT%': '75%',
      '%IMAGE_WIDTH%': '400px',
      '%IMAGE_HEIGHT%': '300px',
      '%IMAGE_POSITION%': 'top',
      '%IMAGE_DISPLAY%': 'block',
      '%IMAGE_BORDER_RADIUS%': '0 0 0 0',
      '%IMAGE_HOVER_TRANSFORM%': 'translate(-50%, -50%)',
      '%IMAGE_HOVER_OPACITY%': '1',
      '%IMAGE_HOVER_TRANSITION%': 'all 0s ease 0s',
      '%LOGO%': 'block',
      '%LOGO_POSITION%': 'bottom-right',
      '%LOGO_POSITION_VERTICAL%': 'bottom',
      '%LOGO_POSITION_HORIZONTAL%': 'right',
      '%LOGO_MARGINS%': '0 0 0 0',
      '%LOGO_STYLE%': 'light',
    },
  },
  tpl: {
    css: `.grf-widget--%ADP_ID% {
                    overflow: hidden;
                    position: relative;
                    font-size: %TITLE_FONT_SIZE%;
                    padding: %WIDGET_PADDINGS%;
                    max-width: %WIDGET_WIDTH%;
                    max-height: %WIDGET_HEIGHT%;
                    background-color: %WIDGET_BG%;
                }

                .grf-widget--%ADP_ID% .grf-widget__logo {
                    position: absolute;
                    display: %LOGO%;
                    margin: %LOGO_MARGINS%;
                    z-index: 100;
                }

                .grf-widget--%ADP_ID% .grf-widget__logo--%LOGO_POSITION% {
                    %LOGO_POSITION_VERTICAL%: 0;
                    %LOGO_POSITION_HORIZONTAL%: 0;
                }

                .grf-widget--%ADP_ID% .grf-widget__logo-link {
                    position: relative;
                    width: ${logoDimensions.width};
                    height: ${logoDimensions.height};
                    display: block;
                    cursor: pointer;
                    float: right;
                    background-repeat: no-repeat;
                  ${logoUrl === 'giraff.io' || logoUrl === 'global.giraff.io'
  // eslint-disable-next-line indent
    ? `background-position: 4px 50%;
                    -webkit-transition: width 0.4s;
                    -o-transition: width 0.4s;
                    -moz-transition: width 0.4s;
                    transition: width 0.4s;` : ''}
                }

                .grf-widget--%ADP_ID% .grf-widget__logo-link--light {
                    background-image: url(${logo.light});
                }

                .grf-widget--%ADP_ID% .grf-widget__logo-link--dark {
                    background-image: url(${logo.dark});
                }
              ${logoUrl === 'giraff.io' || logoUrl === 'global.giraff.io'
  // eslint-disable-next-line indent
    ? `.grf-widget--%ADP_ID% .grf-widget__logo-link:hover {
                  width: 58px;
                }` : ''}

                .grf-widget--%ADP_ID% .grf-list {
                    margin: %WIDGET_GAP_MARGIN%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                }

                .grf-widget--%ADP_ID% .grf-list__item {
                    position: relative;
                    padding: %WIDGET_GAP%;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    width: calc(100%/%COLS%);
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                }

                .grf-widget--%ADP_ID% .grf-list__link {
                    padding: %LINK_PADDING%;
                    display: block;
                    position: relative;
                    text-decoration: %TITLE_FONT_UNDERLINE%;
                    color: %TITLE_FONT_COLOR%;
                    background-color: %TEASER_BG%;
                    height: 100%;
                    border-radius: %LINK_BORDER_RADIUS%;
                    -webkit-box-shadow: %BOX_SHADOW%;
                    box-shadow: %BOX_SHADOW%;
                    -webkit-transition: -webkit-%HOVER_TRANSITION%;
                    transition: -webkit-%HOVER_TRANSITION%;
                    -o-transition: %HOVER_TRANSITION%;
                    transition: %HOVER_TRANSITION%;
                    transition: %HOVER_TRANSITION%, -webkit-%HOVER_TRANSITION%;
                }

                .grf-widget--%ADP_ID% .grf-list__link:hover {
                    -webkit-box-shadow: %HOVER_BOX_SHADOW%;
                    box-shadow: %HOVER_BOX_SHADOW%;
                }

                .grf-widget--%ADP_ID% .grf-list__link:hover .grf-list__img {
                    opacity: %IMAGE_HOVER_OPACITY%;
                    transform: %IMAGE_HOVER_TRANSFORM%;
                    -webkit-transition: -webkit-%IMAGE_HOVER_TRANSITION%;
                    transition: -webkit-%IMAGE_HOVER_TRANSITION%;
                    -o-transition: %IMAGE_HOVER_TRANSITION%;
                    transition: %IMAGE_HOVER_TRANSITION%;
                    transition: %IMAGE_HOVER_TRANSITION%, -webkit-%IMAGE_HOVER_TRANSITION%;
                }

                .grf-widget--%ADP_ID% .grf-list__link:hover .grf-list__title {
                  color: %TITLE_HOVER_COLOR%;
                  transform: %TITLE_HOVER_TRANSITION%;
                  -webkit-transition: -webkit-%TITLE_HOVER_TRANSITION%;
                  transition: -webkit-%TITLE_HOVER_TRANSITION%;
                  -o-transition: %TITLE_HOVER_TRANSITION%;
                  transition: %TITLE_HOVER_TRANSITION%;
                  transition: %TITLE_HOVER_TRANSITION%, -webkit-%TITLE_HOVER_TRANSITION%;
                }

                .grf-widget--%ADP_ID% .grf-list__link-img {
                    display: %IMAGE_DISPLAY%;
                    position: relative;
                    width: 100%;
                    padding-top: %IMAGE_RELATIVE_HEIGHT%;
                    margin-bottom: %IMAGE_GAP%;
                    overflow: hidden;
                    background: #f2f5f6;
                    border-radius: %IMAGE_BORDER_RADIUS%;
                }

                .grf-widget--%ADP_ID% .grf-list__link-title {
                    overflow: hidden;
                    padding: %TEXT_PADDING%;
                    text-align: %TITLE_ALIGN%;
                }

                .grf-widget--%ADP_ID% .grf-list__item[ad-mark="true"] .grf-list__link:after {
                    display: %ADS_SHOW%;
                    content: "Реклама";
                    position: absolute;
                    backdrop-filter: blur(1px);
                    color: hsla(0, 0%, 100%, .64);
                    font-family: %TITLE_FONT%;
                    left: 4px;
                    position: absolute;
                    text-shadow: 0 0 4px rgba(0, 0, 0, 1);
                    top: 4px;
                    font-size: 10px;
                    font-weight: 400;
                    letter-spacing: -.08px;
                    line-height: 12px;
                }

                .grf-widget--%ADP_ID% .grf-list__img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: auto;
                    height: 100%;
                    max-width: none;
                    transform: translate(-50%, -50%);
                }

                .grf-widget--%ADP_ID% .grf-list__title {
                    line-height: %TITLE_LINE_HEIGHT%;
                    color: %TITLE_FONT_COLOR%;
                    font-weight: %TITLE_FONT_WEIGHT%;
                    cursor: pointer;
                    font-family: %TITLE_FONT%;
                    max-height: calc(4 * %TITLE_LINE_HEIGHT%);
                    overflow: hidden;
                    box-sizing: border-box;
                }

                .grf-widget--%ADP_ID% .grf-list__item.grf-left .grf-list__link-img {
                    float: left;
                    width: %IMAGE_WIDTH%;
                    height: %IMAGE_HEIGHT%;
                    min-width: initial;
                    margin-right: %IMAGE_GAP%;
                    margin-bottom: 0;
                    padding: 0;
                }

                .grf-widget--%ADP_ID% .grf-list__item.grf-right .grf-list__link-img {
                    float: right;
                    width: %IMAGE_WIDTH%;
                    height: %IMAGE_HEIGHT%;
                    min-width: initial;
                    margin-right: %IMAGE_GAP%;
                    margin-bottom: 0;
                    padding: 0;
                }

                .grf-widget--%ADP_ID% .grf-list--rv .grf-list__item[data-type="rv"] .grf-list__link-title:after{
                    display: none;
                }

                .grf-widget--%ADP_ID% .grf-list__item-privacy-link{
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: %WIDGET_GAP%;
                    z-index: 2;
                }

                .grf-widget--%ADP_ID% .grf-list__item-privacy-link + a{
                    z-index: 1;
                }

                .grf-widget--%ADP_ID% .grf-list__item-privacy-link img{
                    display: block;
                }

                .grf-widget--%ADP_ID% .grf-list__announce {
                    font-size: %ANNOUNCE_FONT_SIZE%;
                    font-weight: %ANNOUNCE_FONT_WEIGHT%;
                    text-decoration: %ANNOUNCE_FONT_UNDERLINE%;
                    padding-top: %ANNOUNCE_PADDING%;
                    line-height: %ANNOUNCE_LINE_HEIGHT%;
                    color: %ANNOUNCE_FONT_COLOR%;
                    text-align: %ANNOUNCE_ALIGN%;
                    font-family: %ANNOUNCE_FONT%;
                    max-height: calc(4 * %ANNOUNCE_LINE_HEIGHT% + %ANNOUNCE_PADDING%);
                    overflow: hidden;
                    box-sizing: border-box;
                }

                .grf-widget--%ADP_ID% .grf-list__item[data-rtype]:not([data-rtype="cr"])
                 .grf-list__announce {
                    display: %ANNOUNCE_DISPLAY%;
                }

                .grf-widget--%ADP_ID% .grf-list__item[data-rtype="cr"] .grf-list__img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    max-height: 100%;
                    max-width: 100%;
                    width: auto;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                }

                .grf-widget--%ADP_ID% .grf-list__item[ad-mark="true"] .grf-list__erir-btn,
          .grf-widget--%ADP_ID% .grf-list__item[ad-mark="true"] .grf-list__erir-btn:hover {
              display: flex !important;
              justify-content: center;
              align-items: center;
              content: "i";
              position: absolute;
              font-family: %TITLE_FONT%;
              right: 4px;
              top: 4px;
              font-size: 10px;
              font-weight: 400;
              letter-spacing: -.08px;
              line-height: 12px;
              cursor: pointer;
              width: 10px;
              height: 10px;
              color: #a3a3a3;
              border: 1px solid #a3a3a3;
              border-radius: 50%;
              z-index: 99999;
              margin: %WIDGET_GAP%;
              margin-top: 0;
              box-shadow: none;
              background-color: rgba(255, 255, 255, 0.8);
          }

          .grf-widget--%ADP_ID% .grf-list__item[ad-mark="true"] .grf-list__erir {
              position: absolute;
              top: 0;
              left: 0;
              background-color: #fff;
              border: 1px solid #ccc;
              padding: 5px;
              font-size: 10px;
              line-height: 12px;
              font-family: %TITLE_FONT%;
              z-index: 99;
              display: none;
              margin: %WIDGET_GAP%;
              margin-top: 0;
          }

          .grf-widget--%ADP_ID% .grf-list__item[ad-mark="true"] .grf-list__erir.show {
              display: block;
          }

          .grf-widget--%ADP_ID% .grf-list__item[ad-mark="false"] .grf-list__erir,
          .grf-widget--%ADP_ID% .grf-list__item[ad-mark="false"] .grf-list__erir-btn {
              display: none;
          }

            ${logoUrl === 'giraff.io' || logoUrl === 'global.giraff.io'
  // eslint-disable-next-line indent
    ? `@media(max-width: 1024px) {
                .grf-widget--%ADP_ID% .grf-widget__logo-link {
                    width: 58px;
                }
              }` : ''}`,
    footer: `</div>
            </div>
                <div class="grf-widget__logo grf-widget__logo--%LOGO_POSITION%">
                    <a
                      href="//${logoUrl}"
                      class="grf-widget__logo-link grf-widget__logo-link--%LOGO_STYLE%"
                      target="_blank"
                    >
                    </a>
                </div>
            </div>`,
    header: `<div class="grf-widget grf-widget--%ADP_ID%" data-cols="%COLS%">
                    <div class="grf-widget__content">
                        <div class="grf-list" data-cols="%COLS%" data-rows="%ROWS%">`,
    item: `<div
                class="grf-list__item grf-%IMAGE_POSITION%"
                data-type="%CELL_TYPE%"
                ad-mark="%AD_MARK%"
                data-rtype="%CELL_RTYPE%"
            >
            <div class="grf-list__erir-btn">i</div>
            <div class="grf-list__erir">
                Рекламодатель: %CONTRAGENT%
                <br />
                ERID: %ERID%
                <br />
                ИНН: %INN%
            </div>
            <a class="grf-list__link" href="%URL%" target="%TARGET%">
                <div class="grf-list__link-img">
                    <img class="grf-list__img" src="%IMG_300x230%"/>
                </div>
                <div class="grf-list__link-title">
                    <div class="grf-list__title">%TITLE%</div>
                    <div class="grf-list__announce">%ANNOUNCE%</div>
                </div>
            </a>
        </div>`,
    preload_js: `
    (function(n, t) {
        var i = document.getElementById(t.div);
        i.innerHTML = n;
        const infoButton = i.querySelectorAll(".grf-list__item .grf-list__erir-btn");
        const infoErir = i.querySelectorAll(".grf-list__item .grf-list__erir");

        if (typeof infoButton !== undefined && typeof infoErir !== undefined) {
            for (let i = 0; i < infoButton.length; i++) {
                infoButton[i].addEventListener("click", () => {
                    infoErir[i].classList.toggle("show");
                });
            }
        }
    }(tpl, options))`,
    row_header: '',
    row_footer: '',
  },
};
