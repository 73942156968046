import React, { ReactElement } from 'react';
import { Button } from 'uikit';
import { FormattedMessage } from 'react-intl';
import './Header.scss';

import { HeaderProps } from 'app/ts/types/constructor';

const Header = (props: HeaderProps): ReactElement => {
  const {
    actionWidget,
    saveWidget,
    widgetName = '',
    currentSiteName = '',
  } = props;

  return (
    <header className="constructor-header">
      <div className="constructor-header__widget-name">
        {widgetName} {currentSiteName}
      </div>
      <nav className="constructor-header__nav">
        <ul className="constructor-header__nav-list">
          {
            actionWidget && [
              <li className="constructor-header__nav-item" key="nav-item--preview">
                <Button
                  size="l"
                  kind="secondary"
                  type="button"
                  onClick={() => actionWidget('')}
                >
                  <FormattedMessage id="constructor.preview" />
                </Button>
              </li>,
              <li className="constructor-header__nav-item" key="nav-item--export">
                <Button
                  size="l"
                  kind="secondary"
                  type="button"
                  onClick={() => actionWidget('FormImport')}
                >
                  <FormattedMessage id="constructor.import-export" />
                </Button>
              </li>,
            ]
          }
          <li className="constructor-header__nav-item">
            <Button
              size="l"
              kind="secondary"
              type="button"
              onClick={saveWidget}
            >
              <FormattedMessage id="constructor.save" />
            </Button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
