import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { metrics, ajax } from 'utils';
import { TextField } from 'uikit';

import './SetTimeLimits.scss';

const SetTimeLimits = ({ isAdmin, currentSiteName, currentSite }) => {
  const [limits, setLimits] = useState({
    min: 0, value: '', period: '',
  });
  const [applyButtonActive, setApplyButtonActive] = useState(false);
  const [showSavedNotification, setShowSavedNotification] = useState(false);

  const fetchTimeLimits = () => {
    ajax.get('/settings/time_limits').then(({ data }) => {
      if (data) {
        setLimits((prevState) => ({ ...prevState, ...data.limits }));
      }
    });
  };

  useEffect(() => {
    fetchTimeLimits();
    metrics.metricEvent('hit', '/settings/time_limits');
    metrics.metricEvent('reachGoal', 'OPEN_TIME_LIMITS', {
      admin: isAdmin,
      siteName: currentSiteName,
    });
  }, []);

  useEffect(() => {
    fetchTimeLimits();
  }, [currentSite]);

  const submitSettings = () => {
    const params = { action: 'save', ...limits };

    ajax.get('/settings/time_limits', { params }).then(({ data }) => {
      if (data) {
        setApplyButtonActive(false);
        setShowSavedNotification(true);
        setTimeout(() => setShowSavedNotification(false), 2000);
      }
    });
  };

  const changeValue = ({ target: { value } }) => {
    setApplyButtonActive(value >= 0);
    setLimits((prevState => ({ ...prevState, value })));
  };

  const changePeriod = ({ target: { innerText } }) => {
    const period = innerText.toLowerCase();
    setApplyButtonActive(true);
    setLimits((prevState => ({ ...prevState, period })));
  };

  return (
    <div className="wrapper wrapper--dashboard">
      <div className={`container-fluid settingstl ${limits.period}`}>
        <div className="row">
          <div className="col-lg-12">
            <div className="settingstl__explain">Don&#39;t display content older than:</div>
            <div className="settingstl__calendar clearfix">
              <TextField
                type="number"
                name="settingstl__date"
                defaultValue={limits.value}
                className="settingstl__date-input-wrapper"
                onChange={changeValue}
                min="0"
              />
              <button
                type="button"
                onClick={changePeriod}
                className="settingstl__btn settingstl__btn--first days"
                name="days"
              >Days
              </button>
              <button
                type="button"
                onClick={changePeriod}
                className="settingstl__btn weeks"
                name="weeks"
              >Weeks
              </button>
              <button
                type="button"
                onClick={changePeriod}
                className="settingstl__btn settingstl__btn--last-simple months"
              >Months
              </button>
              <button
                type="submit"
                onClick={submitSettings}
                className={`settingstl__submit ${!applyButtonActive ? 'settingstl__submit--disabled' : ''}`}
              >Apply
              </button>
              <span
                className={`savedset ${showSavedNotification ? 'savedset__active' : ''}`}
              >
                Settings are saved
              </span>
              <div className="clearfix" />
            </div>
            <div className="settingstl__notice">
              This setting is applied only for audience engagement and
              new audience features.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetTimeLimits;

SetTimeLimits.propTypes = {
  currentSite: PropTypes.number.isRequired,
  currentSiteName: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
