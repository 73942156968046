import { regions, tz, format } from 'utils';

const prepareCSVName = ({ widgets, currentSiteName, pageType }) => {
  let activeWidgetTitle = '';
  if (pageType === 'date' && widgets.active !== 'all') {
    activeWidgetTitle = widgets.list.filter(item => item.name.toString() === widgets.active)[0].title;
    activeWidgetTitle = `widget-#${activeWidgetTitle.substr(activeWidgetTitle.indexOf('#') + 1)}-`;
  }

  const fileName = `giraff-audienceEngagement-${
    activeWidgetTitle
  }${currentSiteName}-${
    tz.changeFormat(tz.now())}.csv`;
  return fileName;
};

const prepareCSV = ({ stats, totals, pageType, widgets }) => {
  const data = [];
  const tableHeadingOptions = [
    'Audience engagement page views',
    'Widget views',
    'Clicks',
    'CTR',
    'WCTR',
  ];

  let tableHeading = [];
  let tableTotal = [];

  switch (pageType) {
    case 'date':
      tableHeading = ['Date', ...tableHeadingOptions];

      if (widgets.active === 'all') {
        tableHeading.push('Views after clicks', 'Page views increase');
      }

      tableTotal = [
        'Total',
        totals.pageviews,
        totals.widget_views,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        format.mathRound(totals.wctr, 2, true),
      ];
      if (widgets.active === 'all') {
        tableTotal.push(totals.viewafter, totals.increase);
      }

      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          item.date,
          item.pageviews,
          item.widget_views,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          format.mathRound(item.wctr, 2, true),
        );

        if (widgets.active === 'all') {
          row.push(item.viewafter, item.increase);
        }
        return data.push(row);
      });
      break;
    case 'country':
      tableHeading = ['Country', ...tableHeadingOptions, 'Views after clicks', 'Page views increase'];

      tableTotal = [
        'Total',
        totals.pageviews,
        totals.widget_views,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        format.mathRound(totals.wctr, 2, true),
        totals.viewafter,
        totals.increase,
      ];

      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          regions.getRegion(item.country),
          item.pageviews,
          item.widget_views,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          format.mathRound(item.wctr, 2, true),
          item.viewafter,
          item.increase,
        );
        return data.push(row);
      });
      break;
    case 'device':
      tableHeading = ['Device', ...tableHeadingOptions, 'Views after clicks', 'Page views increase'];

      tableTotal = [
        'Total',
        totals.pageviews,
        totals.widget_views,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        format.mathRound(totals.wctr, 2, true),
        totals.viewafter,
        totals.increase,
      ];

      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          item.device,
          item.pageviews,
          item.widget_views,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          format.mathRound(item.wctr, 2, true),
          item.viewafter,
          item.increase,
        );
        return data.push(row);
      });
      break;
    case 'widget':
      tableHeading = ['Widget', ...tableHeadingOptions];

      tableTotal = [
        'Total',
        totals.pageviews,
        totals.widget_views,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        format.mathRound(totals.wctr, 2, true),
      ];

      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          item.widget,
          item.pageviews,
          item.widget_views,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          format.mathRound(item.wctr, 2, true),
        );
        return data.push(row);
      });
      break;
    case 'top':
      tableHeading = [
        'Content item',
        'Impressions',
        'Clicks',
        'CTR',
        'Views after clicks',
      ];

      tableTotal = [
        'Total',
        totals.impressions,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        totals.viewafter,
      ];

      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          item.item_title,
          item.impressions,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          item.viewafter,
        );
        return data.push(row);
      });
      break;
    default:
      break;
  }
  return data;
};

export default { prepareCSVName, prepareCSV };
