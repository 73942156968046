import { regions, format, tz } from 'utils';

const prepareCSVName = ({ widgets, currentSiteName, pageType }) => {
  let activeWidgetTitle = '';

  if (pageType === 'date' && widgets.active !== 'all') {
    activeWidgetTitle = widgets.list.filter(item => item.name.toString() === widgets.active)[0].title;
    activeWidgetTitle = `widget-#${activeWidgetTitle.substr(activeWidgetTitle.indexOf('#') + 1)}-`;
  }

  const fileName =
    `giraff-revenue-${activeWidgetTitle}${currentSiteName}-${tz.changeFormat(tz.now())}.csv`;

  return fileName;
};

const prepareCSV = ({ stats, totals, pageType }) => {
  const data = [];
  const tableHeadingOptions = [
    'Revenue page views',
    'Widget views',
    'Ad shows',
    'Clicks',
    'CTR',
    'WCTR',
    'CPC',
    'RPM',
    'WRPM',
    'Revenue',
  ];

  let tableHeading = [];
  let tableTotal = [];

  switch (pageType) {
    case 'date':
      tableHeading = ['Date', ...tableHeadingOptions];

      tableTotal = [
        'Total',
        totals.pageviews,
        totals.widget_views,
        totals.ad_shows,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        format.mathRound(totals.wctr, 2, true),
        format.mathRound(totals.cpc, 2, true),
        format.mathRound(totals.rpm, 2, true),
        format.mathRound(totals.wrpm, 2, true),
        format.mathRound(totals.revenue, 2, true),
      ];
      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          item.date,
          item.pageviews,
          item.widget_views,
          item.ad_shows,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          format.mathRound(item.wctr, 2, true),
          format.mathRound(item.cpc, 2, true),
          format.mathRound(item.rpm, 2, true),
          format.mathRound(item.wrpm, 2, true),
          format.mathRound(item.revenue, 2, true),
        );
        return data.push(row);
      });
      break;
    case 'country':
      tableHeading = ['Country', ...tableHeadingOptions];

      tableTotal = [
        'Total',
        totals.pageviews,
        totals.widget_views,
        totals.ad_shows,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        format.mathRound(totals.wctr, 2, true),
        format.mathRound(totals.cpc, 2, true),
        format.mathRound(totals.rpm, 2, true),
        format.mathRound(totals.wrpm, 2, true),
        format.mathRound(totals.revenue, 2, true),
      ];
      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          regions.getRegion(item.country),
          item.pageviews,
          item.widget_views,
          item.ad_shows,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          format.mathRound(item.wctr, 2, true),
          format.mathRound(item.cpc, 2, true),
          format.mathRound(item.rpm, 2, true),
          format.mathRound(item.wrpm, 2, true),
          format.mathRound(item.revenue, 2, true),
        );
        return data.push(row);
      });
      break;
    case 'device':
      tableHeading = ['Device', ...tableHeadingOptions];

      tableTotal = [
        'Total',
        totals.pageviews,
        totals.widget_views,
        totals.ad_shows,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        format.mathRound(totals.wctr, 2, true),
        format.mathRound(totals.cpc, 2, true),
        format.mathRound(totals.rpm, 2, true),
        format.mathRound(totals.wrpm, 2, true),
        format.mathRound(totals.revenue, 2, true),
      ];
      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          item.device,
          item.pageviews,
          item.widget_views,
          item.ad_shows,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          format.mathRound(item.wctr, 2, true),
          format.mathRound(item.cpc, 2, true),
          format.mathRound(item.rpm, 2, true),
          format.mathRound(item.wrpm, 2, true),
          format.mathRound(item.revenue, 2, true),
        );
        return data.push(row);
      });
      break;
    case 'widget':
      tableHeading = ['Widget', ...tableHeadingOptions];

      tableTotal = [
        'Total',
        totals.pageviews,
        totals.widget_views,
        totals.ad_shows,
        totals.clicks,
        format.mathRound(totals.ctr, 2, true),
        format.mathRound(totals.wctr, 2, true),
        format.mathRound(totals.cpc, 2, true),
        format.mathRound(totals.rpm, 2, true),
        format.mathRound(totals.wrpm, 2, true),
        format.mathRound(totals.revenue, 2, true),
      ];
      data.push(tableHeading, tableTotal);

      stats.map((item) => {
        const row = [];
        row.push(
          item.widget,
          item.pageviews,
          item.widget_views,
          item.ad_shows,
          item.clicks,
          format.mathRound(item.ctr, 2, true),
          format.mathRound(item.wctr, 2, true),
          format.mathRound(item.cpc, 2, true),
          format.mathRound(item.rpm, 2, true),
          format.mathRound(item.wrpm, 2, true),
          format.mathRound(item.revenue, 2, true),
        );
        return data.push(row);
      });
      break;
    default:
      break;
  }
  return data;
};

export default { prepareCSVName, prepareCSV };
