/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Button, Tooltip } from 'uikit';

export default (overrideProps, totals, { deleteHandler, type }) => ({
  resizable: false,
  Cell: props => (
    <div className="cell-inner">
      <Tooltip
        placement="bottom"
        content={(
          <FormattedMessage id="tooltip.delete">
            {text => <span className="tooltip__text">{text}</span>}
          </FormattedMessage>
          )}
      >
        {/* Workaround for old libraries that don't forward the ref is to use a <span> wrapper tag: */}
        {/* See the tippyjs-react documentation: */}
        <span>
          <Button
            kind="outline"
            format="icon"
            className="yui-button--transparent"
            onClick={() => deleteHandler(type === 'url' ? props.original.url : props.original.title)}
          >
            <Icon name="trash" />
          </Button>
        </span>
      </Tooltip>
    </div>
  ),
  ...overrideProps,
});
