import Config from "app/ts/interfaces/Config";

declare global {
  const CONFIG: Config;
}

// @ts-ignore TODO: check in production for string value
const exportConfig: Config = process.env.BUILD_MODE_ENV === "prod" ? "%CONFIG%" : CONFIG;

export default exportConfig;
