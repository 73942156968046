/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';
import cx from 'classnames';

export default (overrideProps, totals) => ({
  accessor: 'widgetViews',
  resizable: false,
  className: 'border-right',
  headerClassName: 'border-right',
  Header: (
    <FormattedMessage id="table.widgetViews">
      {txt => <p className="cell-inner widget-views">{SortIcon} {txt}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <span
      className={cx('cell-inner', { 'cell-inner__with-small': totals.widgetViewsOfTotal !== undefined })}
      title={formatUtils.integer(totals.widgetViews)}
    >
      {formatUtils.integer(totals.widgetViews)}
      {totals.widgetViewsOfTotal !== undefined && (
        <span className="cell-inner-small">{formatUtils.percentage(totals.widgetViewsOfTotal)} of total</span>
      )}
    </span>
  ),
  Cell: props => (
    <span
      className={cx('cell-inner', { 'cell-inner__with-small': totals.widgetViewsOfTotal !== undefined })}
      title={formatUtils.integer(props.value)}
    >
      {formatUtils.integer(props.value)}
      {totals.widgetViewsOfTotal !== undefined && (
        <span className="cell-inner-small">
          {formatUtils.percentage(props.original.widgetViewsOfTotal)} of total
        </span>
      )}
    </span>
  ),
  ...overrideProps,
});
