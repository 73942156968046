import * as React from 'react';
import PropTypes from 'prop-types';
import js from 'common';
import './Dashboard.scss';
import { useMount, usePrevious, useUnmount, useUpdateEffect } from 'react-use';
import { useSelector } from 'react-redux';
import { metrics, tz, ajax } from '../../utils';
import Filter from './Filter';
import PieChartDataInfo from './PieChartDataInfo';
import PieChartTotal from './PieChartTotal';
import PieChart from './PieChart';
import PerformanceWidgets from './PerformanceWidgets';

const dataPreloadInitialValue = {
  stats: {
    audience: {},
    content: {},
    engagement: {},
    revenue: {},
    social: {},
    totals: {},
  },
  success: true,
};

const Dashboard = ({ test: { dashboardPreview, dashboardPreviewWait } }) => {
  const accountInfo = useSelector(reduxState => reduxState.accountInfo);
  const { currentSite } = accountInfo;
  const [load, setLoad] = React.useState('preload');
  const [dataPreload, setDataPreload] = React.useState(dataPreloadInitialValue);
  const prevCurrentSite = usePrevious(currentSite);

  const getFilterUrl = () => {
    const pgNameStore = `filter${js.D.page('dashboard').storageKey}`;
    const params = {};

    // Инициализация, если хранилище пустое
    if (!sessionStorage.filterDateGlobal) {
      if (!sessionStorage[pgNameStore]) {
        sessionStorage[pgNameStore] = 'Date7days';
        sessionStorage.filterDateGlobal = 'Date7days';
      } else {
        sessionStorage.filterDateGlobal = sessionStorage[pgNameStore];
      }
    } else {
      sessionStorage[pgNameStore] = sessionStorage.filterDateGlobal;
    }

    switch (sessionStorage[pgNameStore]) {
      case 'Date1day':
        if (sessionStorage.getItem('filterDateTo')) {
          if (sessionStorage.getItem('filterDateTo') === tz.now()) {
            params.period = 'today';
          } else if (sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = sessionStorage.getItem('filterDateFrom') || tz.now();
            params.to = sessionStorage.getItem('filterDateTo') || tz.now();
          }
        }
        break;
      case 'Date7days':
        params.from = sessionStorage.getItem('filterDateFrom') || tz.addDayToDate(-7);
        params.to = sessionStorage.getItem('filterDateTo') || tz.now();
        break;
      case 'Date30days':
        params.from = sessionStorage.getItem('filterDateFrom') || tz.addDayToDate(-30);
        params.to = sessionStorage.getItem('filterDateTo') || tz.now();
        break;
      case 'DateInterval':
        params.from = sessionStorage.getItem('filterDateFrom');
        params.to = sessionStorage.getItem('filterDateTo');
        break;
      default:
        break;
    }
    return params;
  };

  const changeState = async () => {
    const params = getFilterUrl();

    ajax.get('/stats/dashboard', { params }).then(({ data }) => {
      if (data) {
        const resp = dashboardPreview ? dataPreload : data;
        setTimeout(() => {
          setLoad(dashboardPreview ? 'preload' : '');
          setDataPreload({
            stats: resp.stats,
            success: resp.success,
          });
        }, dashboardPreviewWait);
      }
    });
  };

  useUpdateEffect(() => {
    if (currentSite !== prevCurrentSite) {
      changeState();
    }
  });

  useMount(() => {
    document.getElementById('mount-point').classList.add('page');
    metrics.metricEvent('hit', '/dashboard');
    changeState();
  });

  useUnmount(() => document.getElementById('mount-point').classList.remove('page'));

  const { stats } = dataPreload;

  let dataPercent;
  if (stats) {
    dataPercent = { eng: 0, aud: 0, rev: 0 };
    if (stats.totals.pageviews > 0) {
      dataPercent = {
        eng: stats.engagement.pageviews_percent?.toFixed(2),
        aud: stats.audience.pageviews_percent?.toFixed(2),
        rev: stats.revenue.pageviews_percent?.toFixed(2),
      };
    }
  }

  const list = (
    <div className="dashboard__info-right">
      <PieChartTotal total={stats.totals} />
      <div className="dashboard__info-block">
        <PieChartDataInfo
          flags="audEng"
          pw={stats.engagement.pageviews}
          pwDelta={stats.engagement.pageviews_delta}
          percent={dataPercent.eng === '0.00' ? 0 : dataPercent.eng}
        />
      </div>
      <div className="dashboard__info-block info-block--dsec">
        <PieChartDataInfo
          flags="newA"
          pw={stats.audience.pageviews}
          pwDelta={stats.audience.pageviews_delta}
          percent={dataPercent.aud === '0.00' ? 0 : dataPercent.aud}
        />
      </div>
      <div className="dashboard__info-block info-block--3rd">
        <PieChartDataInfo
          flags="Rev"
          pw={stats.revenue.pageviews}
          pwDelta={stats.revenue.pageviews_delta}
          percent={dataPercent.rev === '0.00' ? 0 : dataPercent.rev}
        />
      </div>
      <div className="dashboard__info-block dashboard__info-block--gray">
        <PieChartDataInfo
          flags="wGi"
          pw={stats.totals.pageviews_no_widget}
          pwDelta={stats.totals.pageviews_no_widget_delta}
          percent={stats.totals.pageviews_no_widget_inc}
        />
      </div>
    </div>
  );
  return (
    <div className={load}>
      <div className="wrapper wrapper--dashboard wrapper--dashboard-m">
        <div className="container-fluid">
          <div className="dashboard clearfix">
            <div className="dashboard__filter">
              <Filter changeState={changeState} />
            </div>
            <div className="dashboard__info">
              <div className="dashboard__info-title">Page Views</div>
              <div className="dashboard__info-content">
                <PieChart percent={dataPercent} />
                {list}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PerformanceWidgets statsData={stats} />
    </div>
  );
};

Dashboard.propTypes = {
  test: PropTypes.shape({
    dashboardPreview: PropTypes.number,
    dashboardPreviewWait: PropTypes.number,
  }),
};

Dashboard.defaultProps = {
  test: {
    dashboardPreview: 0,
    dashboardPreviewWait: 0,
  },
};

export default React.memo(Dashboard);
