import * as React from 'react';
import { ReactElement } from 'react';
import { Icon, Button } from 'uikit';
import { menuList } from '../../utils';
import { toggleDrawer } from '../../store/appState/appStateSlice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import SitesList from '../SitesList';
import HeaderTitle from './HeaderTitle';
import HeaderRevenueInfo from './HeaderRevenueInfo';
import HeaderNewAudienceInfo from './HeaderNewAudienceInfo';
import HeaderExit from './HeaderExit';
import './Header.scss';

import { Props, MenuListItemType } from '../../ts/types/Header';
import { AccountInfoState } from '../../ts/interfaces';

const Header = ({ path } : Props): ReactElement => {
  const [isMenuOpenMobile, setIsMenuOpenMobile] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<MenuListItemType | null>(null);

  const dispatch = useAppDispatch();
  const {
    totalRevenueBalance,
    VAT,
    revenueBalance,
    newAudienceBalance,
  }: AccountInfoState = useAppSelector(reduxState => reduxState.accountInfo);

  React.useEffect(() => {
    const currentPageName: string = Object.keys(menuList).find(title =>
      RegExp(path.replace(/^\//, '')).test(menuList[title].link)) || '';
    setCurrentPage(menuList[currentPageName]);
  }, [path]);

  const handleMobileMenuToggle = (): void => {
    setIsMenuOpenMobile(!isMenuOpenMobile);
  };

  return (
    <>
      {!path.includes('/constructor/') && (
      <div className={`page-header ${isMenuOpenMobile ? 'page-header--menu' : ''}`}>
        <div className="page-header__top">
          <div className="wrapper wrapper--header">
            <div className="page-header__toggle">
              <Button
                format="icon"
                kind="secondary"
                className="page-header__toggle-button"
                onClick={() => dispatch(toggleDrawer())}
              >
                <Icon name="menu" />
              </Button>
            </div>
            <SitesList
              path={path}
            />
            <div className="page-header__special-info">
              {path === '/reports/revenue' && (
              <>
                <HeaderRevenueInfo
                  title="Site Balancе: "
                  tooltip="Balance of the selected site"
                  value={revenueBalance}
                />
                <HeaderRevenueInfo
                  title="Account Balance: "
                  tooltip="Overall balance of user’s sites"
                  value={totalRevenueBalance}
                />
                {VAT > 0 && (
                  <div className="total-balance__text" tabIndex={-1}>
                    <span className="balance__text--gray">Includes VAT</span>
                  </div>
                )}
              </>
              )}
              {path === '/reports/new_audience' && (
              <HeaderNewAudienceInfo balance={newAudienceBalance} />
              )}
            </div>
            <HeaderExit
              handleMobileMenuToggle={handleMobileMenuToggle}
              path={path}
            />
          </div>
        </div>
        <HeaderTitle page={currentPage} />
      </div>
      )}
    </>
  );
};

export default Header;
