import * as React from 'react';
import PropTypes from 'prop-types';
import { throttle, capitalize } from 'lodash';
import js from 'common';
import { metrics, tz, ajax, ls } from 'utils';
import { confirmAlert } from 'react-confirm-alert';
import PgFilter from 'components/PgFilter';
import PgTablePager from 'components/PgTablePager';
import ManageOwnAndOthersContentPgTable from 'components/tables/containers/ManageOwnAndOthersContent';
import { Button } from 'uikit';
import ContentDefaultImg from 'assets/images/content-default.png';
import { useAppSelector } from '../../store/hooks';
import './ManageOwnAndOthersContent.scss';

const ManageOwnAndOthersContent = ({ mode }) => {
  const [state, setState] = React.useState({
    stats: [],
    totals: {},
    totalItems: 0,
    pageN: 1,
    sortField: '',
    sortOrder: '',
  });
  const { stats, totals, totalItems, pageN, sortField, sortOrder } = state;
  const [isLoading, setIsLoading] = React.useState(true);

  const currentSite = useAppSelector(reduxState => reduxState.accountInfo.currentSite);
  const searchQuery = React.useRef('');

  const getPage = React.useCallback(() => js.D.page(`manage${capitalize(mode)}`), [mode]);

  const getUrlParams = React.useCallback((pageNumberToChange, mode, state) => { // eslint-disable-line
    const getFilterUrl = () => {
      const { sessionStorage } = window;
      const pgNameStore = `filter${getPage().storageKey}`;
      const params = {};

      if (!ls.get('filterOwnType')) {
        localStorage.filterOwnType = 'active';
      }

      params.type = ls.get('filterOwnType');

      if (!sessionStorage.filterDateGlobal) {
        if (!sessionStorage[pgNameStore]) {
          sessionStorage[pgNameStore] = 'Date7days';
          sessionStorage.filterDateGlobal = 'Date7days';
        } else {
          sessionStorage.filterDateGlobal = sessionStorage[pgNameStore];
        }
      } else {
        sessionStorage[pgNameStore] = sessionStorage.filterDateGlobal;
      }

      switch (sessionStorage[pgNameStore]) {
        case 'Date1day':
          if (sessionStorage.getItem('filterDateTo')) {
            if (sessionStorage.getItem('filterDateTo') === tz.now()) {
              params.period = 'today';
            } else if (sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1)) {
              params.period = 'yesterday';
            } else {
              params.from = sessionStorage.getItem('filterDateFrom') || tz.now();
              params.to = sessionStorage.getItem('filterDateTo') || tz.now();
            }
          }
          break;
        case 'Date7days':
          params.from = sessionStorage.getItem('filterDateFrom') || tz.addDayToDate(-7);
          params.to = sessionStorage.getItem('filterDateTo') || tz.now();
          break;
        case 'Date30days':
          params.from = sessionStorage.getItem('filterDateFrom') || tz.addDayToDate(-30);
          params.to = sessionStorage.getItem('filterDateTo') || tz.now();
          break;
        case 'DateInterval':
          if (sessionStorage.getItem('filterDateFrom') === tz.now()
            && sessionStorage.getItem('filterDateTo') === tz.now()) {
            params.period = 'today';
          } else if (sessionStorage.getItem('filterDateFrom') === tz.addDayToDate(-1)
            && sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = sessionStorage.getItem('filterDateFrom');
            params.to = sessionStorage.getItem('filterDateTo');
          }
          break;
        default:
          break;
      }

      if (searchQuery.current) {
        params.url_or_title = searchQuery.current;
      } else {
        delete params.url_or_title;
      }

      return params;
    };

    const tableName = mode === 'own' ? 'manageOwnTableSize' : 'manageOthersTableSize';
    const itemsPerPage = localStorage.getItem(tableName) ? localStorage.getItem(tableName) : 20;
    const params = getFilterUrl();

    params.sort_field = (state && state.sortField) || sortField;
    params.sort_order = (state && state.sortOrder) || sortOrder;
    params.offset = pageNumberToChange ? (pageNumberToChange - 1) * +itemsPerPage : 0;
    params.limit = +itemsPerPage;

    return params;
  }, [getPage, sortField, sortOrder]);

  const fetchData = React.useCallback(async ({ state, pageNumberToChange } = {}) => { // eslint-disable-line
    const params = getUrlParams(pageNumberToChange || pageN, mode, state);
    const url = (mode === 'own') ? '/tools/manage_own' : '/tools/manage_others';

    ajax.get(url, { params })
      .then(({ data }) => {
        if (data) {
          const extraItems = [];

          if (Array.isArray(data.new_promos)) {
            data.new_promos.map(item => extraItems.push({
              clicks: 0,
              ctr: 0,
              impressions: 0,
              is_promo: true,
              is_new: true,
              item_image: ContentDefaultImg,
              item_title: item.url,
              item_url: item.url,
              id: item.id,
              status: item.status,
              statusResult: item.result,
            }));
          }

          const items = pageN === 1 ? extraItems : [];
          const newStats = data.stats || [];

          setState({
            stats: [...items, ...newStats],
            totals: data.totals,
            totalItems: data.total_items || 0,
            pageN: pageNumberToChange || pageN,
            sortField: state?.sortField || sortField,
            sortOrder: state?.sortOrder || sortOrder,
          });

          setIsLoading(false);
        }
      });
  }, [getUrlParams, mode, sortField, sortOrder]); // eslint-disable-line

  const changePageN = (page) => {
    fetchData({ pageNumberToChange: page });
  };

  const changeStatus = (id, flag, e, callback) => {
    if (!flag) {
      const params = {
        id,
        action: e.target.checked ? 'unblock' : 'block',
      };
      const url = (mode === 'own') ? '/tools/manage_own/change_status' : '/tools/manage_others/change_status';
      ajax.get(url, { params });
      if (callback) {
        callback();
      }
    }
  };

  const pinToggle = (e, id, isPromo, forAdblock, callback) => {
    const params = {
      id,
      action: isPromo ? 'off' : 'on',
      for_adblock: forAdblock,
    };

    ajax.get('/tools/set_promo', { params })
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

  const removePromo = id => ajax.delete(`/spider_queue/${id}`);

  const removeItem = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => ( // eslint-disable-line
        <div className="react-confirm-alert-body disabled">
          <div className="react-confirm-alert-message center">
            Are you sure you wish to delete this item?
            <div className="react-confirm-alert-button-group">
              <Button
                type="button"
                onClick={() => {
                  removePromo(id);
                  onClose();
                }}
              >
                Yes
              </Button>
              <Button
                kind="secondary"
                type="button"
                onClick={onClose}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      ),
    });
  };

  const submitNewPromo = (e, mode, url = '') => { // eslint-disable-line @typescript-eslint/no-shadow
    e.preventDefault();

    const dataReq = { url: '', type: 1 };

    switch (mode) {
      case 'url':
        dataReq.url = !(/^(?:http(s)?:\/\/)/g).test(url) ? (`http://${url}`) : url;
        ajax.post('/spider_queue', dataReq).then(() => {
          searchQuery.current = '';
          fetchData();
        });
        break;
      case 'query':
        dataReq.url = !(/^(?:http(s)?:\/\/)/g).test(searchQuery.current)
          ? (`http://${searchQuery.current}`)
          : searchQuery.current;
        ajax.post('/spider_queue', dataReq).then(() => {
          searchQuery.current = '';
          fetchData();
        });
        break;
      default:
        break;
    }
  };

  const onStatusChanged = (status) => {
    window.localStorage.setItem('filterOwnType', status);

    fetchData();
  };

  const doSearch = () => fetchData();

  const onSearchPromo = (passedSearchQuery) => {
    searchQuery.current = passedSearchQuery;
    doSearch();
  };

  React.useEffect(() => {
    fetchData();
    metrics.metricEvent('hit', `/tools/manage_content/${mode}`);
  }, [fetchData, mode]);

  React.useEffect(() => {
    fetchData();
  }, [currentSite, fetchData]);

  return (
    <div>
      <div className="wrapper wrapper--dashboard">
        <div className="container-fluid">
          <PgFilter
            changeState={fetchData}
            getPage={getPage}
            onStatusChanged={onStatusChanged}
            onSearchPromo={mode === 'own' ? throttle(onSearchPromo, 800, { leading: false }) : undefined}
          />
        </div>
      </div>
      <PgTablePager
        pagerName={`manage${capitalize(mode)}TableSize`}
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
      <ManageOwnAndOthersContentPgTable
        mode={mode}
        statsData={stats}
        totalsData={totals}
        totalItems={totalItems}
        changeState={fetchData}
        changeStatus={changeStatus}
        pinToggle={pinToggle}
        removeItem={removeItem}
        searchPromoQuery={searchQuery.current}
        submitNewPromo={submitNewPromo}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ManageOwnAndOthersContent;

ManageOwnAndOthersContent.propTypes = {
  mode: PropTypes.oneOf(['own', 'others']).isRequired,
};
