import React, { BaseSyntheticEvent, ReactElement } from 'react';

import './FilterByList.scss';

import { FilterByListProps } from 'app/ts/types/FilterByList';

const FilterByList = (props: FilterByListProps): ReactElement => {
  const { responsiveFlag = 'desktop', uid, list, onChange } = props;

  const chooseFilter = (item: string, e: BaseSyntheticEvent): void => {
    e.preventDefault();
    onChange(item || e.target.value);
  };

  return (
    <>
      {responsiveFlag !== 'mobile' && (
      <ul className="filterByList">
        {list.map((item, index) => (
          <li key={index} className="filterByList__list-item">
            <a
              onClick={e => chooseFilter(item, e)}
              className="filterByList__link"
              href=""
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
      )}
      {responsiveFlag === 'mobile' && (
        <div>
          <select
            id={uid}
            className="filterByList--mobile"
            onChange={e => chooseFilter('', e)}
          >
            {list.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <label htmlFor={uid}>Page type</label>
        </div>
      )}
    </>
  );
};

export default FilterByList;
