import * as React from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import Charts from '../default/Charts';

const PerformanceGraff = ({ statsData, timeframe }) => {
  if (!statsData?.length) {
    return null;
  }

  const legend = [
    {
      title: 'Visits',
      color: '#ff6363',
      field: 'visits',
      valueDecimals: 0,
      disabled: true,
    },
    {
      title: 'Pageviews',
      color: '#00db71',
      field: 'pageviews',
      valueDecimals: 0,
      disabled: true,
    },
    {
      title: 'Pageviews per visit',
      color: '#ae80ff',
      field: 'pv_visits',
      valueDecimals: 3,
    },
    {
      title: 'Reading depth (%)',
      color: '#ffc400',
      field: 'reading_depth',
      suffix: '%',
    },
    {
      title: 'Average reading time (min)',
      color: '#2a75ff',
      field: 'pv_time',
      suffix: ' min',
    },
    {
      title: `RPM (${config.currency})`,
      color: '#ff8921',
      field: 'rpm',
      suffix: `&thinsp;${config.currency}`,
    },
    {
      title: `Revenue from Giraff.io (${config.currency})`,
      color: '#2df',
      field: 'revenue',
      suffix: `&thinsp;${config.currency}`,
      disabled: true,
    },
  ];

  return (
    <Charts
      statsData={statsData}
      timeframe={timeframe}
      id="Performance"
      legend={legend}
      groups={[]}
      pageType="referer"
    />
  );
};

export default PerformanceGraff;

PerformanceGraff.propTypes = {
  statsData: PropTypes.array.isRequired,
  timeframe: PropTypes.array.isRequired,
};
