import React, { ReactElement, BaseSyntheticEvent } from 'react';

import { FilterByJSONListProps } from 'app/ts/types/FilterByJSONList';
import { Social } from 'app/ts/types/ContentAndSocialFilter';

import './FilterByJSONList.scss';

const FilterByJSONList = (props: FilterByJSONListProps): ReactElement => {
  const { onChange, responsiveFlag = 'desktop', uid, listObjects } = props;
  const isDesktop: boolean = responsiveFlag !== 'mobile';

  const chooseFilter = (event: BaseSyntheticEvent, social?: Social): void => {
    event.preventDefault();
    onChange(social || event);
  };

  return (
    <div>
      {isDesktop && (
      <ul className="filterByJSONList">
        {listObjects.map((item, index) => (
          <li key={index} className="filterByList__list-item">
            <a
              onClick={(e: BaseSyntheticEvent) => chooseFilter(e, item)}
              className="filterByList__link"
              href=""
            >{item.name}
            </a>
          </li>
        ))}
      </ul>
      )}
      {!isDesktop && (
      <div>
        <select
          id={uid}
          className="filterByJSONList--mobile"
          onChange={(e: BaseSyntheticEvent) => chooseFilter(e)}
        >
          {listObjects.map((item, index) => (
            <option
              key={index}
              value={item.value}
              data-id={item.id}
            >
              {item.name}
            </option>
          ))}
        </select>
        <label htmlFor={uid}>Social network</label>
      </div>
      )}
    </div>
  );
};

export default FilterByJSONList;
