import * as React from 'react';
import PropTypes from 'prop-types';
import { format } from '../../utils';

const PieChart = (props) => {
  const { percent } = props;
  const eng = percent.eng || 0;
  const aud = percent.aud || 0;
  const rev = percent.rev || 0;
  let wgi = (100 - eng - aud - rev).toFixed(2);
  wgi = wgi >= 0 ? wgi : 0;

  const sda = [];
  const pos = [];

  const rad = 80;
  const pieMax = Math.PI * 2 * rad;

  const getXY = (deg, radius) => {
    const toRad = Math.PI / 180;
    const pieRotate = -145;
    const result = `${radius * Math.cos((pieRotate + deg) * toRad)}px,
      ${radius * Math.sin((pieRotate + deg) * toRad)}px`;

    return result;
  };
  const getRad = (value, posItem) => {
    let newRad;
    if (value < 5 && value) {
      switch (posItem) {
        case 0:
        case 2:
          newRad = rad - 12;
          break;
        case 1:
        case 3:
          newRad = rad + 12;
          break;
        default:
          break;
      }
    } else newRad = rad;
    return newRad;
  };

  if (percent) {
    sda[0] = (pieMax * eng) / 100;
    pos[0] = getXY(1.8 * eng, getRad(eng, 0));
    sda[1] = (pieMax * aud) / 100;
    pos[1] = getXY(3.6 * eng + 1.8 * aud, getRad(aud, 1));
    sda[2] = (pieMax * rev) / 100;
    pos[2] = getXY(3.6 * eng + 3.6 * aud + 1.8 * rev, getRad(rev, 2));
    sda[3] = (pieMax * wgi) / 100;
    pos[3] = getXY(3.6 * eng + 3.6 * aud + 3.6 * rev + 1.8 * wgi, getRad(wgi, 3));
  }
  const tooltipClass = 'bar__tooltip';

  const creatorPieChartClass = (param, color) => {
    if (param < 5) {
      if (param === 0) {
        return `pie-chart__text-${color} pie-chart__text--null`;
      }

      return `pie-chart__text-${color} pie-chart__text--small`;
    }

    return `pie-chart__text-${color}`;
  };

  const pieChartEngClass = () => creatorPieChartClass(eng, 'blue');
  const pieChartAudClass = () => creatorPieChartClass(aud, 'orange');
  const pieChartRevClass = () => creatorPieChartClass(rev, 'green');
  const pieChartWithoutClass = () => creatorPieChartClass(wgi, 'gray');

  return (
    <div className="dashboard__info-left dashboard__pie-chart-over">
      <div className="dashboard__bar">
        <div className="bar">
          <div className="bar__blue" style={{ width: `${eng}%` }}>
            <div className={tooltipClass + (eng < 5 && eng ? ' bar__tooltip--small' : '')}>{`${eng}%`}</div>
          </div>
          <div className="bar__orange" style={{ width: `${aud}%` }}>
            <div className={tooltipClass + (aud < 5 && aud ? ' bar__tooltip--small' : '')}>{`${aud}%`}</div>
          </div>
          <div className="bar__green" style={{ width: `${rev}%` }}>
            <div className={tooltipClass + (rev < 5 && rev ? ' bar__tooltip--small' : '')}>{`${rev}%`}</div>
          </div>
          <div className="bar__gray" style={{ width: `${wgi}%` }}>
            <div className={tooltipClass + (wgi < 5 && wgi ? ' bar__tooltip--small' : '')}>{`${wgi}%`}</div>
          </div>
        </div>
      </div>
      <div className="dashboard__pie-chart">
        <div className="pie-chart">
          <svg width="208" height="208" className="pie-chart__part">
            <circle
              className="pie-chart__part-blue"
              r="80"
              cx="104"
              cy="104"
              strokeDasharray={eng !== 0 ? `${sda[0]} ${pieMax}` : `0 ${pieMax}`}
            />
            <circle
              className="pie-chart__part-orange"
              r="80"
              cx="104"
              cy="104"
              strokeDasharray={aud !== 0 ? `${sda[1]} ${pieMax}` : `0 ${pieMax}`}
              strokeDashoffset={-sda[0]}
            />
            <circle
              className="pie-chart__part-green"
              r="80"
              cx="104"
              cy="104"
              strokeDasharray={rev !== 0 ? `${sda[2]} ${pieMax}` : `0 ${pieMax}`}
              strokeDashoffset={-sda[0] - sda[1]}
            />
            <circle
              className="pie-chart__part-gray"
              r="80"
              cx="104"
              cy="104"
              strokeDasharray={wgi !== 0 ? `${sda[3]} ${pieMax}` : `0 ${pieMax}`}
              strokeDashoffset={-sda[0] - sda[1] - sda[2]}
            />
          </svg>
          <div className="pie-chart__text">
            <div
              className={pieChartEngClass()}
              style={{ transform: `translate(${pos[0]})` }}
            ><span>{format.percentage(eng)}</span>
            </div>
            <div
              className={pieChartAudClass()}
              style={{ transform: `translate(${pos[1]})` }}
            ><span>{format.percentage(aud)}</span>
            </div>
            <div
              className={pieChartRevClass()}
              style={{ transform: `translate(${pos[2]})` }}
            ><span>{format.percentage(rev)}</span>
            </div>
            <div
              className={pieChartWithoutClass()}
              style={{ transform: `translate(${pos[3]})` }}
            ><span>{format.percentage(wgi)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PieChart.propTypes = {
  percent: PropTypes.object.isRequired,
};

export default PieChart;
