import React, { ReactElement, BaseSyntheticEvent } from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import { Icon, TextField, Select, Dropdown } from 'uikit';

import { AudienceList } from 'app/ts/types/Audience';
import { PgFilterProps, PgFilterState, Change } from 'app/ts/types/PgFilter';
import { IntlMessage } from 'app/ts/types/IntlMessage';

import { useWindowSize } from 'react-use';
import SaveFile from './SaveFile';
import FilterPeriod from './FilterPeriod';

import './PgFilter.scss';

const breakdownByListBase: Array<{ title: string, name: string }> = [
  { title: 'Date', name: 'date' },
  { title: 'Country', name: 'country' },
  { title: 'Device', name: 'device' },
  { title: 'Widget', name: 'widget' },
];

const PgFilter = (props: PgFilterProps): ReactElement => {
  const {
    widgets = undefined,
    change = () => { },
    sites = undefined,
    breakdownByListExtend = [],
    groupBy = '',
    onStatusChanged = undefined,
    onPerformanceChanged = undefined,
    onSearchPromo = undefined,
    csvName = '',
    csvData = [],
    changeState,
    getPage,
    changePage = () => { },
  } = props;

  const [state, setState] = React.useState<PgFilterState>({
    searchQuery: '',
    isSelectWidgetVisible: false,
    isBreakdownByVisible: false,
    isSelectWidgetVisibleMobile: false,
    isBreakdownByVisibleMobile: false,
  });

  const {
    searchQuery,
    isSelectWidgetVisible,
    isBreakdownByVisible,
    isSelectWidgetVisibleMobile,
    isBreakdownByVisibleMobile,
  } = state;

  const { width }: { width: number } = useWindowSize();
  const isDesktop: boolean = width > 767;
  const breakdownByList: Array<AudienceList> = [...breakdownByListBase, ...breakdownByListExtend];
  const currentPageTitle: string = getPage().title;
  const showBreakdownByFilter: string = ((widgets?.active === 'all') || sites) && groupBy;
  const shouldRenderExportButton: boolean = [
    'Revenue',
    'New audience',
    'Audience engagement',
    'Summary report',
  ].includes(currentPageTitle);

  const getActiveWidget = (): IntlMessage => (widgets.active === 'all'
    ? 'All'
    : get(widgets.list.find(item => item.name.toString() === widgets.active), 'title', ''));

  const getDropdownContent = (
    list: Array<AudienceList>, action: Change, type: string, isMobile?: boolean,
  ): ReactElement => (
    <ul>
      {list.map(item => (
        <li key={item.name}>
          <span
            data-sort={item.name}
            onClick={(event: BaseSyntheticEvent) => {
              action(event.target.dataset.sort);
              setState(prevState => ({
                ...prevState,
                [`is${type}Visible${isMobile ? 'Mobile' : ''}`]: false,
              }));
            }}
            className="dropdown__link"
          >
            {item.title}
          </span>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="row">
      {!isDesktop && (
        <div className="col-lg-12">
          <div className="wrapper wrapper--socialf wrapper--noborders complicated-calendar__social">
            {onStatusChanged && (
              <div className="socialf clearfix">
                <div className="socialf__left">Content</div>
                <div className="socialf__right">
                  <select
                    onChange={(event: BaseSyntheticEvent) => onStatusChanged(event.target.value)}
                    value={localStorage.filterOwnType}
                  >
                    <option value="active">Active</option>
                    <option value="blocked">Disabled</option>
                  </select>
                </div>
              </div>
            )}
            {onPerformanceChanged && (
              <div className="socialf clearfix">
                <div className="socialf__left">Performance</div>
                <div className="socialf__right">
                  <select
                    onChange={(event: BaseSyntheticEvent) => onPerformanceChanged(event.target.value)}
                    value={localStorage.filterAuthorsType}
                  >
                    <option value="content">Content</option>
                    <option value="social">Social</option>
                  </select>
                </div>
              </div>
            )}
            {widgets?.list.length > 0 && (
              (
                <div className="socialf clearfix">
                  <div className="dropdown">
                    <div className="dropdown__left">
                      Select widget
                    </div>
                    <div className="dropdown__right">
                      <Dropdown
                        className="widget"
                        visible={isSelectWidgetVisibleMobile}
                        arrow={false}
                        onClickOutside={() => setState(prevState => ({
                          ...prevState,
                          isSelectWidgetVisibleMobile: false,
                        }))}
                        interactive
                        offset={[0, 5]}
                        content={getDropdownContent(widgets.list, change, 'SelectWidget', true)}
                      >
                        <span
                          className="dropdown__trigger-mobile"
                          onClick={() => setState(prevState => ({
                            ...prevState,
                            isSelectWidgetVisibleMobile: !prevState.isSelectWidgetVisibleMobile,
                          }))}
                        >
                          {getActiveWidget()}
                        </span>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              )
            )}
            {sites && (
              <Select
                isMulti
                name="sites"
                onChange={change}
                options={sites && sites.list}
                defaultValue={sites && sites.active}
                className="by-sites-report-select regular-calendar__select regular-calendar__select--filter"
                elementClassName="regular-calendar__select-element regular-calendar__select-element--filter"
              />
            )}
            {showBreakdownByFilter && (
              <div className="socialf clearfix">
                <div className="dropdown">
                  <div className="dropdown__left">
                    Breakdown by
                  </div>
                  <div className="dropdown__right">
                    <Dropdown
                      visible={isBreakdownByVisibleMobile}
                      arrow={false}
                      onClickOutside={() => setState(prevState => ({
                        ...prevState,
                        isBreakdownByVisibleMobile: false,
                      }))}
                      interactive
                      offset={[0, 5]}
                      content={getDropdownContent(breakdownByList, changePage, 'BreakdownBy', true)}
                    >
                      <span
                        className="dropdown__trigger-mobile"
                        onClick={() => setState(prevState => ({
                          ...prevState,
                          isBreakdownByVisibleMobile: !prevState.isBreakdownByVisibleMobile,
                        }))}
                      >
                        {groupBy}
                      </span>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )}
            {shouldRenderExportButton && (
              <div className="socialf clearfix">
                <div className="socialf__left">Export statistics</div>
                <div className="socialf__right">
                  <SaveFile name={csvName} data={csvData} />
                </div>
              </div>
            )}
            <FilterPeriod
              responsiveFlag="mobile"
              changeState={changeState}
              page={getPage}
              groupBy={groupBy}
            />
            <div className="socialf__bottom" />
          </div>
        </div>
      )}
      {isDesktop && (
        <div className="complicated-calendar__big">
          <div className="col-lg-12">
            <div className="complicated-calendar clearfix">
              {onStatusChanged && (
                <div
                  className="complicated-calendar__top complicated-calendar__breaker"
                  data-text-label=""
                >
                  <button
                    type="button"
                    onClick={() => onStatusChanged('active')}
                    className={cx(
                      'settingstl__btn settingstl__btn--first',
                      {
                        'settingstl__btn-active': localStorage.filterOwnType === 'active',
                      },
                    )}
                  >
                    Active
                  </button>
                  <button
                    type="button"
                    onClick={() => onStatusChanged('blocked')}
                    className={cx(
                      'settingstl__btn settingstl__btn--last-simple',
                      {
                        'settingstl__btn-active': localStorage.filterOwnType === 'blocked',
                      },
                    )}
                  >
                    Disabled
                  </button>
                </div>
              )}
              {onPerformanceChanged && (
                <div
                  className="complicated-calendar__top complicated-calendar__breaker"
                  data-text-label="Performance"
                >
                  <button
                    type="button"
                    onClick={() => onPerformanceChanged('content')}
                    className={cx(
                      'settingstl__btn settingstl__btn--first',
                      {
                        'settingstl__btn-active': localStorage.filterAuthorsType === 'content',
                      },
                    )}
                  >
                    Content
                  </button>
                  <button
                    type="button"
                    onClick={() => onPerformanceChanged('social')}
                    className={cx(
                      'settingstl__btn settingstl__btn--last-simple',
                      {
                        'settingstl__btn-active': localStorage.filterAuthorsType === 'social',
                      },
                    )}
                  >
                    Social
                  </button>
                </div>
              )}
              {widgets?.list.length > 0 && (
                <div className="complicated-calendar__top" data-text-label="Select widget">
                  <Dropdown
                    className="widget"
                    visible={isSelectWidgetVisible}
                    content={getDropdownContent(widgets.list, change, 'SelectWidget')}
                    placement="bottom-start"
                    arrow={false}
                    offset={[0, 5]}
                    interactive
                    onClickOutside={() => setState(prevState => ({
                      ...prevState,
                      isSelectWidgetVisible: false,
                    }))}
                  >
                    <button
                      type="button"
                      className="dropdown__button"
                      onClick={() => setState(prevState => ({
                        ...prevState,
                        isSelectWidgetVisible: !prevState.isSelectWidgetVisible,
                      }))}
                    >
                      {getActiveWidget()}
                      <Icon
                        name="arrow-compact-down"
                        className="dropdown__icon"
                      />
                    </button>
                  </Dropdown>
                </div>
              )}
              {showBreakdownByFilter && (
                <div className="complicated-calendar__top" data-text-label="Breakdown by">
                  <Dropdown
                    visible={isBreakdownByVisible}
                    content={getDropdownContent(breakdownByList, changePage, 'BreakdownBy')}
                    placement="bottom-start"
                    arrow={false}
                    offset={[0, 5]}
                    interactive
                    onClickOutside={() => setState(prevState => ({
                      ...prevState,
                      isBreakdownByVisible: false,
                    }))}
                  >
                    <button
                      type="button"
                      className="dropdown__button"
                      onClick={() => setState(prevState => ({
                        ...prevState,
                        isBreakdownByVisible: !prevState.isBreakdownByVisible,
                      }))}
                    >
                      {groupBy}
                      <Icon
                        name="arrow-compact-down"
                        className="dropdown__icon"
                      />
                    </button>
                  </Dropdown>
                </div>
              )}
              <div
                className="complicated-calendar__bottom clearfix"
                data-text-label="Time frame"
                data-current-page={currentPageTitle}
              >
                <FilterPeriod
                  changeState={changeState}
                  page={getPage}
                  groupBy={groupBy}
                />
              </div>
              {onSearchPromo && (
                <>
                  <div className="clearfix" />

                  <div className="form__row form__field--promo clearfix">
                    <div className="form__control">
                      <TextField
                        label={<span>Search content or add new</span>}
                        elementBefore={<Icon name="search" />}
                        type="text"
                        name="searchPromo"
                        elementId="searchPromo"
                        defaultValue={searchQuery}
                        onChange={(event: BaseSyntheticEvent) => {
                          onSearchPromo(event.target.value);
                          setState(prevState => ({
                            ...prevState,
                            searchQuery: event.target.value,
                          }));
                        }}
                        className="own__search"
                        placeholder="Title or URL"
                      />
                      {searchQuery && (
                        <a
                          className="clear-link own__search--clear"
                          onClick={() => {
                            onSearchPromo('');
                            setState(prevState => ({
                              ...prevState,
                              searchQuery: '',
                            }));
                          }}
                        >
                          <i className="icon icon--x" />
                        </a>
                      )}
                    </div>
                  </div>
                </>
              )}
              {sites?.list.length > 0 && (
                <div
                  className="complicated-calendar__top complicated-calendar__top--l2"
                  data-text-label="Select sites"
                >
                  <Select
                    isMulti
                    name="sites"
                    helper="All sites by default"
                    onChange={change}
                    options={sites && sites.list}
                    defaultValue={sites && sites.active}
                    className="complicated-calendar__select complicated-calendar__select--filter"
                    elementClassName="complicated-calendar__select-element complicated-calendar__select-element--filter"
                    helperClassName="complicated-calendar__select-helper complicated-calendar__select-helper--filter"
                  />
                </div>
              )}
              {csvName && <SaveFile name={csvName} data={csvData} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PgFilter;
