import { format } from 'utils';

export const get = (chartData) => {
  const data = [];
  const startDate = 0;
  const xAxistickInterval = 1;
  const tickFactor = 1;
  const chartType = 'category';
  const categories = [];

  chartData.data.map((item) => {
    data.push(item.value);
    categories.push(item.keyValue);
    return data;
  });

  return {
    xAxis: {
      categories,
      crosshair: {
        color: '#e6e6e6',
        width: 1,
      },
      tickInterval: xAxistickInterval,
      type: chartType,
      lineColor: '#eeeef1',
      gridLineColor: '#eeeef1',
      tickColor: '#eeeef1',
      labels: {
        useHTML: true,
        formatter() {
          return this.value;
        },
        style: {
          color: '#b7b6c2',
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      lineColor: '#eeeef1',
      gridLineColor: '#eeeef1',
      tickColor: '#eeeef1',
      labels: {
        style: {
          color: '#b7b6c2',
        },
        formatter() {
          if (this.value >= 1000 && this.value < 1000000) {
            return `${format.float(this.value / 1000, 2)}K`;
          }
          if (this.value >= 1000000) {
            return `${format.float(this.value / 1000000, 2)}M`;
          }
          return this.value;
        },
      },
      tickAmount: 4,
      tickPixelInterval: 25,
      min: 0,
    },
    series: [
      {
        data,
        color: chartData.color,
        pointStart: startDate,
        pointInterval: tickFactor,
        fillOpacity: 0.3,
      },
    ],
  };
};

export default { get };
