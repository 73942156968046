import * as React from 'react';
import PropTypes from 'prop-types';
import js from 'common';
import FilterPeriod from '../../components/FilterPeriod';

const Filter = ({ changeState }) => (
  <div className="wrapper wrapper--socialf wrapper--noborders">
    <div className="complicated-calendar__social">
      <FilterPeriod
        responsiveFlag="mobile"
        changeState={changeState}
        page={() => js.D.page('dashboard')}
      />
    </div>
    <div className="complicated-calendar clearfix">
      <div className="complicated-calendar__big" data-text-label="Time frame">
        <FilterPeriod
          changeState={changeState}
          page={() => js.D.page('dashboard')}
        />
      </div>
    </div>
  </div>
);

Filter.propTypes = {
  changeState: PropTypes.func.isRequired,
};

export default Filter;
