/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import cx from 'classnames';
import moment from 'moment-timezone';
import { tz as tzUtils } from 'utils';

export default overrideProps => ({
  accessor: 'date',
  resizable: false,
  headerClassName: 'uikit-table__cell--first-cell',
  className: 'uikit-table__body-cell--first-cell',
  Header: (
    <FormattedMessage id="table.date">
      {txt => <p className="cell-inner date">{txt} {SortIcon}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <FormattedMessage id="table.total">
      {txt => <span className="cell-inner">{txt}</span>}
    </FormattedMessage>
  ),
  Cell: props => (
    <div
      className={cx('cell-inner', { weekend: [0, 6].includes(moment(props.value).day()) })}
      title={tzUtils.getFormattedDate(props.value, 'DD.MM.YYYY')}
    >
      <span className="day">{moment(props.value).format('dd')}</span>
      {tzUtils.getFormattedDate(props.value, 'DD.MM.YYYY')}
    </div>
  ),
  ...overrideProps,
});
