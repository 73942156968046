import * as React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import columns from 'components/tables/columns';
import { stats as statsUtils } from 'utils';
import './ExcludeUrlsAndTitles.scss';

const ExcludeUrlsAndTitles = ({ type, editList, statsData, isLoading }) => {
  const handleDelete = (value) => editList({ [type === 'url' ? 'url' : 'title']: value, action: 'delete' });

  const getColumnsConfig = passedType => [
    columns.trash({ width: 70 }, {}, { deleteHandler: handleDelete, type: passedType }),
    columns.url({ show: passedType === 'url' }, {}),
    columns.title({ show: passedType === 'title' }, {}),
    columns.excluded({ width: 150 }, {}),
  ];

  return (
    <div className="exclude-urls-pg-table">
      <Table
        columns={getColumnsConfig(type)}
        data={statsData.map(statsUtils.fromServerData)}
        dataCount={statsData.length}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ExcludeUrlsAndTitles;

ExcludeUrlsAndTitles.propTypes = {
  type: PropTypes.oneOf(['url', 'title']).isRequired,
  statsData: PropTypes.array.isRequired,
  editList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
