/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';

export default (overrideProps, totals, { sortField, forceSorting }) => ({
  accessor: sortField,
  resizable: false,
  Header: (
    <p className="cell-inner cell-inner__with-inline" onClick={e => e.stopPropagation()}>
      <FormattedMessage id="table.readingDepth" />
      <FormattedMessage id="table.page">
        {txt => (
          <span className="cell-inner__inline" onClick={e => forceSorting(e, 'reading_depth')}>
            {sortField === 'reading_depth' && SortIcon} {txt}
          </span>
        )}
      </FormattedMessage>
      <FormattedMessage id="table.content">
        {txt => (
          <span className="cell-inner__inline" onClick={e => forceSorting(e, 'content_reading_depth')}>
            {sortField === 'content_reading_depth' && SortIcon} {txt}
          </span>
        )}
      </FormattedMessage>
    </p>
  ),
  Footer: (
    <span className="cell-inner cell-inner__with-small" title={formatUtils.percentage(totals.readingDepth) || '0%'}>
      {formatUtils.percentage(totals.readingDepth) || '0%'}
      <span className="cell-inner-small">{formatUtils.percentage(totals.contentReadingDepth)}</span>
    </span>
  ),
  Cell: props => (
    <span
      className="cell-inner cell-inner__with-small"
      title={formatUtils.percentage(props.original.readingDepth) || '0%'}
    >
      {formatUtils.percentage(props.original.readingDepth) || '0%'}
      <span className="cell-inner-small">{formatUtils.percentage(props.original.contentReadingDepth) || '0%'}</span>
    </span>
  ),
  ...overrideProps,
});
