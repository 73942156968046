const PAGE_VIEWS: string = 'page_views';
const READING_DEPTH: string = 'reading_depth';
const AVERAGE_READING_TIME: string = 'average_reading_time';
const RPM: string = 'rpm';
const REVENUE: string = 'revenue';
const SHARES: string = 'shares';
const SOCIAL_CLICKS: string = 'social_clicks';
const SOC_PER_SHARE: string = 'soc_per_share';
const SHARES_PER_MILE: string = 'spm';

export const metricFilterTypes = {
  PAGE_VIEWS,
  READING_DEPTH,
  AVERAGE_READING_TIME,
  RPM,
  REVENUE,
  SHARES,
  SOCIAL_CLICKS,
  SOC_PER_SHARE,
  SHARES_PER_MILE,
};
