import React, { BaseSyntheticEvent, ReactElement } from 'react';
import cx from 'classnames';
import uniqid from 'uniqid';
import { Icon } from 'uikit';
import './Pin.scss';
import { PinProp } from 'app/ts/types/constructor/Pin';

const Pin = (props: PinProp): ReactElement => {
  const { id, onClickHandler, active: previousActive, param } = props;
  const [active, setActive] = React.useState<boolean>(previousActive);
  const idLabel = uniqid();
  const blockToggle = (event: BaseSyntheticEvent) => {
    onClickHandler(event, id, active, param, () => {
      setActive(!event.target.checked);
    });
  };

  return (
    <div className="cell-inner cell-inner__switcher">
      <div className={cx('link-pin', { 'link-pin__active': active })}>
        <input
          onChange={blockToggle}
          checked={active}
          id={`somePinOption-${idLabel}`}
          name="PinOption001"
          type="checkbox"
        />
        <label htmlFor={`somePinOption-${idLabel}`} className="label-default">
          <Icon name="pin" />
        </label>
      </div>
    </div>
  );
};

export default Pin;
