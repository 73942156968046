import React, { useState, useEffect, ReactElement } from 'react';
import AuthorsContentIntervalGraff from 'app/components/charts/authors/AuthorsContentIntervalGraff';
import AuthorsSocialIntervalGraff from 'app/components/charts/authors/AuthorsSocialIntervalGraff';
import PgFilter from 'app/components/PgFilter';
import PgTablePager from 'app/components/PgTablePager';
import AuthorsPgTable from 'app/components/tables/containers/Authors';
import { dom as domUtils, metrics, tz, ajax, ls, chart } from 'app/utils';

import './Authors.scss';

import { AuthorsParams, AuthorsProps } from 'app/ts/types/Authors';
import { AuthorsData, AuthorsStats, AuthorsTimeFrame } from 'app/ts/interfaces';

import { AxiosResponse } from 'axios';
import { GetChartInfo } from 'app/ts/types/utils';

const Authors = (props: { currentSite: number }): ReactElement => {
  const { currentSite } = props;
  const [stats, setStats] = useState<Array<AuthorsStats>>([]);
  const [totals, setTotals] = useState<AuthorsStats>({});
  const [totalItems, setTotalItems] = useState<number>(0);
  const [timeFrame, setTimeFrame] = useState<Array<AuthorsTimeFrame>>([]);
  const [page, setPage] = useState<string>(sessionStorage.filterAuthorsType || 'content');
  const [pageN, setPageN] = useState<number>(1);
  const [sortField, setSortField] = useState<string>('pageviews');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [loading, setLoading] = useState<boolean>(true);

  const getFilterUrl = (): AuthorsParams => {
    const params: AuthorsParams = {
      type: sessionStorage.filterAuthorsType,
    };
    if (!sessionStorage.filterAuthorsType) {
      sessionStorage.filterAuthorsType = 'content';
    }
    const storageKey: string = chart.initializeStorage('author');
    const filterDateTo: string = sessionStorage.getItem('filterDateTo');
    const filterDateFrom: string = sessionStorage.getItem('filterDateFrom');
    switch (sessionStorage[storageKey]) {
      case 'Date1day':
        if (filterDateTo) {
          if (filterDateTo === tz.now()) {
            params.period = 'today';
          } else if (filterDateTo === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = filterDateFrom || tz.now();
            params.to = filterDateTo || tz.now();
          }
        }
        break;
      case 'Date7days':
        params.from = filterDateFrom || tz.addDayToDate(-7);
        params.to = filterDateTo || tz.now();
        break;
      case 'Date30days':
        params.from = filterDateFrom || tz.addDayToDate(-30);
        params.to = filterDateTo || tz.now();
        break;
      case 'DateInterval':
        if (filterDateFrom === tz.now() && filterDateTo === tz.now()) {
          params.period = 'today';
        } else if (filterDateFrom === tz.addDayToDate(-1) && filterDateTo === tz.addDayToDate(-1)) {
          params.period = 'yesterday';
        } else {
          params.from = filterDateFrom;
          params.to = filterDateTo;
        }
        break;
      default:
        break;
    }
    return params;
  };

  const getUrlParams = (paramsProps: AuthorsProps): AuthorsParams => {
    const { pageNumToChange, state } = paramsProps;
    const itemsPerPage: string = ls.get('authorsTableSize', '20');
    const params: AuthorsParams = getFilterUrl();

    params.limit = +itemsPerPage;
    params.offset = pageNumToChange ? (pageNumToChange - 1) * +itemsPerPage : 0;
    // state существует, если таблица сортируется на бэкенде
    params.sort_field = (state && state.sortField) || sortField;
    params.sort_order = (state && state.sortOrder) || sortOrder;

    return params;
  };

  const fetchAuthor = (fetchAuthorProps: AuthorsProps = {}): void => {
    const { state, pageNumToChange } = fetchAuthorProps;
    setLoading(true);
    const params: AuthorsParams = getUrlParams({ pageNumToChange, state });

    ajax.get('/stats/editor/author', { params }).then(({ data }: AxiosResponse<AuthorsData>) => {
      if (data) {
        setStats(data.stats);
        setTotals(data.totals);
        setTimeFrame(data.timeframe);
        setTotalItems(data.total_items);
        setPageN(pageNumToChange || pageN);
        setSortField((state && state.sortField) || sortField);
        setSortOrder((state && state.sortOrder) || sortOrder);
        domUtils.callUiKitTableApiResize();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchAuthor();
    metrics.metricEvent('hit', '/reports/editorial/authors');
  }, []);

  useEffect(() => {
    fetchAuthor();
  }, [currentSite]);

  const changePage = (num: number): void => fetchAuthor({ pageNumToChange: num });

  const changePageType = (nextPage: string): void => {
    setPage(nextPage);
    setTimeFrame([]);
    setSortField('pageviews');
    setSortOrder('desc');
    setPageN(1);
    localStorage.filterAuthorsType = nextPage;
    sessionStorage.filterAuthorsType = nextPage;
    fetchAuthor();
  };

  const { isChartsVisible, byHour }: GetChartInfo = chart.getChartInfo();

  return (
    <div>
      <div className="wrapper wrapper--dashboard">
        <div className="container-fluid">
          <PgFilter
            changeState={fetchAuthor}
            getPage={() => chart.getPage('author')}
            onPerformanceChanged={changePageType}
          />
          {isChartsVisible && page === 'content' && (
            <AuthorsContentIntervalGraff
              key="aci"
              timeframe={timeFrame}
              byHour={byHour}
            />
          )}
          {isChartsVisible && page === 'social' && (
            <AuthorsSocialIntervalGraff
              key="asi"
              timeframe={timeFrame}
              byHour={byHour}
            />
          )}
        </div>
      </div>
      <PgTablePager
        pagerName="authorsTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePage}
      />
      <AuthorsPgTable
        mode={page}
        statsData={stats}
        totalsData={totals}
        totalItems={totalItems}
        changeState={fetchAuthor}
        isLoading={loading}
      />
      <PgTablePager
        pagerName="authorsTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePage}
      />
    </div>
  );
};

export default Authors;
