import { MenuGroupItemType } from '../ts/types/Header';
import { MenuList } from '../ts/types/utils';

export const menuList: MenuList = {
  front: {
    link: 'dashboard',
    title: 'Giraff.io',
    menuGroup: 'front',
    adminOnly: false,
    id: 1,
  },
  dashboard: {
    link: 'dashboard',
    title: 'Dashboard',
    menuGroup: 'dashboard',
    storageKey: 'Dashboard',
    adminOnly: false,
    id: 4,
  },
  summary: {
    link: 'reports/summary',
    title: 'Summary report',
    menuGroup: 'perf',
    storageKey: 'Summary',
    adminOnly: false,
    id: 5,
  },
  engagement: {
    link: 'reports/engagement',
    title: 'Audience engagement',
    menuGroup: 'perf',
    storageKey: 'Engagement',
    adminOnly: false,
    id: 6,
  },
  newAudience: {
    link: 'reports/new_audience',
    title: 'New audience',
    menuGroup: 'perf',
    storageKey: 'Audience',
    adminOnly: false,
    id: 7,
  },
  revenue: {
    link: 'reports/revenue',
    title: 'Revenue',
    menuGroup: 'perf',
    storageKey: 'Revenue',
    adminOnly: false,
    id: 8,
  },
  content: {
    link: 'reports/editorial/content',
    title: 'Content',
    menuGroup: 'content',
    storageKey: 'Content',
    adminOnly: false,
    id: 9,
  },
  topContent: {
    link: 'reports/engagement/top', // TODO нигде не применяется
    title: 'Top content',
    menuGroup: 'content2',
    storageKey: '',
    adminOnly: false,
    id: 10,
  },
  social: {
    link: 'reports/editorial/social',
    title: 'Social',
    menuGroup: 'content',
    storageKey: 'Social',
    adminOnly: false,
    id: 11,
  },
  author: {
    link: 'reports/editorial/authors',
    title: 'Authors',
    menuGroup: 'content',
    storageKey: 'Authors',
    adminOnly: false,
    id: 12,
  },
  referers: {
    link: 'reports/referrers/performance',
    title: 'Performance report',
    menuGroup: 'refs',
    storageKey: 'Performance',
    adminOnly: false,
    id: 13,
  },
  manageOwn: {
    link: 'tools/manage_content/own',
    title: 'Manage own content',
    menuGroup: 'tools',
    storageKey: 'Own',
    adminOnly: false,
    id: 14,
  },
  manageOthers: {
    link: 'tools/manage_content/others',
    title: 'Manage others content',
    menuGroup: 'tools',
    storageKey: 'Others',
    adminOnly: false,
    id: 15,
  },
  excludeUrl: {
    link: 'tools/exclude/urls',
    title: 'Exclude URLs',
    menuGroup: 'tools',
    storageKey: 'Others',
    adminOnly: false,
    id: 16,
  },
  excludeTitle: {
    link: 'tools/exclude/titles',
    title: 'Exclude titles',
    menuGroup: 'tools',
    storageKey: 'Others',
    adminOnly: false,
    id: 17,
  },
  performance: {
    link: 'settings/performance',
    title: 'Performance',
    menuGroup: 'settings',
    storageKey: '',
    adminOnly: true,
    id: 18,
  },
  timeLimits: {
    link: 'settings/time_limits',
    title: 'Time limits',
    menuGroup: 'settings',
    adminOnly: false,
    storageKey: '',
    id: 19,
  },
  constructor: {
    link: 'constructor',
    title: 'Constructor',
    menuGroup: 'settings',
    adminOnly: false,
    id: 20,
  },
  htmlTags: {
    link: 'html_tags',
    title: 'HTML tags',
    menuGroup: 'settings',
    adminOnly: true,
    id: 21,
  },
  abTest: {
    title: 'A/B testing',
    menuGroup: 'settings',
    link: 'ab_tests',
    adminOnly: false,
    id: 22,
  },
  externalAds: {
    title: 'External ads codes',
    menuGroup: 'settings',
    link: 'external_ads',
    adminOnly: true,
    id: 23,
  },
  siteSettings: { // нет adminOnly потому что находится в adminOnly группе меню
    title: 'Site settings',
    link: 'site-settings',
    menuGroup: 'admins',
    adminOnly: false,
    id: 24,
  },
  paymentHistory: {
    title: 'Payment History',
    link: 'payment_history',
    menuGroup: 'perf',
    adminOnly: false,
    id: 25,
  },
};

export const menuGroupTitles: MenuGroupItemType = {
  front: '',
  dashboard: '',
  perf: { title: 'Giraff.io performance' },
  content: { title: 'Editorial performance' },
  refs: { title: 'Referrers' },
  tools: { title: 'Editorial tools' },
  settings: { title: 'Settings' },
  admins: { title: 'Admin panel', adminOnly: true },
};

export default { menuList, menuGroupTitles };
