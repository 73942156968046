import { CallUiKitTableApiResize, CleanAndAttachFont } from '../ts/types/utils';

export const cleanAndAttachFont: CleanAndAttachFont = ({ ns, url }) => {
  const element = document.querySelector<HTMLLinkElement>('[data-font]');

  if (element) {
    if (!element.href.includes(url)) {
      element.parentNode?.removeChild(element);
    }
  }

  const newElement: HTMLLinkElement = document.createElement('link');
  newElement.href = url;
  newElement.rel = 'stylesheet';
  newElement.setAttribute('data-font', ns);
  document.getElementsByTagName('head')[0].appendChild(newElement);
};

export const callUiKitTableApiResize: CallUiKitTableApiResize = () => {
  const event = document.createEvent('Event');

  event.initEvent('api-resize', true, true);
  window.dispatchEvent(event);
};

export default { cleanAndAttachFont, callUiKitTableApiResize };
