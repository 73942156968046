import React, { ReactElement, BaseSyntheticEvent } from 'react';
import { Icon, Button, TextField, Tooltip } from 'uikit';
import { FormattedMessage } from 'react-intl';
import Table, { SortIcon } from 'app/components/Table';
import { stats as statsUtils } from 'app/utils';
import numeral from 'numeral';
import moment from 'moment-timezone';
import Switch from 'app/components/Switch';

import './ABTests.scss';

import { ABTestListProps } from 'app/ts/types/ABTestList';
import { ColumnsConfig } from 'app/ts/types/ColumnsConfig';
import { NewTest } from 'app/ts/types/ABTests';

const ABTests = (props: ABTestListProps): ReactElement => {
  const { actions: { switchToggle, onChangeInput, updateRatio, removeTest }, testsList, isLoading } = props;
  const getColumnsConfig = (): Array<ColumnsConfig> => [
    {
      accessor: 'active',
      resizable: false,
      Header: (
        <p className="cell-inner">
          <FormattedMessage id="table.widget_status" />
        </p>
      ),
      Footer: '',
      Cell: cellProps => {
        const { original: { is_disabled: isDisabled, id } } = cellProps;
        return (
          <div className="cell-inner">
            <div className="cell-inner--switch">
              <Switch
                flag={false}
                checked={!isDisabled}
                id={id}
                keyId={id}
                action={switchToggle}
              />
            </div>
          </div>
        );
      },
      width: 64,
    },
    {
      accessor: 'settings',
      resizable: false,
      Header: (
        <p className="cell-inner">
          <FormattedMessage id="table.widget" />
        </p>
      ),
      Footer: '',
      Cell: cellProps => {
        const { original: { settings, id } } = cellProps;
        return (
          <div className="cell-inner">
            {
            settings.map((item: NewTest, index: number) => (
              <div
                className="cell-inner--widget-name"
                key={`cell-wigdet-item-${id}-${index}`}
              >
                {item.name}
              </div>
            ))
          }
          </div>
        );
      },
      minWidth: 300,
    },
    {
      accessor: 'ratio',
      resizable: false,
      Header: (
        <p className="cell-inner">
          <FormattedMessage id="table.ratio" />
        </p>
      ),
      Footer: '',
      Cell: cellProps => {
        const { original: { settings, id } } = cellProps;
        return (
          <div className="cell-inner">
            <div className="cell-inner--input-group cell-inner--raw-inputs">
              {
              settings.map((item: NewTest, index: number) => (
                <div
                  className="cell-inner--input"
                  key={`cell-wigdet-item-${id}-${index}`}
                >
                  <TextField
                    min="0"
                    max="100"
                    type="number"
                    name="cell-inner"
                    key={`cell-wigdet-input-${id}-${index}`}
                    value={numeral(item.prob * 100).format('0')}
                    onChange={(event: BaseSyntheticEvent) => onChangeInput(id, item.idx, event)}
                  />
                </div>
              ))
            }
            </div>
            <Tooltip
              content={(
                <FormattedMessage id="tooltip.apply">
                  {text => <span className="tooltip__text">{text}</span>}
                </FormattedMessage>
            )}
            >
              <span>
                <Button
                  format="icon"
                  className="cell-inner--button cell-inner--button-apply"
                  onClick={(event: BaseSyntheticEvent) => updateRatio(id, event)}
                >
                  <Icon name="done" />
                </Button>
              </span>
            </Tooltip>
          </div>
        );
      },
      width: 150,
      minWidth: 150,
    },
    {
      accessor: 'created',
      resizable: false,
      Header: (
        <p className="cell-inner">
          <FormattedMessage id="table.added" /> {SortIcon}
        </p>
      ),
      Footer: '',
      Cell: cellProps => {
        const { original: { created } } = cellProps;
        return (
          <span className="cell-inner">
            <span className="cell-inner--date">{moment(created).format('DD.MM.YYYY')}</span>
            <span className="cell-inner--time">{moment(created).format('hh:mm A')}</span>
          </span>
        );
      },
      width: 112,
    },
    {
      accessor: 'remove',
      resizable: false,
      Header: '',
      Footer: '',
      Cell: cellProps => {
        const { original: { id } } = cellProps;
        return (
          <Tooltip
            content={(
              <FormattedMessage id="tooltip.delete">
                {text => <span className="tooltip__text">{text}</span>}
              </FormattedMessage>
          )}
          >
            <span className="cell-inner">
              <Button
                format="icon"
                className="cell-inner--button cell-inner--button-remove"
                onClick={() => removeTest(id)}
              >
                <Icon name="trash" />
              </Button>
            </span>
          </Tooltip>
        );
      },
      width: 48,
    },
  ];

  return (
    <div className="ab-test-pg-table">
      <Table
        columns={getColumnsConfig()}
        data={testsList.map(statsUtils.fromServerData)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ABTests;
