import { format, tz } from 'utils';

const prepareCSVName = currentSiteName => `giraff-summary-${currentSiteName}-${tz.changeFormat(tz.now())}.csv`;

const prepareCSV = (stats, totals) => {
  const data = [];
  const tableHeading = [
    'Date',
    'Giraff.io page views',
    'Clicks (AE)',
    'CTR (AE)',
    'Out clicks (NA)',
    'In clicks (NA)',
    'Exch. ratio (NA)',
    'RPM (RV)',
    'Revenue (RV)',
  ];

  const tableTotal = [
    'Total',
    format.mathRound((totals.engagement_pageviews + totals.audience_pageviews + totals.revenue_pageviews), 2, true),
    totals.engagement_clicks,
    format.mathRound(totals.engagement_ctr, 2, true),
    totals.out_clicks,
    totals.in_clicks,
    format.mathRound(totals.exchange_ratio, 2, true),
    format.mathRound(totals.rpm, 2, true),
    format.mathRound(totals.revenue, 2, true),
  ];

  data.push(tableHeading, tableTotal);

  stats.map((item) => {
    const row = [];

    row.push(
      item.date,
      format.mathRound((item.engagement_pageviews + item.audience_pageviews + item.revenue_pageviews), 2, true),
      item.engagement_clicks,
      format.mathRound(item.engagement_ctr, 2, true),
      item.out_clicks,
      item.in_clicks,
      format.mathRound(item.exchange_ratio, 2, true),
      format.mathRound(item.rpm, 2, true),
      format.mathRound(item.revenue, 2, true),
    );
    return data.push(row);
  });
  return data;
};

export default { prepareCSVName, prepareCSV };
