import React, { ReactElement, BaseSyntheticEvent } from 'react';
import { TextField } from 'uikit';

import './FilterTitle.scss';

import { FilterTitleProps, FilterTitleState } from 'app/ts/types/FilterTitle';

const FilterTitle = (props: FilterTitleProps): ReactElement => {
  const { statsData, onChange } = props;
  const [state, setState] = React.useState<FilterTitleState>({
    preferData: [],
    value: '',
  });
  const { preferData, value } = state;

  const keyup = (event: BaseSyntheticEvent): void => {
    const val: string = event.target.value;
    const newPreferData: Array<string> = [];

    if (val !== '') {
      statsData.map((v) => {
        if (v.item_title.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
          newPreferData.push(v.item_title);
        }
        return false;
      });
    }
    setState({
      value: val,
      preferData: newPreferData,
    });
  };

  const setValue = (item: string, event?: BaseSyntheticEvent): void => {
    event.preventDefault();
    if (item.length > 0) {
      onChange(item);
    }
    setState({
      value: '',
      preferData: [],
    });
  };

  const chooseTitle = (event: BaseSyntheticEvent): void => {
    event.preventDefault();
    if (value.length > 0) {
      onChange(value);
    }
    setState(prevState => ({ ...prevState, value: '' }));
  };

  return (
    <form className="title-filter" onSubmit={chooseTitle}>
      <div className="form-group">
        <TextField
          type="text"
          name="field"
          elementClassName="title-filter__input"
          placeholder="Enter your text"
          defaultValue={value}
          onChange={keyup}
          helperClassName="helper"
        />
        {preferData.length > 0 && (
        <ul className="title-filter__autocomplete">
          {preferData.map((item, index) => {
            if (index < 5) { // список autocomplete длиной не более 5 строк
              return (
                <li key={index}>
                  <a onClick={() => setValue(item)}>
                    {item}
                  </a>
                </li>
              );
            }
            return '';
          })}
        </ul>
        )}
      </div>
      <input type="submit" className="title-filter-btn__apply" value="Apply" />
    </form>
  );
};

export default FilterTitle;
