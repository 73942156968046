import { ExportToExcel, NewCsvData } from 'app/ts/types/utils';
import XLSX, { WorkSheet } from 'xlsx';

export const exportToExcel: ExportToExcel = (filename, rows) => {
  const heading: { [key: string]: string } = rows[0].reduce(
    (acc: { [key: string]: string; }, current: string) => ({ ...acc, [current]: current }), {},
  );
  const fileType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const ws: WorkSheet = XLSX.utils.json_to_sheet([heading], {
    header: rows[0],
    skipHeader: true,
  });

  const sheetName: string = filename.split('-')[1];
  const data = rows.slice(1, rows.length) as NewCsvData;
  const csvData: Array<{ [key: string]: string | number }> = data.map((arr: Array<string | number>) => (
    arr.reduce((acc: { [key: string]: string | number }, cur: string | number, index: number) => ({
      ...acc,
      [rows[0][index]]: cur,
    }), {})
  ));
  ws['!cols'] = rows[0].map((el: string) => ({ wch: el.length + 6 }));

  XLSX.utils.sheet_add_json(ws, csvData, {
    header: rows[0],
    skipHeader: true,
    origin: -1,
  });
  const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
  const excelBuffer: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelData: Blob = new Blob([excelBuffer], { type: fileType });
  const hrefData: string = URL.createObjectURL(excelData);
  const excelFile: HTMLAnchorElement = document.createElement('a');
  excelFile.setAttribute('href', hrefData);
  excelFile.setAttribute('download', filename);
  document.body.appendChild(excelFile);
  excelFile.click();
  document.body.removeChild(excelFile);
};

export default { exportToExcel };
