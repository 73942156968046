/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Tooltip } from 'uikit';
import { FormattedMessage } from 'react-intl';
import Table from 'components/Table';
import { stats as statsUtils, isMobile } from 'utils';

import './ExternalAds.scss';

const ExternalAds = ({ actions, externalAds, editableId, isLoading }) => {
  const getColumnsConfig = tableActions => [
    {
      accessor: 'codeName',
      resizable: false,
      Header: (
        <p className="cell-inner">
          <FormattedMessage id="table.codeName" />
        </p>
      ),
      Footer: '',
      Cell: tableProps => (
        <div className="cell-inner cell-inner--name">
          {tableProps.original.name}
        </div>
      ),
      width: 120,
    },
    {
      accessor: '',
      resizable: false,
      Header: '',
      Footer: '',
      Cell: tableProps => (
        <span className="cell-inner cell--actions">
          <Tooltip content={(
            <FormattedMessage id="tooltip.edit">
              {text => <span className="tooltip__text">{text}</span>}
            </FormattedMessage>
          )}
          >
            <span>
              <Button
                size="m"
                format="icon"
                kind="outline"
                onClick={() => tableActions.edit(tableProps.original.id)}
              >
                <Icon name="edit" />
              </Button>
            </span>
          </Tooltip>
          <Tooltip content={(
            <FormattedMessage id="tooltip.delete">
              {text => <span className="tooltip__text">{text}</span>}
            </FormattedMessage>
          )}
          >
            <span>
              <Button
                disabled={tableProps.original.widgets && tableProps.original.widgets.length > 0}
                size="m"
                format="icon"
                kind="outline"
                onClick={() => actions.remove(tableProps.original.id)}
              >
                <Icon name="trash" />
              </Button>
            </span>
          </Tooltip>
        </span>
      ),
      width: 126,
    },
    {
      accessor: 'widgets',
      Header: (
        <p className="cell-inner cell-inner--widgets">
          <FormattedMessage id="table.widgets" />
        </p>
      ),
      Footer: '',
      Cell: tableProps => (
        <div className="cell-inner">
          {tableProps.original.widgets && tableProps.original.widgets.join(', ')}
        </div>
      ),
      width: 240,
    },
    {
      accessor: 'description',
      Header: (
        <p className="cell-inner cell-inner--description">
          <FormattedMessage id="table.description" />
        </p>
      ),
      Footer: '',
      Cell: tableProps => (
        <div className="cell-inner">
          {tableProps.original.description}
        </div>
      ),
      minWidth: 256,
    },
  ];

  return (
    <div className={`external-ads-pg-table ${isMobile ? 'touch' : ''}`}>
      <Table
        columns={getColumnsConfig(actions)}
        data={externalAds.map(statsUtils.fromServerData)}
        getTrProps={(state, rowInfo) => (
          {
            style: {
              background: rowInfo?.original.id === editableId && '#EEEEF1',
            },
          }
        )}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ExternalAds;

ExternalAds.propTypes = {
  editableId: PropTypes.number,
  externalAds: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ExternalAds.defaultProps = {
  editableId: null,
};
