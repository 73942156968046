import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerformanceGraff from 'components/charts/performance/PerformanceGraff';
import PgFilter from 'components/PgFilter';
import PgTablePager from 'components/PgTablePager';
import PerformanceReportPgTable from 'components/tables/containers/PerformanceReport';
import { metrics, tz, ajax, ls, chart } from 'utils';

const PerformanceReport = ({ currentSite }) => {
  const [stats, setStats] = useState([]);
  const [totals, setTotals] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [timeFrame, setTimeFrame] = useState([]);
  const [pageN, setPageN] = useState(1);
  const [sortField, setSortField] = useState('pageviews');
  const [sortOrder, setSortOrder] = useState('desc');
  const [loading, setLoading] = useState(true);

  const getFilterUrl = () => {
    const storageKey = chart.initializeStorage('referers');
    const params = {};
    const filterDateTo = sessionStorage.getItem('filterDateTo');
    const filterDateFrom = sessionStorage.getItem('filterDateFrom');
    switch (sessionStorage[storageKey]) {
      case 'Date1day':
        if (filterDateTo) {
          if (filterDateTo === tz.now()) {
            params.period = 'today';
          } else if (filterDateTo === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = filterDateFrom || tz.now();
            params.to = filterDateTo || tz.now();
          }
        }
        break;
      case 'Date7days':
        params.from = filterDateFrom || tz.addDayToDate(-7);
        params.to = filterDateTo || tz.now();
        break;
      case 'Date30days':
        params.from = filterDateFrom || tz.addDayToDate(-30);
        params.to = filterDateTo || tz.now();
        break;
      case 'DateInterval':
        params.from = sessionStorage.filterDateFrom;
        params.to = sessionStorage.filterDateTo;
        break;
      default:
        break;
    }
    return params;
  };

  const getUrlParams = ({ state, pageNumToChange }) => {
    const itemsPerPage = ls.get('referrersPerformanceTableSize', 20);
    const params = getFilterUrl();

    params.offset = pageNumToChange ? (pageNumToChange - 1) * +itemsPerPage : 0;
    params.limit = +itemsPerPage;
    // state существует, если таблица сортируется на бэкенде
    params.sort_field = (state && state.sortField) || sortField;
    params.sort_order = (state && state.sortOrder) || sortOrder;

    return params;
  };

  const fetchStats = ({ state, pageNumToChange }) => {
    setLoading(true);
    const params = getUrlParams({ state, pageNumToChange });

    ajax.get('/stats/referers', { params }).then(({ data }) => {
      if (data) {
        setStats(data.stats);
        setTotals(data.totals);
        setTimeFrame(data.timeframe);
        setTotalItems(data.total_items);
        setPageN(pageNumToChange || pageN);
        setSortField((state && state.sortField) || sortField);
        setSortOrder((state && state.sortOrder) || sortOrder);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchStats({ pageNumToChange: 1 });
    metrics.metricEvent('hit', '/reports/referrers/performance');
  }, []);

  useEffect(() => {
    fetchStats({});
  }, [currentSite]);

  const changePageN = (num) => fetchStats({ pageNumToChange: num });
  const { isChartsVisible } = chart.getChartInfo();

  return (
    <div>
      <div className="wrapper wrapper--dashboard">
        <div className="container-fluid">
          <PgFilter
            changeState={fetchStats}
            getPage={() => chart.getPage('referers')}
          />
          {isChartsVisible && (
            <PerformanceGraff
              statsData={stats}
              timeframe={timeFrame}
            />
          )}
        </div>
      </div>
      <PgTablePager
        pagerName="referrersPerformanceTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
      <PerformanceReportPgTable
        statsData={stats}
        totalsData={totals}
        totalItems={totalItems}
        changeState={fetchStats}
        isLoading={loading}
      />
      <PgTablePager
        pagerName="referrersPerformanceTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
    </div>
  );
};

export default PerformanceReport;

PerformanceReport.propTypes = {
  currentSite: PropTypes.number.isRequired,
};
