import { Icon } from 'uikit';
import React from 'react';
import PropTypes from 'prop-types';

const PerformanceSlider = (
  {
    slider, index, jumpSetting, slideLock, value, changeViews, inputLock, setRefs,
  },
) => (
  <div
    className="psettings__slider clearfix"
    key={slider.name}
    onClick={jumpSetting}
    data-slider_name={slider.name}
    data-index={index}
    disabled={slideLock === slider.name}
    ref={ref => {
      if (ref) setRefs(slider.name, 'parent', ref);
    }}
  >
    <div className="psettings__slider-header">{slider.title}</div>
    <div
      ref={ref => {
        if (ref) setRefs(slider.name, 'children', ref);
      }}
      className={`slider slider-settings slider--${slider.name}`}
    />
    <div className="psettings__slider-input_blankCursor" />
    <div className="psettings__slider-input">
      <input
        type="number"
        name="psettings"
        min="0"
        max="100"
        id={slider.name}
        className="psettings__input"
        value={value}
        onChange={event => changeViews({ name: slider.name, value: event.target.value }, { type: 'change' })}
      />
    </div>
    <a onClick={() => inputLock(slider.name)} className="psettings__lock">
      {
          slideLock === slider.name ?
            <Icon name="lock" />
            :
            <Icon name="lock-open" />
        }
    </a>
  </div>
);

export default PerformanceSlider;

PerformanceSlider.propTypes = {
  slider: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  jumpSetting: PropTypes.func.isRequired,
  slideLock: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  changeViews: PropTypes.func.isRequired,
  inputLock: PropTypes.func.isRequired,
  setRefs: PropTypes.func.isRequired,
};
