import { IsEmptyObject, IsValidJSONString } from 'app/ts/types/utils';

export const isValidJSONString: IsValidJSONString = (jsonData) => {
  try {
    JSON.parse(jsonData);
  } catch (e) {
    return false;
  }
  return true;
};

export const isEmptyObject: IsEmptyObject = (object) => {
  if (Object.keys(object).length === 0 && object.constructor === Object) {
    return true;
  }
  return false;
};

export default { isValidJSONString, isEmptyObject };
