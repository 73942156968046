import * as React from 'react';
import PropTypes from 'prop-types';
import { useMount } from 'react-use';
import { FormattedMessage, injectIntl } from 'react-intl';
import { confirmAlert } from 'react-confirm-alert';
import { Icon, Button } from 'uikit';
import { ajax, showAlert } from 'utils';
import ExternalAdsList from 'components/tables/containers/ExternalAds';
import ExternalAdsForm from './ExternalAdsForm';

import './ExternalAds.scss';

const ExternalAds = ({ intl }) => {
  const [editableId, setEditableId] = React.useState(null);
  const [createFormOpen, setCreateFormOpen] = React.useState(false);
  const [codes, setCodes] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const removeAdsCode = (id) => {
    const msgTitle = intl.messages['externalAds.msg-remove-code'];
    const msgBtnApply = intl.messages['externalAds.btn-yes'];
    const msgBtnCancel = intl.messages['externalAds.btn-cancel'];

    setEditableId(null);

    const action = () => {
      setLoading(true);
      ajax.delete(`/external_ads_codes/${id}`).then(() => {
        const newCodes = codes.filter(item => item.id !== id);
        setCodes(newCodes);
      }).finally(() => {
        setLoading(false);
      });
    };

    confirmAlert({
      // eslint-disable-next-line react/prop-types
      customUI: ({ onClose }) => (
        <div className="react-confirm-alert-body disabled">
          <div className="react-confirm-alert-message center">
            {msgTitle}
            <div className="react-confirm-alert-button-group">
              <Button
                type="button"
                onClick={
                  () => {
                    action();
                    onClose();
                  }
                }
              >
                {msgBtnApply}
              </Button>
              <Button
                kind="secondary"
                type="button"
                onClick={onClose}
              >
                {msgBtnCancel}
              </Button>
            </div>
          </div>
        </div>
      ),
    });
  };

  const changeEditableAdsCode = (id) => {
    setEditableId(id || null);
  };

  const actions = {
    remove: removeAdsCode,
    edit: changeEditableAdsCode,
  };
  const editableData = codes.length ? codes.find(item => item.id === editableId) : [];

  useMount(() => {
    setLoading(true);
    let newCodes = [];

    ajax.get('/external_ads_codes').then(({ data }) => {
      if (data) {
        const { list: codesArr } = data;
        newCodes = codesArr.slice();

        ajax.get('/widgets').then(({ data }) => { // eslint-disable-line @typescript-eslint/no-shadow
          if (data) {
            const { list } = data;
            const newStateCodes = [];

            if (list.length) {
              newCodes.map((item) => {
                const obj = { ...item };
                for (let i = 0; i < list.length; i += 1) {
                  const cells = list[i].settings && list[i].settings.cells;
                  if (cells && cells.some(el => el === obj.name.toLowerCase())) {
                    if (!Array.isArray(obj.widgets)) {
                      obj.widgets = [];
                    }
                    obj.widgets.push(list[i].name);
                  }
                }
                newStateCodes.push(obj);
                return false;
              });
            }

            setCodes(newStateCodes);
          }
        }).finally(() => {
          setLoading(false);
        });
      }
    });
  });

  const createAdsCode = (e) => {
    e.preventDefault();

    setLoading(true);

    ajax.post('/external_ads_codes', {
      name: e.target.code_name.value,
      description: e.target.description.value,
      code: e.target.code.value,
    }).then(({ data: { data: codesArr } }) => {
      const newCodes = [...codes, codesArr];
      setCodes(newCodes);
      setCreateFormOpen(false);
    }).finally(() => {
      showAlert(intl.messages['externalAds.msg-code-created']);
      setLoading(false);
    });
  };

  const closeForm = () => {
    setCreateFormOpen(false);
  };

  const editAdsCode = (e) => {
    e.preventDefault();

    setLoading(true);
    const obj = {
      name: e.target.code_name.value,
      description: e.target.description.value,
      code: e.target.code.value,
    };

    ajax.put(`/external_ads_codes/${editableId}`, obj).then(() => {
      const newCodes = [];
      codes.map((item) => {
        if (item.id === editableId) {
          newCodes.push({ ...item, ...obj });
        } else {
          newCodes.push(item);
        }
        return false;
      });
      setCodes(newCodes);
      setEditableId(null);
    }).finally(() => {
      showAlert(intl.messages['externalAds.msg-code-edited']);
      setLoading(false);
    });
  };

  return (
    <div className="external-ads">
      <div className="wrapper external-ads__creator">
        {createFormOpen ? (
          <>
            <div className="external-ads__creator-title">
              <FormattedMessage id="externalAds.add-code" />
            </div>
            <ExternalAdsForm submit={createAdsCode} onCancelHandler={closeForm} isVisibleCancelBtn />
          </>
        ) : (
          <Button size="l" kind="primary" onClick={() => setCreateFormOpen(true)}>
            <FormattedMessage id="externalAds.add-code" />
          </Button>
        )}
      </div>
      <div className="external-ads__list">
        <ExternalAdsList
          editableId={editableId}
          externalAds={codes}
          actions={actions}
          isLoading={loading}
        />
      </div>
      {codes.length > 0 && (
        <>

          <div className={`external-ads__drawer ${editableId && 'external-ads__drawer--open'}`}>
            <div className="external-ads__drawer-title">
              <FormattedMessage id="externalAds.edit-code" />
            </div>
            <Button
              size="m"
              format="icon"
              kind="secondary"
              className="external-ads__drawer-close"
              onClick={() => changeEditableAdsCode()}
            >
              <Icon name="close" />
            </Button>
            <ExternalAdsForm data={editableData} submit={editAdsCode} isVisibleCancelBtn={false} />
          </div>
        </>
      )}
    </div>
  );
};

ExternalAds.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ExternalAds);
