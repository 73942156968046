/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';

export default (overrideProps, totals, { sortField, forceSorting }) => ({
  accessor: sortField,
  resizable: false,
  Header: (
    <p className="cell-inner cell-inner__with-inline" onClick={e => e.stopPropagation()}>
      <FormattedMessage id="table.readingTime" />
      <FormattedMessage id="table.page">
        {txt => (
          <span className="cell-inner__inline" onClick={e => forceSorting(e, 'pv_time')}>
            {sortField === 'pv_time' && SortIcon} {txt}
          </span>
        )}
      </FormattedMessage>
      <FormattedMessage id="table.content">
        {txt => (
          <span className="cell-inner__inline" onClick={e => forceSorting(e, 'content_pv_time')}>
            {sortField === 'content_pv_time' && SortIcon} {txt}
          </span>
        )}
      </FormattedMessage>
    </p>
  ),
  Footer: (
    <span className="cell-inner cell-inner__with-small" title={formatUtils.time(totals.readingTime)}>
      {totals.readingTime ? formatUtils.time(totals.readingTime) : '0:00'}
      <span className="cell-inner-small">
        {totals.contentReadingTime ? formatUtils.time(totals.contentReadingTime) : '0:00'}
      </span>
    </span>
  ),
  Cell: props => (
    <span className="cell-inner cell-inner__with-small" title={formatUtils.time(props.original.readingTime)}>
      {props.original.readingTime ? formatUtils.time(props.original.readingTime) : '0:00'}
      <span className="cell-inner-small">
        {props.original.contentReadingTime ? formatUtils.time(props.original.contentReadingTime) : '0:00'}
      </span>
    </span>
  ),
  ...overrideProps,
});
