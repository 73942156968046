/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';

export default (overrideProps) => ({
  accessor: 'paymentSum',
  resizable: false,
  Header: (
    <FormattedMessage id="table.payments">{txt => <p className="cell-inner">{SortIcon} {txt}</p>}</FormattedMessage>
  ),
  Cell: props => (
    <span className="cell-inner" title={formatUtils.money(props.value)}>{formatUtils.money(props.value)}</span>
  ),
  ...overrideProps,
});
