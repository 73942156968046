import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from 'uikit';

const ExcludeUrlsAndTitlesFilter = ({ type, editList }) => {
  const [state, setState] = React.useState({
    value: '',
  });

  const { value } = state;
  let inputRef = React.useRef();

  const onInputChange = (e) => {
    const { value: valueNew } = e.target;

    setState(prevState => ({
      ...prevState,
      value: valueNew,
    }));
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (inputRef.value) {
      editList({ action: 'add', [type]: inputRef.value });
      setState(prevState => ({
        ...prevState,
        value: '',
      }));
    }
  };

  return (
    <div>
      <div className="moc-settings clearfix">
        <div className="moc-settings__top clearfix">
          <form onSubmit={onSubmit} className="form__row form__row--flexed-center clearfix">
            <TextField
              elementRef={ref => inputRef = ref}
              type="text"
              name="settingstl__date"
              value={value}
              placeholder={type === 'url' ? 'URL' : 'Title'}
              label={type === 'url' ? 'URL' : 'Title'}
              onChange={onInputChange}
              helper={type === 'url'
                ? 'You can use URL masks for exclusion. Use commas to exclude several URLs.'
                : 'You can use title mask for exclusion.'}
            />
            <Button
              type="submit"
              onClick={onSubmit}
              disabled={value === ''}
              className={`settingstl__submit settingstl__submit--fl ${type === 'url'
                ? 'settingstl__submit--exclude-urls'
                : ''}`}
            >
              Exclude
            </Button>
          </form>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
};

ExcludeUrlsAndTitlesFilter.propTypes = {
  type: PropTypes.oneOf(['url', 'title']).isRequired,
  editList: PropTypes.func.isRequired,
};

export default ExcludeUrlsAndTitlesFilter;
