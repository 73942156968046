import React, { BaseSyntheticEvent, ReactElement } from 'react';
import { TextField } from 'uikit';

import './AuthorsList.scss';

import { AuthorsListProps, AuthorsListState } from 'app/ts/types/AuthorsList';
import { Social } from 'app/ts/types/ContentAndSocialFilter';

const AuthorsList = (props: AuthorsListProps): ReactElement => {
  const { list, onChange, responsiveFlag = '' } = props;
  const [state, setState] = React.useState<AuthorsListState>({
    authors: [],
    authorInput: '',
  });
  const { authors, authorInput } = state;
  const listRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (authorInput === '') {
      setState(prevState => ({ ...prevState, authors: list.slice() }));
      return;
    }

    const newList: Array<Social> =
      list.filter(item => item.name.toUpperCase().indexOf(authorInput.toUpperCase()) !== -1);
    setState(prevState => ({ ...prevState, authors: newList }));
  }, [authorInput, list]);

  const chooseAuthor = (authorId: string, authorName: string, event: BaseSyntheticEvent): void => {
    event.preventDefault();

    onChange('author', {
      author: authorName,
      author_id: authorId,
    });
  };

  const searchAuthor = (event: BaseSyntheticEvent): void => {
    event.preventDefault();
    const { value } = event.target;
    setState(prevState => ({ ...prevState, authorInput: value }));
  };

  const authorsNamesList = authors?.length ? authors.map(v => (
    v.name !== '' && (
      <li key={v.id}>
        <a
          onClick={(event: BaseSyntheticEvent) => chooseAuthor(v.id, v.name, event)}
          className="sdrop-down__menu-item"
          href=""
        >
          {v.name}
        </a>
      </li>
    ))) : null;

  return (
    <>
      {responsiveFlag !== 'mobile' && (
      <div className="dropdown complicated-calendar__dd author-list--desktop" ref={listRef}>
        <div className="sdrop-down__menu-header">
          <form className="sdrop-down__search-author">
            <TextField
              type="text"
              name="search-author"
              defaultValue={authorInput}
              elementClassName="widgetAuthors__search"
              onChange={searchAuthor}
              helperClassName="helper"
            />
          </form>
        </div>
        <ul>
          {authorsNamesList}
        </ul>
      </div>
      )}
      {responsiveFlag === 'mobile' && (
        <div className="author-list--mobile">
          <div className="sdrop-down__menu-header">
            <form className="sdrop-down__search-author">
              <TextField
                type="text"
                name="search-author"
                defaultValue={authorInput}
                onChange={searchAuthor}
                placeholder="Start typing author's name"
                elementClassName="widgetAuthors__search"
                helperClassName="helper"
              />
            </form>
          </div>
          <ul>
            {authorsNamesList}
          </ul>
        </div>
      )}
    </>
  );
};

export default AuthorsList;
