import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Table from 'components/Table';
import columns from 'components/tables/columns';
import { TextField } from 'uikit';
import { stats as statsUtils } from 'utils';
import config from 'config';

import './ManageOwnAndOthersContent.scss';

const urlRegular = new RegExp(
  "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
  'i',
);

const ManageOwnAndOthersContent = (props) => {
  const {
    searchPromoQuery,
    submitNewPromo,
    mode,
    totalsData,
    changeStatus,
    pinToggle,
    removeItem,
    statsData,
    totalItems,
    changeState,
    isLoading,
  } = props;
  const [state, setState] = React.useState({
    url: '',
    isValidUrl: true,
  });
  const { url, isValidUrl } = state;

  const getColumnsConfig = () => {
    const totals = statsUtils.fromServerData(totalsData);
    const { adBlockTeaserTypes } = config;

    return [
      columns.statusSwitcher({ width: 75 }, totals, { removeItem, changeStatus }),
      columns.itemTitle({ minWidth: 300 }, totals),
      columns.pinAction({ width: 75, show: mode === 'own' }, totals, { pinToggle }, 'isPromo'),
      columns.pinAction({
        width: 90,
        show: mode === 'own' && Boolean(adBlockTeaserTypes) && adBlockTeaserTypes.length > 1,
      }, totals, { pinToggle }, 'isAdblockPromo'),
      columns.impressions({ maxWidth: 109 }, totals),
      columns.clicks({ maxWidth: 100 }, totals),
      columns.ctr({ maxWidth: 100 }, totals),
    ];
  };

  const onUrlChange = e => setState({
    url: e.target.value,
    isValidUrl: e.target.value && urlRegular.test(e.target.value),
  });

  const getAddPromoComponent = () => {
    if (!searchPromoQuery) return false;
    const isUrlSearch = urlRegular.test(searchPromoQuery);

    return isUrlSearch
      ? (
        <>
          <form className="add__promo add__promo--form" onSubmit={e => submitNewPromo(e, 'query')}>
            <span className="table_plug_text">
              There is no such content as
              <a href={`http://${searchPromoQuery}`} target="_blank" rel="noopener noreferrer">
                &apos;{searchPromoQuery}&apos;
              </a>.
              <br />Add it to <i className="yui-icon yui-icon--pin" /> Promo?
            </span>
            <input type="submit" value="Add to Promo" className="btn btn--submit" />
          </form>
        </>
      )
      : (
        <>
          <form className="add__promo add__promo--form" onSubmit={e => submitNewPromo(e, 'url', url)}>
            <span className="table_plug_text">
              There is no such content
              <br />
              If you want to add it to <i className="yui-icon yui-icon--pin" /> Promo, please type URL here:
            </span>
            <div
              className={isValidUrl === false ? 'form__control no-valid' : 'form__control'}
              data-error="URL is not valid"
            >
              <TextField
                elementBefore={<i className="yui-icon yui-icon--search" />}
                minLength="5"
                type="text"
                name="own-url"
                onChange={onUrlChange}
                className="own__url"
                placeholder="http://"
                value={url}
              />
            </div>
            <input type="submit" value="Add to Promo" className="btn btn--submit" />
          </form>
        </>
      );
  };

  return (
    <div
      className={
        cx('manage-own-and-others-content-pg-table', {
          'manage-own-and-others-content-pg-table__cell5': mode === 'others',
          'manage-own-and-others-content-pg-table__cell6': mode === 'own',
        })
      }
    >
      <Table
        columns={getColumnsConfig()}
        data={statsData.map(statsUtils.fromServerData)}
        dataCount={totalItems}
        changeState={changeState}
        isLoading={isLoading}
        AddPromoComponent={getAddPromoComponent()}
      />
    </div>
  );
};

export default ManageOwnAndOthersContent;

ManageOwnAndOthersContent.propTypes = {
  mode: PropTypes.oneOf(['own', 'others']).isRequired,
  statsData: PropTypes.array.isRequired,
  totalsData: PropTypes.object.isRequired,
  totalItems: PropTypes.number.isRequired,
  changeState: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  pinToggle: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  searchPromoQuery: PropTypes.string,
  submitNewPromo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ManageOwnAndOthersContent.defaultProps = {
  searchPromoQuery: '',
};
