import React, { ReactElement } from 'react';
import { Button } from 'uikit';
import AudienceGraff from 'app/components/charts/newAudience/AudienceGraff';
import AudienceGraffWidget from 'app/components/charts/newAudience/AudienceGraffWidget';
import PgFilter from 'app/components/PgFilter';
import PgTablePager from 'app/components/PgTablePager';
import NewAudiencePgTable from 'app/components/tables/containers/NewAudience';
import { metrics, tz, ajax, ls, chart } from 'app/utils';
import { useMount, useUpdateEffect } from 'react-use';
import { cloneDeep } from 'lodash';
import { widgets as widgetsCSV, sites as sitesCSV } from 'app/components/csv/newAudience';
import { AxiosResponse } from 'axios';

import {
  Params,
  SiteProps,
  AudienceList,
  AudienceSites,
  AudienceProps,
  AudienceWidgets,
} from 'app/ts/types/Audience';
import { GetChartInfo, NewCsvData } from 'app/ts/types/utils';
import { AccountInfoState, WidgetData, DataItems } from 'app/ts/interfaces';
import { IntlMessage } from 'app/ts/types/IntlMessage';

import { useAppSelector } from '../../store/hooks';

const NewAudience = (): ReactElement => {
  const { currentSite, currentSiteName }: AccountInfoState = useAppSelector(reduxState => reduxState.accountInfo);

  const [stats, setStats] = React.useState<Array<DataItems>>([]);
  const [totals, setTotals] = React.useState<DataItems | {}>({});
  const [totalItems, setTotalItems] = React.useState<number>(0);
  const [timeframe, setTimeframe] = React.useState<Array<DataItems>>([]);
  const [csvData, setCsvData] = React.useState<NewCsvData>([]);
  const [csvName, setCsvName] = React.useState<string>('');
  const [pageN, setPageN] = React.useState<number>(1);
  const [sortField, setSortField] = React.useState<string>('date');
  const [sortOrder, setSortOrder] = React.useState<string>('desc');
  const [pageContent, setPageContent] = React.useState<string>('widgets');
  const [widgets, setWidgets] = React.useState<AudienceWidgets>({
    list: [],
    active: '',
  });
  const [sites, setSites] = React.useState<AudienceSites>({
    list: [],
    active: [],
  });
  const [loading, setLoading] = React.useState<boolean>(true);

  const displayPageContentOptions: boolean = sites.list.length > 1;
  const displayDefaultCharts: boolean = pageContent === 'sites' || widgets.active === 'all';
  const { isChartsVisible, byHour }: GetChartInfo = chart.getChartInfo();

  const getUrlParams = (paramsProps: AudienceProps = {}): Params => {
    const { pageNumToChange, state: passedState } = paramsProps;
    const params: Params = {};
    const storageKey: string = chart.initializeStorage('newaudience');
    const filterDateTo: string = sessionStorage.getItem('filterDateTo');
    const filterDateFrom: string = sessionStorage.getItem('filterDateFrom');
    const itemsPerPage: Storage = ls.get('newAudienceTableSize', 20);

    switch (sessionStorage[storageKey]) {
      case 'Date1day':
        if (filterDateTo) {
          if (filterDateTo === tz.now()) {
            params.period = 'today';
          } else if (filterDateTo === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = filterDateFrom || tz.now();
            params.to = filterDateTo || tz.now();
          }
        }
        break;
      case 'Date7days':
        params.from = filterDateFrom || tz.addDayToDate(-7);
        params.to = filterDateTo || tz.now();
        break;
      case 'Date30days':
        params.from = filterDateFrom || tz.addDayToDate(-30);
        params.to = filterDateTo || tz.now();
        break;
      case 'DateInterval':
        if (filterDateFrom === tz.now() && filterDateTo === tz.now()) {
          params.period = 'today';
        } else if (filterDateFrom === tz.addDayToDate(-1) && filterDateTo === tz.addDayToDate(-1)) {
          params.period = 'yesterday';
        } else {
          params.from = filterDateFrom;
          params.to = filterDateTo;
        }
        break;
      default:
        break;
    }

    params.limit = +itemsPerPage;
    params.offset = pageNumToChange ? (pageNumToChange - 1) * +itemsPerPage : 0;
    // passedState существует, если таблица сортируется на бэкенде
    params.sort_field = passedState?.sortField || sortField;
    params.sort_order = passedState?.sortOrder || sortOrder;

    return params;
  };

  const changeWidget = (wid: string): void => {
    const params: Params = getUrlParams();

    if (wid !== 'all') {
      params.wid = wid;
    }

    ajax.get('/stats/newaudience', { params }).then(({ data }: AxiosResponse<WidgetData>): void => {
      if (data) {
        const newCsvData: NewCsvData = widgetsCSV.prepareCSV({
          stats: data.stats,
          totals: data.totals,
          widgets: {
            ...widgets,
            active: wid,
          },
        });
        const newCsvName: string = widgetsCSV.prepareCSVName({
          widgets: {
            ...widgets,
            active: wid,
          },
          currentSiteName,
        });

        setStats(data.stats);
        setTotals(data.totals);
        setTimeframe(data.timeframe);
        setTotalItems(data.total_items);
        setCsvData(newCsvData);
        setCsvName(newCsvName);
        setWidgets(prevWidgets => {
          const prevWidgetsCopy: AudienceWidgets = cloneDeep(prevWidgets);
          return {
            ...prevWidgetsCopy,
            active: wid,
          };
        });
        setPageN(1);
        setPageContent('widgets');
      }
    });
  };

  const changeSite = (updatedSites: Array<SiteProps>): void => {
    const newSites: Array<SiteProps> | Array<AudienceList> = updatedSites?.length > 0 ? updatedSites : sites.list;
    const params: Params = getUrlParams();

    params.sites = newSites.map(item => item.value).join(',');

    ajax.get('/stats/newaudience', { params }).then(({ data }: AxiosResponse<WidgetData>) => {
      if (data) {
        const newCsvData: NewCsvData = sitesCSV.prepareCSV({ stats: data.stats, totals: data.totals });
        const newCsvName = sitesCSV.prepareCSVName();
        setStats(data.stats);
        setTotals(data.totals);
        setTimeframe(data.timeframe);
        setTotalItems(data.total_items);
        setCsvData(newCsvData);
        setCsvName(newCsvName);
        setSites((prevSites: AudienceSites) => {
          const prevSitesCopy: AudienceSites = cloneDeep(prevSites);
          return {
            ...prevSitesCopy,
            active: newSites,
          };
        });
        setPageN(1);
        setPageContent('sites');
      }
    });
  };

  const fetchStats = (fetchStatsProps: AudienceProps = {}): void => {
    const { pageNumToChange, state: passedState, widgets: passedWidgets } = fetchStatsProps;
    const params: Params = getUrlParams({ pageNumToChange, state: passedState });
    if (pageContent === 'widgets' && widgets.active && widgets.active !== 'all') {
      params.wid = widgets.active;
    }

    if (pageContent === 'sites') {
      params.sites = sites.active.map(item => item.value).join(',');
    }

    ajax.get('/stats/newaudience', { params }).then(({ data }: AxiosResponse<WidgetData>) => {
      if (data) {
        let newCsvData: NewCsvData;
        let newCsvName: string;
        if (pageContent === 'widgets') {
          newCsvData = widgetsCSV.prepareCSV({
            widgets: passedWidgets || widgets,
            stats: data.stats,
            totals: data.totals,
          });
          newCsvName = widgetsCSV.prepareCSVName({
            widgets: passedWidgets || widgets,
            currentSiteName,
          });
        }

        if (pageContent === 'sites') {
          newCsvData = sitesCSV.prepareCSV({ stats: data.stats, totals: data.totals });
          newCsvName = sitesCSV.prepareCSVName();
        }

        setStats(data.stats);
        setTotals(data.totals);
        setTimeframe(data.timeframe);
        setTotalItems(data.total_items);
        setCsvData(newCsvData);
        setCsvName(newCsvName);
        setPageN(pageNumToChange || pageN);
        setSortField(passedState?.sortField || sortField);
        setSortOrder(passedState?.sortOrder || sortOrder);
        setLoading(false);
      }
    });
  };

  const init = async (): Promise<void> => {
    const params: Params = {
      site_id: currentSite,
    };

    const fetchedWidgets: Array<AudienceList> = await ajax.get('/widgets', { params })
      .then(({ data }: AxiosResponse<WidgetData>) => {
        if (data) {
          const { list: widgetArr } = data;
          const widgetList: Array<AudienceList> = [{
            active: true,
            name: 'all',
            title: 'All',
          }];

          widgetArr.sort((a, b) => (a.name > b.name ? 1 : -1));
          widgetArr.forEach((widget) => {
            const title: IntlMessage = /^#\d+$/.test(widget.name as string) ? `Widget ${widget.name}` : widget.name;
            widgetList.push({
              active: widget.settings && ('active' in widget.settings) ? widget.settings.active : true,
              name: widget.id,
              title,
            });
          });

          return widgetList;
        }

        return null;
      });

    const fetchedSites: Array<SiteProps> = await ajax.get('/sites', { params })
      .then(({ data }: AxiosResponse<WidgetData>) => {
        if (data) {
          const { list: siteArr } = data;
          const siteList: Array<SiteProps> = siteArr.map(site => ({ value: site.id, label: site.name }));
          return siteList;
        }
        return null;
      });

    setWidgets({
      list: fetchedWidgets,
      active: 'all',
    });
    setSites({
      list: fetchedSites,
      active: fetchedSites,
    });

    fetchStats({
      widgets: {
        list: fetchedWidgets,
        active: 'all',
      },
    });
  };

  const changePageContent = (contentType: string): void => {
    if (contentType === 'widgets') {
      changeWidget('all');
    } else {
      changeSite(sites.list);
    }
  };

  const changePageN = (pageNumToChange: number): void => {
    fetchStats({ pageNumToChange });
  };

  useMount(() => {
    init();
    metrics.metricEvent('hit', '/reports/new_audience');
  });

  useUpdateEffect(() => {
    init();
  }, [currentSite]);

  return (
    <div className="new-audience__content-wrapper">
      <div className="wrapper wrapper--dashboard">
        <div className="container-fluid">
          {displayPageContentOptions && (
            <>
              <Button
                kind={pageContent !== 'widgets' ? 'outline' : 'primary'}
                className="widgets__button"
                onClick={() => changePageContent('widgets')}
              >
                By widgets
              </Button>
              <Button
                kind={pageContent !== 'sites' ? 'outline' : 'primary'}
                className="widgets__button"
                onClick={() => changePageContent('sites')}
              >
                By sites
              </Button>
            </>
          )}
          <PgFilter
            widgets={pageContent === 'widgets' ? { ...widgets } : null}
            change={pageContent === 'widgets' ? changeWidget : changeSite}
            sites={pageContent === 'sites' ? { ...sites } : null}
            csvName={csvName}
            csvData={csvData}
            changeState={fetchStats}
            getPage={() => chart.getPage('newaudience')}
          />
          {isChartsVisible && displayDefaultCharts && (
            <AudienceGraff
              timeframe={timeframe}
              byHour={byHour}
            />
          )}
          {isChartsVisible && !displayDefaultCharts && (
            <AudienceGraffWidget
              timeframe={timeframe}
              byHour={byHour}
            />
          )}
        </div>
      </div>
      <PgTablePager
        pagerName="newAudienceTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
      <NewAudiencePgTable
        statsData={stats}
        totalsData={totals as DataItems}
        totalItems={totalItems}
        changeState={fetchStats}
        isLoading={loading}
      />
      <PgTablePager
        pagerName="newAudienceTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePageN}
      />
    </div>
  );
};

export default NewAudience;
