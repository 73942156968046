import js from 'common';
import { now, addDayToDate } from './tz';
import { GetChartInfo, GetPage, InitializeStorage } from '../ts/types/utils';

const getChartInfo = (): GetChartInfo => {
  const filterDateTo: string = sessionStorage.getItem('filterDateTo') || '';
  const filterDateFrom: string = sessionStorage.getItem('filterDateFrom') || '';
  const isTimeIntervalToday: boolean = filterDateFrom === now() && filterDateTo === now();
  const isTimeIntervalYesterday: boolean = (filterDateFrom === addDayToDate(-1)) && (filterDateTo === addDayToDate(-1));
  return {
    isChartsVisible: (filterDateFrom !== filterDateTo || isTimeIntervalToday || isTimeIntervalYesterday),
    byHour: isTimeIntervalToday || isTimeIntervalYesterday,
  };
};

const getPage = ((name) => js.D.page(name)) as GetPage;

const initializeStorage: InitializeStorage = (name) => {
  const storageKey: string = `filter${getPage(name).storageKey}`;
  if (!sessionStorage.filterDateGlobal) {
    if (!sessionStorage[storageKey]) {
      sessionStorage[storageKey] = 'Date7days';
      sessionStorage.filterDateGlobal = 'Date7days';
    } else {
      sessionStorage.filterDateGlobal = sessionStorage[storageKey];
    }
  } else {
    sessionStorage[storageKey] = sessionStorage.filterDateGlobal;
  }
  return storageKey;
};

export default { getChartInfo, initializeStorage, getPage };
