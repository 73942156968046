import moment from 'moment';
import numeral from 'numeral';

export const get = (chartData) => {
  let data = [];
  let startDate = 0;
  let xAxistickInterval = 1;
  let tickFactor = 1;
  const chartType = chartData.type !== 'hour' ? 'datetime' : 'category';

  chartData.data.map((item, index) => {
    data.push(item.value);
    if (chartData.type !== 'hour' && index === chartData.data.length - 1) {
      const d = item.keyValue.split('-');
      startDate = Date.UTC(d[0], d[1] - 1, d[2]);
    }

    return data;
  });

  if (chartData.type !== 'hour') {
    tickFactor = 24 * 3600 * 1000;

    if (chartData.data.length === 7) {
      xAxistickInterval = tickFactor;
    } else if (chartData.data.length === 14) {
      xAxistickInterval = 2 * tickFactor;
    } else if (chartData.data.length > 14 && chartData.data.length <= 21) {
      xAxistickInterval = 3 * tickFactor;
    } else if (chartData.data.length > 21 && chartData.data.length < 90) {
      xAxistickInterval = 7 * tickFactor;
    } else if (chartData.data.length >= 90) {
      xAxistickInterval = 30 * tickFactor;
    }

    data = data.reverse();
  }

  return {
    xAxis: {
      categories: null,
      crosshair: {
        color: '#e6e6e6',
        width: 1,
      },
      tickInterval: xAxistickInterval,
      type: chartType,
      lineColor: '#eeeef1',
      gridLineColor: '#eeeef1',
      tickColor: '#eeeef1',
      labels: {
        useHTML: true,
        formatter() {
          if (chartData.type !== 'hour') {
            const day = Number(moment(this.value).format('d'));

            return (
              day === 0 || day === 6
                ? '<span style="color: #d68989;">'
                : '') + moment(this.value).format('MMM D') + (
              day === 0 || day === 6
                ? '</span>'
                : '');
          }
          if (this.value < 10) {
            return `0${this.value}`;
          }
          return this.value;
        },
        style: {
          color: '#b7b6c2',
        },
      },
      dateTimeLabelFormats: {
        day: '%b %e',
        week: '%b %e',
        month: '%b %e',
        year: '%b %e',
      },
      units: [
        ['day', [1, 2, 3, 4]],
        ['week', [1]],
        ['month', [1]],
      ],
    },
    yAxis: {
      title: {
        text: null,
      },
      lineColor: '#eeeef1',
      gridLineColor: '#eeeef1',
      tickColor: '#eeeef1',
      labels: {
        style: {
          color: '#b7b6c2',
        },
        formatter() {
          if (this.value >= 1000 && this.value < 1000000) {
            return `${numeral(this.value / 1000).format('0.[00]')}K`;
          }
          if (this.value >= 1000000) {
            return `${numeral(this.value / 1000000).format('0.[00]')}M`;
          }
          return this.value;
        },
      },
      tickAmount: 4,
      tickPixelInterval: 25,
      min: 0,
    },
    series: [
      {
        data,
        color: chartData.color,
        pointStart: startDate,
        pointInterval: tickFactor,
        fillOpacity: 0.3,
      },
    ],
  };
};

export default { get };
