import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import './App.scss'; // порядок импорта важен (#34746)
import numeral from 'numeral';
import 'numeral/locales/ru';
import config from 'config';
import { metrics } from './utils';
import messages from './messages';
import Drawer from './components/Drawer';
import Header from './components/Header';
import './pages/queries.scss';
import { fetchInfo } from './store/accountInfo/accountInfoSlice';
import { fetchSettings } from './store/accountSettings/accountSettingsSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';

import { Props } from './ts/types/App';
import { AccountInfoState, AppState, Config } from './ts/interfaces';

const { MetricComponent } = metrics;
const { currency } = config as Config;

numeral.locale('ru');
numeral.localeData().currency.symbol = currency || numeral.localeData().currency.symbol;
numeral.localeData().delimiters.decimal = '.';

const App = ({ children }: Props): ReactElement => {
  const location = useLocation();

  const locale: string = localStorage.getItem('language') || 'en';

  const dispatch = useAppDispatch();

  const { isDrawerOpen }: AppState = useAppSelector(reduxState => reduxState.appState);
  const {
    admin,
    sitesList,
    currentSite,
    currentSiteName,
    isConstructorAllowed,
    isConstructorDevModeAllowed,
    lurkCodeDomain,
  }: AccountInfoState = useAppSelector(reduxState => reduxState.accountInfo);

  React.useEffect(() => {
    dispatch(fetchInfo());
    dispatch(fetchSettings());

    /* Для конструктора перезагрузка страницы при смене ориентации или открытии Инспектора (mobile device) создает
      проблемы (сбрасывает все настройки, которые еще не успели сохранить). Поэтому исключим эту возможность. */
    const handleOrientationChange = () => {
      if (!location.pathname.includes('/constructor/')) {
        window.location.reload();
      }
    };
    const onRefresh = (evt: Event) => {
      if (location.pathname.includes('/constructor/')) {
        evt.preventDefault();
      }
    };

    window.addEventListener('beforeunload', onRefresh);
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      window.removeEventListener('beforeunload', onRefresh);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [dispatch]);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      textComponent="span"
    >
      <div
        // eslint-disable-next-line max-len
        className={`outer-container ${isDrawerOpen ? 'outer-container--shown_drawer' : 'outer-container--full outer-container--hidden_drawer'}`}
      >
        <MetricComponent />
        <div>
          {currentSite && (
          <div>
            <Drawer isConstructorAllowed={isConstructorAllowed} />
            <section className="content">
              <Header
                path={location.pathname}
              />
              {React.cloneElement(children, {
                currentSite,
                currentSiteName,
                sitesList,
                isConstructorAllowed,
                isConstructorDevModeAllowed,
                isAdmin: Boolean(admin),
                lurkCodeDomain,
              })}
              <div className="content__overlay" />
            </section>
          </div>
          )}
        </div>
      </div>
    </IntlProvider>
  );
};

export default App;
