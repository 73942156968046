import { Devices, GetDeviceImg } from 'app/ts/types/utils';
import React from 'react';

const Desktop: string = require('assets/images/desktop.svg');
const AndroidTablet: string = require('assets/images/androidtablet.svg');
const AndroidSmartphone: string = require('assets/images/androidsmartphone.svg');
const Ipad: string = require('assets/images/ipad.svg');
const Iphone: string = require('assets/images/iphone.svg');

export const devices: Devices = {
  androidsmartphone: AndroidSmartphone,
  androidtablet: AndroidTablet,
  desktop: Desktop,
  ipad: Ipad,
  iphone: Iphone,
  windowstablet: AndroidTablet,
  windowssmartphone: AndroidSmartphone,
};

export const getDeviceImg: GetDeviceImg = (deviceName) => {
  let device = deviceName.toLowerCase().replace(/\s/g, '');
  if (Object.keys(devices).indexOf(device) < 0) device = 'other';
  return (
    <img src={devices[device]} alt={deviceName} />
  );
};

export default { getDeviceImg };
