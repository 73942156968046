import { invert } from 'lodash';
import { AppToServerParamsMap,
  FromServerData,
  ReplaceParams,
  SortData,
  ToServerData,
  TotalsData,
} from '../ts/types/utils';

export const appToServerParamsMap: AppToServerParamsMap = {
  adShows: 'ad_shows',
  audiencePageViews: 'audience_pageviews',
  engagementClicks: 'engagement_clicks',
  engagementCtr: 'engagement_ctr',
  engagementPageViews: 'engagement_pageviews',
  exchangeRatio: 'exchange_ratio',
  inClicks: 'in_clicks',
  itemAuthor: 'item_author',
  itemImage: 'item_image',
  itemPublished: 'item_published',
  itemTitle: 'item_title',
  itemUrl: 'item_url',
  outAdShows: 'out_ad_shows',
  outClicks: 'out_clicks',
  outCtr: 'out_ctr',
  outPageViews: 'out_pageviews',
  outPageViewsOfTotal: 'out_pageviews_of_total',
  outWctr: 'out_wctr',
  pageViews: 'pageviews',
  pageViewsOfTotal: 'pageviews_of_total',
  pageViewsPerVisit: 'pv_visits',
  readingDepth: 'reading_depth',
  contentReadingDepth: 'content_reading_depth',
  readingTime: 'pv_time',
  contentReadingTime: 'content_pv_time',
  revenuePageViews: 'revenue_pageviews',
  totalPageViews: 'total_pageviews',
  viewsAfter: 'viewafter',
  widgetViews: 'widget_views',
  widgetViewsOfTotal: 'widget_views_of_total',
  isPromo: 'is_promo',
  isAdblockPromo: 'is_adblock_promo',
  isNew: 'is_new',
  sharesPerMile: 'shares_pv',
  socialClicks: 'social_clicks',
  socialClicksPerShare: 'social_clicks_shares',
  authorId: 'author_id',
  codeName: 'code_name',
  widgets: 'widgets',
  description: 'description',
  paymentSum: 'amount',
  paymentDate: 'created',
};

export const serverToAppParamsMap: AppToServerParamsMap = invert(appToServerParamsMap);

export const replaceParams: ReplaceParams = (data, paramsMap) => {
  const result: TotalsData = {};

  Object.keys(data).forEach((key: string) => result[paramsMap[key] || key] = data[key]);

  return result;
};

export const toServerData: ToServerData = data => replaceParams(data, appToServerParamsMap);

export const fromServerData: FromServerData = data => replaceParams(data, serverToAppParamsMap);

export const sortData: SortData = (data, key) => data.sort((a, b) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (b[key] > a[key]) {
    return 1;
  }
  return 0;
});

export default { appToServerParamsMap, serverToAppParamsMap, replaceParams, toServerData, fromServerData, sortData };
