import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, TextArea } from 'uikit';
import { FormattedMessage } from 'react-intl';
import './ExternalAdsForm.scss';

const ExternalAdsForm = ({ data: { id, name, description, code }, submit, isVisibleCancelBtn, onCancelHandler }) => {
  const codeId = `code_${id}`;

  return (
    <form action="" className="form form--external-ads" onSubmit={submit}>
      <div className="form__group">
        <TextField
          label={<FormattedMessage id="form.codeName" />}
          key={codeId}
          name="code_name"
          defaultValue={name}
          type="text"
          required
        />
      </div>
      <div className="form__group">
        <TextField
          key={codeId}
          label={<FormattedMessage id="form.description" />}
          name="description"
          defaultValue={description}
          type="text"
          required
        />
      </div>
      <div className="form__group">
        <TextArea
          className="form--external-ads__code_edit"
          key={codeId}
          label={<FormattedMessage id="form.code" />}
          name="code"
          defaultValue={code}
          required
          style={{ resize: 'vertical' }}
        />
      </div>
      <div className="form__group actions_wrapper">
        <Button type="submit" size="l" kind="primary">Apply</Button>
        { isVisibleCancelBtn && <Button size="l" kind="outline" onClick={onCancelHandler}>Cancel</Button> }
      </div>
    </form>
  );
};

ExternalAdsForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    code: PropTypes.string,
    id: PropTypes.number,
  }),
  onCancelHandler: PropTypes.func,
  submit: PropTypes.func.isRequired,
  isVisibleCancelBtn: PropTypes.bool.isRequired,
};

ExternalAdsForm.defaultProps = {
  data: {
    name: '',
    description: '',
    code: '',
    id: 0,
  },
  onCancelHandler: () => {},
};

export default ExternalAdsForm;
