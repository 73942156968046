import { Get, Set } from 'app/ts/types/utils';

export const get: Get = (key, defaultValue) => {
  const val = localStorage[key];
  if (typeof val === 'undefined') {
    return defaultValue;
  }
  return /[{[][^}]+[}\]]/.test(val) ? JSON.parse(val) : val;
};

export const set: Set = (key, val) => {
  localStorage[key] = JSON.stringify(val);
};

export default { get, set };
