import * as React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Tooltip } from 'uikit';
import { FormattedMessage } from 'react-intl';
import { format } from '../../utils';

const AudienceEngagement = ({ engagement }) => {
  const className = 'info-block__value-dif info-block__value-dif--board';
  return (
    <div className="board__content board__table">
      <div className="info-block info-block--board">
        <div className="info-block__value">{format.percentage(engagement.ctr)}</div>
        <div
          className={engagement.ctr_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(engagement.ctr_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.audience_engagement_ctr" />
                </span>
              )}
              placement="bottom"
            >
              <span>CTR</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info-block info-block--board">
        <div className="info-block__value">{format.percentage(engagement.wctr)}</div>
        <div
          className={engagement.wctr_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(engagement.wctr_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.audience_engagement_wctr" />
                </span>
              )}
              placement="bottom"
            >
              <span>WCTR</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info-block info-block--board">
        <div className="info-block__value">
          {typeof (engagement.clicks) === 'number' ? numeral(engagement.clicks).format() : 0}
        </div>
        <div
          className={engagement.clicks_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(engagement.clicks_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.audience_engagement_clicks" />
                </span>
              )}
              placement="bottom"
            >
              <span>Clicks</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info-block info-block--board">
        <div className="info-block__value">{format.percentage(engagement.pv_increase)}</div>
        <div
          className={engagement.pv_increase_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(engagement.pv_increase_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.audience_engagement_page_views_increase" />
                </span>
              )}
              placement="bottom"
            >
              <span>Page views increase</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

AudienceEngagement.propTypes = {
  engagement: PropTypes.object.isRequired,
};

export default AudienceEngagement;
