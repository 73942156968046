import * as React from 'react';
import PropTypes from 'prop-types';

const ChartLegend = ({ legend, groups, clickHandler, legendDisabled }) => {
  const groupArray = groups.slice(0, groups.length);

  for (const i in groupArray) {
    if (groupArray[i]) {
      groupArray[i].items = [];
    }
  }

  for (const i in legend) {
    if (legend[i]) {
      if (groupArray.length === 0) {
        groupArray.push({
          items: [],
        });
      }
      groupArray[legend[i].group || 0].items.push(legend[i]);
    }
  }

  let seriesCounter = -1;
  return (
    <div className="row">
      {
        groupArray.map(group => (
          <div
            key={`${group.title || 'default'}-${seriesCounter}`}
            className={`col-xs-${group.title ? 4 : 12} col-md-12 legend__groupe`}
          >
            <div className="legend__title">{group.title || ''}</div>
            {
              group.items.map(item => (
                <div
                  key={item.field}
                  data-series={seriesCounter += 1}
                  className={`legend__item ${legendDisabled[seriesCounter] && 'legend__item--disabled'}`}
                  onClick={clickHandler.bind(this, seriesCounter)}
                >
                  <div className="legend__marker" style={{ backgroundColor: item.color }} />
                  <div className="legend__text">{item.title}</div>
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  );
};

ChartLegend.propTypes = {
  legend: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  clickHandler: PropTypes.func.isRequired,
  legendDisabled: PropTypes.array.isRequired,
};

export default ChartLegend;
