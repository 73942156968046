import { ls, regions } from 'utils';
import moment from 'moment';
import numeral from 'numeral';

export const get = (chartData, data, keyDisabledLegends) => (
  {
    tooltip: {
      hideDelay: 0,
      shared: true,
      useHTML: true,
      borderWidth: 0,
      shadow: false,
      backgroundColor: 'rgba(255,255,255,0)',
      headerFormat: '',
      shape: false,
      style: {
        color: '#c0c0cc',
        fontSize: '11px',
        fontFamily: "'Roboto', Helvetica, Arial, sans-serif",
        backgroundColor: '#30303d',
      },
      positioner(labelWidth, labelHeight, point) {
        const tooltipX = point.plotX + this.chart.plotLeft + (
          point.plotX > this.chart.plotWidth / 2
            ? -labelWidth - 5 : 5);
        return { x: tooltipX, y: 5 };
      },
      formatter() {
        let xVal = '';
        let hoveredPoint = '';

        // Определим заголовок тултипа в зависимости от типа графика и точки, на которую навел пользователь
        if (chartData.type === 'date') {
          const date = moment(this.x);
          xVal = `${date.format('YYYY MMMM DD')}
            <div class="ctooltip__day">${date.format('dddd')}</div>`;
          hoveredPoint = date.format('YYYY-MM-DD');
        } else if (chartData.type === 'hour') {
          hoveredPoint = this.x;
          // Если количество часов меньше 10, то доставим 0 перед цифрой
          xVal = hoveredPoint > 9 ? `${hoveredPoint}:00` : `0${hoveredPoint}:00`;
        } else if (chartData.type === 'country') {
          hoveredPoint = this.x;
          xVal = regions.getRegion(this.x);
        } else {
          hoveredPoint = this.x;
          xVal = `${hoveredPoint}`;
        }

        let text = `<div class="ctooltip__time">${xVal}</div>`;

        // Проверяем, какие данные показывать в тултипе
        const hiddenCharts = ls.get(keyDisabledLegends, []);
        data.map((item, index) => {
          if (item && !hiddenCharts[index]) {
            text += `${item.title}: `;
            let { value } = item.data.filter(el => el.keyValue === hoveredPoint)[0] || {};
            value = numeral(value).format('0.[00]');
            value += (
              item.suffix
                ? item.suffix : ''
            );
            text += `<span style="color:${item.color};">
              ${value}</span></span><br>`;
          }
          return text;
        });
        return text;
      },
      valueDecimals: chartData.valueDecimals,
      suffix: chartData.suffix,
    },
  }
);

export default { get };
