import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import nouislider from 'nouislider';

import { metrics, ajax } from 'utils';
import {
  getStrokeDashValues,
  getPerformanceValues,
  getActiveSliderState,
  getNextSliderState,
  getFixedSliderState,
  getSliderOldValue,
  getSliderNextOldValue,
  pieMax,
} from './helpers';

import 'nouislider/distribute/nouislider.css';
import './SetPerformance.scss';
import PerformanceSlider from './PerformanceSlider';
import SliderPieChart from './SliderPieChart';

const SetPerformance = ({ currentSiteName, currentSite, isAdmin, performanceSliders }) => {
  let blockPerf = useRef();
  const slider = {
    parent: {
      newAudience: useRef(), revenue: useRef(), engagement: useRef(),
    },
    children: {
      newAudience: useRef(), revenue: useRef(), engagement: useRef(),
    },
  };

  const [slideLock, setSlideLock] = useState('');
  const [values, setValues] = useState({
    newAudience: 0, revenue: 0, engagement: 0,
  });
  const [applyButtonActive, setApplyButtonActive] = useState(false);
  const [saveSettings, setSaveSettings] = useState(false);
  const [prevCurrentSite, setPrevCurrentSite] = useState(currentSite);

  const setSliderLimits = (currentSlider, nextSlider, limitValue) => {
    nextSlider.updateOptions({
      limit: +limitValue,
      step: 1,
      range: {
        min: [0],
        max: [100],
      },
    });
    currentSlider.updateOptions({
      limit: +limitValue,
      step: 1,
      range: {
        min: [0],
        max: [100],
      },
    });
  };
  const changeViews = ({ name: sliderName, value: sliderValue }, event) => {
    const slideLockName = JSON.parse(localStorage.getItem('settingsLock'))?.name;
    const { parent } = slider;
    const { activeSlide, activeSlider, activeValue } = getActiveSliderState({ sliderName, sliderValue, event, parent });
    const { nextSlide, nextValue, nextSlider } = getNextSliderState({ sliderName, parent, slideLock: slideLockName });
    const { fixedSlide, fixedSlider } = getFixedSliderState({ sliderName, slideLock: slideLockName, parent });
    const fixedSlideValue = Number(slider.parent[fixedSlide].querySelector('.psettings__input').value);
    const oldValue = getSliderOldValue(performanceSliders, activeSlide, activeValue);
    const oldValueNext = getSliderNextOldValue(performanceSliders, nextSlide, nextValue);
    const performanceValues = getPerformanceValues({
      activeSlide,
      nextSlide,
      fixedSlide,
      activeValue,
      oldValueNext,
      oldValue,
      slideLock: slideLockName,
      value: fixedSlideValue,
    });

    setSliderLimits(activeSlider, nextSlider, slideLockName ? performanceValues[nextSlide] + activeValue : 100);
    if (event.type !== 'start') {
      if (nextSlide !== slideLockName) { nextSlider.set([0, performanceValues[nextSlide]]); }
      if (fixedSlide !== slideLockName) fixedSlider.set([0, performanceValues[fixedSlide]]);
      setValues(performanceValues);
      setApplyButtonActive(true);
    }
    if (/change|click/.test(event.type)) activeSlider.set([0, performanceValues[activeSlide]]);
    return true;
  };

  const setValuesExternal = (h, init) => { // {type, val}, init
    for (const sliderName in h) {
      if (performanceSliders.find(el => el.name === sliderName)) { // проверяем допустимость типа
        const sliderBar = slider.children[sliderName]; // бар слайдера
        if (!init) {
          sliderBar.noUiSlider.set([0, h[sliderName]]);
        } else {
          nouislider.create(sliderBar, {
            // создать слайдер по данным; в .noUiSlider - on, set, options{}, pips, target, steps, updateOptions
            start: [0, h[sliderName]],
            connect: true,
            step: 1,
            range: {
              min: [0],
              max: [100],
            },
            limit: 100,
            format: {
              to(val) {
                return val;
              },
              from(val) {
                return val;
              },
            },
          });
          const { noUiSlider } = sliderBar;
          const handleSlide = name => (slideValues, handle) => {
            changeViews({ name, value: slideValues[handle] }, { type: 'slide' });
          };
          const handleStart = name => (slideValues, handle) => {
            changeViews({ name, value: slideValues[handle] }, { type: 'start' });
          };
          noUiSlider.on('slide', handleSlide(sliderName));
          noUiSlider.on('start', handleStart(sliderName));
        }
      }
    }
  };

  const readValues = (init) => {
    ajax.get('/settings/performance').then(({ data }) => {
      if (data) {
        const { performance: perf } = data;
        const nextValues = {
          newAudience: Number(perf.new_audience),
          revenue: Number(perf.revenue),
          engagement: Number(perf.engagement),
        };
        setValues(nextValues);
        setValuesExternal(nextValues, init);
      }
    });
  };

  const jumpSetting = (ev) => {
    const { parent } = slider;
    const sliderName = ev.currentTarget.getAttribute('data-slider_name');
    const sliderOver = parent[sliderName];
    const isInTitleOrHandle = ev.target.closest('.psettings__slider-header, .noUi-handle');
    const slideLeftPos = blockPerf.offsetLeft + sliderOver.offsetLeft;
    const mouseLeft = ev.pageX - slideLeftPos;
    const sliderW = sliderOver.querySelector('.slider').offsetWidth;
    const percent = Math.floor((100 * mouseLeft) / sliderW); // показание слайдера, которое надо установить
    if (isInTitleOrHandle || percent > 102) return;
    // выбирается клик на слайдере или чуть правее, до границы поля ввода (зависит от вёрстки!)
    if (slideLock !== sliderName) {
      changeViews({ name: sliderName, value: Math.min(100, percent) }, ev);
    }
  };

  useEffect(() => {
    readValues('init');
    // Если в localStorage есть замок, который был ранее сохранен, то устанавливаем его состояние в lock
    if (localStorage.getItem('settingsLock') !== '' && localStorage.getItem('settingsLock') != null) {
      const lock = JSON.parse(localStorage.getItem('settingsLock'));
      setSlideLock(lock.name);
    }
    metrics.metricEvent('hit', '/settings/performance');
    metrics.metricEvent('reachGoal', 'OPEN_PERFORMANCE', {
      admin: isAdmin,
      siteName: currentSiteName,
    });
  }, []);

  useEffect(() => {
    if (prevCurrentSite !== currentSite) {
      setPrevCurrentSite(currentSite);
      readValues();
    }
  }, [currentSite]);

  const submitSettings = () => { // Ajax
    const { engagement, newAudience, revenue } = values;
    // Если замок установлен, то сохранить его в localStorage
    if (slideLock !== '') {
      const { [slideLock]: value } = values;
      localStorage.setItem('settingsLock', JSON.stringify({ name: slideLock, value }));
    } else {
      localStorage.setItem('settingsLock', '');
    }
    const params = {
      engagement,
      new_audience: newAudience,
      revenue,
      action: 'save',
    };
    ajax.get('/settings/performance', { params }).then(({ data }) => {
      if (data && data.success) {
        setApplyButtonActive(false);
        setTimeout(() => setSaveSettings(true), 500);
        setTimeout(() => setSaveSettings(false), 2000);
      }
    });
  };

  const getSliderValue = (currentSlider) => {
    const { [currentSlider.name]: value } = values;
    if (slideLock !== currentSlider.name) {
      // eslint-disable-next-line no-param-reassign
      currentSlider.oldValue = value;
    }
    return value;
  };

  const setRefs = (name, key, ref) => {
    slider[key][name] = ref;
  };

  const inputLock = (nextSideLock) => {
    const nextSlideLockValue = slideLock === nextSideLock ? '' : nextSideLock;
    setSlideLock(nextSlideLockValue);
    const { [nextSideLock]: value } = values;
    localStorage.setItem('settingsLock', JSON.stringify({
      name: nextSlideLockValue && nextSideLock, value,
    }));
  };
  const { engagement, newAudience, revenue } = values;
  const sda = getStrokeDashValues({ engagement, newAudience, revenue });
  return (
    <div className="wrapper wrapper--dashboard">
      <div className="container-fluid performance-page">
        <div className="row">
          <div className="col-lg-12 js-blockPerf" ref={ref => blockPerf = ref}>
            <div className="pie-chart pie-chart--performance">
              <svg width="224" height="224" className="pie-chart__part">
                <circle
                  className="pie-chart__part-blue"
                  r="87"
                  cx="112"
                  cy="112"
                  strokeDasharray={engagement !== 0 ? `${sda[0]} ${pieMax}` : `0 ${pieMax}`}
                />
                <circle
                  className="pie-chart__part-orange"
                  r="87"
                  cx="112"
                  cy="112"
                  strokeDasharray={newAudience !== 0 ? `${sda[1]} ${pieMax}` : `0 ${pieMax}`}
                  strokeDashoffset={-sda[0]}
                />
                <circle
                  className="pie-chart__part-green"
                  r="87"
                  cx="112"
                  cy="112"
                  strokeDasharray={revenue !== 0 ? `${sda[2]} ${pieMax}` : `0 ${pieMax}`}
                  strokeDashoffset={-sda[0] - sda[1]}
                />
              </svg>
              <SliderPieChart
                engagement={engagement}
                newAudience={newAudience}
                revenue={revenue}
              />
            </div>
            <div className="psettings">
              {performanceSliders.map((currentSlider, index) => (
                <PerformanceSlider
                  key={currentSlider.name}
                  slider={currentSlider}
                  index={index}
                  slideLock={slideLock}
                  jumpSetting={jumpSetting}
                  value={getSliderValue(currentSlider)}
                  changeViews={changeViews}
                  inputLock={inputLock}
                  setRefs={setRefs}
                />
              ))}
              <div className="psettings__slider clearfix">
                <input
                  type="button"
                  onClick={submitSettings}
                  value="Apply"
                  className={`psettings__submit ${!applyButtonActive ? 'psettings__submit--disabled' : ''}`}
                />
                <span
                  className="savedset"
                  style={
                    saveSettings
                      ? { display: window.innerWidth <= 480 ? 'block' : 'inline-block' }
                      : { display: 'none' }
                  }
                >
                  Settings are saved
                </span>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPerformance;

SetPerformance.propTypes = {
  currentSite: PropTypes.number.isRequired,
  currentSiteName: PropTypes.string.isRequired,
  performanceSliders: PropTypes.array,
  isAdmin: PropTypes.bool.isRequired,
};

SetPerformance.defaultProps = {
  performanceSliders: [{ // Данные по слайдерам для их генерации
    title: 'Audience engagement',
    name: 'engagement',
  }, {
    title: 'New audience',
    name: 'newAudience',
  }, {
    title: 'Revenue',
    name: 'revenue',
  }],
};
