/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';
import cx from 'classnames';

export default (overrideProps, totals) => ({
  accessor: 'outPageViews',
  resizable: false,
  style: { paddingLeft: '40px' },
  Header: (
    <FormattedMessage id="table.outPageViews">
      {txt => (
        <p className="cell-inner na-page-views">
          {SortIcon} {txt[0].split(' ').slice(0, 2).join(' ')}<br />{txt[0].split(' ').slice(2).join(' ')}
        </p>
      )}
    </FormattedMessage>
  ),
  Footer: (
    <span
      className={cx('cell-inner', { 'cell-inner__with-small': totals.outPageViewsOfTotal !== undefined })}
      title={formatUtils.integer(totals.outPageViews)}
    >
      {formatUtils.integer(totals.outPageViews)}
      {totals.outPageViewsOfTotal !== undefined && (
        <span className="cell-inner-small">
          {formatUtils.percentage(totals.outPageViewsOfTotal)} of total
        </span>
      )}
    </span>
  ),
  Cell: props => (
    <span
      className={cx('cell-inner', { 'cell-inner__with-small': totals.outPageViewsOfTotal !== undefined })}
      title={formatUtils.integer(props.value)}
    >
      {formatUtils.integer(props.value)}
      {totals.outPageViewsOfTotal !== undefined && (
        <span className="cell-inner-small">
          {formatUtils.percentage(props.original.outPageViewsOfTotal)} of total
        </span>
      )}
    </span>
  ),
  ...overrideProps,
});
