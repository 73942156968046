import * as React from 'react';
import PropTypes from 'prop-types';
import js from 'common';
import { Link } from 'react-router-dom';
import AudienceEngagement from './AudienceEngagement';
import NewAudience from './NewAudience';
import Revenue from './Revenue';
import Content from './Content';
import Social from './Social';

const PerformanceWidgets = (props) => {
  const { statsData } = props;

  if (statsData) {
    return (
      <div className="wrapper pb60">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12">
              <div className="page__row-title">Giraff.io performance</div>
            </div>
          </div>
          <div className="row page__content">
            <div className="col-lg-4 col-md-6 col-xs-12">
              <Link className="board__link" to={js.D.pages.engagement.urlPage}>
                <div className="board__header">Audience engagement</div>
                <AudienceEngagement engagement={statsData.engagement} />
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <Link className="board__link" to={js.D.pages.newaudience.urlPage}>
                <div className="board__header">New audience</div>
                <NewAudience audience={statsData.audience} />
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <Link className="board__link" to={js.D.pages.revenue.urlPage}>
                <div className="board__header">Revenue</div>
                <Revenue revenue={statsData.revenue} />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="page__row-title performance">Editorial performance</div>
            </div>
          </div>
          <div className="row page__content">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link className="board__link" to={js.D.pages.content.urlPage}>
                <div className="board__header">Content</div>
                <Content content={statsData.content} />
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link className="board__link" to={js.D.pages.social.urlPage}>
                <div className="board__header">Social</div>
                <Social social={statsData.social} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

PerformanceWidgets.propTypes = {
  statsData: PropTypes.object.isRequired,
};

export default PerformanceWidgets;
