/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';

export default (overrideProps, totals) => ({
  accessor: 'revenue',
  resizable: false,
  Header: (
    <FormattedMessage id="table.revenue">
      {txt => <p className="cell-inner revenue">{SortIcon} {txt}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <span className="cell-inner" title={formatUtils.money(totals.revenue)}>{formatUtils.money(totals.revenue)}</span>
  ),
  Cell: props => (
    <span className="cell-inner" title={formatUtils.money(props.value)}>{formatUtils.money(props.value)}</span>
  ),
  ...overrideProps,
});
