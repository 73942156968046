import * as React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'uikit';
import { format } from '../../utils';

const Social = (props) => {
  const className = 'info-block__value-dif info-block__value-dif--board';
  const { social } = props;
  return (
    <div className="board__content">
      <div className="info-block info-block--board">
        <div className="info-block__value">{
          typeof (social.shares_to_pv) === 'number' ? numeral(social.shares_to_pv).format('0.[00]') : 0
}
        </div>
        <div
          className={social.shares_to_pv_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(social.shares_to_pv_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.social_shares_per_mille" />
                </span>
              )}
              placement="bottom"
            >
              <span>Shares per mille</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info-block info-block--board">
        <div className="info-block__value">
          { typeof (social.social_click_to_shares) === 'number' ?
            numeral(social.social_click_to_shares).format('0.[00]') : 0 }
        </div>
        <div className={social.social_click_to_shares_inc >= 0 ?
          className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(social.social_click_to_shares_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--b">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.social_clicks_per_share" />
                </span>
              )}
              placement="bottom"
            >
              <span> Social clicks per share</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

Social.propTypes = {
  social: PropTypes.object.isRequired,
};

export default Social;
