/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';

export default overrideProps => ({
  accessor: 'itemTitle',
  resizable: false,
  Header: (
    <FormattedMessage id="table.contentItem">
      {txt => <p className="cell-inner">{txt} {SortIcon}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <FormattedMessage id="table.total">{txt => <span className="cell-inner">{txt}</span>}</FormattedMessage>
  ),
  Cell: props => (
    <span className="cell-inner image-status" title={props.value}>
      <span className="image-holder"><img src={props.original.itemImage} alt={props.value} /></span>
      {props.original.isNew && (
        props.original.status === 'ERROR' ? (
          <div className="image-status__error">
            <span
              className="image-status__error-text tooltip tooltip--own-error"
              data-tooltip={props.original.statusResult
                ? props.original.statusResult.error_message
                : 'Unknown error'}
            >
              Error
            </span>
          </div>
        ) : (
          <div className="image-status__new">
            <span className="image-status__new-text">new</span>
          </div>
        ))}
      <a target="_blank" rel="noopener noreferrer" href={props.original.itemUrl}>{props.value}</a>
    </span>
  ),
  ...overrideProps,
});
