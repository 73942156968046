import React, { ReactElement } from 'react';
import { DatePicker } from 'uikit';
import moment from 'moment';
import { tz } from 'app/utils';

import './FilterPublicDate.scss';
import { FilterPublicDateProps } from 'app/ts/types/FilterPublicDate';
import { DateIntervalProps } from 'app/ts/types/DateIntervalProps';

const maxDate: string = tz.now();
const defaultStartDate: Date = new Date();
const defaultEndDate: Date = new Date();
defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
const mask: Array<string | RegExp> = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

const FilterPublicDate = (props: FilterPublicDateProps): ReactElement => {
  const {
    responsiveFlag = 'desktop',
    selectInpPubFrom = () => { },
    selectInpPubTo = () => { },
    handleDateIntervalPub = () => { },
    data_pubdate_from: dataPubdateFrom = '',
    data_pubdate_to: dataPubdateTo = '',
  } = props;

  return (
    <>
      {responsiveFlag !== 'mobile' && (
        <div>
          <div className="dropdown pub open">
            <div
              aria-labelledby="ddcal-pub"
            >
              <DatePicker
                applyDatePicker={({ startDate, endDate }: DateIntervalProps) =>
                  handleDateIntervalPub({ startDate, endDate })}
                applyText="Select"
                placeholder="dd.mm.yyyy"
                displayFormat="DD.MM.YYYY"
                daySize={40}
                initialStartDate={dataPubdateFrom ?
                  moment(dataPubdateFrom, 'YYYY-MM-DD') :
                  moment(defaultStartDate, 'YYYY-MM-DD')}
                initialEndDate={dataPubdateTo ?
                  moment(dataPubdateTo, 'YYYY-MM-DD') :
                  moment(defaultEndDate, 'YYYY-MM-DD')}
                mask={mask}
              />
            </div>
          </div>
        </div>
      )}
      {responsiveFlag === 'mobile' && (
        <div>
          <div className="modal-fmenu__item-submenu mobile-pubdate">
            <input
              onChange={selectInpPubFrom}
              id="otherPeriodPubCalendar-beg"
              type="date"
              name="otherPeriodPubCalendar-beg"
              data-date="From"
              min=""
              max={maxDate}
              className="socialf__link socialf__input socialf__input--beg"
            />
            <div className="socialf__sepr">-</div>
            <input
              onChange={selectInpPubTo}
              id="otherPeriodPubCalendar-end"
              type="date"
              name="otherPeriodPubCalendar-end"
              data-date="To"
              min=""
              max={maxDate}
              className="socialf__link socialf__input socialf__input--end"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FilterPublicDate;
