import React, { BaseSyntheticEvent, ReactElement } from 'react';
import { TextField } from 'uikit';
import config from 'config';

import './FilterFromTo.scss';

import { Current, FilterFromToProps, FilterFromToState } from 'app/ts/types/FilterFromTo';
import { metricFilterTypes } from 'app/utils';

const { PAGE_VIEWS, READING_DEPTH, AVERAGE_READING_TIME, RPM, REVENUE } = metricFilterTypes;

const FilterFromTo = (props: FilterFromToProps): ReactElement => {
  const { store: propsStore = null, onChange, filter } = props;
  const [state, setState] = React.useState<FilterFromToState>({
    store: null,
    beginValue: 0,
    endValue: 0,
    error: false,
  });

  const { store, beginValue, endValue, error } = state;
  const beginNumber: number = Number(beginValue.toString(10));
  const endNumber: number = Number(endValue.toString(10));

  let word: string = '';
  switch (filter) {
    case READING_DEPTH:
      word = '%';
      break;
    case AVERAGE_READING_TIME:
      word = 'min';
      break;
    case REVENUE:
      word = config.currency;
      break;
    case RPM:
      word = config.currency;
      break;
    default:
      break;
  }

  React.useEffect(() => {
    if (propsStore !== store) {
      const obj: Array<Current> = JSON.parse(propsStore);
      const current: Array<Current> = obj.filter((item: Current) => item.label === filter);

      if (current.length > 0) {
        setState(prevState => ({
          ...prevState,
          store: propsStore,
          beginValue: current[0].object.from,
          endValue: current[0].object.to,
        }));
      }
    }
  }, [propsStore, filter, store]);

  const chooseInterval = (event: BaseSyntheticEvent): void => {
    event.preventDefault();

    if (
      (beginValue !== '' && endValue === '') ||
      (beginValue === '' && endValue !== '') ||
      (endValue !== '' && beginValue !== '' && endNumber >= beginNumber)
    ) {
      setState(prevState => ({
        ...prevState,
        error: false,
      }));
      onChange(filter, beginNumber, endNumber);
    } else {
      setState(prevState => ({
        ...prevState,
        error: true,
      }));
    }
  };

  const handleChangeBegin = (event: BaseSyntheticEvent): void => {
    setState(prevState => ({
      ...prevState,
      beginValue: event.target.validity.valid ? event.target.value : '',
    }));
  };

  const handleChangeEnd = (event: BaseSyntheticEvent): void => {
    setState(prevState => ({
      ...prevState,
      endValue: event.target.validity.valid ? event.target.value : '',
    }));
  };

  return (
    <div className="filterFromTo">
      <form
        action=""
        onSubmit={chooseInterval}
        className="widgetFromTo"
      >
        <div className="form__row tooltip--bottom">
          <TextField
            type="number"
            step={filter !== PAGE_VIEWS ? '0.01' : '1'}
            name="data-begin"
            placeholder="From"
            autoComplete="none"
            min="0"
            max={filter === READING_DEPTH ? '100' : null}
            defaultValue={beginNumber === 0 ? '' : beginNumber}
            onChange={handleChangeBegin}
            elementClassName="widgetFromTo__data-input--begin"
            invalid={error}
          />
          <span data-text="From" />
          <span className="measure">{word}</span>
        </div>
        <div className="widgetFromTo__iseparator">-</div>
        <div className="form__row tooltip--bottom">
          <TextField
            type="number"
            step={filter !== PAGE_VIEWS ? '0.01' : '1'}
            name="data-end"
            placeholder="To"
            autoComplete="none"
            min="0"
            max={filter === READING_DEPTH ? '100' : null}
            defaultValue={endNumber === 0 ? '' : endNumber}
            onChange={handleChangeEnd}
            elementClassName="widgetFromTo__data-input--end"
            invalid={error}
          />
          <span data-text="To" />
          <span className="measure">{word}</span>
        </div>
        <input
          type="submit"
          value="Select"
          className="widgetFromTo__submit"
        />
      </form>
    </div>
  );
};

export default FilterFromTo;
