import * as React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import columns from 'components/tables/columns';
import { stats as statsUtils } from 'utils';
import './PerformanceReport.scss';

const PerformanceReport = ({ statsData, totalsData, totalItems, changeState, isLoading }) => {
  const getColumnsConfig = () => {
    const totals = statsUtils.fromServerData(totalsData);

    return [
      columns.referer({ minWidth: 140 }, totals),
      columns.visits({ maxWidth: 120 }, totals),
      columns.pageViews({ maxWidth: 120 }, totals),
      columns.pageViewsPerVisit({ maxWidth: 110 }, totals),
      columns.readingDepthSimple({ maxWidth: 110 }, totals),
      columns.readingTimeSimple({ maxWidth: 120 }, totals),
      columns.rpm({ maxWidth: 110 }, totals),
      columns.revenueGiraff({ maxWidth: 120 }, totals),
    ];
  };

  return (
    <div className="performance-report-pg-table">
      <Table
        columns={getColumnsConfig()}
        data={statsData.map(statsUtils.fromServerData)}
        dataCount={totalItems}
        changeState={changeState}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PerformanceReport;

PerformanceReport.propTypes = {
  statsData: PropTypes.array.isRequired,
  totalsData: PropTypes.object.isRequired,
  totalItems: PropTypes.number.isRequired,
  changeState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
