import accountInfoReducer from 'app/store/accountInfo/accountInfoSlice';
import accountSettingsReducer from 'app/store/accountSettings/accountSettingsSlice';
import appStateReducer from 'app/store/appState/appStateSlice';

const rootReducer = {
  accountInfo: accountInfoReducer,
  accountSettings: accountSettingsReducer,
  appState: appStateReducer,
};

export default rootReducer;
