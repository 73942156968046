import React from 'react';
import PropTypes from 'prop-types';
import { getPercentages } from './helper';
import './Indicator.scss';

const Indicator = ({ engagementPageViews, audiencePageViews, revenuePageViews }) => {
  const { engagementPercentage, audiencePercentage, revenuePercentage } =
    getPercentages({ engagementPageViews, audiencePageViews, revenuePageViews });
  return (
    <div className="indicator-bar2">
      <i
        style={{ width: `${Math.round(engagementPercentage)}%` }}
        className="indicator-bar2__graph indicator-bar2__graph--blue"
      />
      <i
        style={{ width: `${Math.round(audiencePercentage)}%` }}
        className="indicator-bar2__graph indicator-bar2__graph--yellow"
      />
      <i
        style={{ width: `${Math.round(revenuePercentage)}%` }}
        className="indicator-bar2__graph indicator-bar2__graph--green"
      />
    </div>
  );
};

export default Indicator;

Indicator.propTypes = {
  engagementPageViews: PropTypes.number,
  audiencePageViews: PropTypes.number,
  revenuePageViews: PropTypes.number,
};

Indicator.defaultProps = {
  engagementPageViews: 0,
  audiencePageViews: 0,
  revenuePageViews: 0,
};
