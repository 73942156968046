import * as React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Tooltip } from 'uikit';
import { FormattedMessage } from 'react-intl';
import { format } from '../../utils';

const NewAudience = (props) => {
  const className = 'info-block__value-dif info-block__value-dif--board';
  const classNameInline = 'info-block__value-dif info-block__value-dif--inline';
  const { audience } = props;
  return (
    <div className="board__content">
      <div className="info-block info-block--board">
        <div className="info-block__value">
          {typeof (audience.in_clicks) === 'number' ? numeral(audience.in_clicks).format() : 0}
        </div>
        <div
          className={audience.in_clicks_inc >= 0 ? className : `${className} info-block__value-dif--negative`}
        >
          { format.percentage(audience.in_clicks_inc) }
        </div>
        <div className="clearfix" />
        <div className="info-block__title info-block__title--inline-board info-block__title--inline-bb">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.new_audience_incoming_clicks" />
                </span>
              )}
              placement="bottom"
            >
              <span>Incoming clicks</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info--inline info-block info-block--inline">
        <div className="info-block__value">
          {typeof (audience.out_clicks) === 'number' ? numeral(audience.out_clicks).format() : 0}
        </div>
        <div
          className={audience.out_clicks_inc >= 0 ?
            classNameInline : `${classNameInline} info-block__value-dif--negative`}
        >
          { format.percentage(audience.out_clicks_inc) }
        </div>
        <div className="info-block__title info-block__title--inline">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.new_audience_outgoing_clicks" />
                </span>
              )}
              placement="bottom"
            >
              <span>Outgoing clicks</span>
            </Tooltip>
          </div>
        </div>
        <div className="clearfix" />
      </div>
      <div className="info--inline info-block info-block--inline info-block--inlinel">
        <div className="info-block__value">
          {typeof (audience.exchange_ratio) === 'number' ? numeral(audience.exchange_ratio).format('0.[00]') : 0}
        </div>
        <div
          className={audience.exchange_ratio_inc >= 0 ?
            classNameInline : `${classNameInline} info-block__value-dif--negative`}
        >
          { format.percentage(audience.exchange_ratio_inc) }
        </div>
        <div className="info-block__title info-block__title--inline-last">
          <div className="info-block__text">
            <Tooltip
              content={(
                <span className="tooltip__text">
                  <FormattedMessage id="performanceWidgets.new_audience_exchange_ratio" />
                </span>
              )}
              placement="bottom"
            >
              <span>Exchange ratio</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

NewAudience.propTypes = {
  audience: PropTypes.object.isRequired,
};

export default NewAudience;
