import React from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';
import config from 'config';

import Config from '../ts/interfaces/Config';
import {
  MetricEvent as MetricEventType,
  MetricComponent as MetricComponentType,
} from '../ts/types/utils';

export const MetricComponent: MetricComponentType = () => {
  const { ymActive }: Config = config as Config;
  return ymActive ? (
    <YMInitializer
      accounts={[41484439]}
      options={{ defer: true, clickmap: false, trackLinks: true, accurateTrackBounce: true, webvisor: false }}
      version="2"
    />
  ) : null;
};

export const metricEvent: MetricEventType = (event, ...args) => {
  const { ymActive }: Config = config as Config;
  if (ymActive) {
    ym(event, ...args);
  }
};

export default { MetricComponent, metricEvent };
