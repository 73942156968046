import React from 'react';
import PropTypes from 'prop-types';
import { getPieChartClassName, getPieChartPositions } from './helpers';

const SliderPieChart = ({ engagement, newAudience, revenue }) => {
  const positions = getPieChartPositions({ engagement, newAudience, revenue });
  return (
    <div className="pie-chart__text">
      <div
        className={getPieChartClassName(engagement, 'blue')}
        style={{ transform: `translate(${positions[0]})` }}
      ><span>{`${engagement}%`}</span>
      </div>
      <div
        className={getPieChartClassName(newAudience, 'orange')}
        style={{ transform: `translate(${positions[1]})` }}
      ><span>{`${newAudience}%`}</span>
      </div>
      <div
        className={getPieChartClassName(revenue, 'green')}
        style={{ transform: `translate(${positions[2]})` }}
      ><span>{`${revenue}%`}</span>
      </div>
    </div>
  );
};

export default SliderPieChart;

SliderPieChart.propTypes = {
  engagement: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  newAudience: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  revenue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
