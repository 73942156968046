import React, { ReactElement } from 'react';

import { SocialIntervalGraffProps, SocialLegend } from 'app/ts/types/SocialIntervalGraff';

import Charts from '../default/Charts';

const SocialIntervalGraff = (props: SocialIntervalGraffProps): ReactElement => {
  const { timeframe, byHour } = props;
  if (!timeframe?.length) {
    return null;
  }

  const legend: Array<SocialLegend> = [
    {
      title: 'Pageviews',
      field: 'pageviews',
      color: '#ae80ff',
      valueDecimals: 0,
      disabled: true,
    }, {
      title: 'Shares',
      field: 'shares',
      color: '#00db71',
      valueDecimals: 0,
    }, {
      title: 'Shares per mille',
      field: 'shares_pv',
      valueDecimals: 3,
      color: '#ff6363',
      disabled: true,
    }, {
      title: 'Social clicks',
      field: 'social_clicks',
      color: '#ffc400',
      valueDecimals: 0,
    }, {
      title: 'Social clicks per share',
      field: 'social_clicks_shares',
      valueDecimals: 3,
      color: '#22ddff',
      disabled: true,
    },
  ];

  return (
    <Charts
      timeframe={timeframe}
      byHour={byHour}
      id="Social"
      legend={legend}
      groups={[]}
      pageType="date"
    />
  );
};

export default SocialIntervalGraff;
