/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'app/ts/interfaces';

const initialState: AppState = {
  isDrawerOpen: window.innerWidth > 1189,
};

const appStateSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleDrawer: (state: AppState) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    setDrawerState: (state: AppState, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const { toggleDrawer, setDrawerState } = appStateSlice.actions;

export default appStateSlice.reducer;
