import React, { ReactElement, BaseSyntheticEvent } from 'react';
import { Icon, Button, TextField, Checkbox } from 'uikit';
import { FormattedMessage } from 'react-intl';
import './Workspace.scss';

import { WorkspaceProps } from 'app/ts/types/constructor';
import WidgetPreview from './WidgetPreview';

const Workspace = (props: WorkspaceProps): ReactElement => {
  const {
    widgetPattern,
    content,
    preloadJS,
    classList = '',
    workspaceSize = {
      auto: true,
      height: 600,
      width: 600,
    },
    fullscreen = false,
    fullscreenToggle = () => { },
    showBar = false,
    workspaceSizeChange = () => { },
  } = props;

  const changeSize = (param: string, event: BaseSyntheticEvent): void => {
    const { target: { value } } = event;

    switch (param) {
      case 'auto':
        workspaceSizeChange({
          auto: !workspaceSize.auto,
        });
        break;
      case 'width':
        workspaceSizeChange({
          width: Number(value),
        });
        break;
      case 'height':
        workspaceSizeChange({
          height: Number(value),
        });
        break;
      case 'rotate':
        workspaceSizeChange({
          width: Number(workspaceSize.height),
          height: Number(workspaceSize.width),
        });
        break;
      default:
        break;
    }
  };

  return (
    <main className={`workspace ${classList} ${fullscreen ? 'workspace--fullscreen' : ''}`}>
      {
        showBar && (
          <div className="workspace__bar">
            <div className="workspace__sizes sizes">
              <div className="sizes__wrapper sizes__wrapper--disable sizes__wrapper--left">
                <div className="sizes__control">
                  <Checkbox
                    id="auto-size"
                    checked={workspaceSize.auto}
                    name="auto"
                    className="sizes__control-checkbox"
                    onChange={(event: BaseSyntheticEvent) => changeSize('auto', event)}
                  >
                    <FormattedMessage id="workspace_label" />
                  </Checkbox>
                </div>
              </div>
              <div className="sizes__wrapper sizes__wrapper--wh">
                <TextField
                  type="number"
                  min="0"
                  defaultValue={workspaceSize.width}
                  elementClassName="sizes__control-field"
                  elementId="width"
                  name="width"
                  onChange={(event: BaseSyntheticEvent) => changeSize('width', event)}
                  required="required"
                  disabled={workspaceSize.auto}
                />
                <span>
                  <Icon name="close" />
                </span>
                <TextField
                  type="number"
                  min="0"
                  defaultValue={workspaceSize.height}
                  elementClassName="sizes__control-field"
                  elementId="height"
                  name="height"
                  onChange={(event: BaseSyntheticEvent) => changeSize('height', event)}
                  required="required"
                  disabled={workspaceSize.auto}
                />
                <Button
                  kind="outline"
                  format="icon"
                  onClick={(event: BaseSyntheticEvent) => changeSize('rotate', event)}
                  disabled={workspaceSize.auto}
                >
                  <Icon name="screen_rotation" />
                </Button>
              </div>
              <div className="sizes__wrapper sizes__wrapper--rotate sizes__wrapper--right">
                <Button
                  kind="outline"
                  format="icon"
                  onClick={fullscreenToggle}
                >
                  {
                    fullscreen ? (
                      <Icon name="fullscreen_exit" />
                    ) : (
                      <Icon name="fullscreen" />
                    )
                  }
                </Button>
              </div>
            </div>
          </div>
        )
      }

      {(typeof (content) === 'string') ?
        (
          <WidgetPreview
            size={workspaceSize}
            previewWidgetContent={content}
            preloadJS={preloadJS}
            pattern={widgetPattern}
          />
        ) : (
          <div className="workspace__content-wrapper">
            <div className="workspace__content">
              {content}
            </div>
          </div>
        )}

    </main>
  );
};

export default Workspace;
