import config from 'config';

export const titleBoldness = [
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
];

const { baseDomain, logoRedirectTarget, partnerCodeDomain: codeDomain } = config;

export const logoUrl = logoRedirectTarget || baseDomain;

export const logo = {
  light: `//${codeDomain}/data/logo.svg`,
  dark: `//${codeDomain}/data/logo_dark.svg`,
};

export const logoDimensions = { // на тайском Жирафе другое лого
  width: ['giraff.io', 'global.giraff.io'].includes(baseDomain) ? '20px' : '85px',
  height: ['giraff.io', 'global.giraff.io'].includes(baseDomain) ? '20px' : '30px',
};

export const blockToTypeMap = block => (({
  config: 'text',
  css: 'css',
  footer: 'html',
  header: 'html',
  item: 'html',
  preload_js: 'javascript',
  row_footer: 'html',
  row_header: 'html',
})[block]) || 'text';

export const defaultTeasers = [
  {
    title: 'SEO-аудит сайта бесплатно',
    announce: 'Аудит сайта по 20-ти основным критериям успешного продвижения в поисковых системах.',
  }, {
    title: 'Samsung S8 распродажа',
    announce: 'Отличный телефон с изогнутым экраном и мощным железом. Топовая модель всего за 6550 руб.',
  }, {
    title: 'Нильс Фрам и Native Instruments выпустили виртуальное пианино Noire',
    announce: 'Новое VST-пианино Native Instruments Noire создано совместно с немецким музыкантом и ' +
      'композитором Нильсом Фрамом. Его игра - основа этой библиотеки.',
  }, {
    title: 'Warner Music заключила контракт с искусственным интеллектом на выпуск 20 альбомов',
    announce: 'Лейбл Warner Music подписал контракт на выпуск музыки с искусственным интеллектом внутри ' +
      'приложения Endel. Он пишет индивидуальную музыку под настроен',
  }, {
    title: 'Продажа автомобиля',
    announce: 'Сколько стоит Ваш автомобиль? Узнайте цену сейчас. Деньги у Вас в кармане через 2 часа!',
  }, {
    title: 'Чисто сыграно',
    announce: 'Гости Универсиады показали, как обращаться с мусором.',
  }, {
    title: 'Жительница Омутнинска продала дом, машину и взяла девять кредитов, чтобы снять «смертельную порчу»',
    announce: 'Новости',
  }, {
    title: 'Отвар шиповника: польза и норма употребления',
    announce: 'Сегодня расскажем о свойствах и пользе шиповника. Ягода Чаще всего в употребление используют' +
      ' именно ягоды шиповника. Хотя на самом деле полезны все ча',
  }, {
    title: 'Осси Сааринен – фотограф, который делает сказочные снимки',
    announce: 'Он – профессиональный фотограф, который очень любит природу. Большую часть Финляндии покрывают ' +
      'леса, в которых живет огромное количество самых разных',
  }, {
    title: 'Почему Прага популярна у туристов',
    announce: 'Чем так примечательна Прага, что постоянно притягивает к себе море людей? Прага – город с ' +
      'богатой историей. Судя по официальным источникам, год основа',
  }, {
    title: 'Курс-интенсив по вёрстке',
    announce: 'Курс для начинающих в вёрстке: лекции, личный наставник, итоговый проект, сертификат.',
  }, {
    title: '100 дней без %. Снятие...',
    announce: 'Кредитный лимит до 500 000 руб., 0% по кредиту на любые покупки в течение 100 дней',
  }, {
    title: 'Адская работа в McDonald\'s: взгляд изнутри',
    announce: 'Аноним рассказал об ужасных условиях.',
  }, {
    title: 'Викиум тренажер для мозга',
    announce: 'Курсы развития памяти, внимания, логики и мышления с помощью игр. Попробуй!',
  }, {
    title: 'Остекленный балкон станет гармоничным продолжением вашей квартиры',
    announce: 'Теплое, холодное и безрамное остекление. Бесплатный замер.',
  },
];

export default { blockToTypeMap, titleBoldness, logo, logoUrl, defaultTeasers };
