import { format, tz } from 'utils';

const prepareCSVName = () => `giraff-newAudience-sites-list-${tz.changeFormat(tz.now())}.csv`;

const prepareCSV = ({ stats, totals }) => {
  const data = [];
  const tableHeading = [
    'Date',
    'New audience page views',
    'Widget views',
    'Ad shows',
    'Clicks',
    'CTR',
    'WCTR',
    'Clicks (Incoming new traffic)',
    'Exchange ratio',
  ];

  const tableTotal = [
    'Total',
    totals.out_pageviews,
    totals.widget_views,
    totals.out_ad_shows,
    totals.out_clicks,
    format.mathRound(totals.out_ctr, 2, true),
    format.mathRound(totals.wctr, 2, true),
    totals.in_clicks,
    format.mathRound(totals.exchange_ratio, 2, true),
  ];

  data.push(tableHeading, tableTotal);

  stats.map((item) => {
    const row = [];
    row.push(
      item.date,
      item.out_pageviews,
      item.widget_views,
      item.out_ad_shows,
      item.out_clicks,
      format.mathRound(item.out_ctr, 2, true),
      format.mathRound(item.wctr, 2, true),
      item.in_clicks,
      format.mathRound(item.exchange_ratio, 2, true),
    );
    return data.push(row);
  });

  return data;
};

export default { prepareCSVName, prepareCSV };
