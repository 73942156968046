import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Icon, TextField } from 'uikit';

const ABFormItem = ({ item: { idx, prob }, index, changeTestWidget, removeWidget, filteredWidgets, newTest }) => (
  <div className="ab-creator__form-group">
    <div className="ab-creator__form-group-item ab-creator__field-wrapper ab-creator__field-wrapper--select">
      <label htmlFor="" className="ab-creator__field-label">
        <FormattedMessage id="abtest.select-widget" />
        <select
          name="widget-select-test"
          id={`test-id-${index}`}
          value={idx}
          className="ab-creator__field ab-creator__field--select"
          onChange={(e) => changeTestWidget(index, 'idx', e)}
        >
          {
            idx === -1 && (
              <option value="-1" key="item-default">No widget selected</option>
            )
          }
          {
            filteredWidgets.map(widget => (
              <option
                value={widget.idx}
                key={`item-${widget.idx}`}
                disabled={newTest.filter(({ idx: newTestIndex }) => newTestIndex === widget.idx).length}
              >
                {widget.name}
              </option>
            ))
          }
        </select>
      </label>
    </div>
    <div className="ab-creator__form-group-item ab-creator__field-wrapper ab-creator__field-wrapper--ratio">
      <TextField
        label={<FormattedMessage id="table.ratio" />}
        type="number"
        name="ab-creator"
        min="1"
        max="99"
        defaultValue={prob}
        required
        className="ab-creator__ratio-wrapper"
        onChange={(e) => changeTestWidget(index, 'prob', e)}
        elementAfter="%"
      />
    </div>
    {
      index > 1 && (
        <div className="ab-creator__form-group-item ab-creator__field-wrapper ab-creator__field-wrapper--remove">
          <button
            type="button"
            className="ab-creator__link ab-creator__link--icon-only"
            onClick={() => removeWidget(index)}
          >
            <Icon name="trash" />
          </button>
        </div>
      )
    }
  </div>
);

export default ABFormItem;

ABFormItem.propTypes = {
  item: PropTypes.shape({
    idx: PropTypes.number.isRequired,
    prob: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  changeTestWidget: PropTypes.func.isRequired,
  removeWidget: PropTypes.func.isRequired,
  filteredWidgets: PropTypes.array.isRequired,
  newTest: PropTypes.array.isRequired,
};
