import * as React from 'react';
import PropTypes from 'prop-types';
import { metrics, ajax } from 'utils';
import ExcludeUrlsAndTitlesPgTable from 'components/tables/containers/ExcludeUrlsAndTitles';
import { useMount, usePrevious, useUpdateEffect } from 'react-use';
import ExcludeUrlsAndTitlesFilter from './ExcludeUrlsAndTitlesFilter';

import './ExcludeUrlsAndTitles.scss';

const ExcludeUrlsAndTitles = ({ type, currentSite }) => {
  const [state, setState] = React.useState({
    stats: [],
    loading: true,
  });

  const { stats, loading } = state;
  const prevCurrentSite = usePrevious(currentSite);

  const changeState = async (params = {}) => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));
    const url = (type === 'url') ? '/tools/exclude_url' : '/tools/exclude_title';
    ajax.get(url, { params }).then(({ data }) => {
      if (data) {
        setState(prevState => ({
          ...prevState,
          ...data,
        }));
      }
    }).finally(() => {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    });
  };

  useMount(() => {
    metrics.metricEvent('hit', type === 'url' ? '/tools/exclude/urls' : '/tools/exclude/titles');
    changeState();
  });

  useUpdateEffect(() => {
    if (currentSite !== prevCurrentSite) {
      changeState();
    }
  });

  return (
    <div>
      <div className="wrapper wrapper--dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <ExcludeUrlsAndTitlesFilter type={type} editList={changeState} />
            </div>
          </div>
        </div>
      </div>
      <ExcludeUrlsAndTitlesPgTable type={type} statsData={stats} editList={changeState} isLoading={loading} />
    </div>
  );
};

ExcludeUrlsAndTitles.propTypes = {
  type: PropTypes.oneOf(['url', 'title']),
  currentSite: PropTypes.number.isRequired,
};

ExcludeUrlsAndTitles.defaultProps = {
  type: 'url',
};

export default ExcludeUrlsAndTitles;
