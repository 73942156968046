import React, { ReactElement } from 'react';
import js from 'common';
import ContentAndSocialFilter from 'app/components/ContentAndSocialFilter';
import PgTablePager from 'app/components/PgTablePager';
import ContentIntervalGraff from 'app/components/charts/content/ContentIntervalGraff';
import { ls, tz, ajax, metrics, pageTypes, metricFilterTypes } from 'app/utils';
import ContentPgTable from 'app/components/tables/containers/Content';
import { useMount, useUpdateEffect } from 'react-use';

import './Content.scss';

import { AxiosResponse } from 'axios';
import { GetPage } from 'app/ts/types/utils';
import { ContentData } from 'app/ts/interfaces';
import { Filters, ParamsProps } from 'app/ts/types/ContentSocial';
import { ContentState, ContentParams } from 'app/ts/types/Content';

const { PAGE_CONTENT_ARTICLES, PAGE_OTHER } = pageTypes;
const { PAGE_VIEWS, READING_DEPTH, AVERAGE_READING_TIME, RPM, REVENUE } = metricFilterTypes;

const Content = ({ currentSite }: { currentSite: number }): ReactElement => {
  const [state, setState] = React.useState<ContentState>({
    stats: [],
    totals: {},
    totalItems: 0,
    timeframe: [],
    pageN: 1,
    sortField: 'pageviews',
    sortOrder: 'desc',
    loading: true,
  });
  const { stats, totals, totalItems, timeframe, pageN, sortField, sortOrder, loading } = state;

  const getPage: GetPage = () => js.D.page('content');

  const getFilterUrl = (): ContentParams => {
    const pgNameStor: string = 'filterContent';
    const params: ContentParams = Object.create(null);

    const filters: Filters = sessionStorage.filterContentDataFilters
      ? JSON.parse(sessionStorage.filterContentDataFilters)
      : [];
    if (!sessionStorage.filterDateGlobal) {
      if (!sessionStorage[pgNameStor]) {
        sessionStorage[pgNameStor] = 'Date7days';
        sessionStorage.filterDateGlobal = 'Date7days';
      } else {
        sessionStorage.filterDateGlobal = sessionStorage[pgNameStor];
      }
    } else {
      sessionStorage[pgNameStor] = sessionStorage.filterDateGlobal;
    }

    switch (sessionStorage[pgNameStor]) {
      case 'Date1day':
        if (sessionStorage.getItem('filterDateTo')) {
          if (sessionStorage.getItem('filterDateTo') === tz.now()) {
            params.period = 'today';
          } else if (sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = sessionStorage.getItem('filterDateFrom') || tz.now();
            params.to = sessionStorage.getItem('filterDateTo') || tz.now();
          }
        }
        break;
      case 'Date7days':
        params.from = sessionStorage.getItem('filterDateFrom') || tz.addDayToDate(-7);
        params.to = sessionStorage.getItem('filterDateTo') || tz.now();
        break;
      case 'Date30days':
        params.from = sessionStorage.getItem('filterDateFrom') || tz.addDayToDate(-30);
        params.to = sessionStorage.getItem('filterDateTo') || tz.now();
        break;
      case 'DateInterval':
        if (sessionStorage.getItem('filterDateFrom') === tz.now()
          && sessionStorage.getItem('filterDateTo') === tz.now()) {
          params.period = 'today';
        } else if (sessionStorage.getItem('filterDateFrom') === tz.addDayToDate(-1)
          && sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1)) {
          params.period = 'yesterday';
        } else {
          params.from = sessionStorage.getItem('filterDateFrom');
          params.to = sessionStorage.getItem('filterDateTo');
        }
        break;
      default:
        break;
    }

    for (let i: number = 0; i < filters.length; i += 1) {
      if (filters[i].label === 'publicDate' && filters[i].object.from) {
        params.pubdate_from = filters[i].object.from;
      }
      if (filters[i].label === 'publicDate' && filters[i].object.to) {
        params.pubdate_to = filters[i].object.to;
      }
      if (filters[i].label === 'title' && filters[i].object.text) {
        params.title = filters[i].object.text;
      }
      if (filters[i].label === 'author' && filters[i].object.author_id) {
        params.author_id = filters[i].object.author_id;
      }
      if (filters[i].label === 'page_type' && filters[i].object.type) {
        switch (filters[i].object.type) {
          case PAGE_CONTENT_ARTICLES:
            params.is_content = 1;
            break;
          case PAGE_OTHER:
            params.is_content = 0;
            break;
          default:
            params.is_content = '';
            break;
        }
      }
      if (filters[i].label === PAGE_VIEWS && filters[i].object.from) {
        params.pv_from = filters[i].object.from;
      }
      if (filters[i].label === PAGE_VIEWS && filters[i].object.to) {
        params.pv_to = filters[i].object.to;
      }
      if (filters[i].label === READING_DEPTH && filters[i].object.from) {
        params.sd_from = filters[i].object.from;
      }
      if (filters[i].label === READING_DEPTH && filters[i].object.to) {
        params.sd_to = filters[i].object.to;
      }
      if (filters[i].label === AVERAGE_READING_TIME && filters[i].object.from) {
        params.art_from = filters[i].object.from;
      }
      if (filters[i].label === AVERAGE_READING_TIME && filters[i].object.to) {
        params.art_to = filters[i].object.to;
      }
      if (filters[i].label === RPM && filters[i].object.from) {
        params.rpm_from = filters[i].object.from;
      }
      if (filters[i].label === RPM && filters[i].object.to) {
        params.rpm_to = filters[i].object.to;
      }
      if (filters[i].label === REVENUE && filters[i].object.from) {
        params.rev_from = filters[i].object.from;
      }
      if (filters[i].label === REVENUE && filters[i].object.to) {
        params.rev_to = filters[i].object.to;
      }
    }
    return params;
  };

  const getUrlParams = (paramsProps: ParamsProps): ContentParams => {
    const { pageNumToChange, state: newState } = paramsProps;
    const itemsPerPage: string = ls.get('contentTableSize', '20');
    const params: ContentParams = getFilterUrl();

    params.limit = +itemsPerPage;
    params.offset = pageNumToChange ? (pageNumToChange - 1) * +itemsPerPage : 0;
    params.sort_field = newState?.sortField || sortField; // newState существует, если таблица сортируется на бэкенде
    params.sort_order = newState?.sortOrder || sortOrder;

    return params;
  };

  const changeState = async (paramsProps: ParamsProps): Promise<void> => {
    const { pageNumToChange = 1, state: newState } = paramsProps;
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    const params: ContentParams = getUrlParams({ pageNumToChange, state: newState });
    const { data }: AxiosResponse<ContentData> = await ajax.get('/stats/editor/content', { params });

    if (data) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        stats: data.stats,
        totals: data.totals,
        timeframe: data.timeframe,
        totalItems: data.total_items,
        pageN: pageNumToChange,
        sortField: newState?.sortField || sortField,
        sortOrder: newState?.sortOrder || sortOrder,
      }));
    }
  };

  const changePage = (pageNumToChange: number): void => {
    changeState({ pageNumToChange });
  };

  useMount(() => {
    changeState({}); // получаем данные с сервера после маунта
    metrics.metricEvent('hit', '/reports/editorial/content');
  });

  useUpdateEffect(() => {
    if (currentSite != null && sessionStorage.filterContentDataFilters) {
      const activeFilters: Array<any> = JSON.parse(sessionStorage.filterContentDataFilters);

      for (let i: number = 0; i < activeFilters.length; i += 1) {
        if (activeFilters[i].label === 'author') {
          activeFilters.splice(i, 1);
          sessionStorage.setItem('filterContentDataFilters', JSON.stringify(activeFilters));
        }
      }
    }

    changeState({}); // получаем новые данные с сервера после выбора другого сайта
  }, [currentSite]); // eslint-disable-line

  const isTimeIntervalToday: boolean = sessionStorage.getItem('filterDateFrom') === tz.now()
    && sessionStorage.getItem('filterDateTo') === tz.now();
  const isTimeIntervalYesterday: boolean = (sessionStorage.getItem('filterDateFrom') === tz.addDayToDate(-1))
    && (sessionStorage.getItem('filterDateTo') === tz.addDayToDate(-1));
  const isChartsVisible: boolean = (
    sessionStorage.getItem('filterDateFrom') !== sessionStorage.getItem('filterDateTo')
    || isTimeIntervalToday
    || isTimeIntervalYesterday
  ); // интервал > 1 дня && не сегодня/вчера (приходят данные по часам)
  const byHour: boolean = isTimeIntervalToday || isTimeIntervalYesterday;

  return (
    <div>
      <div className="wrapper wrapper--dashboard">
        <div className="container-fluid">
          <ContentAndSocialFilter
            mode="content"
            currentSite={currentSite}
            changeState={changeState}
            getPage={getPage}
            changePage={changePage}
            statsData={stats}
          />
          {isChartsVisible && (
            <ContentIntervalGraff
              timeframe={timeframe}
              byHour={byHour}
            />
          )}
        </div>
      </div>
      <PgTablePager
        pagerName="contentTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePage}
      />
      <ContentPgTable
        statsData={stats}
        totalsData={totals}
        totalItems={totalItems}
        changeState={changeState}
        isLoading={loading}
      />
      <PgTablePager
        pagerName="contentTableSize"
        totalItems={totalItems}
        pageN={pageN}
        changePageN={changePage}
      />
    </div>
  );
};

export default Content;
