/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Pin from 'components/Pin';

export default (overrideProps, totals, { pinToggle }, param) => ({
  accessor: param,
  resizable: false,
  Header: (
    <FormattedMessage id={`table.${param}`}>
      {txt => (
        <p className="cell-inner">
          {txt[0].split(' ').slice(0, 1).join(' ')}<br />{txt[0].split(' ').slice(1).join(' ')}
        </p>
      )}
    </FormattedMessage>
  ),
  Cell: props => !props.original.isNew && (
    <Pin
      active={props.original[param] || false}
      id={props.original.id}
      param={param === 'isAdblockPromo'}
      onClickHandler={pinToggle}
    />
  ),
  ...overrideProps,
});
