import React, { useEffect, useState, ReactElement, BaseSyntheticEvent } from 'react';
import { injectIntl } from 'react-intl';
import numeral from 'numeral';
import ABTestList from 'app/components/tables/containers/ABTests';
import { ajax, showAlert } from 'app/utils';

import { ABTestsProps, NewTest, NewWidgets, TestsList } from 'app/ts/types/ABTests';
import { ABTestsDataGet, ABTestsDataPost } from 'app/ts/interfaces';
import { AxiosResponse } from 'axios';

import ABTestCreator from './ABTestCreator';
import './ABTests.scss';

const ABTests = (props: ABTestsProps): ReactElement => {
  const { currentSite, intl } = props;
  const [newTest, setNewTest] = useState<Array<NewTest>>([]);
  const [widgets, setWidgets] = useState<Array<NewWidgets>>([]);
  const [testsList, setTestsList] = useState<Array<TestsList>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const cancelTest = (): void => {
    setNewTest([
      {
        idx: -1,
        name: 'No widget selected',
        prob: 50,
      },
    ]);
  };

  const addWidget = (newTestState: Array<NewTest>): void => {
    const newWidget: NewTest = {
      idx: -1,
      name: 'No widget selected',
      prob: 50,
    };
    setNewTest(prevState => ([...(newTestState || prevState), newWidget]));
  };

  const changeTestWidget = (index: number, option: string, event: BaseSyntheticEvent): void => {
    const newState: Array<NewTest> = [...newTest];
    const val: string = event.target.value;
    const oldValue: string | number = newTest[index][option as keyof NewTest];
    const changedWidget: NewWidgets = { ...widgets.find(item => item.idx === Number(val)) };

    if (option === 'idx') {
      newState[index] = {
        ...newTest[index],
        name: changedWidget.name,
        idx: Number(val),
      };
    } else {
      newState[index] = {
        ...newTest[index],
        [option]: Number(val),
      };
    }

    if (index === 0 && oldValue === -1) {
      addWidget(newState);
    } else {
      setNewTest(newState);
    }
  };

  const onChangeInput = (id: number, idx: number, event: BaseSyntheticEvent): void => {
    const { target } = event;
    const val: number = Number(numeral(target.value).format('0')) || 0;
    const newTestList: Array<TestsList> = [];

    testsList.forEach((testItem) => {
      if (testItem.id === id) {
        const newSettings: Array<NewTest> = [];
        testItem.settings.forEach((item) => {
          if (item.idx === idx) {
            newSettings.push({ ...item, prob: val / 100 });
          } else {
            newSettings.push(item);
          }
        });
        newTestList.push({ ...testItem, settings: newSettings });
      } else {
        newTestList.push(testItem);
      }
      setTestsList(newTestList);
    });
  };

  const updateTest = (test: TestsList, callback: () => void): void => {
    ajax.put(`/ab_tests/${test.id}`, test).then(() => {
      if (callback) callback();
    });
  };

  // Change test status (true/false)
  const switchToggle = (id: number): void => {
    const newList: Array<TestsList> = [];
    let changedTest: TestsList;
    testsList.forEach((item: TestsList) => {
      const el: TestsList = item;
      if (el.id === id) {
        changedTest = { ...el };
        changedTest.is_disabled = !changedTest.is_disabled;
        newList.push(changedTest);
      } else {
        newList.push(el);
      }
    });

    updateTest(changedTest, () => {
      setTestsList(newList);
    });
  };

  const ratioValidation = (array: Array<NewTest>): boolean => {
    const sum: number = array.reduce((result, current) => result + current.prob, 0);
    const empties: Array<NewTest> = array.filter(item => item.prob < 0);

    return sum === 1 && !empties.length;
  };

  // Update ratio (по клику в списке(таблице))
  const updateRatio = (id: number, event: BaseSyntheticEvent): void => {
    event.preventDefault();
    const testList: TestsList = testsList.find(test => test.id === id);

    if (ratioValidation(testList.settings)) {
      const { created, ...newState } = testList;

      const newTestsList = testsList.map((item: TestsList): TestsList => {
        if (item.id === newState.id) {
          return { ...item, settings: newState.settings };
        }
        return item;
      });

      updateTest(newState, () => {
        setTestsList(newTestsList);
        showAlert(intl.messages['abtest.updated-ratios']);
      });
    } else {
      showAlert(intl.messages['abtest.incorrect-ratios']);
    }
  };

  const removeWidget = (index: number): void => {
    const newState: Array<NewTest> = [].concat(newTest);
    newState.splice(index, 1);
    setNewTest(newState);
  };

  const changeWidgetInTest = (idxs: Array<number>, wgts: Array<NewWidgets>, status: boolean) =>
    wgts.map(item => (idxs.indexOf(item.idx) > -1
      ? ({ ...item, in_test: status })
      : item));

  const createTest = async (): Promise<void> => {
    const test: { settings: Array<NewTest> } = {
      settings: [],
    };

    newTest.forEach(item => test.settings.push({
      idx: item.idx,
      name: item.name,
      prob: item.prob / 100,
    }));

    if (ratioValidation(test.settings)) {
      const { data: responseData }: AxiosResponse<ABTestsDataPost> = await ajax.post('/ab_tests', {
        settings: test.settings,
      });

      if (responseData) {
        const { data } = responseData;
        const idxs: Array<number> = data.settings.map((item: NewTest) => item.idx);
        const newWidgetState: Array<NewWidgets> = changeWidgetInTest(idxs, widgets, true);
        setTestsList(prevState => [...prevState, data]);
        setNewTest([
          {
            idx: -1,
            name: 'No widget selected',
            prob: 50,
          },
        ]);
        setWidgets(newWidgetState);
      }
    } else {
      showAlert(intl.messages['abtest.incorrect-ratios']);
    }
  };

  // Remove test in list
  const removeTest = (id: number): void => {
    const idxs: Array<number> = testsList.reduce((res, cur) => {
      if (cur.id === id) {
        cur.settings.map((item) => {
          res.push(item.idx);
          return res;
        });
      }
      return res;
    }, []);

    ajax.delete(`/ab_tests/${id}`).then(() => {
      const newWidgetState: Array<NewWidgets> = changeWidgetInTest(idxs, widgets, false);
      setTestsList(prevState => prevState.filter(item => item.id !== id));
      setWidgets(newWidgetState);
    });
  };

  const getData = (): boolean => {
    setLoading(true);
    ajax.get('/ab_tests').then(({ data }: AxiosResponse<ABTestsDataGet>) => {
      if (data) {
        const showedWidgets = data.widgets.filter(selectWidget => selectWidget.is_active);
        setTestsList(data.ab_tests);
        setNewTest([
          {
            idx: -1,
            name: 'No widget selected',
            prob: 50,
          },
        ]);
        setWidgets(showedWidgets);
      }
    }).finally(() => {
      setLoading(false);
    });
    return true;
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [currentSite]);

  return (
    <div className="ab-test">
      <div className="ab-test__creator">
        <div className="wrapper">
          <ABTestCreator
            newTest={newTest}
            widgets={widgets}
            actions={
              {
                changeTestWidget,
                removeWidget,
                addWidget,
                createTest,
                cancelTest,
              }
            }
          />
        </div>
      </div>
      <div className="ab-test__list">
        <ABTestList
          isLoading={loading}
          testsList={testsList}
          actions={{
            switchToggle,
            removeTest,
            updateRatio,
            onChangeInput,
          }}
        />
      </div>
    </div>
  );
};

export default injectIntl(ABTests);
