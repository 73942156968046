import React, { ReactElement } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dropdown, Icon, Checkbox } from 'uikit';
import Table from 'app/components/Table';
import { stats as statsUtils } from 'app/utils';
import { AppDispatch } from 'app/store';

import { RevenuePgTable } from 'app/ts/types/RevenuePgTable';
import { RevenueColumns } from 'app/ts/interfaces';
import { ColumnsConfig } from 'app/ts/types/ColumnsConfig';

import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { setRevenueColumns } from '../../../../store/accountSettings/accountSettingsSlice';
import { getDisplayedColumns } from '../../columnsHelpers/revenue';

import './Revenue.scss';

const Revenue = (props: RevenuePgTable): ReactElement => {
  const { totalsData, pageType, statsData, totalItems, changeState, isLoading } = props;
  const columnsConfig: RevenueColumns =
    useAppSelector(reduxState => reduxState.accountSettings.reports.revenue.columns);
  const dispatch: AppDispatch = useAppDispatch();

  const displayedColumns: Array<ColumnsConfig> = getDisplayedColumns({
    totalsData,
    pageType,
    columnsConfig,
  });

  const columnsSettingsContent: ReactElement = (
    <ul>
      {displayedColumns.map((item, index) => {
        if (['widget', 'date', 'country', 'device'].includes(item.accessor)) {
          return false;
        }
        return (
          <li
            key={index}
          >
            <Checkbox
              id={item.accessor}
              name={item.accessor}
              checked={columnsConfig[item.accessor as keyof RevenueColumns]}
              onChange={() => {
                const payload = {
                  ...columnsConfig,
                  [item.accessor]: !columnsConfig[item.accessor as keyof RevenueColumns],
                };
                dispatch(setRevenueColumns(payload));
              }}
            >
              <FormattedMessage id={`table.${item.accessor}`} />
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      <div className="columns-settings">
        <Dropdown
          theme="dropdown-checkbox-list"
          placement="bottom-end"
          content={columnsSettingsContent}
          arrow={false}
          trigger="click"
          interactive
        >
          <button
            type="button"
            className="dropdown__button dropdown__button--columns-settings"
          >
            Settings
            <Icon name="settings" size={18} className="dropdown__icon dropdown__icon--columns-settings" />
          </button>
        </Dropdown>
      </div>
      <div className="revenue-pg-table">
        <Table
          columns={displayedColumns}
          data={statsData.map(statsUtils.fromServerData)}
          dataCount={totalItems}
          changeState={changeState}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default injectIntl(Revenue);
