const req = require.context('./locales', true, /\.json.*$/);
type ExportFile = { [key: string]: any };

const exportFiles: ExportFile = {};

req.keys().forEach((file: string) => {
  const locale = file.replace('./', '').replace('.json', '');
  exportFiles[locale] = req(file);
});

export default exportFiles as ExportFile;
