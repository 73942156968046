import { DefaultTagSettings } from 'app/ts/interfaces';

const defaultTagSettings: DefaultTagSettings = {
  code: 'console.log("test");',
  is_enabled: true,
  is_javascript: true,
  location: 'after',
  selector: 'div.h1',
  title: 'Tag',
  trigger: '/',
};

export default defaultTagSettings;
