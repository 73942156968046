import * as React from 'react';
import AceEditor from 'react-ace';
import { useIntl, FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import { ajax, showAlert } from 'app/utils';
import { Select, Button, TextField, Checkbox, Tooltip } from 'uikit';
import { confirmAlert } from 'react-confirm-alert';
import defaultTagSettings from 'app/pages/HtmlTags/defaultTagSettings';
import tagPositions from 'app/pages/HtmlTags/tagPositions';
import 'ace-builds/src-noconflict/theme-kuroir';
import './HtmlTags.scss';

const HtmlTags = () => {
  const [tags, setTags] = React.useState([]);
  const [selectedTag, setSelectedTag] = React.useState(null);
  const [codeError, setCodeError] = React.useState(false);
  const code = React.useRef(null);
  const intl = useIntl();

  React.useEffect(() => {
    const getHtmlTags = async () => {
      try {
        const { data } = await ajax.get('/html_tags');
        setTags(data.list);
      } catch (error) {
        console.error(error) // eslint-disable-line
      }
    };

    getHtmlTags();
  }, []);

  const handleSelectTag = (id) => {
    const clickedTag = tags.find(tag => tag.id === +id);
    code.current = clickedTag.code;
    setSelectedTag(clickedTag);
  };

  const handleAddTag = () => {
    const addNewTag = async () => {
      try {
        const newTagNumber = tags.length ? +tags[tags.length - 1].title.substring(5) + 1 : 1;

        const newTagSettings = {
          ...defaultTagSettings,
          title: `Tag #${newTagNumber}`,
        };
        const { data: { data: newTag } } = await ajax.post('/html_tags', newTagSettings);

        setTags(prevTags => prevTags.concat(newTag));
        code.current = newTag.code;
        setSelectedTag(newTag);
      } catch (error) {
        console.error(error) // eslint-disable-line
      }
    };

    addNewTag();
  };

  const updateTagList = (updatedTag) => {
    const selectedTagIndex = tags.findIndex(tag => tag.id === selectedTag.id);
    const updatedTagList = [...tags];

    if (updatedTag) {
      updatedTagList.splice(selectedTagIndex, 1, updatedTag);
    } else {
      updatedTagList.splice(selectedTagIndex, 1);
    }

    setTags(updatedTagList);
  };

  const handleSaveTag = async () => {
    try {
      if (!code.current) {
        setCodeError(true);
        showAlert(intl.messages['htmltags.no-code']);
        return;
      }

      const updatedTag = { ...selectedTag, code: code.current };
      delete updatedTag.id;
      delete updatedTag.site_id;

      const { data: { data: fetchedUpdatedTag } } = await ajax.put(`html_tags/${selectedTag.id}`, updatedTag);
      setSelectedTag(fetchedUpdatedTag);
      updateTagList(fetchedUpdatedTag);

      showAlert(intl.messages['htmltags.tag-updated']);
    } catch (error) {
      console.error(error) // eslint-disable-line
    }
  };

  const handleDeleteTag = async () => {
    try {
      await ajax.delete(`html_tags/${selectedTag.id}`);
      updateTagList();
      setSelectedTag(null);
    } catch (error) {
      console.error(error) // eslint-disable-line
    }
  };

  const handleTitleChange = (e) => setSelectedTag(prevState => ({
    ...prevState,
    title: String(e.target.value),
  }));

  const handleTriggerChange = (e) => setSelectedTag(prevState => ({
    ...prevState,
    trigger: String(e.target.value),
  }));

  const handleClearTrigger = () => setSelectedTag(prevState => ({
    ...prevState,
    trigger: '/',
  }));

  const handleSelectorChange = (e) => setSelectedTag(prevState => ({
    ...prevState,
    selector: String(e.target.value),
  }));

  const handlePositionChange = (e) => setSelectedTag(prevState => ({
    ...prevState,
    location: e.value,
  }));

  const handleIsJavascriptToggle = () => setSelectedTag(prevState => ({
    ...prevState,
    is_javascript: !prevState.is_javascript,
  }));

  const handleIsEnabledToggle = () => setSelectedTag(prevState => ({
    ...prevState,
    is_enabled: !prevState.is_enabled,
  }));

  const handleCodeEditorChange = (newValue) => {
    setCodeError(false);
    code.current = newValue.toString();
  };

  const confirmAction = (action, e) => {
    const id = e?.currentTarget.dataset.id;
    if (selectedTag && +id === selectedTag.id) return; // при клике на выбранный тег действия не нужны

    setCodeError(false); // после совершения action не должен подсвечиваться редактор кода

    if (!selectedTag) { // если не выбран тег, то подтверждение действия не нужно
      if (action === 'select_tag') {
        handleSelectTag(id);
        return;
      }

      if (action === 'create_new_tag') {
        handleAddTag();
        return;
      }
    }

    if (selectedTag) {
      if (action !== 'delete_tag') {
        // если тег не редактировали, то подтверждение действия не нужно
        const selectedTagPrevState = tags.find(tag => tag.id === selectedTag.id);
        const isSelectedTagChanged = !isEqual(selectedTag, selectedTagPrevState);

        if (!isSelectedTagChanged && action === 'select_tag') {
          handleSelectTag(id);
          return;
        }

        if (!isSelectedTagChanged && action === 'create_new_tag') {
          handleAddTag();
          return;
        }
      }

      const handleClick = (onClose) => {
        switch (action) {
          case 'create_new_tag':
            handleAddTag();
            onClose();
            break;
          case 'select_tag':
            handleSelectTag(id);
            onClose();
            break;
          case 'delete_tag':
            handleDeleteTag();
            onClose();
            break;
          default:
            break;
        }
      };

      confirmAlert({
        customUI: ({ onClose }) => ( // eslint-disable-line
          <div className="react-confirm-alert-body disabled">
            <div className="react-confirm-alert-message center">
              {action === 'create_new_tag' || action === 'select_tag'
                ? intl.messages['htmltags.changes-warning']
                : intl.messages['htmltags.delete-warning']}
              <div className="react-confirm-alert-button-group">
                <Button
                  type="button"
                  onClick={() => handleClick(onClose)}
                >
                  {action === 'create_new_tag'
                    ? intl.messages['htmltags.confirm-ok']
                    : intl.messages['htmltags.confirm-yes']}
                </Button>
                <Button
                  kind="secondary"
                  type="button"
                  onClick={onClose}
                >
                  {intl.messages['htmltags.confirm-cancel']}
                </Button>
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const tagList = tags.map(tag => (
    <li key={tag.id}>
      <button
        type="button"
        data-id={tag.id}
        onClick={(e) => confirmAction('select_tag', e)}
        // eslint-disable-next-line max-len
        className={`tag-list__tag ${selectedTag?.id === tag.id ? 'tag-list__tag--active' : ''} ${tag.is_enabled ? '' : 'tag-list__tag--disabled'}`}
      >
        {tag.title}
      </button>
    </li>
  ));

  return (
    <div className="wrapper wrapper--html-tags">
      <section className="tag-list">
        <FormattedMessage id="htmltags.heading">
          {text => <h3 className="tag-list__heading">{text}</h3>}
        </FormattedMessage>
        {tags.length > 0 && (
        <ul className="tag-list__list">
          {tagList}
        </ul>
        )}
        <Button
          size="l"
          kind="primary"
          onClick={() => confirmAction('create_new_tag')}
          className="tag-list__new-tag-button"
        >
          <FormattedMessage id="htmltags.new-tag">
            {text => text}
          </FormattedMessage>
        </Button>
      </section>
      {selectedTag && (
      <>
        <section className="tag-editor">
          <FormattedMessage id="htmltags.default-tag-title">
            {text => <h3 className="tag-editor__heading">{selectedTag.title || text}</h3>}
          </FormattedMessage>
          <div className="tag-editor__line-wrapper">
            <FormattedMessage id="htmltags.title">
              {text => <label htmlFor="title" className="tag-editor__label">{text}</label>}
            </FormattedMessage>
            <TextField
              type="text"
              elementId="title"
              className="tag-editor__text-input"
              value={selectedTag.title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="tag-editor__line-wrapper">
            <FormattedMessage id="htmltags.trigger">
              {text => (
                <Tooltip
                  placement="top"
                  offset={[0, -1]}
                  content={(
                    <FormattedMessage id="htmltags.trigger-tooltip">
                      {tooltip => <span className="tooltip__text">{tooltip}</span>}
                    </FormattedMessage>
                  )}
                >
                  <label htmlFor="trigger" className="tag-editor__label">{text}</label>
                </Tooltip>
              )}
            </FormattedMessage>
            <TextField
              type="text"
              elementId="trigger"
              className="tag-editor__text-input"
              value={selectedTag.trigger}
              onChange={handleTriggerChange}
              helper={(
                <p className="tag-editor__tooltip">
                  <FormattedMessage id="htmltags.trigger-desc">
                    {text => text}
                  </FormattedMessage>
                  <button
                    type="button"
                    className="tag-editor__clear-trigger"
                    onClick={handleClearTrigger}
                  >
                    <FormattedMessage id="htmltags.clear-trigger">
                      {text => text}
                    </FormattedMessage>
                  </button>
                </p>
              )}
            />
          </div>
          <div className="tag-editor__line-wrapper">
            <FormattedMessage id="htmltags.selector">
              {text => <label htmlFor="selector" className="tag-editor__label">{text}</label>}
            </FormattedMessage>
            <TextField
              type="text"
              elementId="selector"
              className="tag-editor__text-input"
              value={selectedTag.selector}
              onChange={handleSelectorChange}
            />
          </div>
          <div className="tag-editor__line-wrapper">
            <FormattedMessage id="htmltags.position">
              {text => <span className="tag-editor__position-span">{text}</span>}
            </FormattedMessage>
            <Select
              kind="white"
              name="position"
              options={tagPositions}
              value={tagPositions.find(position => position.value === selectedTag.location)}
              onChange={handlePositionChange}
            />
          </div>
          <div className="tag-editor__checkbox-wrapper">
            <Checkbox
              id="javascript"
              checked={selectedTag.is_javascript}
              onChange={handleIsJavascriptToggle}
            >
              JavaScript
            </Checkbox>
            <Checkbox
              id="enabled"
              checked={selectedTag.is_enabled}
              onChange={handleIsEnabledToggle}
            >
              <FormattedMessage id="htmltags.enabled">
                {text => text}
              </FormattedMessage>
            </Checkbox>
          </div>
          <div className="tag-editor__line-wrapper">
            <Button
              size="l"
              kind="primary"
              onClick={handleSaveTag}
              className="tag-editor__button"
              disabled={!selectedTag.title || !selectedTag.selector || codeError}
            >
              <FormattedMessage id="htmltags.save">
                {text => text}
              </FormattedMessage>
            </Button>
            <Button
              size="l"
              kind="outline"
              onClick={() => confirmAction('delete_tag')}
            >
              <FormattedMessage id="htmltags.delete">
                {text => text}
              </FormattedMessage>
            </Button>
          </div>
        </section>
        <section className={`tag-code ${codeError ? 'tag-code--error' : ''}`}>
          <AceEditor
            mode={selectedTag.is_javascript ? 'javascript' : 'html'}
            showGutter={false}
            width="100%"
            height="398px"
            theme="kuroir"
            wrapEnabled
            editorProps={{ $blockScrolling: true }}
            highlightActiveLine={false}
            value={code.current}
            onChange={handleCodeEditorChange}
            setOptions={{
              useWorker: false,
              enableLiveAutocompletion: true,
              tabSize: 2,
            }}
          />
        </section>
      </>
      )}
    </div>
  );
};

export default HtmlTags;
