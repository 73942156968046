import React, { useState, useEffect, ReactElement, BaseSyntheticEvent } from 'react';
import SummaryGraff from 'app/components/charts/summary/SummaryGraff';
import PgFilter from 'app/components/PgFilter';
import PgTablePager from 'app/components/PgTablePager';
import SummaryPgTable from 'app/components/tables/containers/SummaryReport';
import { Tooltip, Checkbox } from 'uikit';
import { metrics, tz, ajax, ls, chart } from 'app/utils';
import { widgets as widgetsCSV } from 'app/components/csv/summaryReport';

import './SummaryReport.scss';
import { SummaryReportStatusData, SummaryReportStatsItem, SummaryReportData } from 'app/ts/interfaces';
import { FetchStatsProps, ParamsProps, SummaryReportProps } from 'app/ts/types/SummaryReport';
import { GetChartInfo, NewCsvData } from 'app/ts/types/utils';
import { Params } from 'app/ts/types/Audience';
import { AxiosResponse } from 'axios';

const SummaryReport = (props: SummaryReportProps): ReactElement => {
  const { currentSite, currentSiteName } = props;
  const [stats, setStats] = useState<Array<SummaryReportStatsItem>>([]);
  const [totals, setTotals] = useState<SummaryReportStatsItem | {}>({});
  const [totalItems, setTotalItems] = useState<number>(0);
  const [timeFrame, setTimeFrame] = useState<Array<SummaryReportStatsItem>>([]);
  const [csvData, setCsvData] = useState<NewCsvData>([]);
  const [csvName, setCsvName] = useState<string>('');
  const [pageN, setPageN] = useState<number>(1);
  const [sortField, setSortField] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [sendReports, setSendReports] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [windowResolution, setWindowResolution] = useState(window.innerWidth);

  const getFilterUrl = (): Params => {
    const params: Params = {};
    const storageKey: string = chart.initializeStorage('summary');
    const filterDateTo: string = sessionStorage.getItem('filterDateTo');
    const filterDateFrom: string = sessionStorage.getItem('filterDateFrom');

    switch (sessionStorage[storageKey]) {
      case 'Date1day':
        if (filterDateTo) {
          if (filterDateTo === tz.now()) {
            params.period = 'today';
          } else if (filterDateTo === tz.addDayToDate(-1)) {
            params.period = 'yesterday';
          } else {
            params.from = filterDateFrom || tz.now();
            params.to = filterDateTo || tz.now();
          }
        }
        break;
      case 'Date7days':
        params.from = filterDateFrom || tz.addDayToDate(-7);
        params.to = filterDateTo || tz.now();
        break;
      case 'Date30days':
        params.from = filterDateFrom || tz.addDayToDate(-30);
        params.to = filterDateTo || tz.now();
        break;
      case 'DateInterval':
        if (filterDateFrom === tz.now()
          && filterDateTo === tz.now()) {
          params.period = 'today';
        } else if (filterDateFrom === tz.addDayToDate(-1)
          && filterDateTo === tz.addDayToDate(-1)) {
          params.period = 'yesterday';
        } else {
          params.from = filterDateFrom;
          params.to = filterDateTo;
        }
        break;
      default:
        break;
    }
    return params;
  };

  const getUrlParams = (paramsProps: ParamsProps): Params => {
    const { pageNumToChange, passedState } = paramsProps;
    const itemsPerPage: number = ls.get('summaryTableSize', 20);
    const params: Params = getFilterUrl();

    params.limit = +itemsPerPage;
    params.offset = pageNumToChange ? (pageNumToChange - 1) * +itemsPerPage : 0;
    params.groupby = 'date';
    // state существует, если таблица сортируется на бэкенде
    params.sort_field = passedState?.sortField || sortField;
    params.sort_order = passedState?.sortOrder || sortOrder;

    if (params.from == null) {
      delete params.from;
    }

    return params;
  };

  const getSendReportsStatus = (): void => {
    ajax.get('/notifications/email_rv').then(({ data }: AxiosResponse<SummaryReportStatusData>) => {
      if (data) {
        setSendReports(data.notification_status);
      }
    });
  };

  const fetchStats = (fetchStatsProps: FetchStatsProps = {}): void => {
    const { state: passedState, pageNumToChange } = fetchStatsProps;
    setLoading(true);
    const params: Params = getUrlParams({ pageNumToChange, passedState });

    ajax.get('/stats/summary', { params }).then(({ data }: AxiosResponse<SummaryReportData>) => {
      if (data) {
        setStats(data.stats);
        setTotals(data.totals);
        setTimeFrame(data.timeframe);
        setTotalItems(data.total_items);
        setCsvData(widgetsCSV.prepareCSV(data.stats, data.totals));
        setCsvName(widgetsCSV.prepareCSVName(currentSiteName));
        setPageN(pageNumToChange || pageN);
        setSortField(passedState?.sortField || sortField);
        setSortOrder(passedState?.sortOrder || sortOrder);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const changePageNumber = (pageNumToChange: number): void => {
    fetchStats({ pageNumToChange });
  };

  useEffect(() => {
    fetchStats();
    metrics.metricEvent('hit', '/reports/summary');
    getSendReportsStatus();
    window.addEventListener('resize', () => {
      setWindowResolution(window.innerWidth);
    }, true);
  }, []);

  useEffect(() => {
    fetchStats();
  }, [currentSite]);

  const { isChartsVisible, byHour }: GetChartInfo = chart.getChartInfo();

  return (
    <>
      <div className="wrapper wrapper--checkbox">
        <div className="wrapper--checkbox-inner">
          <Checkbox
            id="send-reports-checkbox"
            checked={sendReports}
            onChange={(event: BaseSyntheticEvent) => setSendReports(event.target.checked)}
          />
          <Tooltip
            content={(
              <span className="tooltip__text">
                Send a monthly revenue report for all sites to my email
              </span>
            )}
            placement={windowResolution > 600 ? 'right' : 'bottom'}
            maxWidth={400}
          >
            <label htmlFor="send-reports-checkbox" className="send-reports-label" tabIndex={-1}>
              Subscribe for a monthly report
            </label>
          </Tooltip>
        </div>
      </div>
      <div className="common-page">
        <div className="wrapper wrapper--dashboard wrapper--dashboard-summary">
          <div className="container-fluid">
            <PgFilter
              csvName={csvName}
              csvData={csvData}
              changeState={fetchStats}
              getPage={() => chart.getPage('summary')}
            />
            {isChartsVisible && (
              <SummaryGraff
                timeframe={timeFrame}
                byHour={byHour}
              />
            )}
          </div>
        </div>
        <PgTablePager
          pagerName="summaryTableSize"
          totalItems={totalItems}
          pageN={pageN}
          changePageN={changePageNumber}
        />
        <SummaryPgTable
          statsData={stats}
          totalsData={totals}
          totalItems={totalItems}
          changeState={fetchStats}
          isLoading={loading}
        />
        <PgTablePager
          pagerName="summaryTableSize"
          totalItems={totalItems}
          pageN={pageN}
          changePageN={changePageNumber}
        />
      </div>
    </>
  );
};

export default SummaryReport;
