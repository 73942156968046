export const get = (chartData) => {
  const heightValue = window.innerWidth <= 768 ? 150 : 130;
  const type = chartData.type === 'date' || chartData.type === 'hour' ? 'line' : 'column';
  return {
    chart: {
      height: heightValue,
      width: null,
      marginLeft: 45, // Keep all charts left aligned
      spacingTop: 0,
      spacingBottom: 22,
      type,
    },
    title: {
      useHTML: true,
      text: chartData.title,
      align: 'left',
      margin: 0,
      x: 35,
      y: 10,
      style: {
        fontSize: '12px',
        fontFamily: "'Roboto', Helvetica, Arial, sans-serif",
      },
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        marker: {
          radius: 3,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

export default { get };
