import { FeedWidget, WidgetConfig } from 'app/ts/interfaces';
import { logo, defaultTeasers } from './config/constructor';

import { Logo } from './ts/types/constructor';

export default {
  showBlock(params: FeedWidget): string {
    const { tpl, settings: { config } } = params;
    const getConfig = (type: string): string => String(config ? config[type as keyof WidgetConfig] : '');

    if (config && Object.keys(config).length) {
      let styles: string = tpl.css;
      // заменяем макросы
      for (const prop in config) {
        if (prop) {
          const re: RegExp = new RegExp(prop, 'g');
          styles = styles.replace(re, String(config[prop as keyof WidgetConfig]));
        }
      }

      const logoUrl: string = getConfig('%LOGO_URL%') || logo[getConfig('%LOGO_STYLE%') as keyof Logo];
      const pos: Array<string> | string = getConfig('%LOGO_POSITION%') && getConfig('%LOGO_POSITION%').split('-');

      if (pos && pos.length > 0) {
        styles = styles
          .replace('%LOGO_POSITION_VERTICAL%', pos[0])
          .replace('%LOGO_POSITION_HORIZONTAL%', pos[1])
          .replace('%LOGO_URL%', logoUrl);
      }

      const paramsId: string = params.id ? params.id.toString() : '';

      styles = styles
        .replace(/%COLS%/g, String(params.settings.cols))
        .replace(/%ADP_ID%/g, paramsId);

      let block: string = `<style>${styles}</style>`;

      block += tpl.header;

      // Добавляем ячейки и заменяем макросы
      params.settings.cells.map((type: string) => {
        let imgW: number;
        let imgH: number;
        let img: string = '';
        if (tpl.item.match(/%IMG(.*)%/g)) {
          const { item } = tpl;
          [img] = item.match(/%IMG(.*)%/g) || [];
          if (getConfig('%IMAGE_WIDTH%').replace(/[^-0-9]/gim, '')
            && getConfig('%IMAGE_HEIGHT%').replace(/[^-0-9]/gim, '')) {
            imgW = Number(getConfig('%IMAGE_WIDTH%').replace(/[^-0-9]/gim, ''));
            imgH = Number(getConfig('%IMAGE_HEIGHT%').replace(/[^-0-9]/gim, ''));
          } else {
            imgW = img ? Number(img.slice(5, -1).split('x')[0]) : 300;
            imgH = img ? Number(img.slice(5, -1).split('x')[1]) : 300;
          }
        }

        const createSvgUrl = (imgWidth: number = 300, imgHeight: number = 250) => {
          const svg = (`
           <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 ${imgWidth} ${imgHeight}">
               <rect width="${imgWidth}" height="${imgHeight}" fill="#c8c8c8" />
               <text font-family="Arial, Helvetica, sans-serif" x="50%" y="50%" text-anchor="middle"
                alignment-baseline="central" dominant-baseline="central" font-size="${imgWidth / 8}"
                textLength="${imgWidth / 2.5}"
                lengthAdjust="spacingAndGlyphs" style="fill:#9D9D9D;">
                  ${imgWidth} x ${imgHeight}
               </text>
           </svg>
           `);
          const blob: Blob = new Blob([svg], { type: 'image/svg+xml' });
          return URL.createObjectURL(blob);
        };

        const teaserData: {
          title: string,
          announce: string,
        } = defaultTeasers[Math.floor(Math.random() * defaultTeasers.length)];
        let src: string = createSvgUrl(imgW, imgH);
        let cell: string = tpl.item
          .replace('%CELL_TYPE%', type)
          .replace('%CELL_RTYPE%', type)
          .replace('%TITLE%', teaserData.title)
          .replace('%ANNOUNCE%', teaserData.announce)
          .replace('%IMAGE_POSITION%', getConfig('%IMAGE_POSITION%'))
          .replace('%URL%', '#')
          .replace('%TARGET%', type === 'ae' ? '_top' : '_blank')
          .replace(new RegExp(img || '%IMAGE%', 'g'), src);

        if (/cc([0-9]+)/.test(type)) {
          src = createSvgUrl();
          cell = tpl.item.replace(/<a([^])*a>/, `<img style="width: 100%" src="${src}">`);
        }
        return block += cell;
      });

      const logoDisplay: string = (config['%LOGO%'] === 'block' ? 'visible' : 'hidden');

      block += tpl.footer;

      block = block
        .replace(/%ADP_ID%/g, String(params.id))
        .replace(/%COLS%/g, String(params.settings.cols))
        .replace(/%ROWS%/g, String(params.settings.cells.length / Number(params.settings.cols)))
        .replace(/%LOGO%/g, logoDisplay)
        .replace(/%LOGO_STYLE%/g, getConfig('%LOGO_STYLE%'))
        .replace(/%LOGO_POSITION%/g, getConfig('%LOGO_POSITION%'));

      // Хак для отсутствия LOGO_POSITION
      if (config['%LOGO_POSITION%']) {
        block
          .replace(
            '%LOGO_POSITION_VERTICAL%',
            config['%LOGO_POSITION_VERTICAL%'] || config['%LOGO_POSITION%'].split('-')[0] || 'bottom',
          )
          .replace(
            '%LOGO_POSITION_HORIZONTAL%',
            config['%LOGO_POSITION_HORIZONTAL%'] || config['%LOGO_POSITION%'].split('-')[0] || 'right',
          );
      }

      return block;
    }

    return '';
  },
};
