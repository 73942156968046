/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon, Indicator, IndicatorTooltip } from 'components/Table';
import { format as formatUtils } from 'utils';
import { pick } from 'lodash';

export default (overrideProps, totals) => ({
  accessor: 'totalPageViews',
  resizable: false,
  Header: (
    <FormattedMessage id="table.pageViewsGiraff">
      {txt => <p className="cell-inner">{SortIcon} {txt}</p>}
    </FormattedMessage>
  ),
  Footer: (
    <span
      className="cell-inner"
      title={formatUtils.integer(totals.engagementPageViews + totals.revenuePageViews + totals.audiencePageViews)}
    >
      {formatUtils.integer(totals.engagementPageViews + totals.revenuePageViews + totals.audiencePageViews)}
    </span>
  ),
  Cell: props => (
    <div className="cell-inner uikit-table--indicator-holder" title={formatUtils.integer(props.value)}>
      <IndicatorTooltip {...pick(props.original, ['engagementPageViews', 'revenuePageViews', 'audiencePageViews'])} />
      <span>{formatUtils.integer(props.value)}</span>
      <Indicator {...pick(props.original, ['engagementPageViews', 'revenuePageViews', 'audiencePageViews'])} />
    </div>
  ),
  ...overrideProps,
});
