import { DetectMob } from 'app/ts/types/utils';

export const detectMob: DetectMob = () => {
  const { navigator } = window; // eslint-disable-line
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};

export default { detectMob };
