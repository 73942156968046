import React, { ReactElement } from 'react';
import config from 'config';

import { GraffProps, GraffLegend } from 'app/ts/types/AuthorsSocialIntervalGraff';

import Charts from '../default/Charts';

const AuthorsContentIntervalGraff = (props: GraffProps): ReactElement => {
  const { timeframe, byHour } = props;
  if (!timeframe?.length) {
    return null;
  }

  const legend: Array<GraffLegend> = [
    {
      title: 'Pageviews',
      field: 'pageviews',
      color: '#ae80ff',
      valueDecimals: 0,
      disabled: true,
    },
    {
      title: 'Reading depth (%)',
      field: 'reading_depth',
      color: '#00db71',
      suffix: '%',
    },
    {
      title: 'Average reading time (min)',
      field: 'pv_time',
      color: '#ff6363',
      suffix: '&thinsp;min',
    },
    {
      title: `RPM (${config.currency})`,
      field: 'rpm',
      color: '#ffc400',
      suffix: `&thinsp;${config.currency}`,
      disabled: true,
    },
    {
      title: `Revenue from Giraff.io (${config.currency})`,
      field: 'revenue',
      color: '#22ddff',
      suffix: `&thinsp;${config.currency}`,
      disabled: true,
    },
  ];

  return (
    <Charts
      timeframe={timeframe}
      byHour={byHour}
      id="AuthorsContentInterval"
      legend={legend}
      groups={[]}
      pageType="date"
    />
  );
};

export default AuthorsContentIntervalGraff;
