import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const GiraffLogo = ({ inverse }) => (<span className={cx('giraff-logo', { inverse })} />);

GiraffLogo.propTypes = {
  inverse: PropTypes.bool,
};

GiraffLogo.defaultProps = {
  inverse: false,
};

export default GiraffLogo;
