import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'uikit';
import { format } from 'utils';
import config from 'config';

const HeaderRevenueInfo = ({ title, tooltip, value, offset }) => (
  <Tooltip
    content={(
      <span className="tooltip__text">
        {tooltip}
      </span>
        )}
    offset={offset}
  >
    <div className="total-balance__text" tabIndex="-1">
      <span className="balance__text--gray">{title}</span>
      <span className="balance__text--default">
        {`${format.float(value, 2)} ${config.currency}`}
      </span>
    </div>
  </Tooltip>
);

HeaderRevenueInfo.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  offset: PropTypes.array,
};

HeaderRevenueInfo.defaultProps = {
  offset: [0, 5],
};

export default HeaderRevenueInfo;
