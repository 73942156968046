import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';

import { useAppSelector } from 'app/store/hooks';
import SummaryReport from 'app/pages/SummaryReport';
import ClientConstructor from './pages/ClientConstructor';
import ConstructorList from './pages/ConstructorList';
import Dashboard from './pages/Dashboard';
import AudienceEngagement from './pages/AudienceEngagement';
import NewAudience from './pages/NewAudience';
import Revenue from './pages/Revenue';
import Content from './pages/Content';
import Social from './pages/Social';
import Authors from './pages/Authors';
import Performance from './pages/PerformanceReport';
import ManageOwnContent from './pages/ManageOwnContent';
import ManageOthersContent from './pages/ManageOthersContent';
import { ExcludeUrls, ExcludeTitles } from './pages/ExcludeUrlsAndTitles';
import SetPerformance from './pages/SetPerformance';
import SetTimeLimits from './pages/SetTimeLimits';
import SiteSettings from './pages/SiteSettings';
import ABTests from './pages/ABTests';
import ExternalAds from './pages/ExternalAds';
import HtmlTags from './pages/HtmlTags';
import PaymentHistory from './pages/PaymentHistory';

const Constructor = lazy(() => import ('./pages/Constructor'));

const Layout = (props) => {
  const {
    isConstructorAllowed,
    isConstructorDevModeAllowed,
  } = useAppSelector(reduxState => reduxState.accountInfo);

  function getConstructor(routerProps) {
    if (isConstructorAllowed && isConstructorDevModeAllowed) {
      return <Constructor {...props} {...routerProps} />;
    }

    if (isConstructorAllowed && !isConstructorDevModeAllowed) {
      return <ClientConstructor {...props} {...routerProps} />;
    }

    return null;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/" exact>
          <Dashboard {...props} />
        </Route>
        <Route path="/reports/summary">
          <SummaryReport {...props} />
        </Route>
        <Route path="/reports/engagement">
          <AudienceEngagement {...props} />
        </Route>
        <Route path="/reports/new_audience">
          <NewAudience {...props} />
        </Route>
        <Route path="/reports/revenue">
          <Revenue {...props} />
        </Route>
        <Route path="/reports/editorial/content">
          <Content {...props} />
        </Route>
        <Route path="/reports/editorial/social">
          <Social {...props} />
        </Route>
        <Route path="/reports/editorial/authors">
          <Authors {...props} />
        </Route>
        <Route path="/reports/referrers/performance">
          <Performance {...props} />
        </Route>
        <Route path="/tools/manage_content/own">
          <ManageOwnContent {...props} />
        </Route>
        <Route path="/tools/manage_content/others">
          <ManageOthersContent {...props} />
        </Route>
        <Route path="/tools/exclude/urls">
          <ExcludeUrls {...props} />
        </Route>
        <Route path="/tools/exclude/titles">
          <ExcludeTitles {...props} />
        </Route>
        <Route path="/settings/performance">
          <SetPerformance {...props} />
        </Route>
        <Route path="/settings/time_limits">
          <SetTimeLimits {...props} />
        </Route>
        <Route
          path="/constructor/:id"
        >
          {(routerProps) => getConstructor(routerProps)}
        </Route>
        <Route path="/constructor">
          <ConstructorList {...props} />
        </Route>
        <Route path="/html_tags">
          <HtmlTags />
        </Route>
        <Route path="/site-settings">
          <SiteSettings />
        </Route>
        <Route path="/payment_history">
          <PaymentHistory {...props} />
        </Route>
        <Route path="/ab_tests">
          <ABTests {...props} />
        </Route>
        <Route path="/external_ads">
          <ExternalAds {...props} />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default Layout;

Layout.propTypes = {
  isConstructorAllowed: PropTypes.bool,
  isConstructorDevModeAllowed: PropTypes.bool,
};

Layout.defaultProps = {
  isConstructorAllowed: true,
  isConstructorDevModeAllowed: false,
};
