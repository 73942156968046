import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { format as formatUtils } from 'utils';
import './IndicatorTooltip.scss';
import { getPercentages } from './helper';

const IndicatorTooltip = ({ engagementPageViews, audiencePageViews, revenuePageViews }) => {
  const { engagementPercentage, audiencePercentage, revenuePercentage } =
    getPercentages({ engagementPageViews, audiencePageViews, revenuePageViews });
  return (
    <div className="tooltip-black2">
      <p className="tooltip-black2__paragraph">
        <FormattedMessage id="table.audienceEngagement">
          {txt => <span className="tooltip-black2__paragraph-name">{txt}:</span>}
        </FormattedMessage>
        <span className="tooltip-black2__paragraph-rel tooltip-black2__paragraph-rel--blue">
          {formatUtils.percentage(engagementPercentage)}
        </span>
        <span className="tooltip-black2__paragraph-abs">
          ({engagementPageViews})
        </span>
      </p>
      <p className="tooltip-black2__paragraph">
        <FormattedMessage id="table.newAudience">
          {txt => <span className="tooltip-black2__paragraph-name">{txt}:</span>}
        </FormattedMessage>
        <span className="tooltip-black2__paragraph-rel tooltip-black2__paragraph-rel--yellow">
          {formatUtils.percentage(audiencePercentage)}
        </span>
        <span className="tooltip-black2__paragraph-abs">
          ({audiencePageViews})
        </span>
      </p>
      <p className="tooltip-black2__paragraph">
        <FormattedMessage id="table.revenue">
          {txt => <span className="tooltip-black2__paragraph-name">{txt}:</span>}
        </FormattedMessage>
        <span className="tooltip-black2__paragraph-rel tooltip-black2__paragraph-rel--green">
          {formatUtils.percentage(revenuePercentage)}
        </span>
        <span className="tooltip-black2__paragraph-abs">
          ({revenuePageViews})
        </span>
      </p>
    </div>
  );
};

export default IndicatorTooltip;

IndicatorTooltip.propTypes = {
  engagementPageViews: PropTypes.number,
  audiencePageViews: PropTypes.number,
  revenuePageViews: PropTypes.number,
};

IndicatorTooltip.defaultProps = {
  engagementPageViews: 0,
  audiencePageViews: 0,
  revenuePageViews: 0,
};
