/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'components/Table';
import { format as formatUtils } from 'utils';
import cx from 'classnames';

export default (overrideProps, totals) => ({
  accessor: 'pageViews',
  resizable: false,
  Header: (
    <FormattedMessage id="table.revenueWidgetShows">
      {txt => (
        <p className="cell-inner">
          {SortIcon} {txt[0].split(' ').slice(0, 1).join(' ')}<br />{txt[0].split(' ').slice(1).join(' ')}
        </p>
      )}
    </FormattedMessage>
  ),
  Footer: (
    <span
      className={cx('cell-inner', { 'cell-inner__with-small': totals.pageViewsOfTotal !== undefined })}
      title={formatUtils.integer(totals.pageViews)}
    >
      {formatUtils.integer(totals.pageViews)}
      {totals.pageViewsOfTotal !== undefined && (
        <span className="cell-inner-small">{formatUtils.percentage(totals.pageViewsOfTotal)} of total</span>
      )}
    </span>
  ),
  Cell: props => (
    <span
      className={cx('cell-inner', { 'cell-inner__with-small': totals.pageViewsOfTotal !== undefined })}
      title={formatUtils.integer(props.value)}
    >
      {formatUtils.integer(props.value)}
      {totals.pageViewsOfTotal !== undefined && (
        <span className="cell-inner-small">
          {formatUtils.percentage(props.original.pageViewsOfTotal)} of total
        </span>
      )}
    </span>
  ),
  ...overrideProps,
});
